import { inject, Injectable } from '@angular/core';
import { CallType } from '@models/call-type';
import { type TechnicianViewModel } from '@models/cards/technician-map';
import { isScheduleEventCallInformation, type ScheduleEventInformation } from '@models/dashboard-event';
import { type TechnicianInformation } from '@models/technician';
import { EventsService } from '@services/live/events.service';
import { LocationsService } from '@services/live/locations.service';
import { StaticDataService } from '@services/static-data.service';
import { indexBy, notEmpty } from '@utility/array';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { BehaviorSubject, combineLatest, merge, of, type Observable } from 'rxjs';
import { map, publishReplay, refCount, share, switchMap, tap } from 'rxjs/operators';
import { type ScheduleEventInformationViewModel } from './../models/cards/technician-map';

@Injectable()
export class TechnicianMapService {
  private readonly staticDataService = inject(StaticDataService);
  private readonly eventsService = inject(EventsService);
  private readonly locationsService = inject(LocationsService);

  public techniciansById$: Observable<Record<string, TechnicianViewModel>>;

  public get selectedDate$(): Observable<Date | null> {
    return this._selectedDate$.asObservable();
  }

  public get selectedDate(): Date | null {
    return this._selectedDate$.getValue();
  }

  public get isFetching(): boolean {
    return Object.keys(this._fetchingStatus).length > 0;
  }

  private readonly _fetchingStatus: any = {};
  private readonly _selectedDate$ = new BehaviorSubject<Date | null>(null);
  private readonly _callsForSelectedDate$: Observable<ScheduleEventInformation[]>;
  private readonly _refreshGpsPositions$ = new BehaviorSubject<any>(null);

  constructor() {
    this.techniciansById$ = this.getTechniciansSource$();
    this._callsForSelectedDate$ = this.getCallsSource$();
  }

  public changeDate(date: Date): void {
    this._selectedDate$.next(date);
  }

  public refreshGpsPositions(): void {
    this._refreshGpsPositions$.next(null);
  }

  private getTechniciansSource$(): Observable<Record<number, TechnicianViewModel>> {
    return this.staticDataService.getTechnicians().pipe(
      map(technicians => technicians.filter(technician => technician.showOnMap)),
      switchMap(technicians => this.mapToTechnicianViewModels$(technicians)),
      map(technicians => indexBy(technicians, 'id')),
      publishReplay(1),
      refCount()
    );
  }

  private getCallsSource$(): Observable<ScheduleEventInformation[]> {
    // TODO cache values
    return merge(this._selectedDate$, this._refreshGpsPositions$).pipe(
      tap(() => (this._fetchingStatus.calls = true)), // is this an ok use of tap?
      switchMap(() => {
        const selectedDate = this.selectedDate;

        if (!selectedDate || !isValid(selectedDate)) {
          return of([]);
        }

        return this.eventsService.list(startOfDay(selectedDate), endOfDay(selectedDate)).pipe(
          map(events => events.filter(event => event.callDetails && event.callDetails.callType !== CallType.Blocked)),
          switchMap(events => this.getSiteGpsLocations$(events)),
          share()
        );
      }),
      tap(() => delete this._fetchingStatus.calls)
    );
  }

  private getSiteGpsLocations$(events: ScheduleEventInformation[] = []): Observable<ScheduleEventInformationViewModel[]> {
    if (events.length === 0) {
      return of([]);
    }

    const callEvents = events.map(m => (isScheduleEventCallInformation(m) ? m : null)).filter(notEmpty);

    return this.locationsService.getSiteLocations(Array.from(new Set(callEvents.map(event => event.callDetails.siteId)))).pipe(
      map(gpsLocationInformations => {
        const gpsLocationInformationsBySiteId = indexBy(gpsLocationInformations, 'siteId');

        return callEvents.map(event => {
          const clonedEvent = Object.assign({}, event) as ScheduleEventInformationViewModel;
          clonedEvent.callDetails.siteGpsLocation = gpsLocationInformationsBySiteId[event.callDetails.siteId];
          return clonedEvent;
        });
      })
    );
  }

  private mapToTechnicianViewModels$(technicians: TechnicianInformation[]): Observable<TechnicianViewModel[]> {
    return combineLatest([
      this._callsForSelectedDate$.pipe(map(calls => this.groupBy(calls, 'technicianId'))),
      this._refreshGpsPositions$.pipe(
        tap(() => (this._fetchingStatus.refreshPositions = true)),
        switchMap(() => {
          return this.locationsService.list().pipe(
            map(gpsPositions => indexBy(gpsPositions, 'technicianId')),
            publishReplay(1),
            refCount()
          );
        }),
        tap(() => delete this._fetchingStatus.refreshPositions)
      ),
    ]).pipe(
      map(([callsByTechnicianId, gpsPositionsByTechnicianId]) => {
        return technicians.map(technician => {
          return Object.assign({}, technician, {
            calls: (callsByTechnicianId[technician.id] || []).sort(this.sortCallsByDate),
            gpsPosition: gpsPositionsByTechnicianId[technician.id] || null,
          });
        });
      })
    );
  }

  private sortCallsByDate(call1: ScheduleEventInformationViewModel, call2: ScheduleEventInformationViewModel): -1 | 0 | 1 {
    if (call1.start < call2.start) {
      return -1;
    } else if (call1.start > call2.start) {
      return 1;
    }

    return 0;
  }

  private groupBy<T>(arr: T[], selector: string): any {
    const keys = selector.split('.');

    const indexed = arr.reduce((rv, x) => {
      const val = keys.reduce((obj, key) => obj[key], x);
      (rv[val] = rv[val] || []).push(x);
      return rv;
    }, {});

    return indexed;
  }
}
