import { Injectable, inject } from '@angular/core';
import { Router, type PreloadingStrategy, type Route } from '@angular/router';
import { of, type Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelectivePreloadingStrategyService implements PreloadingStrategy {
  private readonly router = inject(Router);

  preloadedModules: string[] = [];

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // We don't preload anything if we are about to pay with a customer account.
    if (this.router.url === '/payments' || this.router.url.startsWith('/payments/')) {
      return of(null);
    } else {
      if (environment.production) {
        if (route?.data?.preload !== 'payment') {
          return load();
        }
      }

      return of(null);
    }
  }
}
