import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { type ActionReducer, StoreModule } from '@ngrx/store';
import { saveReducerWithCode } from '@services/save-store/save.actions';
import { PriceBookDetailEffects } from './price-book-detail.effects';
import { reducer as priceBookDetailReducer } from './price-book-detail.reducer';
import { PRICE_BOOK_DETAIL_STORE_KEY } from './price-book-detail.selectors';

export function reducer(r: ActionReducer<any>): ActionReducer<any> {
  return saveReducerWithCode(PRICE_BOOK_DETAIL_STORE_KEY)(r);
}

export const metaReducers = [reducer];

@NgModule({
  imports: [
    StoreModule.forFeature(PRICE_BOOK_DETAIL_STORE_KEY, priceBookDetailReducer, { metaReducers }),
    EffectsModule.forFeature([PriceBookDetailEffects]),
  ],
})
export class PriceBookDetailStoreModule {}
