import { type CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { isSandbox } from '@utility/sandbox';

const sandboxAuthGuard =
  (sandbox: boolean): CanActivateFn =>
  route => {
    const allowed = isSandbox() === sandbox;
    if (!allowed) {
      return createUrlTreeFromSnapshot(route, ['/']);
    }

    return true;
  };

export const LiveOnlyAuthGuard = sandboxAuthGuard(false);
export const SandboxOnlyAuthGuard = sandboxAuthGuard(true);
