import { CdkPortalOutlet, ComponentPortal, PortalInjector, PortalModule } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  ViewChild,
  type ComponentRef,
  type OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogFilterDataInternal, FILTER_DIALOG_DATA, FILTER_DIALOG_REF, type DialogFilterImpl } from '@models/dialog';
import { FilterAction, type FilterResult } from '@models/filter-models';
import { SharedModule } from '@modules/shared.module';
import { SubscriptionManagerService } from '@services/ephemerals/subscription-manager.service';

/** @deprecated */
@Component({
  templateUrl: 'dialog-filter.component.html',
  styleUrls: ['dialog-filter.component.scss'],
  providers: [SubscriptionManagerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, PortalModule, MatDialogModule],
})
export class DialogFilterComponent<T extends DialogFilterImpl, D> implements OnInit {
  filterPortal: ComponentPortal<T>;

  @ViewChild('portal', { static: true })
  portal!: CdkPortalOutlet;

  get dialogFilterImpl(): DialogFilterImpl | null {
    const componentRef = this.portal.attachedRef as ComponentRef<T>;
    return componentRef?.instance || null;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<DialogFilterComponent<T, D>>,
    @Inject(MAT_DIALOG_DATA)
    private readonly dialogData: DialogFilterDataInternal<T, D>,
    injector: Injector,
    private readonly cd: ChangeDetectorRef
  ) {
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(FILTER_DIALOG_DATA, dialogData.data);
    injectionTokens.set(FILTER_DIALOG_REF, this);

    const portalInjector = new PortalInjector(injector, injectionTokens);
    this.filterPortal = new ComponentPortal(dialogData.component, null, portalInjector);
  }

  ngOnInit(): void {
    // Because we fetch the intakePortal later, we need to call this.
    this.cd.detectChanges();
  }

  close(): void {
    this.cancel();
  }

  cancel(): void {
    this.dialogRef.close({
      action: FilterAction.Cancel,
    } as FilterResult<D>);
  }

  addFilters(): void {
    this.dialogRef.close({
      action: FilterAction.AddFilter,
      result: this.dialogData.data,
    } as FilterResult<D>);
  }
}
