import { Component, inject, input } from '@angular/core';
import { PopoverHelpComponent } from '@dialogviews/popover-help.component';
import { DIALOG_SERVICE_IMPL, type PopoverRef } from '@models/dialog';
import { getObjectKeys } from '@utility/object';

@Component({
  template: `@if (text(); as text) {
    <span
      class="notes print-hide"
      data-id="icon-notes"
      #notes
      (mouseenter)="showHelp(getTruncatedNotes(text), notes, true)"
      (mouseleave)="hideHelp(notes.dataset.id)"
    >
      <img src="{{ icon() }}" alt="" />
    </span>
    <span class="print-show">{{ getTruncatedNotes(text) }}</span>
  }`,
  styles: `
    .notes {
      display: inline-flex;
      vertical-align: middle;
      width: 100%;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }
  `,
  standalone: true,
})
export class TableDynamicNodeNotesComponent {
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  readonly text = input<string | null>(null);
  readonly icon = input<string>('/assets/images/dashboard-card-notes.svg');

  private currentPopoverRef: Record<string, PopoverRef<unknown>> = {};

  getTruncatedNotes(notes: string): string {
    let truncatedNotes = notes.substring(0, 300);
    if (notes.length > 300) {
      truncatedNotes += '...';
    }

    return truncatedNotes;
  }

  private removeAllHelp(): void {
    getObjectKeys(this.currentPopoverRef).forEach(id => {
      this.currentPopoverRef[id].close();
    });
    this.currentPopoverRef = {};
  }

  showHelp(text: string, parent: HTMLElement, isNotes: boolean = false): void {
    this.removeAllHelp();
    this.currentPopoverRef[parent.dataset.id ?? ''] = PopoverHelpComponent.open(this.dialog2, parent, {
      text,
      isNotes,
    });
  }

  hideHelp(id: string | undefined): void {
    if (id && this.currentPopoverRef[id]) {
      this.currentPopoverRef[id].close();
      delete this.currentPopoverRef[id];
    }
  }
}
