import { type Creatable, type Updatable } from '@models/mutable';
import { type PartItemInformation } from '@models/price-book-models';
import { type Resource } from '@models/resource';
import { type Action, createReducer, on } from '@ngrx/store';
import { type SaveState } from '@services/save-store/save.actions';
import { clone } from '@utility/object';
import * as PartItemDetailActions from './part-item-detail.actions';

export type PartItemInformationState = PartItemInformation & Creatable & Updatable;
export type ServiceRepairState = { ids: { id: Id; isNew: boolean }[] } & Updatable;
export type PartItemCategoryIdState = { ids: [] } & Updatable;

export interface EntitiesState {
  partItem: PartItemInformationState;
  categoryIds: PartItemCategoryIdState;
  serviceRepairs: ServiceRepairState;
}

export interface PartItemDetailState extends SaveState {
  edit: boolean;
  entities: EntitiesState;
  entitiesSnapshot: EntitiesState | null;
}

const initialState: PartItemDetailState = {
  edit: false,
  entities: {
    partItem: null,
    categoryIds: null,
    serviceRepairs: null,
  },
  entitiesSnapshot: null,
  _submitCounter: 0,
  _error: null,
  _cacheCorrelationId: null,
  _correlationId: null,
};

const deleteReducer = function (stateResource: Record<number, Resource>, arg: { id: Id }) {
  return {
    [arg.id]: { ...stateResource[arg.id], deleted: true },
  };
};

const entityReducer = function (state: PartItemDetailState, key: keyof PartItemDetailState['entities'], value: any) {
  const obj = {};
  obj[key] = value;
  return { ...state, entities: { ...state.entities, ...obj } };
};

// Start: partItemDetailReducer
const partItemDetailReducer = createReducer(
  initialState,
  on(PartItemDetailActions.clear, (state, action) => {
    return clone(initialState);
  }),
  on(PartItemDetailActions.loadPartItem, (state, action) => entityReducer(state, 'partItem', action.data)),
  on(PartItemDetailActions.updatePartItem, (state, action) =>
    entityReducer(state, 'partItem', { ...state.entities.partItem, ...action.patch, updated: true })
  ),
  on(PartItemDetailActions.loadCategoryIds, (state, action) =>
    entityReducer(state, 'categoryIds', {
      updated: false,
      ids: action.data,
    })
  ),
  on(PartItemDetailActions.setCategoryIds, (state, action) =>
    entityReducer(state, 'categoryIds', {
      updated: true,
      ids: action.data.slice(0),
    })
  ),
  on(PartItemDetailActions.loadServiceRepairs, (state, action) =>
    entityReducer(state, 'serviceRepairs', { updated: false, ids: action.data.map(n => ({ id: n, isNew: false })) })
  ),
  on(PartItemDetailActions.addServiceRepair, (state, action) => {
    let ids = state.entities.serviceRepairs.ids;

    if (!ids.some(n => n.id === action.data)) {
      ids = ids.concat([{ id: action.data, isNew: true }]);
    }

    return entityReducer(state, 'serviceRepairs', { updated: true, ids });
  }),
  on(PartItemDetailActions.deleteServiceRepair, (state, action) =>
    entityReducer(state, 'serviceRepairs', { updated: true, ids: state.entities.serviceRepairs.ids.filter(n => n.id !== action.id) })
  ),
  on(PartItemDetailActions.savePartItemSuccess, (state, action) =>
    entityReducer(state, 'partItem', { ...state.entities.partItem, id: action.id, created: false, updated: false })
  ),
  on(PartItemDetailActions.saveCategoryIdsSuccess, (state, action) =>
    entityReducer(state, 'categoryIds', {
      updated: false,
      ids: state.entities.categoryIds.ids.slice(0),
    })
  ),
  on(PartItemDetailActions.saveServiceRepairSaveSuccess, (state, action) =>
    entityReducer(state, 'serviceRepairs', {
      updated: false,
      ids: state.entities.serviceRepairs.ids.map(n => ({ id: n.id, isNew: false })),
    })
  ),
  on(PartItemDetailActions.editMode, state => ({ ...state, entitiesSnapshot: clone(state.entities), edit: true })),
  on(PartItemDetailActions.cancel, state => ({
    ...state,
    entities: state.entitiesSnapshot,
    entitiesSnapshot: null,
    edit: false,
  }))
);

export function reducer(state: PartItemDetailState | undefined, action: Action) {
  return partItemDetailReducer(state, action);
}
