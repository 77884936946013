import { isValid } from 'date-fns';
import parse from 'date-fns/parse';

const longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
export function parseDateNicely(dateString: string, formatStr: string, referenceDate: number | Date): Date {
  function parseFormat(tryLong: boolean): { shouldTryLong: boolean; format: string } {
    let shouldTryLong = false;
    const format = formatStr
      .match(longFormattingTokensRegExp)
      ?.map(substring => {
        const firstCharacter = substring[0];

        if (firstCharacter === 'P') {
          // We try with yyyy, if it works great! otherwise we try with yy.
          shouldTryLong = true;
          return tryLong ? 'MM/dd/yyyy' : 'MM/dd/yy';
        }

        return substring;
      })
      .join('');

    return { shouldTryLong, format: format ?? '' };
  }

  const { shouldTryLong: finalTryLong, format: finalFormat } = parseFormat(false);

  let triedParsed = parse(dateString, finalFormat, referenceDate);
  if (!isValid(triedParsed) && finalTryLong) {
    triedParsed = parse(dateString, parseFormat(true).format, referenceDate);
  }

  return triedParsed;
}
