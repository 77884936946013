import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { type Entity } from '@models/entity';
import { type HitHighlights } from '@models/result';

@Pipe({
  name: 'entity',
  standalone: true,
})
@Injectable()
export class EntityPipe implements PipeTransform {
  transform(entity: Entity | null | undefined): string {
    if (!entity) {
      return '';
    }

    if (entity.company) {
      return entity.company;
    }

    const list: string[] = [];

    if (entity.lastName) {
      list.push(entity.lastName);
    }

    if (entity.firstName) {
      list.push(entity.firstName);
    }

    return list.join(', ');
  }

  transformWithHighlights(entity: Entity, highlights: HitHighlights): string {
    if (highlights) {
      if (highlights.CompanyName) {
        return highlights.CompanyName[0];
      } else if (highlights.ContactFirstName || highlights.ContactLastName) {
        const firstName = (highlights.ContactFirstName && highlights.ContactFirstName[0]) || entity.firstName;
        const lastName = (highlights.ContactLastName && highlights.ContactLastName[0]) || entity.lastName;

        return this.transform({ company: null, firstName, lastName });
      }
    }

    return this.transform(entity);
  }
}
