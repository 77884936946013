// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  opacity: 0;
}
:host.is-ready {
  opacity: 1;
}
:host.visible {
  display: inline-block;
}

.content {
  border-radius: 4px;
  border-radius: 4px;
  max-width: 500px;
}

:host.position-top .popover .content {
  margin-bottom: 8px;
}
:host.position-top .popover > .arrow {
  border-top-color: #232628;
  border-bottom-width: 0;
}
:host.position-top .popover > .arrow::after {
  margin-left: -7px;
  margin-top: -8px;
  content: " ";
  border-top-color: #232628;
  border-bottom-width: 0;
}
:host.position-top.is-light .popover > .arrow {
  border-top-color: #d1d5d8;
}
:host.position-top.is-light .popover > .arrow::after {
  border-top-color: #f1f2f3;
}

:host.position-right .popover .content {
  margin-left: 8px;
}
:host.position-right .popover > .arrow {
  border-right-color: #232628;
  border-left-width: 0;
}
:host.position-right .popover > .arrow::after {
  margin-left: 1px;
  margin-top: -7px;
  content: " ";
  border-right-color: #232628;
  border-left-width: 0;
}
:host.position-right.is-light .popover > .arrow {
  border-right-color: #d1d5d8;
}
:host.position-right.is-light .popover > .arrow::after {
  border-right-color: #f1f2f3;
}

:host.position-bottom .popover .content {
  margin-top: 8px;
}
:host.position-bottom .popover .arrow {
  border-top-width: 0;
  border-bottom-color: #232628;
}
:host.position-bottom .popover .arrow::after {
  margin-left: -7px;
  margin-top: 1px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #232628;
}
:host.position-bottom.is-light .popover .arrow {
  border-bottom-color: #d1d5d8;
}
:host.position-bottom.is-light .popover .arrow::after {
  border-bottom-color: #f1f2f3;
}

:host.position-left .popover .content {
  margin-right: 8px;
}
:host.position-left .popover .arrow {
  border-right-width: 0;
  border-left-color: #232628;
}
:host.position-left .popover .arrow::after {
  margin-left: -8px;
  margin-top: -7px;
  content: " ";
  border-right-width: 0;
  border-left-color: #232628;
}
:host.position-left.is-light .popover .arrow {
  border-left-color: #d1d5d8;
}
:host.position-left.is-light .popover .arrow::after {
  border-left-color: #f1f2f3;
}

.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover > .arrow {
  border-width: 8px;
}

.popover > .arrow:after {
  content: "";
  border-width: 7px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
