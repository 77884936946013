import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type Board } from '@models/board';
import {
  type SettingsLaborRateModel,
  type EmailSettings,
  type SettingCostRecoveryReport,
  type SettingCostRecoverySetting,
  type SettingCustomersViewData,
  type SettingIPadViewData,
  type SettingInvoicesViewData,
  type SettingSmsSettingsViewData,
  type SettingWorkOrdersViewData,
  type SmsSettings,
  type SettingPodiumViewData,
} from '@models/cards/setting-view-data';
import { EmailEventType, type EmailAddressState, type UnsubscribedEmailAddress } from '@models/email-models';
import { Direction, type ListParameters, type PaginatedList } from '@models/filter-models';
import { allowRetries, skipBubbleError } from '@utility/angular';
import { sort } from '@utility/observable';
import { type Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { type LocationSettings } from '../../models/cards/location-information';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { HttpClientService, type ExtraOptionsJson } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class SettingsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  // Uninflated rates.
  getLaborRates(): Observable<SettingsLaborRateModel> {
    return this.http.get<SettingsLaborRateModel>(this.url.settingsGetCurrentLaborRates);
  }

  saveLaborRates(laborRates: SettingsLaborRateModel): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveCurrentLaborRates, laborRates).pipe(
      tap(_ => {
        this.clearCache(UpdateCategory.LaborPricings);
        this.clearCache(UpdateCategory.ContractorSettings);
      }),
      share()
    );
  }

  getInvoices(): Observable<SettingInvoicesViewData> {
    return this.http.get<SettingInvoicesViewData>(this.url.settingsGetInvoices);
  }

  saveInvoices(settings: SettingInvoicesViewData): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveInvoices, { ...settings });
  }

  getWorkOrders(): Observable<SettingWorkOrdersViewData> {
    return this.http.get<SettingWorkOrdersViewData>(this.url.settingsGetWorkOrders);
  }

  saveWorkOrders(settings: Partial<SettingWorkOrdersViewData>): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveWorkOrders, { ...settings });
  }

  getCustomers(): Observable<SettingCustomersViewData> {
    return this.http.get<SettingCustomersViewData>(this.url.settingsGetCustomers);
  }

  saveCustomers(settings: Partial<SettingCustomersViewData>): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveCustomers, { ...settings });
  }

  getLocations(): Observable<LocationSettings> {
    return this.http.get<LocationSettings>(this.url.settingsGetLocation);
  }

  setLocations(settings: LocationSettings): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveLocation, { ...settings });
  }

  getSms(): Observable<SmsSettings> {
    return this.http.get<SmsSettings>(this.url.settingsGetSms);
  }

  setSms(settings: SmsSettings): Observable<void> {
    this.http.trimStringProperties(settings);

    return this.http.post<void>(this.url.settingsSaveSms, { ...settings });
  }

  getEmail(): Observable<EmailSettings> {
    return this.http.get<EmailSettings>(this.url.settingsEmailGet);
  }

  saveEmail(settings: EmailSettings): Observable<EmailSettings> {
    this.http.trimStringProperties(settings);

    return this.http.post<EmailSettings>(this.url.settingsEmailSave, { ...settings }, skipBubbleError());
  }

  deleteEmail(): Observable<void> {
    return this.http.delete<void>(this.url.settingsEmailDelete);
  }

  validateEmail(): Observable<{ valid: boolean }> {
    return this.http.post<{ valid: boolean }>(this.url.settingsEmailValidate, null);
  }

  patchEmail(settings: EmailSettings): Observable<void> {
    return this.http.patch<void>(this.url.settingsEmailPatch, { ...settings });
  }

  getEmailResults(): Observable<PaginatedList<EmailAddressState>> {
    return this.http.post<PaginatedList<EmailAddressState>>(
      this.url.settingsEmailGetResults,
      {
        orderBys: [
          {
            direction: Direction.Desc,
            field: 'date',
          },
        ],
        filterBys: {
          condition: 'or',
          rules: [
            {
              field: 'type',
              operator: 'in',
              value: [EmailEventType.Bounce, EmailEventType.Dropped],
            },
          ],
        },
      } as ListParameters<EmailAddressState>,
      allowRetries()
    );
  }

  getEmailUnsubscribes(offset: number, limit: number): Observable<UnsubscribedEmailAddress[]> {
    return this.http.get<UnsubscribedEmailAddress[]>(
      this.url.settingsEmailGetUnsubscribes.replace('$0', offset.toString()).replace('$1', limit.toString())
    );
  }

  deleteEmailUnsubscribes(email: string): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(email),
    };

    return this.http.delete<void>(this.url.settingsEmailDeleteUnsubscribes, options);
  }

  getSmsSettings(): Observable<SettingSmsSettingsViewData> {
    return this.http.get<SettingSmsSettingsViewData>(this.url.settingsGetSmsSettings);
  }

  saveSmsSettings(settings: Partial<SettingSmsSettingsViewData>): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveSmsSettings, { ...settings });
  }

  getIPad(): Observable<SettingIPadViewData> {
    return this.http.get<SettingIPadViewData>(this.url.settingsGetIPad);
  }

  saveIPad(settings: SettingIPadViewData): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveIPad, { ...settings }, skipBubbleError()).pipe(
      tap(_ => {
        this.clearCache(UpdateCategory.Contractors);
      }),
      share()
    );
  }

  getPodium(): Observable<SettingPodiumViewData> {
    return this.http.get<SettingPodiumViewData>(this.url.settingsGetPodium);
  }

  savePodium(settings: SettingPodiumViewData): Observable<void> {
    return this.http.post<void>(this.url.settingsSavePodium, { ...settings }, skipBubbleError());
  }

  getBoards(): Observable<Board[]> {
    return this.http.get<Board[]>(this.url.settingGetBoards).pipe(sort());
  }

  saveBoards(boards: Board[], extraOptions?: ExtraOptionsJson): Observable<Id[]> {
    return this.http.post<Id[]>(this.url.settingSaveBoards, boards, extraOptions);
  }

  getCostRecovery(): Observable<SettingCostRecoverySetting> {
    return this.http.get<SettingCostRecoverySetting>(this.url.settingsGetCostRecoverySettings);
  }

  getCostRecoveryReport(from: Date | null = null, to: Date | null = null): Observable<SettingCostRecoveryReport> {
    return this.http.get<SettingCostRecoveryReport>(
      this.url.settingsGetCostRecoveryReport.replace('$0', from ? from.toJSON() : '').replace('$1', to ? to.toJSON() : '')
    );
  }

  saveCostRecovery(activate: boolean): Observable<void> {
    return this.http.post<void>(this.url.settingsSaveCostRecovery.replace('$0', activate ? 'true' : 'false'), null);
  }

  deleteInvoiceFile(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.settingsDeleteInvoiceFile.replace('$0', id.toString()));
  }

  private clearCache(category: UpdateCategory): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(category);
    }
  }
}
