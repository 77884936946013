import { Pipe, type PipeTransform } from '@angular/core';
import { getObjectKeys } from '@utility/object';

@Pipe({
  name: 'kvp',
  standalone: true,
})
export class KvpPipe implements PipeTransform {
  transform(value: {}): any {
    const keys = [];
    for (const key of getObjectKeys(value)) {
      keys.push({ key, value: value[key] });
    }

    return keys;
  }
}
