import { Directive, EventEmitter, type OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[destroy]',
  standalone: true,
})
export class DestroyDirective implements OnDestroy {
  @Output() destroy = new EventEmitter<any>();

  ngOnDestroy(): void {
    this.destroy.emit();
  }
}
