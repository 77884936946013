import { Injectable, inject } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import { type SquareWorkOrderChargeConfirmation, type SquareWorkOrderChargeConfirmationError } from '@models/charges';
import { PaymentGateway } from '@models/payments';
import { type SubscriptionDetail } from '@models/subscriptions';
import { ConfigService } from '@services/config.service';
import { notEmpty, unique } from '@utility/array';
import { type PaymentGatewayHandler } from './payment-gateway-handler';

const cantUseError = 'We could not use this credit card. Try again.';
const invalidCardError = 'The provided card data is invalid.';
const unsupportedEntryError = 'This card must be swiped, tapped, or dipped.';
const cvvInvalidError = 'The CVV value is invalid.';
const authorizationError = 'The connection with Square ended. You have to re-enable it.';

const errorMessages: Record<string, Record<string, string>> = {
  AUTHENTICATION_ERROR: {
    UNAUTHORIZED: authorizationError,
  },
  V1_ERROR: {
    'service.not_authorized': authorizationError,
  },
  INVALID_REQUEST_ERROR: {
    NOT_FOUND: 'The card has not been found in Square. Have you deleted it?',
    INVALID_CARD_DATA: invalidCardError,
  },
  PAYMENT_METHOD_ERROR: {
    // Errors coming from ProcessCharge
    ADDRESS_VERIFICATION_FAILURE: 'The postal code is invalid.',
    ALLOWABLE_PIN_TRIES_EXCEEDED: 'Maximum PIN attempts exceeded. Card holder may need to contact card issuer.',
    BAD_EXPIRATION: 'The card expiration date is either missing or incorrectly formatted',
    CARDHOLDER_INSUFFICIENT_PERMISSIONS: 'The card issuer does not permit use for this type of transaction.',
    CARD_EXPIRED: 'The card is expired.',
    CARD_NOT_SUPPORTED: 'The card is not supported either in the geographic region or by the merchant category.',
    CHIP_INSERTION_REQUIRED: 'This card must be read using a chip reader.',
    CVV_FAILURE: cvvInvalidError,
    EXPIRATION_FAILURE: 'The expiration date entered is invalid or has lapsed.',
    GENERIC_DECLINE: 'Card declined due to an unexpected error.',
    INSUFFICIENT_FUNDS: 'There are insufficient funds to cover the payment.',
    INSUFFICIENT_PERMISSIONS: 'The Square account does not have the permissions to accept this payment.',
    INVALID_ACCOUNT: 'The card issuer was unable to locate the account.',
    INVALID_CARD_DATA: invalidCardError,
    INVALID_EMAIL_ADDRESS: 'The provided email address is invalid.',
    INVALID_EXPIRATION: 'The expiration date for the payment card is invalid.',
    INVALID_LOCATION: 'The Square account cannot take payments in the specified region.',
    INVALID_PIN: 'The PIN is invalid.',
    INVALID_POSTAL_CODE: 'The postal code is incorrectly formatted.',
    MANUALLY_ENTERED_PAYMENT_NOT_SUPPORTED: unsupportedEntryError,
    PAN_FAILURE: 'The specified card number is invalid.',
    PAYMENT_LIMIT_EXCEEDED: 'The payment amount exceeded the Square account processing limit.',
    TRANSACTION_LIMIT: 'The payment amount is either too high or too low for the card issuer.',
    VOICE_FAILURE: 'The card issuer requires voice authorization from the cardholder.',

    // Errors coming from AttachCreditCard
    CARD_PROCESSING_NOT_ENABLED: 'Your Square account is not allowed to process credit card.',
    CARD_TOKEN_EXPIRED: cantUseError,
    CARD_TOKEN_USED: cantUseError,
    INVALID_CARD: invalidCardError,
    UNSUPPORTED_ENTRY_METHOD: unsupportedEntryError,
    VERIFY_AVS_FAILURE: 'The address verification failed.',
    VERIFY_CVV_FAILURE: cvvInvalidError,
  },
};

@Injectable()
export class SquarePaymentGatewayHandlerService implements PaymentGatewayHandler {
  private readonly sanitizer = inject(DomSanitizer);
  private readonly configService = inject(ConfigService);

  paymentGateway = PaymentGateway.Square;

  hasCustomConfirmationMessage(): boolean {
    return true;
  }

  getErrorMessage(confirmation: string | null): string | null {
    if (confirmation) {
      try {
        const squareConfirmation = JSON.parse(confirmation) as SquareWorkOrderChargeConfirmation;
        return this.findErrorMessage(squareConfirmation.errors);
      } catch {}
    }

    return null;
  }

  getConfirmationMessage(confirmation: string): SafeHtml {
    const squareConfirmation = JSON.parse(confirmation) as SquareWorkOrderChargeConfirmation;
    const url = this.configService.config.square.transactionUrl.replace('$0', squareConfirmation.orderId);
    const isSuccess = this.isSuccess(squareConfirmation);
    const linkUrl = isSuccess ? 'link-blue.svg' : 'link-red.svg';

    if (isSuccess) {
      return this.sanitizer.bypassSecurityTrustHtml(`<a href="${url}" target="_blank" class="inline text-with-icon">
<img src="/assets/images/${linkUrl}" alt="">
<span>${squareConfirmation.orderId}</span>
</a>
`);
    }

    return this.sanitizer.bypassSecurityTrustHtml(`<div class="text-with-icon confirmation-error"
title="${this.findErrorMessage(squareConfirmation.errors)}">
<img src="/assets/images/${linkUrl}" alt="">
<span>${squareConfirmation.orderId}</span>
<img src="/assets/images/red-warning.svg" alt="">
</div>
`);
  }

  private findErrorMessage(errors: SquareWorkOrderChargeConfirmationError[]): string | null {
    if (errors && errors.length > 0) {
      const errorArray = unique(errors.map(m => errorMessages[m.category]?.[m.code]).filter(notEmpty));

      if (errorArray.length > 0) {
        return errorArray.join('\n');
      }

      return 'An error happened.';
    }

    return null;
  }

  private isSuccess(confirmation: SquareWorkOrderChargeConfirmation): boolean {
    // APPROVED is when paid from Mobile App, COMPLETED is once it's paid by the office.
    return confirmation.status === 'APPROVED' || confirmation.status === 'COMPLETED';
  }

  getSubscriptionInformation(subscriptionExtra: string): SubscriptionDetail | null {
    // Not supported yet.
    return null;
  }
}
