import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br',
  standalone: true,
})
export class Nl2BrPipe implements PipeTransform {
  transform(text: string, safe: boolean = false): string {
    if (!safe) {
      text = text?.replace(/</g, '&lt;');
    }

    return text?.replace(/(\r)?\n/g, '<br />');
  }
}
