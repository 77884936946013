import { type HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, type Subscriber } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { skipBubbleError } from '@utility/angular';

@Injectable()
export class UrlHelperService {
  private readonly httpClientService = inject(HttpClientService);

  get(url: string): Observable<{ blobUrl: string; blob: Blob | null }> {
    return new Observable((observer: Subscriber<{ blobUrl: string; blob: Blob | null }>) => {
      let objectUrl: string | null = null;

      this.httpClientService
        .get(url, {
          ...skipBubbleError(),
          responseType: 'blob',
        })
        .subscribe({
          next: m => {
            objectUrl = URL.createObjectURL(m);
            observer.next({ blobUrl: objectUrl, blob: m });
          },
          error: (m: HttpErrorResponse) => {
            if (m.status === 404) {
              observer.next({ blobUrl: '/assets/images/image-not-found.svg', blob: null });
            }
          },
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }
}
