import { type PartItemInformation, type ServiceRepairInformation } from '@models/price-book-models';
import { createAction, props } from '@ngrx/store';
import {
  createSubmitAction,
  createSubmitFailAction,
  createSubmitInitialAction,
  createSubmitSuccessAction,
} from '@services/save-store/save.actions';
import { SERVICE_REPAIR_DETAIL_STORE_KEY } from './service-repair-detail.selectors';

export const clear = createAction('[ServiceRepairDetail] clear');

export const loadServiceRepair = createAction('[ServiceRepairDetail] loadServiceRepair', props<{ data: ServiceRepairInformation }>());
export const updateServiceRepair = createAction(
  '[ServiceRepairDetail] updateServiceRepair',
  props<{ patch: Partial<ServiceRepairInformation> }>()
);

export const loadCategoryIds = createAction('[ServiceRepairDetail] loadCategoryIds', props<{ data: Id[] }>());
export const setCategoryIds = createAction('[ServiceRepairDetail] setCategoryIds', props<{ data: Id[] }>());

export const loadPartItems = createAction('[ServiceRepairDetail] loadPartItems', props<{ data: PartItemInformation[] }>());
export const addPartItem = createAction('[ServiceRepairDetail] addPartItem', props<{ data: PartItemInformation }>());
export const updatePartItem = createAction(
  '[ServiceRepairDetail] updatePartItem',
  props<{ id: Id; patch: Partial<PartItemInformation> }>()
);
export const deletePartItem = createAction('[ServiceRepairDetail] deletePartItem', props<{ id: Id }>());

export const save = createSubmitInitialAction(SERVICE_REPAIR_DETAIL_STORE_KEY, '[ServiceRepairDetail] save');

export const noop = createAction('[ServiceRepairDetail] noop');
export const noop2 = createAction('[ServiceRepairDetail] noop2', props<{ id: Id }>());
export const noopWithCounting = createSubmitSuccessAction(SERVICE_REPAIR_DETAIL_STORE_KEY, '[ServiceRepairDetail] noopWithCounting');

export const saveServiceRepairSuccess = createSubmitSuccessAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Success] ServiceRepair',
  props<{ serviceRepair: ServiceRepairInformation }>()
);
export const saveServiceRepairFail = createSubmitFailAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Fail] ServiceRepair',
  props<{ error: string }>()
);

export const saveCategoryIds = createSubmitAction(SERVICE_REPAIR_DETAIL_STORE_KEY, '[ServiceRepairDetail][Save] saveCategoryIds');
export const saveCategoryIdsSuccess = createSubmitSuccessAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Success] saveCategoryIds'
);
export const saveCategoryIdsFail = createSubmitFailAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Fail] saveCategoryIds',
  props<{ error: string }>()
);

export const savePartItemSave = createSubmitAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save] PartItem',
  props<{ id: Id }>()
);
export const savePartItemSaveSuccess = createSubmitSuccessAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Success] PartItem',
  props<{ oldId: Id; newId: Id }>()
);
export const savePartItemFail = createSubmitFailAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Fail] PartItem',
  props<{ error: string }>()
);

export const savePartItemServiceRepairsSave = createSubmitAction(SERVICE_REPAIR_DETAIL_STORE_KEY, '[ServiceRepairDetail][Save] PartItemSR');
export const savePartItemServiceServiceRepairsSaveSuccess = createSubmitSuccessAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Success] PartItemSR'
);
export const savePartItemServiceRepairsFail = createSubmitFailAction(
  SERVICE_REPAIR_DETAIL_STORE_KEY,
  '[ServiceRepairDetail][Save][Fail] PartItemSR',
  props<{ error: string }>()
);

export const editMode = createAction('[ServiceRepairDetail] edit');
export const cancel = createAction('[ServiceRepairDetail] cancel');

export type Actions = ReturnType<
  | typeof clear
  | typeof loadServiceRepair
  | typeof updateServiceRepair
  | typeof loadCategoryIds
  | typeof setCategoryIds
  | typeof save
  | typeof noop
  | typeof noop2
  | typeof saveServiceRepairSuccess
  | typeof saveServiceRepairFail
  | typeof saveCategoryIds
  | typeof saveCategoryIdsSuccess
  | typeof saveCategoryIdsFail
  | typeof savePartItemSave
  | typeof savePartItemSaveSuccess
  | typeof savePartItemFail
  | typeof savePartItemServiceRepairsSave
  | typeof savePartItemServiceServiceRepairsSaveSuccess
  | typeof savePartItemServiceRepairsFail
  | typeof editMode
  | typeof cancel
>;
