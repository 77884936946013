// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wm-pager-bubble * {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}

.wm-pager-bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a3aab1;
}
.wm-pager-bubble .text {
  display: flex;
  padding-top: 2px;
}
.wm-pager-bubble .value {
  font-size: 14px;
}
.wm-pager-bubble .bubble {
  min-width: 16px;
  border-radius: 4px;
  height: 8px;
  display: block;
  content: "";
  background-color: #d1d5d8;
}
.wm-pager-bubble.active {
  color: #246ee9;
}
.wm-pager-bubble.active .bubble {
  background-color: #246ee9;
}
.wm-pager-bubble.initialized .bubble {
  transition: width 0.35s ease-out;
}
.wm-pager-bubble .arrow {
  display: inline-block;
  background: none;
  border: 0;
  text-align: center;
}
.wm-pager-bubble .arrow img {
  width: 8px;
  height: 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
