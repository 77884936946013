import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { InputTextComponent } from '@controls/input-text/input-text.component';
import { RadioButtonComponent, RadioGroupDirective } from '@controls/radio/radio.component';
import { type DialogIntakeComponent } from '@dialogs/dialog-intake.component';
import { type Dialog2ServiceImpl, type DialogIntakeImpl, INTAKE_DIALOG_DATA_TYPED, buttonIds, buttons } from '@models/dialog';
import { SharedModule } from '@modules/shared.module';
import { type Observable, combineLatest, map, of } from 'rxjs';

export interface SegmentDynamicSaveData {
  /**
   * Indicates that we are potentially saving on the existing segment.
   */
  segmentId: Id | null;

  /**
   * The current name of the segment if we have a segmentId.
   */
  segmentName: string | null;

  /**
   * Saving method, so we can adapt the UI.
   */
  save$: (segmentId: Id | null, segmentName: string) => Observable<void>;
}

export interface SegmentDynamicSaveResult {
  /**
   * If the segmentId is null, then we create a new one.
   */
  segmentId: Id | null;

  /**
   * The name is mandatory. It's the segment name.
   */
  segmentName: string;
}

const injectionToken = INTAKE_DIALOG_DATA_TYPED<SegmentDynamicSaveData>();

const existingId = 0;
const createNewId = 1;

@Component({
  templateUrl: 'segment-dynamic-save.component.html',
  styleUrls: ['segment-dynamic-save.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RadioGroupDirective, RadioButtonComponent, InputTextComponent],
})
export class SegmentDynamicSaveComponent implements DialogIntakeImpl {
  readonly existingId = existingId;
  readonly createNewId = createNewId;

  private readonly dialogRef = inject(MatDialogRef<DialogIntakeComponent<SegmentDynamicSaveComponent>>);
  readonly data = inject(injectionToken);

  readonly createNew = signal(true);
  readonly segmentName = signal('');
  readonly isSaving = signal(false);

  private readonly createNew$ = toObservable(this.createNew);
  private readonly segmentName$ = toObservable(this.segmentName);
  private readonly isSaving$ = toObservable(this.isSaving);

  readonly option = new FormControl<number>(createNewId, { nonNullable: true });

  _ = effect(() => {
    if (this.data.segmentId) {
      this.option.patchValue(existingId);
    }
  });

  constructor() {
    this.option.valueChanges.pipe(takeUntilDestroyed()).subscribe(v => {
      this.createNew.set(v === createNewId);
    });
  }

  save(): void {
    this.isSaving.set(true);

    const createNew = this.createNew();
    const segmentId = createNew ? null : this.data.segmentId;
    const segmentName = createNew ? this.segmentName() : this.data.segmentName ?? '';
    const result: SegmentDynamicSaveResult = {
      segmentId,
      segmentName,
    };
    this.data.save$(segmentId, segmentName).subscribe({
      error: () => {},
      complete: () => {
        this.dialogRef.close(result);
      },
    });
  }

  getButtonDisabled$(id: string): Observable<boolean> {
    return combineLatest([this.isSaving$, this.createNew$, this.segmentName$]).pipe(
      map(([isSaving, createNew, segmentName]) => {
        if (isSaving) {
          return true;
        }

        if (id === buttonIds.save) {
          return createNew && !segmentName.trim();
        }

        return false;
      })
    );
  }

  getButtonText$(id: string): Observable<string> {
    if (id === buttonIds.cancel) {
      return of('Cancel');
    }

    if (this.createNew()) {
      return of('Save New List');
    }

    return of('Save List');
  }

  static open(dialog: Dialog2ServiceImpl, data: SegmentDynamicSaveData) {
    return dialog
      .intake<SegmentDynamicSaveComponent, SegmentDynamicSaveData, SegmentDynamicSaveResult | null>(SegmentDynamicSaveComponent, {
        intake: {
          title: 'Save List',
          buttons: buttons.confirmButtons({
            withCancel: true,
            text: 'Save List',
          }),
          intakeData: {
            injectionToken,
            data,
          },
        },
      })
      .afterClosed();
  }
}
