import { HttpContextToken, HttpErrorResponse, HttpHeaders, type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AppResponseError, ErrorCode, SimpleErrorMessage } from '@models/error-models';
import { TimeoutError, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../error.service';
import { ErrorLoggingService, networkErrors } from '@services/error-logging.service';
import { UrlService } from '@services/url.service';

export const SKIP_ERROR_HANDLING = new HttpContextToken<boolean>(() => false);

export const ErrorInterceptor: HttpInterceptorFn = (
  req,
  next,
  errorService = inject(ErrorService),
  errorLoggingService = inject(ErrorLoggingService),
  urlService = inject(UrlService)
) => {
  const logAndThrowError = (error: AppResponseError<unknown>) => {
    errorService.trigger(error);
    errorLoggingService.logError(error.response);

    return throwError(() => error.response);
  };

  const ignoreError = (err: HttpErrorResponse) => {
    if (req.context.get(SKIP_ERROR_HANDLING) && !networkErrors.includes(err.status)) {
      return true;
    }

    if (req.url.endsWith(urlService.errorLog)) {
      return true;
    }

    return false;
  };

  return next(req).pipe(
    catchError((err: Error) => {
      if (err instanceof TimeoutError) {
        const error = new AppResponseError();
        error.response = {
          message: 'Our server has not responded in time, check your connection and try again. If the problem persists, contact support.',
          status: 0,
          statusText: '',
          name: 'HttpErrorResponse',
          url: req.url,
          error: 'TimeoutError',
          ok: false,
          type: 2,
          headers: new HttpHeaders(),
        };

        error.url = error.response.url;
        error.payload = error.response.message;
        error.code = ErrorCode.Simple;

        console.error('Request timed out.', error, err);

        return logAndThrowError(error);
      }

      if (err instanceof HttpErrorResponse) {
        if (ignoreError(err)) {
          return throwError(() => err);
        }

        console.error('Request failed.', err);

        if (networkErrors.includes(err.status)) {
          // We are not connected on the internet!
          // We can't report the error, let's just tell the user.
          let errorMessage = 'We lost the connection to our server. ';
          errorMessage += 'Please check your internet connection and refresh this page.';

          const error = new SimpleErrorMessage(errorMessage);

          errorService.trigger(error);
          return throwError(() => err);
        } else {
          const error = new AppResponseError();
          error.response = err;
          error.url = err.url;

          const body = err.error || err.message;
          if (typeof body === 'string') {
            error.payload = body;
            error.code = ErrorCode.Simple;
          } else {
            error.payload = body.payload || body.detail || body.title;
            error.code = body.code ?? ErrorCode.Simple;
          }

          return logAndThrowError(error);
        }
      }

      return throwError(() => err);
    })
  );
};

