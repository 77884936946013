import { Injectable, inject, type OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationEnd, NavigationStart, Router, type Event, type Route } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ErrorMessage, type AppErrorHandler } from '../models/error-models';

export { ErrorMessage, type AppErrorHandler };

@Injectable()
export class CustomTitleService implements OnDestroy {
  private readonly router = inject(Router);
  private readonly titleService = inject(Title);

  private _map = new Map<Route, string>();
  private _title: string[] = [];

  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(o => {
      this.onNavigation(o);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clear(): void {
    this._map = new Map();
  }

  registerCustomTitle(forData: Route, title: string): void {
    this._map.set(forData, title);
  }

  onNavigation(event: Event): void {
    if (event instanceof NavigationStart) {
      this._title = [];
    } else if (event instanceof ActivationEnd) {
      // The data flows down the routes.
      // We will never want to display twice the same title in our array.
      let currentTitle = event.snapshot.data.title;
      if (event.snapshot.routeConfig && this._map.has(event.snapshot.routeConfig)) {
        currentTitle = this._map.get(event.snapshot.routeConfig);
      }

      if (currentTitle && this._title[this._title.length - 1] !== currentTitle) {
        this._title.push(currentTitle);
      }
    } else if (event instanceof NavigationEnd) {
      let finalTitle = this._title.length > 0 ? this._title.join(' - ') + ' - ' : '';
      finalTitle += '3C Connect';
      this.changeTitle(finalTitle);
    }
  }

  changeTitle(title: string): void {
    this.titleService.setTitle(title);
  }
}
