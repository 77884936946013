import { NgModule, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PriceBooksService } from '@services/live/price-books.service';
import { SalesProposalStoreModule } from '@services/sales-proposal/sales-proposal.store.module';
import { SaveStoreModule } from '@services/save-store/save.store.module';
import { WorkOrderStoreModule } from '@services/work-order/work-order.store.module';
import { environment } from '../../environments/environment';
import { DashboardStoreModule } from './dashboard/dashboard-store.module';
import { PartItemDetailStoreModule } from './part-item-detail/part-item-detail-store.module';
import { PriceBookDetailStoreModule } from './price-book-detail/price-book-detail-store.module';
import { ServiceRepairDetailStoreModule } from './service-repair-detail/service-repair-store.module';

@NgModule({
  imports: [
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionImmutability: false,
          strictStateImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    PriceBookDetailStoreModule,
    SalesProposalStoreModule,
    WorkOrderStoreModule,
    SaveStoreModule,
    ServiceRepairDetailStoreModule,
    PartItemDetailStoreModule,
    DashboardStoreModule,
  ],
  declarations: [],
  providers: [PriceBooksService],
})
export class AppStoreModule {}
