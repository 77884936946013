import { CommonModule } from '@angular/common';
import { Component, TemplateRef, contentChild, forwardRef, input, type Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputTextComponent } from '@controls/input-text/input-text.component';
import { Rw2Component } from '@controls/rw/rw2.component';
import { InitDirective } from '@directives/init.directive';
import { Mode } from '@models/form';

const RW_TEXT_COMPONENT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RwText2Component),
  multi: true,
};

// Angular 18
@Component({
  selector: 'wm-rw-text2',
  templateUrl: 'rw-text2.component.html',
  styleUrls: ['rw-text.component.scss'],
  providers: [RW_TEXT_COMPONENT_VALUE_ACCESSOR],
  exportAs: 'rwText',
  host: {
    '[class.rw]': 'true',
  },
  standalone: true,
  imports: [CommonModule, InitDirective, ReactiveFormsModule, Rw2Component, InputTextComponent],
})
export class RwText2Component extends ElementBaseWithFormControl<string | number | null> {
  readonly Mode = Mode;

  readonly name = input<string>();
  readonly mode = input(Mode.Read);
  readonly required = input(false);
  readonly helper = input<string>();
  readonly maxLength = input<number>();
  readonly type = input('text');
  readonly spellCheck = input(false);
  readonly placeholder = input('');
  readonly autoComplete = input<string>();

  readonly writeTemplate2 = contentChild('writeTemplate', { read: TemplateRef });
  readonly readTemplate2 = contentChild('readTemplate', { read: TemplateRef });
  readonly helperTemplate2 = contentChild('helperTemplate', { read: TemplateRef });

  defaultInput?: InputTextComponent;

  constructor() {
    super(() => null);
  }

  focus(): void {
    this.defaultInput?.focus();
  }
}
