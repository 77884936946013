import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { type SiteSystemWording } from '@models/cards/site-system-information';

@Pipe({
  name: 'siteSystemWording',
  standalone: true,
})
@Injectable()
export class SiteSystemWordingPipe implements PipeTransform {
  transform(siteSystemData: { wording: SiteSystemWording } | undefined | null, code: keyof SiteSystemWording = 'singular'): string {
    return siteSystemData?.wording[code] ?? '';
  }
}
