import { HttpContextToken, type HttpInterceptorFn, type HttpErrorResponse } from '@angular/common/http';
import { type Observable, throwError, timer } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// Max retry delay is 32s
export const DEFAULT_RETRY_COUNT = 5;
const REQUEST_TIMEOUT = environment.requestTimeout;

export const ALLOW_RETRIES = new HttpContextToken<number>(() => 0);

export const RetryInterceptor: HttpInterceptorFn = (req, next) => {
  const requestedRetryCount = req.context.get(ALLOW_RETRIES);
  const isRetryable = (status?: number) =>
    (requestedRetryCount || req.method.toLowerCase() === 'get') &&
    (!status || [408, 422, 423, 425, 426, 429, 451].includes(status) || status >= 499);

  return next(req).pipe(
    timeout(REQUEST_TIMEOUT),
    retry({
      count: requestedRetryCount,
      delay: (error: HttpErrorResponse, retryCount: number): Observable<number> => {
        if (isRetryable(error.status)) {
          return timer(1000 * Math.pow(2, retryCount - 1));
        }

        return throwError(() => error);
      },
    })
  );
};
