import { Directive, Input } from '@angular/core';
import { type FilterInfo } from '@models/filter-models';

/** @deprecated */
@Directive()
export class FilterComponent {
  private _filters: FilterInfo[] | null;

  @Input()
  get filters(): FilterInfo[] {
    return this._filters;
  }

  set filters(value: FilterInfo[] | null) {
    this._filters = value;
    this.filtersChanged();
  }

  filtersChanged() {
    // abstract
  }

  getValue(filterName: string, predicate?: (filterInfo: FilterInfo) => any): any | null {
    if (this.filters) {
      const filter = this.filters.find(m => m.id === filterName);
      if (filter) {
        if (predicate) {
          return predicate(filter);
        }

        return filter.value;
      }
    }

    return null;
  }

  setValue(filterName: string, display: string | null, value: any): void {
    if (!this.filters) {
      this.filters = [];
    }

    let filter = this.filters.find(m => m.id === filterName);
    if (!filter) {
      filter = {
        id: filterName,
        text: display,
        value,
      };
      this.filters.push(filter);
    }

    filter.text = display;
    filter.value = value;
  }

  removeValue(filterName: string) {
    if (this.filters) {
      const filterIndex = this.filters.findIndex(m => m.id === filterName);

      if (filterIndex >= 0) {
        this.filters.splice(filterIndex, 1);
      }
    }
  }
}
