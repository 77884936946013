import { InjectionToken } from '@angular/core';
import { type Observable } from 'rxjs';

export const HELP_JSON_IMPL = new InjectionToken<HelpJsonImpl>('HELP_JSON_IMPL');

export interface HelpModelLink {
  type: 'external' | 'storylane';
  text: string;
  url: string;
}

export interface HelpModelSection {
  title: string;
  text?: string;
  links?: HelpModelLink[];
}

export interface HelpModelData {
  locations: string[];
  sections: HelpModelSection[];
}

export interface HelpModel {
  displayLocations?: boolean;
  help: HelpModelData[];
}

export interface HelpJsonImpl {
  getHelpId: (page: string, section: string) => string;
  getJson$: () => Observable<HelpModel | null>;
  getHelpData$: (helpId: string | null | undefined) => Observable<HelpModelData | undefined>;
}

