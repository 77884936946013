import { CommonModule } from '@angular/common';
import { Component, computed, forwardRef, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { TagComponent } from '@controls/tag/tag.component';
import { NgOptionHighlightModule } from '@directives/ng-option-highlight.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { indexBy } from '@utility/array';
import { unfreezeIfNecessary } from '@utility/object';

const noop = (): void => {};

const INPUT_DROPDOWN_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputDropdownComponent),
  multi: true,
};

const blankValue = '__BLANK__';

type Option = any;

// Angular 18
@Component({
  selector: 'wm-input-dropdown',
  templateUrl: 'input-dropdown.component.html',
  styleUrls: ['input-dropdown.component.scss'],
  providers: [INPUT_DROPDOWN_COMPONENT_VALUE_ACCESSOR],
  exportAs: 'wmDropdown',
  standalone: true,
  host: {
    '[class.is-docked]': 'docked()',
  },
  imports: [CommonModule, NgOptionHighlightModule, FormsModule, NgSelectModule, TagComponent],
})
export class InputDropdownComponent implements ControlValueAccessor {
  readonly docked = input(false);
  readonly changeEmptyTo = input<any | null>(null);
  readonly bindValue = input('id');
  readonly bindLabel = input('text');
  readonly options = input<Option[]>([]);
  readonly multiselect = input(false);
  readonly placeholder = input<string | undefined>(undefined);
  readonly optgroupField = input<string | undefined>(undefined);
  readonly searchFn = input<((item: string, term: Option) => boolean) | undefined>(undefined);
  readonly optgroups = input<{ value: string; label: string }[] | undefined>(undefined);
  readonly helper = input<string | undefined>(undefined); // Not used
  readonly allowCreate = input<boolean | ((input: any) => unknown | null)>(false);
  readonly clearable = input(false);
  readonly dropdownParent = input<'body' | undefined>(undefined);
  readonly disabled = input(false);
  readonly closeOnSelection = input(false);

  readonly unfrozenOptions = computed(() => {
    return unfreezeIfNecessary(this.options());
  });

  readonly optgroupByValue = computed<Record<string, { value: string; label: string }> | null>(() => {
    const v = this.optgroups();
    if (v) {
      return indexBy(v, 'value');
    }

    return null;
  });

  keyIsNumber = false;
  keyIsBoolean = false;

  // The internal data model
  // TODO With Angular 17.3.2, if we change this to a signal it doesn't work.
  innerValue: any | null = null;

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    v ??= null;
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    value ??= null;
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  _handleChange(event: Event): void {
    this.value = (event.target as HTMLInputElement).value;
    this.onTouchedCallback();
  }

  assignValue(value: any): void {
    let newValue = value;
    if (newValue === blankValue) {
      newValue = this.changeEmptyTo();
    } else if (this.keyIsNumber) {
      if (this.multiselect()) {
        for (let i = 0; i < newValue.length; i++) {
          newValue[i] = parseInt((newValue as string[])[i], 10);
        }
      } else {
        if (newValue === '') {
          newValue = this.changeEmptyTo();
        } else {
          newValue = parseInt(newValue as string, 10);
        }
      }
    } else if (this.keyIsBoolean) {
      if (newValue === '') {
        newValue = false;
      } else {
        // Selectize transforms this into 1 or 0, so we have to transform it back to boolean.
        // eslint-disable-next-line eqeqeq
        newValue = newValue == '1';
      }
    }

    this.value = newValue;
  }
}
