import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import Decimal from 'decimal.js';
import { MoneyPipe } from './money.pipe';

@Pipe({
  name: 'cents',
  standalone: true,
})
@Injectable()
export class CentsPipe extends MoneyPipe implements PipeTransform {
  // Need to keep string|boolean to satisfy the CurrencyPipe
  transform(cents: Cents | null | undefined, hideSymbol?: string | boolean, hideDecimal?: string | boolean): string {
    const money = new Decimal(cents ?? 0).div(100).toNumber();
    return super.transform(money, hideSymbol, hideDecimal);
  }
}
