import { NgModule } from '@angular/core';
import { FilterDynamicCheckboxComponent } from './filter-dynamic-checkbox.component';
import { FilterDynamicDropdownComponent } from './filter-dynamic-dropdown.component';
import { FilterDynamicEasyDateComponent } from './filter-dynamic-easydate.component';
import { FilterDynamicMagicDateComponent } from './filter-dynamic-magicdate.component';
import { FilterDynamicTechnicianComponent } from './filter-dynamic-technician.component';
import { FilterDynamicTextComponent } from './filter-dynamic-text.component';

const controls = [
  FilterDynamicCheckboxComponent,
  FilterDynamicDropdownComponent,
  FilterDynamicEasyDateComponent,
  FilterDynamicMagicDateComponent,
  FilterDynamicTechnicianComponent,
  FilterDynamicTextComponent,
];

@NgModule({
  imports: [controls],
  exports: [controls],
})
export class FilterDynamicModule {}
