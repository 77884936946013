import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { InputImageComponent } from '@controls/input-image/input-image.component';
import { PagerComponent } from '@controls/pager/pager.component';
import { TableActionComponent } from '@controls/table-action/table-action.component';
import { TableDynamicComponent, type NoResult } from '@controls/table-dynamic/table-dynamic.component';
import { PopoverHelpComponent } from '@dialogviews/popover-help.component';
import { RowSelectDirective } from '@directives/table/row-select.directive';
import { TableDirective } from '@directives/table/table.directive';
import { type AgreementInformation } from '@models/cards/agreement-information';
import {
  AgreementSiteSystemSetupStatus,
  type AgreementSiteSystemInformation,
  type AgreementSiteSystemInformationForList,
} from '@models/cards/agreement-site-system-information';
import { DIALOG_SERVICE_IMPL, type PopoverRef } from '@models/dialog';
import { getStatusText as getDutyStatusText, type Status as DutyStatus } from '@models/duty-models';
import { Feature } from '@models/feature';
import { ConnectionType } from '@models/filter-models';
import { AddressPipe } from '@pipes/address.pipe';
import { AsyncSafePipe } from '@pipes/async-safe.pipe';
import { DeletedPipe } from '@pipes/deleted.pipe';
import { EntityPipe } from '@pipes/entity.pipe';
import { SiteSystemWordingPipe } from '@pipes/site-system-wording.pipe';
import { AgreementsService } from '@services/live/agreements.service';
import { FeaturesService } from '@services/live/features.service';
import { SiteSystemBehaviorService } from '@services/live/site-system-behavior.service';
import { defaultFilterTableData, type FilterTable, type FilterTable2 } from '@services/pager.service';
import { notEmpty } from '@utility/array';
import { addDays, addMonths, isAfter } from 'date-fns';
import { of, type Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

export const isNavigatable = (line: AgreementSiteSystemInformation): boolean => {
  return line.setupStatus !== AgreementSiteSystemSetupStatus.StandBy || (line.hidden ?? false);
};

export const isADate = (obj: any): boolean => {
  return obj instanceof Date;
};

export const getAgreementLinkName = (line: AgreementSiteSystemInformationForList): string | Date => {
  if (line.data.hidden) {
    return 'Deleted';
  }

  if (line.nextVisit) {
    return line.nextVisit;
  }

  if (line.data.setupStatus === AgreementSiteSystemSetupStatus.ReadyForSetup) {
    return 'Set Up';
  }

  if (line.data.setupStatus === AgreementSiteSystemSetupStatus.Activated) {
    return 'None. Depleted';
  }

  if (line.data.setupStatus === AgreementSiteSystemSetupStatus.Grandfathered) {
    return 'None. Grandfathered';
  }

  // This should not happen.
  return `Agreement #${line.data.localId}`;
};

export const findAgreement$ = (agreementId: Id, agreementsService: AgreementsService): Observable<AgreementInformation | null> => {
  return agreementsService.list(agreementId).pipe(map(agreements => agreements.find(n => n.id === agreementId) ?? null));
};

export const getAgreementNameFromAgreements = (line: AgreementSiteSystemInformation, agreements: AgreementInformation[]): string => {
  const agreement = agreements.find(x => x.id === line.agreementId);
  return agreement?.text ?? '';
};

export const getAgreementName$ = (line: AgreementSiteSystemInformation, agreementsService: AgreementsService): Observable<string> => {
  return findAgreement$(line.agreementId, agreementsService).pipe(
    map(agreement => {
      return agreement?.text ?? '';
    })
  );
};

export const getVisitLeft = (line: AgreementSiteSystemInformationForList): number | null => {
  if (line.data.setupStatus === AgreementSiteSystemSetupStatus.ReadyForSetup) {
    return null;
  }

  return line.visitsLeft;
};

export const getAgreementBadgeUrl = (line: AgreementSiteSystemInformationForList): string => {
  return getAgreementOption(line).url;
};

export const getAgreementBadgeTitle = (line: AgreementSiteSystemInformationForList): string => {
  return getAgreementOption(line)?.title ?? '';
};

export const getEndDate = (line: AgreementSiteSystemInformationForList, agreements: AgreementInformation[]): Date | null => {
  if (line.data.endDate) {
    return line.data.endDate;
  }

  const agreement = agreements.find(x => x.id === line.data.agreementId);
  if (agreement) {
    return addMonths(line.data.createdDate, agreement.monthsValidFor);
  }

  return null;
};

export const getEndDate$ = (line: AgreementSiteSystemInformationForList, agreementsService: AgreementsService): Observable<Date | null> => {
  if (line.data.endDate) {
    return of(line.data.endDate);
  }

  return findAgreement$(line.data.agreementId, agreementsService).pipe(
    map(agreement => {
      if (agreement) {
        return addMonths(line.data.createdDate, agreement.monthsValidFor);
      }

      return null;
    })
  );
};

export const getDutyCompletionText = (status: DutyStatus): string => {
  return getDutyStatusText(status);
};

export const isRecurring$ = (line: AgreementSiteSystemInformationForList, agreementsService: AgreementsService): Observable<boolean> => {
  return findAgreement$(line.data.agreementId, agreementsService).pipe(
    map(agreement => {
      if (agreement) {
        return !!agreement.interval;
      }

      return false;
    })
  );
};

const getAgreementOption = (
  line: AgreementSiteSystemInformationForList
): {
  url: string;
  title: string | null;
} => {
  const deadDate = line.data.deadDate;
  if (deadDate) {
    const now = new Date();
    const datePlus30Days = addDays(new Date(), 30);
    if (isAfter(now, deadDate)) {
      return { url: '/assets/images/agreement-expired.svg', title: 'Expired' };
    } else if (isAfter(datePlus30Days, deadDate)) {
      return { url: '/assets/images/agreement-expiring.svg', title: 'Expiring' };
    }
  }

  return { url: '/assets/images/agreement-valid.svg', title: 'Current' };
};

// Angular 18
@Component({
  selector: 'wm-card-agreement-site-system-list',
  templateUrl: 'agreement-site-system-list.component.html',
  styleUrls: ['../card.component.scss', 'agreement-site-system-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TableActionComponent,
    TableDirective,
    RowSelectDirective,
    SiteSystemWordingPipe,
    RouterModule,
    AddressPipe,
    AsyncSafePipe,
    DeletedPipe,
    PagerComponent,
    EntityPipe,
    InputImageComponent,
    TableDynamicComponent,
  ],
})
export class AgreementSiteSystemListComponent {
  readonly ConnectionType = ConnectionType;

  private readonly featuresService = inject(FeaturesService);
  private readonly agreementsService = inject(AgreementsService);
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly siteSystemBehaviorService = inject(SiteSystemBehaviorService);

  /** @deprecated */
  readonly tableDeprecated = input<FilterTable<AgreementSiteSystemInformationForList>>(); // .required
  readonly table = input<FilterTable2<AgreementSiteSystemInformationForList>>(); // .required

  readonly showTotalCount = input(false);
  readonly printPage = input<string>();
  readonly helpPage = input<string>();
  readonly helpSection = input<string>();
  readonly asPageTitle = input(false);

  /** @deprecated */
  readonly betaFeatureOn = input(false);

  /** @deprecated */
  readonly tableData = toSignal(
    toObservable(this.tableDeprecated).pipe(
      filter(notEmpty),
      switchMap(x => x.data$)
    ),
    { initialValue: defaultFilterTableData<AgreementSiteSystemInformationForList>() }
  );

  readonly hasDeletedEntries = computed(() => this.tableData().pagedResult.results.some(m => m.data.hidden));

  readonly showDeleted = signal(false);
  readonly showDeletedInCog = input(false);

  readonly safeResults = computed(() =>
    this.tableData().pagedResult.results.filter(result => !this.showDeletedInCog() || !(result.data.hidden ?? false) || this.showDeleted())
  );

  /** @deprecated */
  readonly connectionTypes = model<ConnectionType[] | undefined>(undefined);

  readonly colspan = computed(() => 8 + (this.connectionTypes()?.length ?? 0) + (this.hasDeletedEntries() ? 1 : 0));

  readonly showingDuties: Record<number, boolean> = {};

  private currentPopoverRef: Record<string, PopoverRef<any>> = {};

  readonly siteSystemBehavior$ = this.siteSystemBehaviorService.getLocalizedSiteSystemBehavior();
  readonly displaySiteSystemData$ = this.featuresService.hasFeature$(Feature.BeltsAndFilters);

  readonly noResult: NoResult = {
    url: '/assets/images/assets/no-agreements.svg',
    text: "We didn't find any agreements.",
  };

  isADate(obj: any): boolean {
    return isADate(obj);
  }

  getAgreementLinkName(line: AgreementSiteSystemInformationForList): string | Date {
    return getAgreementLinkName(line);
  }

  getAgreementName$(line: AgreementSiteSystemInformation): Observable<string> {
    return getAgreementName$(line, this.agreementsService);
  }

  isSetupReady(line: AgreementSiteSystemInformation): boolean {
    return line.setupStatus === AgreementSiteSystemSetupStatus.ReadyForSetup && !line.hidden;
  }

  isDeleted(line: AgreementSiteSystemInformation): boolean {
    return line.hidden ?? false;
  }

  isNavigatable(line: AgreementSiteSystemInformation): boolean {
    return isNavigatable(line);
  }

  getVisitLeft(line: AgreementSiteSystemInformationForList): number | null {
    return getVisitLeft(line);
  }

  getAgreementBadgeUrl(line: AgreementSiteSystemInformationForList): string {
    return getAgreementBadgeUrl(line);
  }

  getAgreementBadgeTitle(line: AgreementSiteSystemInformationForList): string {
    return getAgreementBadgeTitle(line);
  }

  getEndDate$(line: AgreementSiteSystemInformationForList): Observable<Date | null> {
    return getEndDate$(line, this.agreementsService);
  }

  getDutyCompletionText(status: DutyStatus): string {
    return getDutyCompletionText(status);
  }

  hasConnectionType(connectionType: ConnectionType): boolean {
    return (this.connectionTypes()?.indexOf(connectionType) ?? -1) >= 0;
  }

  isRecurring$(line: AgreementSiteSystemInformationForList): Observable<boolean> {
    return isRecurring$(line, this.agreementsService);
  }

  getTruncatedNotes(notes: string): string {
    let truncatedNotes = notes.substring(0, 300);
    if (notes.length > 300) {
      truncatedNotes += '...';
    }

    return truncatedNotes;
  }

  showHelp(text: string, parent: HTMLElement, isNotes: boolean = false): void {
    this.removeAllHelp();
    this.currentPopoverRef[parent.dataset.id ?? ''] = PopoverHelpComponent.open(this.dialog2, parent, {
      text,
      isNotes,
    });
  }

  hideHelp(id: string | undefined): void {
    if (id && this.currentPopoverRef[id]) {
      this.currentPopoverRef[id].close();
      delete this.currentPopoverRef[id];
    }
  }

  removeAllHelp(): void {
    Object.keys(this.currentPopoverRef).forEach(id => {
      this.currentPopoverRef[id].close();
    });
    this.currentPopoverRef = {};
  }
}
