// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  display: block;
  position: relative;
}
:host .loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}
:host .loading .spinner {
  border: 2px solid #ffffff; /* Light grey */
  border-top: 2px solid #7ca8f2; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
}
:host .loading .spinner {
  margin-top: 2px;
  zoom: 6;
}
:host.has-data {
  border-radius: 4px;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
}
:host table {
  width: 100%;
  border-collapse: separate;
  border: 1px #d1d5d8 solid;
  border-radius: 4px;
  border-spacing: 0;
  background-color: #ffffff;
  table-layout: auto;
}
:host table ::ng-deep th,
:host table ::ng-deep td {
  border-right: 1px #d1d5d8 solid;
  border-bottom: 1px #d1d5d8 solid;
  color: #464b4f;
}
:host table ::ng-deep th:first-child {
  border-top-left-radius: 4px;
}
:host table ::ng-deep th:last-child {
  border-top-right-radius: 4px;
}
:host table ::ng-deep th:last-child,
:host table ::ng-deep td:last-child {
  border-right: 0;
}
:host table ::ng-deep tbody:last-child tr:last-child td,
:host table ::ng-deep tbody:last-child tr:last-child th {
  border-bottom: 0;
}
:host table ::ng-deep thead tr {
  line-height: 24px;
}
:host table ::ng-deep thead tr th {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 11px;
  text-align: left;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #f1f2f3;
  vertical-align: middle;
  line-height: 14px;
}
:host table ::ng-deep tbody tr:not(.no-hover):hover {
  background-color: rgba(36, 110, 233, 0.03);
}
:host table ::ng-deep tbody tr.row-selected {
  background-color: rgba(36, 110, 233, 0.17);
}
:host table ::ng-deep tbody tr.row-selected td:first-child {
  border-left: 4px solid #246ee9;
  padding-left: 12px;
}
:host table ::ng-deep tbody td {
  padding: 4px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 24px;
}
:host table ::ng-deep .row-drag {
  cursor: grab;
}
:host table ::ng-deep .row-drag button {
  line-height: 0;
  cursor: grab;
}
:host table ::ng-deep .row-drag button img {
  width: 16px;
  height: 16px;
}

:host.is-padding-disabled table ::ng-deep tbody td {
  padding: 0;
}

:host.is-fixed-layout table {
  table-layout: fixed;
}

:host.no-rows table {
  border-bottom: 0;
}

col.row-drag {
  width: 20px;
}
col.row-selection {
  width: 49px;
}
col.row-delete {
  width: 60px;
}

th.row-drag,
td.row-drag {
  min-width: 20px;
  width: 20px;
}

th.row-selection,
td.row-selection {
  min-width: 49px;
  width: 49px;
}

th.row-delete,
td.row-delete {
  min-width: 60px;
  width: 60px;
}

td.row-drag {
  text-align: center !important;
  line-height: 0 !important;
  vertical-align: middle !important;
}
td.row-drag > * {
  width: inherit !important;
}
td.row-selection {
  text-align: center !important;
  line-height: 0 !important;
  vertical-align: middle !important;
}
td.row-selection > * {
  width: inherit !important;
}
td.row-delete {
  vertical-align: top;
  text-align: center;
}
td.row-delete button {
  cursor: pointer;
  background: none;
  border: 0;
}
td.row-delete button img {
  width: 20px;
  height: 24px;
  display: block;
  margin: auto;
}

:host.with-header table {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
:host.with-header table ::ng-deep th:first-child {
  border-top-left-radius: 0 !important;
}
:host.with-header table ::ng-deep th:last-child {
  border-top-right-radius: 0 !important;
}

:host.with-footer table,
:host.has-row-add table {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[wm-table-footer] {
  border: 1px solid #d1d5d8;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

:host.with-footer [wm-table-footer] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[wm-table-add-row] {
  display: flex;
  min-height: 32px;
  padding: 4px 16px;
}
[wm-table-add-row] button {
  font-size: 12px;
}
[wm-table-add-row] button img {
  margin-right: 2px;
  width: 8px;
  height: 8px;
}

button[disabled] {
  opacity: 0.2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
