import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RwText2Component } from '@controls/rw-text/rw-text2.component';
import { type DialogIntakeComponent } from '@dialogs/dialog-intake.component';
import { INTAKE_DIALOG_DATA_TYPED, type DialogIntakeImpl, type Dialog2ServiceImpl, DialogType, buttons } from '@models/dialog';
import { Mode } from '@models/form';
import { SharedModule } from '@modules/shared.module';
import { BehaviorSubject, of, type Observable } from 'rxjs';

export interface EnerBankApplicantNameData {
  company: string | null;
  firstName: string | null;
  lastName: string | null;
  mainPhone: string | null;
  mobilePhone: string | null;
}

const injectionToken = INTAKE_DIALOG_DATA_TYPED<EnerBankApplicantNameData>();

@Component({
  selector: 'wm-ener-bank-applicant-name',
  templateUrl: 'ener-bank-applicant-name.component.html',
  styleUrls: ['ener-bank-applicant-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RwText2Component],
})
export class EnerBankApplicantNameComponent implements DialogIntakeImpl {
  readonly Mode = Mode;
  private readonly data = inject(injectionToken);
  private readonly dialogRef = inject(MatDialogRef<DialogIntakeComponent<EnerBankApplicantNameComponent>>);

  company: string | null = this.data.company;
  firstName: string | null = this.data.firstName;
  lastName: string | null = this.data.lastName;
  mainPhone: string | null = this.data.mainPhone;
  mobilePhone: string | null = this.data.mobilePhone;

  readonly disableSave$ = new BehaviorSubject<boolean>(true);

  save(): void {
    this.dialogRef.close({
      company: this.company,
      firstName: this.firstName,
      lastName: this.lastName,
      mainPhone: this.mainPhone,
      mobilePhone: this.mobilePhone,
    } satisfies EnerBankApplicantNameData);
  }

  internalModelChange() {
    if (!!this.firstName && !!this.lastName && !!this.mainPhone) {
      this.disableSave$.next(false);
    } else {
      this.disableSave$.next(true);
    }
  }

  getButtonDisabled$(id: string): Observable<boolean> {
    if (id === 'save') {
      return this.disableSave$;
    }

    return of(false);
  }

  static open(dialog: Dialog2ServiceImpl, data: EnerBankApplicantNameData) {
    return dialog
      .intake<EnerBankApplicantNameComponent, EnerBankApplicantNameData, EnerBankApplicantNameData>(EnerBankApplicantNameComponent, {
        disableClose: false,
        width: '400px',
        intake: {
          type: DialogType.Question,
          title: 'Customer Information',
          text: 'We need the first and last name for this customer to continue. We will save this data on the customer page.',
          buttons: buttons.confirmButtons({
            withCancel: true,
            text: 'Save Customer',
          }),
          intakeData: {
            injectionToken,
            data,
          },
          disableExit: true,
        },
      })
      .afterClosed();
  }
}
