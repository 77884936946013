export enum Feature {
  RoleAssignments = 1, // ALL
  CostRecoveryManager = 2, // ALL
  BoardManagement = 3, // ALL
  GpsLocationTracking = 4,
  EmailWhitelisting = 5,
  HelpCenter = 6, // ALL
  PhoneSupport = 7,
  HVACFieldDiagnosticVideos = 8, // ALL
  KPIDashboardReporting = 9,
  IncentiveTracking = 10,
  Leaderboards = 11,
  Debrief = 12,
  CustomerSiteManagement = 13, // ALL
  EquipmentManagement = 14, // ALL
  SchedulingDashboard = 15, // ALL
  TaskManagement = 16,
  AgreementManagement = 17,
  ZoneManagement = 18,
  RecurringBilling = 19,
  WorkOrders = 20, // ALL
  Discounts = 21, // ALL
  Rebates = 22, // ALL
  PrintingEmailingInvoices = 23, // ALL
  TaxManagement = 24, // ALL
  AcceptCreditCardPayments = 25,
  FieldPricingCalculator = 26, // ALL
  ServicePriceBook = 27,
  SalesPriceBook = 28,
  PartItems = 29,
  ReplenishmentDistributors = 31,
  Podium = 34,
  SmsTextMagic = 35,
  QuickBooks = 36,
  PictureRecommendations = 37, // ALL
  CustomWorkOrders = 38, // ALL
  CustomerHistory = 39, // ALL
  FieldInvoicing = 40, // ALL
  InspectionSheets = 41,
  BeltsAndFilters = 42,
  Tag = 46,
  Marketing = 47,
  Zapier = 48,
}

export enum ConciseFeature {
  FieldMobileApp = 1,
  CRM = 2,
  Account = 3,
  FlatRateServicePricing = 4,
  AdvancedReporting = 5,
  BaseReporting = 6,
  Agreements = 7,
  Payment = 8,
  Accounting = 9,
  RecurringMemberships = 10,
  BestBetterGoodSalesSystem = 11,
  Marketing = 12,
  Texting = 13,
  WhiteGlove = 14,
  ProductSupport = 15,
  Onboarding = 16,
  EliteMembership = 17,
  ReplenishmentDistributors = 18,
}

export const FeatureLabels: Record<number, { text: string; helper?: string }> = {
  [Feature.RoleAssignments]: {
    text: 'Role Assignments',
    helper: 'Assign specific roles to each of your user giving them access to only what they need',
  },
  [Feature.CostRecoveryManager]: {
    text: 'Cost Recovery Manager',
    helper:
      'By adding a small amount to each service line item per day, 3C Connect effectively pays for itself, which means you get all of the benefits and none of the overhead',
  },
  [Feature.BoardManagement]: {
    text: 'Scheduling & Board Management',
    helper: 'Create custom schedule boards for individual users and teams',
  },
  [Feature.GpsLocationTracking]: {
    text: 'GPS Location Tracking',
    helper: 'Monitor field users location as well as geographic scheduling efficiency',
  },
  [Feature.EmailWhitelisting]: {
    text: 'Email Whitelisting',
    helper: '3C\'s Email whitelisting ensures a "safe" and approved list of email addresses of which a spam filter always allows delivery',
  },
  [Feature.HelpCenter]: {
    text: 'Help Center, Walkthroughs & Virtual Support',
    helper:
      'Interactive guides provide a significantly better user experience, helping customer walk through the solution they need one step at a time without waiting for a live business support agent to assist',
  },
  [Feature.PhoneSupport]: {
    text: 'Phone Support',
    helper: "Includes Phone Support from your direct account manager. Starter accounts can access support by email and 3C's help center",
  },
  [Feature.HVACFieldDiagnosticVideos]: {
    text: '(HVAC) Field Diagnostic Video Support',
    helper: 'HVAC service technician training and diagnostic video support',
  },
  [Feature.KPIDashboardReporting]: {
    text: 'KPI Dashboard Reporting',
    helper: 'Fully customizable, easy to use reporting so you can track what’s important to you and your business',
  },
  [Feature.IncentiveTracking]: {
    text: 'Incentive Tracking',
    helper: 'Generate technician incentive reports to reward and stimulate additional field sales',
  },
  [Feature.Leaderboards]: {
    text: 'Leaderboards',
    helper:
      'Create fully customizable leaderboards for data driven management and productive performance reviews and inner-department meetings',
  },
  [Feature.Debrief]: {
    text: 'Work Order Debrief',
    helper:
      'Save hours in administration with our work order debrief system to close appointments accurately and generate real time reports',
  },
  [Feature.CustomerSiteManagement]: {
    text: 'Customer & Site Management',
    helper: "Keep a comprehensive roster of sites and equipment under a customer's profile",
  },
  [Feature.EquipmentManagement]: {
    text: 'Equipment & Work Area Management',
    helper: 'Better manage your individual equipment and work area information',
  },
  [Feature.SchedulingDashboard]: {
    text: 'Scheduling Dashboard',
    helper:
      'The scheduling dashboard saves you time by allowing you to easily locate technicians out in the field, track their progress and adjust their workload in real time',
  },
  [Feature.TaskManagement]: {
    text: 'Task Management',
    helper:
      'Increase customer retention and revenue with detailed customer tracking and task management, preventing frustrated customers and sales opportunities from falling through the cracks',
  },
  [Feature.Tag]: {
    text: 'Opportunity Tagging',
    helper: 'Tag call opportunities and grow your marketing funnel',
  },
  [Feature.Marketing]: {
    text: 'List Management',
    helper: 'Sort and filter your marketing lists to enhances your email & SMS efforts',
  },
  [Feature.AgreementManagement]: {
    text: 'Agreement Management',
    helper: 'Automate Annual and Monthly memberships, billing and receiving, allowing the support team to focus on other tasks',
  },
  [Feature.ZoneManagement]: {
    text: 'Zone Management',
    helper: 'Assign customers to geographic zones and automate pricing based on travel time/distance',
  },
  [Feature.RecurringBilling]: {
    text: 'Recurring Payment Manager',
    helper:
      '3C Connect automatically generates monthly invoices, processes recurring payments and seamlessly synchronizes them to QuickBooks. Set it and forget it!',
  },
  [Feature.WorkOrders]: {
    text: 'Work Orders',
    helper: 'Create individual work orders within one appointment. Great for multiple work areas, rooms, equipment etc.',
  },
  [Feature.Discounts]: {
    text: 'Discounts',
    helper: 'Assign company approved discounts for field users to apply when invoicing customer or allow for manual discount entries',
  },
  [Feature.Rebates]: {
    text: 'Rebates',
    helper: 'Add or update company approved rebates for your sales and field users to select when quoting or invoicing customers.',
  },
  [Feature.PrintingEmailingInvoices]: {
    text: 'Printing & Emailing',
    helper: 'Easily email completed invoices or print them out directly from the customers work order',
  },
  [Feature.TaxManagement]: { text: 'Tax Management', helper: 'Add or update your companies tax data for individual regions' },
  [Feature.AcceptCreditCardPayments]: {
    text: 'Accept Credit Card Payments',
    helper: 'Process online or send payment request links right from 3C Connects completed work orders',
  },
  [Feature.FieldPricingCalculator]: {
    text: 'Field Pricing Calculator',
    helper:
      'Allows field users to manually add service description and pricing by entering the necessary information into the pricing calculator',
  },
  [Feature.ServicePriceBook]: {
    text: 'Flat Rate Service Pricing',
    helper: 'Select pricing from your companies fully customizable approved service price book',
  },
  [Feature.SalesPriceBook]: {
    text: 'Sales Price Book',
    helper: "Customize your own sales book and Best, Better, Good pricing templates through 3C's sales builder",
  },
  [Feature.PartItems]: {
    text: 'Part Items',
    helper:
      'Offer different brands of parts from multiple distributors under the same services or repairs, and use part markups to maintain consistent Gross Margin',
  },
  [Feature.ReplenishmentDistributors]: {
    text: 'Inventory Replenishment',
    helper:
      'Automated parts and materials replenishment sent directly to your own warehouse inventory or parts distributor for immediate replenishment',
  },
  [Feature.Podium]: {
    text: 'Review Accelerator',
    helper:
      '3C initiates a proactive review solicitation through our invoicing as well as an automated text review link sent directly from the technicians field device upon the call completion<br />*The Automated text invites require using one of our integrations',
  },
  [Feature.SmsTextMagic]: {
    text: 'SMS Text Magic',
    helper: 'Automatically group and send all next day appointment confirmations with the push of a button',
  },
  [Feature.QuickBooks]: {
    text: 'QuickBooks',
    helper:
      "Automatically create new QB's customers or update existing customers, sync all invoice line items to appropriate class, auto sync payments with invoices and, if needed, collect payments via your customers' invoice",
  },
  [Feature.PictureRecommendations]: {
    text: 'Capture and Pictures',
    helper: 'Attach pictures, findings and recommendations to work orders and easily access for future review',
  },
  [Feature.CustomWorkOrders]: {
    text: 'Custom Work Orders',
    helper:
      'Create individual work orders within one appointment.  (Great for multiple work areas, rooms, equipment, etc.) Add service line items with and without agreements and capture customers approval',
  },
  [Feature.CustomerHistory]: { text: 'Customer History', helper: 'Easily review, add or edit your customers information from the field' },
  [Feature.FieldInvoicing]: {
    text: 'Technician Sales Flow',
    helper: 'The 3C Field application has a built-in steps technician sales presentation process that when used will skyrocket services',
  },
  [Feature.InspectionSheets]: {
    text: 'Work Performed & Inspection Forms',
    helper: 'Fully customizable red, yellow, green inspection and work performed sheets',
  },
  [Feature.Zapier]: {
    text: 'Zapier',
    helper: 'Creates Zap with our partner to automate other parts of your business with other software',
  },
};

export const ConciseFeatureLabels: Record<number, { text: string; helper?: string }> = {
  [ConciseFeature.FieldMobileApp]: {
    text: 'Field Mobile App',
    helper:
      'The 3C Field Mobile application has a built-in steps technician sales presentation process that when used will skyrocket services',
  },
  [ConciseFeature.ReplenishmentDistributors]: {
    text: 'Inventory Replenishment',
    helper:
      'Automated parts and materials replenishment sent directly to your own warehouse inventory or parts distributor for immediate replenishment',
  },
  [ConciseFeature.CRM]: { text: 'CRM', helper: "Manage your company's relationship and interactions with your customers" },
  [ConciseFeature.Account]: { text: '3C Account Management', helper: 'Manage your 3C account by tracking taxes and your technicians' },
  [ConciseFeature.FlatRateServicePricing]: {
    text: 'Flat Rate Service Pricing',
    helper:
      'Allows field users to manually add service description and pricing by entering the necessary information into the pricing calculator',
  },
  [ConciseFeature.AdvancedReporting]: {
    text: 'Advanced Reporting',
    helper: 'Create fully customizable reports tracking KPI and user leaderboards by following important metrics',
  },
  [ConciseFeature.BaseReporting]: {
    text: 'Base Reporting',
    helper: 'Create fully customizable reports tracking KPI by following important metrics',
  },
  [ConciseFeature.Agreements]: {
    text: 'Service Agreements',
    helper: 'Automate Annual and Monthly memberships, billing and receiving, allowing the support team to focus on other tasks',
  },
  [ConciseFeature.Payment]: {
    text: 'Payment Processing',
    helper: 'Process online or send payment request links right from 3C Connects completed work orders',
  },
  [ConciseFeature.Accounting]: {
    text: 'Accounting Integration',
    helper: 'Integrate seemlesly with QuickBooks Desktop or Online for your accounting needs',
  },
  [ConciseFeature.RecurringMemberships]: {
    text: 'Recurring Memberships',
    helper: '3C Connect automatically generates monthly invoices, processes recurring payments. Set it and forget it!',
  },
  [ConciseFeature.BestBetterGoodSalesSystem]: {
    text: 'Best, Better, Good Sales System',
    helper: "Customize your own sales book and Best, Better, Good pricing templates through 3C's sales builder",
  },
  [ConciseFeature.Marketing]: {
    text: 'Marketing (Text & Email)',
    helper: 'Sort and filter your marketing lists to enhances your email & SMS efforts',
  },
  [ConciseFeature.Texting]: {
    text: 'Texting (In-route and review automation)',
    helper:
      '3C initiates a proactive review solicitation through our invoicing as well as an automated text review link sent directly from the technicians field device upon the call completion<br />*The Automated text invites require using one of our integrations',
  },
  [ConciseFeature.WhiteGlove]: {
    text: 'White Glove Support',
    helper:
      'Priority Service Level product support<br /><br />Priority Support includes access through Chat, email, or 1:1 support with a 3C product specialist',
  },
  [ConciseFeature.ProductSupport]: { text: 'Product Support', helper: 'Receive normal support during business hours' },
  [ConciseFeature.Onboarding]: {
    text: 'Onboarding, Training & Product Setup',
    helper:
      'This top level 3C integration support will provide the oversight & resources needed to ensure you can get started quickly & efficiently.<br />This includes:<br /><br /><ul><li>Dedicated onboarding support</li><li>Personalizing your branding set up</li><li>Improved integrations</li><li>Improved training</li></ul>This is guaranteed to save time, set-up, training & roll out, money, and fast track operational efficiency.',
  },
  [ConciseFeature.EliteMembership]: {
    text: '3C Elite - Customer Membership',
    helper:
      'This includes:<br /><br /><ul><li>Monthly 1:1 General Manger support & accountability session with real world Business owners and coaches</li><li>On Demand Business Advice</li><li>Access to 3C Growth Tools and Business Playbooks. Elite Members will receive customizable downloads for the following Growth Tools (up to 4 x per month)</li><li>Schedule your free 1:1 Demo with a 3C Product Specialist for a brief overview of our 3C Elite - Customer Membership</li></ul>',
  },
};
