import { type TaxItem } from '@models/tax-item';
import { Decimal } from 'decimal.js';

export interface LineItemDetail {
  id: Id;
  discountAmount?: Money | null; // Defaults to null
  accepted?: boolean; // Defaults to true
  amount: Money | null;
  hidden?: boolean;
}

export interface LineItemDiscount {
  afterTax: boolean;
  amount: Money | null;
  percentage: Fraction | null;
  hidden?: boolean;
}

export interface LineItemRebate {
  afterTax: boolean;
  amount: Money;
  hidden?: boolean;
}

export interface LineItemSubDetail extends LineItemDetail {
  parentId: Id | null;
}

export interface TaxEntry {
  taxAgency: string | null;
  taxAmount: Money;
  taxPercentage: Percentage | null;
}

export interface PriceSnapshot {
  subtotalBeforeDiscounts: Money;
  beforeTaxDiscount: Money;
  beforeTaxDiscountPercentage: Percentage | null;
  beforeTaxRebate: Money;
  subtotalAfterDiscounts: Money;
  savings: Money;
  taxes: TaxEntry[];
  taxSum: Money;
  totalAfterTax: Money;
  afterTaxDiscount: Money;
  afterTaxDiscountPercentage: Percentage | null;
  afterTaxRebate: Money;
  totalAfterDiscounts: Money;
  payment: Money;
  balanceOwed: Money;
}

export interface TaxItemEntry {
  taxItem: TaxItem | null;
  taxItemManual: Percentage | null;
  taxOverride: Money | null;
}

export function convertTaxItemEntry(
  taxItemEntry: TaxItemEntry | null
): { taxItemId: Id | null; taxOverride: Money | null; salesTaxPercentage: Fraction | null } | null {
  if (taxItemEntry) {
    let salesTaxPercentage: Fraction | null = null;
    if (taxItemEntry.taxItemManual) {
      salesTaxPercentage = new Decimal(taxItemEntry.taxItemManual).dividedBy(100).toNumber();
    } else if (taxItemEntry.taxItem) {
      salesTaxPercentage = taxItemEntry.taxItem.rate;
    }

    return {
      taxItemId: taxItemEntry.taxItem?.id ?? null,
      taxOverride: taxItemEntry.taxOverride,
      salesTaxPercentage,
    };
  }

  return null;
}
