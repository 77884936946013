import { CommonModule } from '@angular/common';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  type OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { type ClickableResource, type OrderedResource, type Resource } from '@models/resource';
import { getObjectKeys } from '@utility/object';
import { type SortableHidden, assignNewOrder, sortFnc } from '@utility/sort-fnc';
import { newGuid } from '@utility/string';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'wm-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DragulaService],
  standalone: true,
  imports: [CommonModule, CheckboxComponent, FormsModule],
})
export class TableComponent<T> implements AfterViewInit, OnInit {
  private readonly cd = inject(ChangeDetectorRef);

  @Input()
  disabled = false;

  _rows: T[] = [];

  @Input()
  get rows(): T[] {
    return this._rows;
  }

  set rows(value: T[] | null | undefined) {
    const rows = value ?? [];

    if (this.orderable || this.draggable) {
      const orderableRows = rows as unknown as { order: number }[];
      orderableRows.sort(sortFnc(false, 'order'));
      orderableRows.forEach((item, index) => {
        item.order = index;
      });
    }

    if (!rows || rows.length === 0) {
      this.allSelected = false;
    }

    this._rows = rows;
    this.currentViewRows = [...rows];
  }

  @HostBinding('class.is-padding-disabled')
  @Input()
  disablePadding = false;

  // In some scenario, having the table-layout set to auto, this will mess up the UI when the rows are added and then removed.
  @Input()
  includeColgroup = true;

  @HostBinding('class.is-fixed-layout')
  @Input()
  isFixedLayout = false;

  @Input()
  rowSelection = false;

  @Input()
  disableAllRowSelection = false;

  @Input()
  rowDelete = false;

  @Input()
  @HostBinding('class.has-row-add')
  rowAdd = false;

  @HostBinding('class.has-errors')
  @Input()
  errors = false;

  @HostBinding('class.with-footer')
  @Input()
  withFooter = false;

  @HostBinding('class.with-header')
  @Input()
  withHeader = false;

  @Input()
  withThead = true;

  @Input()
  removeText = 'Remove';

  @Input()
  isLoading = false;

  @Input()
  rowSelectionRowSpan = (_row: T): number => 1;

  @HostBinding('class.has-data')
  get hasData(): boolean {
    return !(this.rows.length === 0);
  }

  private _selectionModel: Record<number, boolean> = {};

  @Input()
  public get selectionModel(): Record<number, boolean> {
    return this._selectionModel;
  }

  public set selectionModel(value: Record<number, boolean>) {
    this._selectionModel = value;

    if (this.hasViewInit) {
      this.checkAllSelection();
    }
  }

  @Input()
  rowAddText?: string;

  @Input()
  customRowClassFnc?: (item: T) => string;

  @Input()
  draggable = false;

  @Input()
  orderable = false;

  @Output()
  rowAddClick = new EventEmitter<void>();

  @Output()
  rowDeleteClick = new EventEmitter<T>();

  @Output()
  rowUpdated = new EventEmitter<T>();

  @ContentChild('addTemplate') addTemplate?: TemplateRef<any>;
  @ContentChild('footerTemplate') footerTemplate?: TemplateRef<any>;
  @ContentChild('noResults') noResultsTemplate?: TemplateRef<any>;

  private draggableBagName?: string;
  private hasViewInit = false;

  // Future

  _allSelected = false;
  get allSelected(): boolean {
    return this._allSelected;
  }

  set allSelected(value: boolean) {
    this._allSelected = value;

    // We can't reset our object because we might not own it.
    getObjectKeys(this.selectionModel).forEach(key => {
      delete this.selectionModel[key];
    });

    if (this._rows && this._allSelected) {
      for (const row of this._rows) {
        if (this.isRowResource(row)) {
          if (this.isRowSelectionDisabled(row)) {
            continue;
          }

          this.selectionModel[row.id] = true;
        }
      }
    }

    this.cd.markForCheck();
  }

  @ContentChild('tableHeaderTemplate') tableHeaderTemplate: TemplateRef<any> | null = null;
  @ContentChild('tableBodyTemplate') tableBodyTemplate: TemplateRef<any> | null = null;
  @ContentChild('tableColgroupTemplate') tableColgroupTemplate: TemplateRef<any> | null = null;
  @ContentChild('tableFooterTemplate') tableFooterTemplate: TemplateRef<any> | null = null;

  @ViewChild('tbody', { read: ElementRef, static: true })
  tbody!: ElementRef<HTMLElement>;

  currentViewRows: T[] = [];

  get rowLength(): number {
    return this.rows?.length ?? 0;
  }

  @Input()
  shouldIncludeRowSelection: (item: T) => boolean = () => true;

  @Input()
  isRowSelectionDisabled: (item: T) => boolean = () => false;

  @Input()
  shouldIncludeRowDelete: (item: T) => boolean = () => true;

  @Input()
  shouldShowCheckbox: (item: T) => boolean = () => true;

  @Input()
  isRowDeleteDisabled: (item: T) => boolean = () => false;

  @Input()
  getRowTestId: (item: T) => string = () => '';

  @HostBinding('class.no-rows')
  get noRows(): boolean {
    return !this.currentViewRows || this.currentViewRows.length === 0;
  }

  @Input()
  idTracking = (item: T): number => (this.isRowResource(item) && item.id) || 0;

  _idTracking = (_index: number, item: T): number => {
    return this.idTracking(item);
  };

  private readonly dragulaService = inject(DragulaService);

  ngOnInit(): void {
    // Make sure we set the right order property on all rows on init
    // eslint-disable-next-line no-self-assign
    this.rows = this.rows;
  }

  ngAfterViewInit(): void {
    const dragulaBagName = this.getDraggableBagName();
    this.dragulaService.createGroup(dragulaBagName, {
      containers: [this.tbody.nativeElement],
      moves: (_, __, handle) => {
        if (this.orderable || this.draggable) {
          let current = handle as HTMLElement;
          do {
            if (current.classList?.contains('row-drag')) {
              return true;
            }
          } while ((current = current.parentNode as HTMLElement));
        }

        return false;
      },
    });

    this.dragulaService.drop().subscribe(({ el, source }) => {
      const htmlElement = el as HTMLElement;
      const rowId = +(htmlElement.dataset.rowId ?? '0');
      const newIndex = Array.from(source.children).indexOf(htmlElement);
      this.movingRow(rowId, newIndex);
    });

    this.hasViewInit = true;
    this.checkAllSelection();
  }

  isSelected(row: any): boolean {
    if (this.isRowResource(row)) {
      return !!this.selectionModel[row.id];
    }

    return false;
  }

  getSelectionAmount(): number {
    return this.getSelectedRows().length;
  }

  getSelectedRows(): Resource[] {
    const selectedRows = [];
    if (this._rows) {
      for (const row of this._rows) {
        if (this.isRowResource(row)) {
          if (this.isSelected(row)) {
            selectedRows.push(row);
          }
        }
      }
    }

    return selectedRows;
  }

  checkAllSelection(): void {
    let allSelected = true;

    for (const row of this.rows) {
      if (this.isRowResource(row)) {
        if (this.isRowSelectionDisabled(row)) {
          continue;
        }

        if (!this.isSelected(row)) {
          allSelected = false;
          break;
        }
      }
    }

    if (allSelected) {
      this._allSelected = true;
    } else {
      this._allSelected = false;
    }
  }

  emitRowSelectionActions(clickableResource: ClickableResource<Resource[]>): void {
    clickableResource.click(this.getSelectedRows());
  }

  emitRowAdd(): void {
    this.rowAddClick.emit();
  }

  getAddTemplateContext(): {
    rowAdd: () => void;
    rowAddText: string;
  } {
    return {
      rowAdd: () => {
        this.emitRowAdd();
      },
      rowAddText: this.rowAddText ?? '',
    };
  }

  getRowClass(item: T): string | null {
    if (this.customRowClassFnc) {
      return this.customRowClassFnc(item);
    }

    return null;
  }

  getDraggableBagName(): string {
    if (!this.draggableBagName) {
      this.draggableBagName = newGuid();
    }

    return this.draggableBagName;
  }

  private movingRow(rowId: number | string, newIndex: number): void {
    const rowIndex = this.currentViewRows.findIndex(m => this.isOrderedResourceRow(m) && m.id === Number(rowId));

    if (rowIndex >= 0) {
      const movingUp = newIndex < rowIndex;
      const row = this.currentViewRows[rowIndex] as SortableHidden;

      row.order = newIndex + (movingUp ? -0.5 : 0.5);

      const newArray = [...this.currentViewRows] as SortableHidden[];
      const { items, updatedItems } = assignNewOrder(newArray);

      this.rows = items as T[];

      updatedItems.forEach(x => {
        this.rowUpdated.emit(x as T);
      });
    }
  }

  isOrderedResourceRow(row: any): row is OrderedResource {
    const order = (row as OrderedResource).order;

    return order !== null && order !== undefined;
  }

  isRowResource(row: any): row is Resource {
    return !!(row as Resource).id;
  }
}
