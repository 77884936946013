import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { type ContractorInformation } from '@models/cards/contractor-information';
import { type SettingContractorViewData } from '@models/cards/setting-view-data';
import { type ContractorMarkupPricing } from '@models/contractor-markup-pricing';
import { PubSubService } from '@services/pub-sub.service';
import { allowRetries } from '@utility/angular';
import { of, type Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { swap } from '../../utility/array';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

export interface ContractorSettingEntry {
  setting: ContractorSetting;
  value: string | null;
}

export enum ContractorSetting {
  QB_VERSION,
  QB_SYNC_CUSTOMERS_FROM_QUICKBOOKS,
  QB_SYNC_CUSTOMER_LIST,
  QB_SYNC_INVOICE_LIST,
  QB_SYNC_LAST_CUSTOMER_MODIFIED,
  QB_SYNC_LAST_INVOICE_MODIFIED,
  QB_SYNC_LAST_SALESTAX_MODIFIED,
  QB_SYNC_LAST_SALESTAXCODE_MODIFIED,
  QB_SYNC_LAST_PAYMENTMETHOD_MODIFIED,
  QB_SYNC_LAST_CUSTOMER_DELETED,
  QB_SYNC_LAST_SALESTAX_DELETED,
  QB_SYNC_LAST_PAYMENTMETHOD_DELETED,
  QB_SYNC_LAST_SALESTAXCODE_DELETED,
  QB_SYNC_LAST_INVOICE_DELETED,
  QB_SYNC_LAST_CUSTOMER_LIST,
  QB_SYNC_CUSTOMER_MAX_RETURN,
  QB_SYNC_ACCOUNT_QUERY,
  QB_SYNC_ACCOUNT_ADD,
  QB_SYNC_ACCOUNT_MAP,
  QB_SYNC_CLASS_QUERY,
  QB_SYNC_INVOICES_TO_QB,
  QB_SYNC_IMPORT_ALL_ENABLED,
  QB_SYNC_LAST_IMPORT_ALL_ID,
  QB_SYNC_TAX_NO_WARNING,
  QB_SYNC_CLASS_SERVICE_REPAIR,
  QB_SYNC_ITEM_NON_INVENTORY_MAP,
  QB_SYNC_ITEM_DISCOUNT_MAP,
  QB_SYNC_ITEM_TAXABLE_SALES_TAX_MAP,
  QB_SYNC_GUID,

  IPAD_SERVICE_RATING_NAME,
  IPAD_SERVICE_RATING_STARS,
  IPAD_CONSUMABLE_DAYS_WARNING,
  IPAD_CONTRACTOR_BENEFITS_URL,
  IPAD_IMAGE_MAX_DIMENSION,
  IPAD_IMAGE_MAX_SIZE,
  IPAD_IMAGE_QUALITY,
  IPAD_CONTRACTOR_AGREEMENT_NAME,
  IPAD_DASHBOARD_URL,

  CUSTOMER_SHOULD_HAVE_EMAIL,
  DASHBOARD_TIME_START,
  DASHBOARD_LATE_BEACON,

  APPOINTMENT_DEFAULT_DURATION,

  SMS_USE_SITE_MOBILE_PHONE,

  BILLING_PRICE,
  BILLING_DAY_OF_MONTH,

  SITESYSTEM_BEHAVIOR,

  ACCOUNT_NEEDS_CORE_SETUP,
  ACCOUNT_NEEDS_EXTENDED_SETUP,
  ACCOUNT_SUPPORTS_AFTER_HOURS_PRICING,
  ACCOUNT_SUPPORTS_COMMERCIAL_PRICING,
  ACCOUNT_REQUESTED_BILLING,
}

const contractorSettingKey: Record<number, string> = {};
contractorSettingKey[ContractorSetting.QB_VERSION] = 'QuickBooks.Version';
contractorSettingKey[ContractorSetting.QB_SYNC_CUSTOMERS_FROM_QUICKBOOKS] = 'QuickBooks.Synchronize.CustomersFromQuickBooks'; // Deprecated.
contractorSettingKey[ContractorSetting.QB_SYNC_CUSTOMER_LIST] = 'QuickBooks.Synchronize.CustomersLISTQuickBooks';
contractorSettingKey[ContractorSetting.QB_SYNC_INVOICE_LIST] = 'QuickBooks.Synchronize.InvoicesLISTQuickBooks';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_CUSTOMER_MODIFIED] = 'QuickBooks.Synchronize.LastCustomerModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_INVOICE_MODIFIED] = 'QuickBooks.Synchronize.LastInvoiceLISTModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_SALESTAX_MODIFIED] = 'QuickBooks.Synchronize.LastItemSalesTaxLISTModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_SALESTAXCODE_MODIFIED] = 'QuickBooks.Synchronize.LastSalesTaxCodeLISTModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_PAYMENTMETHOD_MODIFIED] = 'QuickBooks.Synchronize.LastItemPaymentMethodLISTModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_CUSTOMER_DELETED] = 'QuickBooks.Synchronize.LastCustomerDELETEDModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_SALESTAX_DELETED] = 'QuickBooks.Synchronize.LastItemSalesTaxDELETEDModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_PAYMENTMETHOD_DELETED] = 'QuickBooks.Synchronize.LastPaymentMethodDELETEDModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_SALESTAXCODE_DELETED] = 'QuickBooks.Synchronize.LastSalesTaxCodeDELETEDModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_INVOICE_DELETED] = 'QuickBooks.Synchronize.LastInvoiceDELETEDModified';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_CUSTOMER_LIST] = 'QuickBooks.Synchronize.LastCustomerLISTModified';
contractorSettingKey[ContractorSetting.QB_SYNC_CUSTOMER_MAX_RETURN] = 'QuickBooks.Synchronize.CustomerLISTMaxReturn';
contractorSettingKey[ContractorSetting.QB_SYNC_ACCOUNT_QUERY] = 'QuickBooks.Synchronize.AccountQuery';
contractorSettingKey[ContractorSetting.QB_SYNC_ACCOUNT_ADD] = 'QuickBooks.Synchronize.AccountAdd';
contractorSettingKey[ContractorSetting.QB_SYNC_ACCOUNT_MAP] = 'QuickBooks.Synchronize.AccountMap';
contractorSettingKey[ContractorSetting.QB_SYNC_CLASS_QUERY] = 'QuickBooks.Synchronize.ClassQuery';
contractorSettingKey[ContractorSetting.QB_SYNC_INVOICES_TO_QB] = 'QuickBooks.Synchronize.InvoicesToQuickBooks';
contractorSettingKey[ContractorSetting.QB_SYNC_IMPORT_ALL_ENABLED] = 'QuickBooks.Synchronize.ImportAll.Enabled';
contractorSettingKey[ContractorSetting.QB_SYNC_LAST_IMPORT_ALL_ID] = 'QuickBooks.Synchronize.LastImportAllId';
contractorSettingKey[ContractorSetting.QB_SYNC_TAX_NO_WARNING] = 'QuickBooks.Synchronize.Tax.NoWarning';
contractorSettingKey[ContractorSetting.QB_SYNC_CLASS_SERVICE_REPAIR] = 'QuickBooks.Synchronize.Class.ServiceRepair';
contractorSettingKey[ContractorSetting.QB_SYNC_ITEM_NON_INVENTORY_MAP] = 'QuickBooks.Synchronize.ItemNonInventoryMap';
contractorSettingKey[ContractorSetting.QB_SYNC_ITEM_DISCOUNT_MAP] = 'QuickBooks.Synchronize.ItemDiscountMap';
contractorSettingKey[ContractorSetting.QB_SYNC_ITEM_TAXABLE_SALES_TAX_MAP] = 'QuickBooks.Synchronize.ItemTaxableSalesTaxMap';
contractorSettingKey[ContractorSetting.QB_SYNC_GUID] = 'QuickBooks.Synchronize.Guid';

contractorSettingKey[ContractorSetting.IPAD_SERVICE_RATING_NAME] = 'IPad.ServiceRating.Name';
contractorSettingKey[ContractorSetting.IPAD_CONSUMABLE_DAYS_WARNING] = 'IPad.ConsumableDaysWarning';
contractorSettingKey[ContractorSetting.IPAD_CONTRACTOR_BENEFITS_URL] = 'IPad.ContractorBenefitsUrl';
contractorSettingKey[ContractorSetting.IPAD_IMAGE_MAX_DIMENSION] = 'IPad.ImageMaxDimension';
contractorSettingKey[ContractorSetting.IPAD_IMAGE_MAX_SIZE] = 'IPad.ImageMaxSize';
contractorSettingKey[ContractorSetting.IPAD_IMAGE_QUALITY] = 'IPad.ImageQuality';
contractorSettingKey[ContractorSetting.IPAD_CONTRACTOR_AGREEMENT_NAME] = 'IPad.ContractorAgreementName';
contractorSettingKey[ContractorSetting.IPAD_DASHBOARD_URL] = 'IPad.DashboardUrl';

contractorSettingKey[ContractorSetting.CUSTOMER_SHOULD_HAVE_EMAIL] = 'Customer.ShouldHaveEmail';
contractorSettingKey[ContractorSetting.DASHBOARD_TIME_START] = 'Dashboard.TimeStart';
contractorSettingKey[ContractorSetting.DASHBOARD_LATE_BEACON] = 'Dashboard.LateBeacon';

contractorSettingKey[ContractorSetting.APPOINTMENT_DEFAULT_DURATION] = 'Appointment.DefaultDuration';

contractorSettingKey[ContractorSetting.SMS_USE_SITE_MOBILE_PHONE] = 'Sms.UseSiteMobilePhone';

contractorSettingKey[ContractorSetting.SITESYSTEM_BEHAVIOR] = 'SiteSystem.Behavior';

contractorSettingKey[ContractorSetting.ACCOUNT_NEEDS_CORE_SETUP] = 'Account.NeedsCoreSetup';
contractorSettingKey[ContractorSetting.ACCOUNT_NEEDS_EXTENDED_SETUP] = 'Account.NeedsExtendedSetup';
contractorSettingKey[ContractorSetting.ACCOUNT_REQUESTED_BILLING] = 'Account.RequestedBilling';
contractorSettingKey[ContractorSetting.ACCOUNT_SUPPORTS_AFTER_HOURS_PRICING] = 'Account.Supports.AfterHoursPricing';
contractorSettingKey[ContractorSetting.ACCOUNT_SUPPORTS_COMMERCIAL_PRICING] = 'Account.Supports.CommercialPricing';

contractorSettingKey[ContractorSetting.BILLING_PRICE] = 'Billing.Price';
contractorSettingKey[ContractorSetting.BILLING_DAY_OF_MONTH] = 'Billing.DayOfMonth';

@Injectable()
export class ContractorSettingsService {
  private readonly http = inject(HttpClientService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);
  private readonly pubSubService = inject(PubSubService);

  _cachedDictionary: Dictionary<number, string> = {};

  constructor() {
    this.pubSubService
      .getEventEmitter('LOGIN')
      .pipe(takeUntilDestroyed())
      .pipe(
        tap(() => {
          console.log('Clearing ContractorSettingsService.');
        })
      )
      .subscribe(() => {
        this._cachedDictionary = {};
      });
  }

  saveContractor(contractorInformation: Omit<ContractorInformation, 'id'>): Observable<{ id: Id }> {
    this.http.setEmptyStringToNull(contractorInformation);
    this.http.trimStringProperties(contractorInformation);

    return this.http.post<{ id: Id }>(this.url.contractorSettingsSave, contractorInformation);
  }

  getSettings(): Observable<SettingContractorViewData> {
    return this.http.get<SettingContractorViewData>(this.url.contractorSettingGetSettings);
  }

  postProcessLogo(photoId: Id): Observable<void> {
    return this.http.post<void>(this.url.contractorSettingPostProcessLogo.replace('$0', photoId.toString()), {});
  }

  get(setting: ContractorSetting): Observable<string | null> {
    return this.getMultiple(setting).pipe(map(settings => settings[setting]));
  }

  getMultiple(...settings: ContractorSetting[]): Observable<Dictionary<number, string | null>> {
    if (this.applicationCacheService.isStale(UpdateCategory.ContractorSettings)) {
      this._cachedDictionary = {};
    }

    const finalArrayRequest = settings
      .filter(m => !!contractorSettingKey[m])
      .filter(m => this._cachedDictionary[m] === undefined)
      .map(m => contractorSettingKey[m]);

    let observableRequest: Observable<Dictionary<string, string>> = of(this._cachedDictionary);
    if (finalArrayRequest.length > 0) {
      observableRequest = this.http
        .post<Dictionary<string, string>>(this.url.contractorSettingGetMultiple, finalArrayRequest, allowRetries())
        .pipe(
          map(m => {
            const swappedSettings = swap(contractorSettingKey);
            for (const key in m) {
              if (swappedSettings[key]) {
                this._cachedDictionary[swappedSettings[key]] = m[key];
              }
            }

            return this._cachedDictionary;
          })
        );
    }

    return observableRequest.pipe(
      map(m => {
        const finalDictionary: Dictionary<number, string> = {};

        for (const setting of settings) {
          finalDictionary[setting] = m[setting];
        }

        return finalDictionary;
      })
    );
  }

  getMarkups(): Observable<ContractorMarkupPricing[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.MarkupPricings, this.url.contractorSettingGetMarkups);
  }

  saveMarkups(contractorMarkupPricings: ContractorMarkupPricing[]): Observable<void> {
    return this.http.post<void>(this.url.contractorSettingSaveMarkups, contractorMarkupPricings).pipe(
      tap(_ => {
        this.clearCache(UpdateCategory.MarkupPricings);
      }),
      share()
    );
  }

  clearCache(category: UpdateCategory): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(category);
    }
  }
}
