import { Injectable, inject } from '@angular/core';
import { type BeltInformation } from '@models/cards/belt-information';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class BeltsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<BeltInformation> {
    return this.http.get<BeltInformation>(this.url.beltSingle.replace('$0', id.toString()));
  }

  save(beltInformation: BeltInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(beltInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.beltSave, obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.beltDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.beltRestore.replace('$0', id.toString()), null);
  }
}
