import { Injectable, inject } from '@angular/core';
import { type DiscountInformation } from '@models/sales-proposal-models';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { HttpClientService } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';

@Injectable()
export class DiscountsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(): Observable<DiscountInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.Discounts, this.url.discountsList);
  }

  save(priceBookInformation: DiscountInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.discountsCreate, priceBookInformation, skipBubbleError()).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.discountsDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.Discounts);
    }
  }
}
