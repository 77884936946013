import { type ModuleWithProviders, NgModule } from '@angular/core';
import { StickyDirective } from './sticky.directive';
import { StickyService } from './sticky.service';

@NgModule({
  declarations: [StickyDirective],
  exports: [StickyDirective],
})
export class StickyModule {
  static forRoot(): ModuleWithProviders<StickyModule> {
    return {
      ngModule: StickyModule,
      providers: [StickyService],
    };
  }
}
