import { Injectable, inject, type OnDestroy } from '@angular/core';
import { AppRole } from '@models/app-role';
import { Subject, type Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AccountsService } from '../services/live/accounts.service';
import { PubSubService } from './pub-sub.service';

export { AppRole };

@Injectable()
export class RolesService implements OnDestroy {
  private readonly accountsService = inject(AccountsService);
  private readonly pubSubService = inject(PubSubService);

  private savedRoles?: AppRole[];
  private readonly destroy$ = new Subject<void>();
  private roles$?: Observable<AppRole[]>;

  constructor() {
    this.pubSubService
      .getEventEmitter('LOGIN')
      .pipe(takeUntil(this.destroy$))
      .pipe(
        tap(() => {
          console.log('Clearing RolesService.');
        })
      )
      .subscribe(() => {
        this.clear();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clear(): void {
    this.savedRoles = undefined;
    this.roles$ = undefined;
  }

  getRoles(): AppRole[] {
    return (this.savedRoles || []).slice(0);
  }

  refreshRolesIfNecessary(): Observable<AppRole[]> {
    return this.roles$ ?? (this.roles$ = this.refreshRoles());
  }

  refreshRoles(): Observable<AppRole[]> {
    return this.accountsService.getRoles().pipe(
      tap(m => {
        this.savedRoles = m;
      })
    );
  }

  isInRole(appRole: AppRole): boolean {
    return this.getRoles().includes(appRole);
  }
}
