import { ChangeDetectionStrategy, Component, Injector, computed, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BackdropType, type Dialog2ServiceImpl, POPOVER_DATA_TYPED, PopoverRef } from '@models/dialog';
import { type FancyFilterConfig } from '@models/filter-models';
import { SharedModule } from '@modules/shared.module';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import { merge, of, skip } from 'rxjs';

export interface PopoverFilterDynamicData {
  uiConfigs: FancyFilterConfig<any, unknown>[];
  service: DynamicFilterService2;
  filterId: string;
}

const injectionToken = POPOVER_DATA_TYPED<PopoverFilterDynamicData>();

@Component({
  templateUrl: 'popover-filter-dynamic.component.html',
  styleUrls: ['popover-filter-dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
})
export class PopoverFilterDynamicComponent {
  readonly popoverData = inject(injectionToken);
  private readonly popoverRef = inject(PopoverRef<PopoverFilterDynamicComponent>);

  readonly injector = computed(() => {
    return Injector.create({
      name: 'Popover_Filter',
      providers: [{ provide: DynamicFilterService2, useValue: this.popoverData.service }],
    });
  });

  _ = merge(
    ...this.popoverData.uiConfigs.map(uiConfig =>
      uiConfig.ui.closeAfterSelection
        ? this.popoverData.service.changes(this.popoverData.filterId, uiConfig.ui.inputs.code, uiConfig.ui.inputs.name).pipe(skip(1))
        : of()
    )
  )
    .pipe(takeUntilDestroyed())
    .subscribe(() => {
      this.popoverRef.close();
    });

  getInputs(config: FancyFilterConfig): Record<string, unknown> {
    return {
      ...((config.ui as { inputs?: Record<string, unknown> }).inputs ?? {}),
      filterId: this.popoverData.filterId,
    };
  }

  static open(dialog: Dialog2ServiceImpl, element: HTMLElement, data: PopoverFilterDynamicData) {
    return dialog.popover(PopoverFilterDynamicComponent, element, {
      backdrop: BackdropType.Transparent,
      popoverData: {
        injectionToken,
        data,
      },
    });
  }
}
