import { Injectable, inject } from '@angular/core';
import { type ReportCategoryInformation } from '@models/report-models';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class ReportCategoriesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  list(): Observable<ReportCategoryInformation[]> {
    return this.http.get<ReportCategoryInformation[]>(this.url.reportCategoryGetList);
  }

  get(id: Id): Observable<ReportCategoryInformation> {
    return this.http.get<ReportCategoryInformation>(this.url.reportCategoryGet.replace('$0', id.toString()));
  }

  save(reportCategory: ReportCategoryInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.reportCategoryPost, reportCategory);
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.reportCategoryDelete.replace('$0', id.toString()));
  }

  hide(id: Id): Observable<void> {
    return this.http.post<void>(this.url.reportCategoryHide.replace('$0', id.toString()), null);
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.reportCategoryRestore.replace('$0', id.toString()), null);
  }
}
