import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { type AdminContractorInformation } from '@models/admin/contractor-information';
import { FILTER_DIALOG_DATA } from '@models/dialog';
import { type FilterData } from '@models/filter-models';
import { SharedModule } from '@modules/shared.module';
import { FilterComponent } from './filter.component';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';

export interface ContractorFilterData extends FilterData {
  contractors: AdminContractorInformation[];
}

/** @deprecated */
@Component({
  templateUrl: 'filter-export.component.html',
  styleUrls: ['filter-export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, InputDropdownComponent],
})
export class FilterExportComponent extends FilterComponent {
  _datePipe: DatePipe;

  contractors: AdminContractorInformation[];

  constructor(@Inject(FILTER_DIALOG_DATA) data: ContractorFilterData) {
    super(data);
    this._datePipe = new DatePipe('en-US');
    this.contractors = data.contractors;
  }

  get contractorId(): number | undefined {
    return this.getValue('contractorId');
  }

  set contractorId(value: number | undefined) {
    if (value) {
      this.setValue('contractorId', `Contractor: ${this.getContractorName(value)}`, value);
    } else {
      this.removeValue('contractorId');
    }
  }

  getContractorName(value: Id): string {
    return this.contractors.find(m => m.id === value)?.text ?? '';
  }
}
