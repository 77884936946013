// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  display: block;
  max-width: 500px;
  min-width: 500px;
  border: 1px solid #d1d5d8;
  border-radius: 4px;
  overflow: auto;
  background-color: #f1f2f3;
}
:host section.top {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 16px;
  border-bottom: 1px solid #d1d5d8;
}
:host section.top .text {
  display: flex;
  flex-direction: column;
  flex: 1;
}
:host section.top .title {
  font-size: 14px;
  color: #232628;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 16px;
}
:host section.top .subtitle {
  margin-top: 4px;
  font-size: 12px;
  color: #232628;
  line-height: 16px;
}
:host section.top img.type {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}
:host section.top .text {
  position: relative;
}
:host section.top .right-aligned {
  position: absolute;
  right: 0;
  top: 0;
}
:host section.content {
  margin: 16px;
  padding: 0;
  /* Not in used here?
    section {
        margin: 0 (-\$dialog-padding) 0 (-\$dialog-padding);
        border-bottom: 1px solid gray;
        padding: \$dialog-padding;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        &:first-child {
            padding-top: 0;
        }
    }
    */
}
:host section.content .wm-card2 {
  width: 100%;
  box-shadow: none;
}
:host section.actions {
  padding: 0;
  margin: 16px;
  display: flex;
  justify-content: flex-end;
  min-height: inherit;
}
:host section.actions > * {
  margin-right: 8px;
}
:host section.actions > *:last-child {
  margin-right: 0;
}
:host section.actions .buttons {
  justify-content: flex-end;
  flex: 1;
  display: flex;
  align-items: center;
}
:host section.actions .buttons > * {
  margin-right: 8px;
}
:host section.actions .buttons > *:last-child {
  margin-right: 0;
}

.tabs {
  margin-bottom: 16px;
}
.tabs ul {
  display: flex;
}
.tabs ul li {
  margin-right: 16px;
}
.tabs ul li button {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 11px;
}
.tabs ul li:last-child {
  margin-right: 0;
}
.tabs ul li.selected button {
  color: #232628;
}

.tab-content .tab {
  display: none;
  background-color: #ffffff;
  color: #232628;
  padding: 16px;
  border-radius: 4px;
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #d1d5d8;
}
.tab-content .tab.tab-puny {
  background-color: transparent;
  padding: 0;
  border: 0;
}
.tab-content .tab.selected {
  display: block;
}

.icons {
  text-align: right;
  flex-grow: 1;
}
.icons ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.icons ul li {
  margin-left: 8px;
}
.icons .call-type {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  display: block;
  font-size: 10px;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 2px;
  text-transform: uppercase;
}
.icons .call-type.Scheduled {
  background-color: #246ee9;
}
.icons .call-type.Released {
  background-color: #1f961e;
}
.icons .call-type.Arrived {
  background-color: #ff9926;
}
.icons .call-type.Departed {
  background-color: #f6c01a;
}
.icons .call-type.Completed {
  background-color: #6031a3;
}
.icons .call-type.Processed {
  background-color: #232628;
}
.icons .call-type.Blocked {
  background-color: #697076;
}
.icons .call-type.Deleted {
  background-color: #e52217;
}
.icons .send-to-ipad img {
  width: 16px;
  height: 16px;
}

.call wm-input-dropdown {
  width: 100%;
}

.site-history h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}
.site-history .flex {
  display: flex;
}
.site-history .flex > * {
  width: 50%;
}
.site-history .systems {
  margin-top: 16px;
}
.site-history .systems .title {
  border: none;
  margin-bottom: 8px;
}
.site-history .systems ul {
  list-style: disc;
  margin-left: 24px;
}
.site-history .systems .wm-description {
  margin-left: 32px;
}
.site-history .site-system .text {
  display: flex;
  align-items: center;
}
.site-history .site-system .text img {
  margin-right: 4px;
}
.site-history .template-text {
  white-space: pre-wrap;
}
.site-history .medium-icon {
  width: 16px;
  height: 16px;
}

.agreement .vertical-table th {
  width: 40%;
}
.agreement .vertical-table .small-icon {
  width: 10px;
  height: 10px;
}
.agreement .vertical-table .agreement-visits .badge {
  margin-right: 8px;
  margin-bottom: 2px;
}
.agreement .vertical-table .agreement-visits .badge:last-child {
  margin-bottom: 0;
}

label {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #232628;
  margin-bottom: 4px;
  display: block;
}

.wm-description:not([hidden]) ~ .wm-description,
wm-popover-event-note-metadata {
  margin-top: 12px;
}

.read {
  margin-left: 8px;
  line-height: 24px;
  min-height: 24px;
}

button.more {
  position: relative;
}
button.more.more .text {
  margin-right: 8px;
}
button.more .btn-arrow {
  position: absolute;
  right: 6px;
}
button.more .btn-arrow img {
  width: 8px;
  height: 5px;
}

.loading {
  text-align: center;
}
.loading .spinner {
  border: 2px solid #ffffff; /* Light grey */
  border-top: 2px solid #7ca8f2; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
}
.loading .spinner {
  zoom: 6;
}

.section {
  margin-bottom: 32px;
}
.section:last-child {
  margin-bottom: 0;
}

.beacon-box {
  background-color: #ffddd6;
  color: #e52217;
  margin-left: 0 !important;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  line-height: normal !important;
  margin-bottom: 16px;
}
.beacon-box .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.beacon-box .content .take-charge {
  margin-top: 16px;
}
.beacon-box::before {
  content: " ";
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
  background-image: url(/assets/images/late-snail-circle.svg);
  background-repeat: no-repeat;
  margin: 0;
  margin-right: 4px;
  vertical-align: sub;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
