import { type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ConfigService } from '@services/config.service';

export const JWTInterceptor: HttpInterceptorFn = (req, next, configService = inject(ConfigService), authService = inject(AuthService)) => {
  // If we are making a local call, or if we are contacting our server or sub-server.
  if (
    authService &&
    req.url &&
    !req.url.startsWith('/assets/') &&
    (req.url.startsWith('/') || configService.config.allowedOrigins.includes(getOrigin(req.url)))
  ) {
    const token = authService.getCurrentToken();

    if (token) {
      const JWT = `Bearer ${token.token.access_token}`;
      req = req.clone({
        setHeaders: {
          Authorization: JWT,
        },
      });
    }
  }

  return next(req);
};

const getOrigin = (url: string) => {
  if (url && (url.startsWith('http://') || url.startsWith('https://'))) {
    return new URL(url).origin;
  }

  return location.origin;
};

