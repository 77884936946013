import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[enter]',
  standalone: true,
})
export class EnterDirective {
  @Output() enter = new EventEmitter<any>();

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.which === 13) {
      this.enter.emit(event);
    }
  }
}
