import { Component, effect, forwardRef, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { Mode } from '@models/form';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import endOfDay from 'date-fns/endOfDay';
import { combineLatest, concatMap, filter } from 'rxjs';

// Angular 18
@Component({
  template: `<div class="wm-description">
    <label>{{ label() }}</label>
    <div class="read range">
      <div>
        <label>{{ fromLabel() }}</label>
        <wm-input-moment-picker [showDate]="true" [ngModel]="getFrom()" (ngModelChange)="setFrom($event)" />
      </div>
      <div>
        <label>{{ toLabel() }}</label>
        <wm-input-moment-picker [showDate]="true" [ngModel]="getTo()" (ngModelChange)="setTo($event)" />
      </div>
    </div>
  </div>`,
  styleUrl: 'filter-dynamic-common.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDynamicEasyDateComponent),
      multi: true,
    },
  ],
  imports: [InputMomentPickerComponent, FormsModule],
  standalone: true,
})
export class FilterDynamicEasyDateComponent extends ElementBaseWithFormControl<{ from?: Date; to?: Date } | null> {
  readonly Mode = Mode;

  private readonly dynamicFilterService = inject(DynamicFilterService2);

  readonly filterId = input<string>('');
  readonly label = input<string>();
  readonly code = input<string>(); // required
  readonly name = input<string>(); // required
  readonly fromLabel = input('From');
  readonly toLabel = input('To');

  private readonly valueChanges = toSignal(this.control.valueChanges);
  private readonly serviceChanges = toSignal(
    combineLatest([toObservable(this.code), toObservable(this.name)]).pipe(
      filter(([code, name]) => !!code && !!name),
      concatMap(([code, name]) =>
        this.dynamicFilterService.changes<{ from?: Date; to?: Date } | null>(this.filterId(), code ?? '', name ?? '')
      )
    ),
    { initialValue: null }
  );

  _ = effect(() => {
    this.dynamicFilterService.patch(this.filterId(), this.code() ?? '', this.name() ?? '', this.valueChanges());
  });

  __ = effect(
    () => {
      this.control.patchValue(this.serviceChanges() ?? null);
    },
    { allowSignalWrites: true }
  );

  constructor() {
    super(() => null);
  }

  getFrom(): Date | undefined {
    return this.control.getRawValue()?.from;
  }

  setFrom(value: Date | null): void {
    const existing = { ...(this.control.getRawValue() ?? {}) };
    delete existing['from'];
    if (value) {
      existing['from'] = value;
    }

    this.control.patchValue(existing);
  }

  getTo(): Date | undefined {
    return this.control.getRawValue()?.to;
  }

  setTo(value: Date | null): void {
    const existing = { ...(this.control.getRawValue() ?? {}) };
    delete existing['to'];
    if (value) {
      existing['to'] = endOfDay(value);
    }

    this.control.patchValue(existing);
  }
}
