import { type AppRole } from '@models/app-role';
import { type ScheduleEventInformation } from '@models/dashboard-event';
import { createAction, props } from '@ngrx/store';
import { createSubmitAction, createSubmitFailAction, createSubmitSuccessAction } from '@services/save-store/save.actions';
import { DASHBOARD_STORE_KEY } from './dashboard.selectors';

export enum DashboardSignalRMessageType {
  Event = 1,
  Customer = 2,
  Site = 3,
  WorkOrder = 4,
}

export interface DashboardSignalRMessageEvent {
  messageType: DashboardSignalRMessageType.Event;
  id: Id;
  callId: Id | null;
  start: Date | null;
  end: Date | null;
  removed: boolean;
}

export interface DashboardSignalRMessageCustomer {
  messageType: DashboardSignalRMessageType.Customer;
  id: Id;
}

export interface DashboardSignalRMessageSite {
  messageType: DashboardSignalRMessageType.Site;
  id: Id;
}

export interface DashboardSignalRMessageWorkOrder {
  messageType: DashboardSignalRMessageType.WorkOrder;
  id: Id;
}

export type DashboardSignalRMessage =
  | DashboardSignalRMessageEvent
  | DashboardSignalRMessageCustomer
  | DashboardSignalRMessageSite
  | DashboardSignalRMessageWorkOrder;

export const clear = createAction('[Dashboard] Clear');

export const setRoles = createAction('[Dashboard] Set Roles', props<{ roles: AppRole[] }>());
export const changeStatus = createAction('[Dashboard] Change Status', props<{ active: boolean }>());
export const changeConnected = createAction('[Dashboard] Change Connected', props<{ connected: boolean }>());
export const changeLiveUpdate = createAction('[Dashboard] Change Live Update', props<{ enable: boolean }>());

export const changeCurrentDate = createAction('[Dashboard] Change Current Date', props<{ date: Date }>());
export const forceRefresh = createAction('[Dashboard] Force Refresh');

export const loadEventsByDate = createSubmitAction(DASHBOARD_STORE_KEY, '[Dashboard] Load Events by date', props<{ date: Date }>());
export const loadEventsByDateSuccess = createSubmitSuccessAction(
  DASHBOARD_STORE_KEY,
  '[Dashboard][Success] Load Events by date',
  props<{ date: Date; scheduleEvents: ScheduleEventInformation[] }>()
);
export const loadEventsByDateFail = createSubmitFailAction(
  DASHBOARD_STORE_KEY,
  '[Dashboard][Fail] Load Events by date',
  props<{ error: string }>()
);

export const loadEventById = createSubmitAction(DASHBOARD_STORE_KEY, '[Dashboard] Load Event by Id', props<{ id: Id }>());
export const loadEventByIdSuccess = createSubmitSuccessAction(
  DASHBOARD_STORE_KEY,
  '[Dashboard][Success] Load Event by Id',
  props<{ scheduleEvent: ScheduleEventInformation }>()
);
export const loadEventByIdFail = createSubmitFailAction(
  DASHBOARD_STORE_KEY,
  '[Dashboard][Fail] Load Event by Id',
  props<{ error: string }>()
);

export const signalRMessage = createAction('[Dashboard] SignalR Message', props<{ message: DashboardSignalRMessage }>());
export const signalRMessageEvent = createAction('[Dashboard] SignalR Event', props<{ message: DashboardSignalRMessageEvent }>());
export const signalRMessageCustomer = createAction('[Dashboard] SignalR Customer', props<{ message: DashboardSignalRMessageCustomer }>());
export const signalRMessageSite = createAction('[Dashboard] SignalR Site', props<{ message: DashboardSignalRMessageSite }>());
export const signalRMessageWorkOrder = createAction(
  '[Dashboard] SignalR WorkOrder',
  props<{ message: DashboardSignalRMessageWorkOrder }>()
);

export const updateDashboardEvent = createAction(
  '[Dashboard] Update Dashboard Event',
  props<{ scheduleEvent: ScheduleEventInformation }>()
);

export const evictSpecificEvents = createAction('[Dashboard] Evict Specific Events', props<{ loadedDates: Date[] }>());
export const evictOldEvents = createAction('[Dashboard] Evict Old Events');
export const trackEviction = createAction('[Dashboard] Track Eviction', props<{ loadedDates: number; scheduleEvents: number }>());
export const noop = createAction('[Dashboard] noop');

export type Actions = ReturnType<typeof changeStatus | typeof loadEventsByDate | typeof loadEventById>;
