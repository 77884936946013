import { type PartItemInformation } from '@models/price-book-models';
import { createAction, props } from '@ngrx/store';
import {
  createSubmitAction,
  createSubmitFailAction,
  createSubmitInitialAction,
  createSubmitSuccessAction,
} from '@services/save-store/save.actions';
import { PART_ITEM_DETAIL_STORE_KEY } from './part-item-detail.selectors';

export const clear = createAction('[PartItemDetail] clear');

export const loadPartItem = createAction('[PartItemDetail] loadPartItem', props<{ data: PartItemInformation }>());
export const updatePartItem = createAction('[PartItemDetail] updatePartItem', props<{ patch: Partial<PartItemInformation> }>());

export const loadCategoryIds = createAction('[PartItemDetail] loadCategoryIds', props<{ data: Id[] }>());
export const setCategoryIds = createAction('[PartItemDetail] setCategoryIds', props<{ data: Id[] }>());

export const loadServiceRepairs = createAction('[PartItemDetail] loadServiceRepairs', props<{ data: Id[] }>());
export const addServiceRepair = createAction('[PartItemDetail] addServiceRepair', props<{ data: Id }>());
export const deleteServiceRepair = createAction('[PartItemDetail] deleteServiceRepair', props<{ id: Id }>());

export const save = createSubmitInitialAction(PART_ITEM_DETAIL_STORE_KEY, '[PartItemDetail] save');

export const noop = createAction('[PartItemDetail] noop');
export const noop1 = createAction('[PartItemDetail] noop1');
export const noopWithCounting = createSubmitSuccessAction(PART_ITEM_DETAIL_STORE_KEY, '[PartItemDetail] noopWithCounting');

export const savePartItemSuccess = createSubmitSuccessAction(
  PART_ITEM_DETAIL_STORE_KEY,
  '[PartItemDetail][Save][Success] PartItem',
  props<{ id: Id }>()
);
export const savePartItemFail = createSubmitFailAction(
  PART_ITEM_DETAIL_STORE_KEY,
  '[PartItemDetail][Save][Fail] PartItem',
  props<{ error: string }>()
);

export const saveCategoryIds = createSubmitAction(PART_ITEM_DETAIL_STORE_KEY, '[PartItemDetail][Save] saveCategoryIds');
export const saveCategoryIdsSuccess = createSubmitSuccessAction(
  PART_ITEM_DETAIL_STORE_KEY,
  '[PartItemDetail][Save][Success] saveCategoryIds'
);
export const saveCategoryIdsFail = createSubmitFailAction(
  PART_ITEM_DETAIL_STORE_KEY,
  '[PartItemDetail][Save][Fail] saveCategoryIds',
  props<{ error: string }>()
);

export const saveServiceRepairSave = createSubmitAction(PART_ITEM_DETAIL_STORE_KEY, '[PartItemDetail][Save] ServiceRepair');
export const saveServiceRepairSaveSuccess = createSubmitSuccessAction(
  PART_ITEM_DETAIL_STORE_KEY,
  '[PartItemDetail][Save][Success] ServiceRepair'
);
export const saveServiceRepairFail = createSubmitFailAction(
  PART_ITEM_DETAIL_STORE_KEY,
  '[PartItemDetail][Save][Fail] ServiceRepair',
  props<{ error: string }>()
);

export const editMode = createAction('[PartItemDetail] edit');
export const cancel = createAction('[PartItemDetail] cancel');

export type Actions = ReturnType<
  | typeof clear
  | typeof loadPartItem
  | typeof updatePartItem
  | typeof loadCategoryIds
  | typeof setCategoryIds
  | typeof loadServiceRepairs
  | typeof addServiceRepair
  | typeof deleteServiceRepair
  | typeof save
  | typeof noop
  | typeof noopWithCounting
  | typeof savePartItemSuccess
  | typeof savePartItemFail
  | typeof saveCategoryIds
  | typeof saveCategoryIdsSuccess
  | typeof saveCategoryIdsFail
  | typeof saveServiceRepairSave
  | typeof saveServiceRepairSaveSuccess
  | typeof saveServiceRepairFail
  | typeof editMode
  | typeof cancel
>;
