import { Injectable, Pipe, inject, type PipeTransform } from '@angular/core';
import { type SiteInformation } from '@models/cards/site-information';
import { EntityPipe } from './entity.pipe';

@Pipe({
  name: 'site',
  standalone: true,
})
@Injectable()
export class SitePipe implements PipeTransform {
  private readonly entityPipe = inject(EntityPipe);

  transform(site: SiteInformation): string {
    if (!site) {
      return '';
    }

    if (!site.address?.street) {
      return this.entityPipe.transform(site.entity);
    }

    return site.address.street;
  }
}
