import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PubSubService } from '@services/pub-sub.service';
import { map, of, tap, type Observable } from 'rxjs';
import { lru } from 'tiny-lru';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

type SupportedFeatueFlags = 'TableFilters' | 'DutyTableFilters' | 'SalesProposalTableFilters' | 'AgreementTableFilters';

const thirtyMinutes = 30 * 1000 * 1000;
const featureFlagKey = 'FeatureFlag';
const cache = lru<string[]>(1, thirtyMinutes, true);

@Injectable()
export class FeatureFlagsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly pubSubService = inject(PubSubService);

  constructor() {
    this.pubSubService
      .getEventEmitter('LOGIN')
      .pipe(takeUntilDestroyed())
      .pipe(
        tap(() => {
          console.log('Clearing FeatureFlags.');
        })
      )
      .subscribe(() => {
        cache.clear();
      });
  }

  get(): Observable<string[]> {
    const featureFlags = cache.get(featureFlagKey);
    if (!featureFlags) {
      return this.http.get<string[]>(this.url.featureFlagsGet).pipe(tap({ next: ff => cache.set(featureFlagKey, ff) }));
    }

    return of(featureFlags);
  }

  hasFeature(featureFlag: SupportedFeatueFlags): Observable<boolean> {
    return this.get().pipe(map(m => m.includes(featureFlag)));
  }
}
