/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type WorkOrderInformation } from '@models/cards/work-order-information';
import { type Creatable, type Deletable, type Updatable, type Mutable } from '@models/mutable';
import { type TaxItemEntry } from '@models/pricing-models';
import { type Resource } from '@models/resource';
import { type TaxItem } from '@models/tax-item';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { indexBy } from '@utility/array';
import { normalOrder } from '@utility/sort-fnc';
import { Decimal } from 'decimal.js';
import {
  type DebriefAnswerState,
  type RecommendationPhotoState,
  type RecommendationState,
  type TagEntityState,
  type WorkOrderAgreementState,
  type WorkOrderInformationState,
  type WorkOrderPaymentState,
  type WorkOrderRepairPartState,
  type WorkOrderState,
} from './work-order.reducer';
import { isPlainObject } from 'lodash';

function buildArray<T extends Resource>(obj: Record<string, T & Mutable>, includeHidden?: boolean): T[] {
  const objArray = Object.values(obj);

  if (includeHidden) {
    return objArray;
  }

  return objArray.filter(m => !m.deleted);
}

export const WORK_ORDER_STORE_KEY = 'workOrder';

export const workOrderStateSelector = createFeatureSelector<WorkOrderState>(WORK_ORDER_STORE_KEY);

export const mode = createSelector(workOrderStateSelector, (state: WorkOrderState) => state.edit);
export const workOrder$ = createSelector(workOrderStateSelector, state => state.entities.workOrder);
export const workOrderLoanApplications$ = createSelector(workOrderStateSelector, state => state.entities.workOrderLoanApplications);
export const workOrderDetails$ = createSelector(workOrderStateSelector, state => state.entities.workOrderDetails);
export const workOrderRepairParts$ = createSelector(workOrderStateSelector, state => state.entities.workOrderRepairParts);
export const nonStockItems$ = createSelector(workOrderStateSelector, state => state.entities.nonStockItems);
export const workOrderDiscounts$ = createSelector(workOrderStateSelector, state => state.entities.workOrderDiscounts);
export const workOrderRebates$ = createSelector(workOrderStateSelector, state => state.entities.workOrderRebates);
export const workOrderAgreements$ = createSelector(workOrderStateSelector, state => state.entities.workOrderAgreements);
export const workOrderPayments$ = createSelector(workOrderStateSelector, state => state.entities.workOrderPayments);
export const recommendations$ = createSelector(workOrderStateSelector, state => state.entities.recommendations);
export const recommendationPhotos$ = createSelector(workOrderStateSelector, state => state.entities.recommendationPhotos);
export const debriefAnswers$ = createSelector(workOrderStateSelector, state => state.entities.debriefAnswers);
export const tagEntities$ = createSelector(workOrderStateSelector, state => state.entities.tagEntities);
export const inspectionAnswers$ = createSelector(workOrderStateSelector, state => state.entities.inspectionAnswers);
export const siteSystems$ = createSelector(workOrderStateSelector, state => state.entities.siteSystems);
export const workOrderSiteSystems$ = createSelector(workOrderStateSelector, state => state.entities.workOrderSiteSystems);
export const call$ = createSelector(workOrderStateSelector, state => state.entities.call);
export const propertyTypeId$ = createSelector(workOrderStateSelector, state => state.entities.propertyTypeId);
export const classMappingPerDetail$ = createSelector(workOrderStateSelector, state => state.entities.classMappingPerDetail);
export const salesProposal$ = createSelector(workOrderStateSelector, state => state.entities.salesProposal);
export const salesProposalPackage$ = createSelector(workOrderStateSelector, state => state.entities.salesProposalPackage);
export const agreementSiteSystems$ = createSelector(workOrderStateSelector, state => state.entities.agreementSiteSystems);
export const taxItemSections$ = createSelector(workOrderStateSelector, state => state.taxItemSections);
export const internalTaxItemEntry$ = createSelector(workOrderStateSelector, state => state.entities.internalTaxItemEntry);

export const workOrderDetailArray$ = createSelector(workOrderDetails$, workOrderDetails =>
  buildArray(workOrderDetails).sort(normalOrder())
);
export const workOrderRepairPartArray$ = createSelector(workOrderRepairParts$, workOrderRepairParts => buildArray(workOrderRepairParts));
export const nonStockItemArray$ = createSelector(nonStockItems$, nonStockItems => buildArray(nonStockItems));
export const workOrderDiscountArray$ = createSelector(workOrderDiscounts$, workOrderDiscounts => buildArray(workOrderDiscounts));
export const workOrderRebateArray$ = createSelector(workOrderRebates$, workOrderRebates => buildArray(workOrderRebates));
export const workOrderAgreementArray$ = createSelector(workOrderAgreements$, workOrderAgreements => buildArray(workOrderAgreements));
export const workOrderPaymentArray$ = createSelector(workOrderPayments$, workOrderPayments => buildArray(workOrderPayments));
export const recommendationArray$ = createSelector(recommendations$, recommendations => buildArray(recommendations));
export const recommendationPhotoArray$ = createSelector(recommendationPhotos$, recommendationPhotos => buildArray(recommendationPhotos));
export const debriefAnswerArray$ = createSelector(debriefAnswers$, debriefAnswers => buildArray(debriefAnswers, true));
export const tagEntityArray$ = createSelector(tagEntities$, tagEntities => buildArray(tagEntities, false));
export const inspectionAnswersArray$ = createSelector(inspectionAnswers$, inspectionAnswers =>
  buildArray(inspectionAnswers).sort(normalOrder())
);
export const siteSystemsArray$ = createSelector(siteSystems$, siteSystems => buildArray(siteSystems));
export const workOrderSiteSystemsArray$ = createSelector(workOrderSiteSystems$, workOrderSiteSystems => buildArray(workOrderSiteSystems));
export const agreementSiteSystemArray$ = createSelector(agreementSiteSystems$, agreementSiteSystems => buildArray(agreementSiteSystems));
export const workOrderLoanApplicationArray$ = createSelector(workOrderLoanApplications$, workOrderLoanApplications =>
  buildArray(workOrderLoanApplications)
);

export const cacheCorrelationId$ = createSelector(workOrderStateSelector, state => state._cacheCorrelationId);
export const saveCorrelationId$ = createSelector(workOrderStateSelector, state => state._correlationId);
export const error$ = createSelector(workOrderStateSelector, state => state._error);
export const submitCounter$ = createSelector(workOrderStateSelector, state => state._submitCounter);
export const entities$ = createSelector(workOrderStateSelector, state => state.entities);

export const taxItemEntry$ = createSelector(
  workOrder$,
  taxItemSections$,
  internalTaxItemEntry$,
  (workOrder, taxItemSections, internalTaxItemEntry) => {
    if (internalTaxItemEntry && typeof internalTaxItemEntry.taxItem !== 'undefined') {
      return internalTaxItemEntry;
    }

    if (workOrder && taxItemSections) {
      let taxItem: TaxItem = null;
      if (workOrder.taxItemId) {
        for (const taxItemSection of taxItemSections) {
          taxItem = taxItemSection.items.find(m => m.id === workOrder.taxItemId);
          if (taxItem) {
            break;
          }
        }
      }

      return {
        taxItem,
        taxItemManual: workOrder.salesTaxPercentage && new Decimal(workOrder.salesTaxPercentage).times(100).toNumber(),
        taxOverride: workOrder.taxOverride,
      } as TaxItemEntry;
    }

    return null;
  }
);

export const workOrderAndTaxItemEntry = createSelector(workOrder$, internalTaxItemEntry$, (workOrder, taxItemEntry) => ({
  workOrder,
  taxItemEntry,
}));

export const workOrderRepairPartsByDetail$ = (props: { workOrderDetailId: Id }) =>
  createSelector(workOrderRepairParts$, (repairParts: WorkOrderRepairPartState) =>
    indexBy(
      Object.values(repairParts).filter(m => m.parentId === props.workOrderDetailId),
      'id'
    )
  );

export const workOrderPaymentWithParentId$ = createSelector(
  workOrder$,
  workOrderPayments$,
  (workOrder: WorkOrderInformation, workOrderPayments: WorkOrderPaymentState, props) => ({
    workOrderId: workOrder.id,
    resource: workOrderPayments[props.id],
  })
);

export const recommendationWithParentId$ = createSelector(
  workOrder$,
  recommendations$,
  (workOrder: WorkOrderInformation, recommendations: RecommendationState, props) => ({
    workOrderId: workOrder.id,
    resource: recommendations[props.id],
  })
);

export const recommendationPhotoWithParentId$ = createSelector(
  workOrder$,
  recommendationPhotos$,
  (workOrder: WorkOrderInformation, recommendationPhotos: RecommendationPhotoState, props) => ({
    workOrderId: workOrder.id,
    recommendationId: recommendationPhotos[props.id].recommendationId,
    resource: recommendationPhotos[props.id],
  })
);

export const workOrderDebriefAnswerWithParentId$ = createSelector(
  workOrder$,
  debriefAnswers$,
  (workOrder, debriefAnswers: DebriefAnswerState, props) => ({
    workOrderId: workOrder.id,
    resource: debriefAnswers[props.id],
  })
);

export const workOrderTagEntityWithParentId$ = createSelector(
  workOrder$,
  tagEntities$,
  (workOrder, tagEntities: TagEntityState, props) => ({
    workOrderId: workOrder.id,
    resource: tagEntities[props.id],
  })
);

export const siteSystem$ = createSelector(siteSystems$, (siteSystems, props) => siteSystems[props.id]);

export const workOrderDiscountsithParentId$ = createSelector(workOrder$, workOrderDiscounts$, (workOrder, workOrderDiscounts, props) => ({
  workOrderId: workOrder.id,
  resource: workOrderDiscounts[props.id],
}));

export const workOrderRebateWithParentId$ = createSelector(workOrder$, workOrderRebates$, (workOrder, workOrderRebates, props) => ({
  workOrderId: workOrder.id,
  resource: workOrderRebates[props.id],
}));

export const workOrderDetailWithParentId$ = (props: { workOrderDetailId: Id }) =>
  createSelector(workOrder$, workOrderDetails$, nonStockItems$, (workOrder, workOrderDetails, nonStockItems) => {
    const resource = workOrderDetails[props.workOrderDetailId];
    const nonStockItem = resource?.nonStockItemId ? nonStockItems[resource.nonStockItemId] : null;

    return {
      workOrderId: workOrder.id,
      resource,
      nonStockItem,
    };
  });

export const workOrderLoanApplicationWithParentId$ = createSelector(
  workOrder$,
  workOrderLoanApplications$,
  (workOrder, workOrderLoanApplications, props) => ({
    workOrderId: workOrder.id,
    resource: workOrderLoanApplications[props.id],
  })
);

export const agreementSiteSystemWithParentId$ = createSelector(
  workOrder$,
  agreementSiteSystems$,
  (workOrder, agreementSiteSystems, props) => ({
    workOrderId: workOrder.id,
    resource: agreementSiteSystems[props.id],
  })
);

export const workOrderRepairPartWithParentId$ = createSelector(
  workOrder$,
  workOrderRepairParts$,
  (workOrder: WorkOrderInformation, workOrderRepairParts: WorkOrderRepairPartState, props) => ({
    workOrderId: workOrder.id,
    detailId: workOrderRepairParts[props.id].parentId,
    resource: workOrderRepairParts[props.id],
  })
);

export const workOrderAgreementsByAgreementSiteSystemId$ = createSelector(
  workOrderAgreements$,
  (workOrderAgreements: WorkOrderAgreementState, props) => ({
    workOrderAgreements: Object.values(workOrderAgreements).filter(wOA => wOA.agreementSiteSystemId === props.agreementSiteSystemId),
  })
);

export const workOrderAgreementByIdWithParentId$ = createSelector(
  workOrder$,
  workOrderAgreements$,
  (workOrder: WorkOrderInformationState, workOrderAgreements: WorkOrderAgreementState, props) => ({
    workOrderId: workOrder.id,
    resource: workOrderAgreements[props.id],
  })
);

export const hasVisibleData$ = createSelector(
  workOrderDetails$,
  workOrderAgreements$,
  workOrderDiscounts$,
  workOrderRebates$,
  (workOrderDetails, workOrderAgreements, workOrderDiscounts, workOrderRebates) => {
    return (
      Object.values(workOrderDetails).some(m => !m.hidden && !m.deleted) ||
      Object.values(workOrderAgreements).some(m => !m.hidden && !m.deleted) ||
      Object.values(workOrderDiscounts).some(m => !m.hidden && !m.deleted) ||
      Object.values(workOrderRebates).some(m => !m.hidden && !m.deleted)
    );
  }
);

export const hasFinishedUpdate$ = createSelector(entities$, submitCounter$, (entities, submitCounter) => {
  const isPending = (entity: Resource | null | undefined): boolean =>
    entity &&
    (!!(entity as Creatable).created ||
      (!!(entity as Updatable).updated && entity.id > 0) ||
      (!!(entity as Deletable).deleted && entity.id > 0));

  return submitCounter === 0 && !Object.values(entities).some(x => isPending(x) || (isPlainObject(x) && Object.values(x).some(isPending)));
});
