import { Injectable, inject } from '@angular/core';
import { type ZoneInformation } from '@models/cards/zone-information';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class ZonesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(id?: Id): Observable<ZoneInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.Zones, this.url.zonesList, id);
  }

  save(zoneInformation: ZoneInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.zonesCreate, zoneInformation, skipBubbleError()).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.zonesDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.Zones);
    }
  }
}
