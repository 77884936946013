// Custom to EnerBank
export enum Ownership {
  Occupant = 'occupant',
  Owner = 'owner',
}

// Custom to EnerBank
export enum PropertyType {
  SingleFamily = 'singleFamily',
  MultiFamily = 'multiFamily',
  CondominiumOrTownhouse = 'condominiumOrTownhouse',
  Mobile = 'mobile',
}

export class EnerBankExtra {
  applicationId?: string;
  redirectUrl?: string;
  ownership?: Ownership;
  propertyType?: PropertyType;
  monthlyMortgagePayment?: Money | null;
}
