import { Injectable, inject } from '@angular/core';
import { type PaymentGateway } from '@models/payments';
import { type SubscriptionInitialViewData, type SubscriptionSettings } from '@models/subscriptions';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { HttpClientService } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class SubscriptionsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  getSettings(): Observable<SubscriptionInitialViewData> {
    return this.applicationCacheService.getSettingsFromCache(UpdateCategory.Subscriptions, this.url.subscriptionsGetSettings);
  }

  setSettings(chargeSettings: SubscriptionSettings): Observable<void> {
    return this.http.post<void>(this.url.subscriptionsSetSettings, chargeSettings).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  disconnect(paymentGateway: PaymentGateway): Observable<void> {
    return this.http.post<void>(this.url.subscriptionsDisconnect, { input: paymentGateway }).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  deleteCard(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.subscriptionsDeleteCard.replace('$0', id.toString()));
  }

  clearCache(): void {
    this.applicationCacheService.clearCategory(UpdateCategory.Subscriptions);
  }
}
