import { Injectable, inject } from '@angular/core';
import { EnerBankApplicantNameComponent } from '@dialogviews/ener-bank-applicant-name.component';
import { EnerBankProjectInfoComponent } from '@dialogviews/ener-bank-project-info.component';
import { DIALOG_SERVICE_IMPL } from '@models/dialog';
import { type EnerBankExtra } from '@models/ener-bank';
import { LoanCompany, getLoanStatus, type LoanCreate, type LoanSelection, type WorkOrderLoanApplicationInformation } from '@models/loans';
import { CustomersService } from '@services/live/customers.service';
import { WindowRefService } from '@services/window-ref.service';
import { notEmpty } from '@utility/array';
import { clone } from '@utility/object';
import { of, type Observable } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { type LoanContext, type LoanHandler } from './loan-handler';

@Injectable()
export class EnerBankLoanHandlerService implements LoanHandler {
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly customersService = inject(CustomersService);
  private readonly windowRefService = inject(WindowRefService);

  loanCompany: LoanCompany = LoanCompany.EnerBank;

  handle(loanSelection: LoanSelection, loanCreate: LoanCreate, context: LoanContext): Observable<LoanCreate> {
    return this.checkFirstLastName(loanSelection, loanCreate, context)
      .pipe(filter(notEmpty))
      .pipe(concatMap(lc => this.checkPropertyData(loanSelection, lc, context)))
      .pipe(filter(notEmpty));
  }

  canStartLoan(): boolean {
    return true;
  }

  startLoan(workOrderLoanApplication: WorkOrderLoanApplicationInformation): void {
    const extra = workOrderLoanApplication.extra as EnerBankExtra;
    this.windowRefService.nativeWindow.open(extra.redirectUrl);
  }

  getLoanLink(application: WorkOrderLoanApplicationInformation): { url: string; text: string } | null {
    let text = null;

    switch (getLoanStatus(application.status).code) {
      case 'yellow':
        text = 'Continue Application';
        break;
      case 'green':
      case 'red':
      default:
        // Right now, EnerBank does not allow to review the application. So we will remove the link.
        /// / text = 'Review Application';
        text = null;
        break;
    }

    if (text !== null) {
      const extra = application.extra as EnerBankExtra;
      if (extra) {
        return { url: extra.redirectUrl ?? '', text };
      }
    }

    return null;
  }

  getCompanyName(): string {
    return 'EnerBank';
  }

  canModifyReferenceNumber(): boolean {
    return false;
  }

  private checkFirstLastName(loanSelection: LoanSelection, loanCreate: LoanCreate, context: LoanContext): Observable<LoanCreate | null> {
    if (
      !context.customerInformation.entity.firstName ||
      !context.customerInformation.entity.lastName ||
      !context.customerInformation.mainPhone
    ) {
      return EnerBankApplicantNameComponent.open(this.dialog2, {
        ...context.customerInformation.entity,
        mainPhone: context.customerInformation.mainPhone,
        mobilePhone: context.customerInformation.mobilePhone,
      }).pipe(
        concatMap(data => {
          if (!data?.firstName || !data.lastName || !data.mainPhone) {
            return of(null);
          }

          const customerInformation = clone(context.customerInformation);
          customerInformation.entity = {
            company: data.company,
            firstName: data.firstName,
            lastName: data.lastName,
          };
          customerInformation.mobilePhone = data.mobilePhone;
          customerInformation.mainPhone = data.mainPhone;

          return this.customersService.save(customerInformation).pipe(map(_ => loanCreate));
        })
      );
    }

    return of(loanCreate);
  }

  private checkPropertyData(loanSelection: LoanSelection, loanCreate: LoanCreate, context: LoanContext): Observable<LoanCreate | null> {
    return EnerBankProjectInfoComponent.open(this.dialog2, {}).pipe(
      map(data => {
        if (!data?.ownership || !data.propertyType) {
          return null;
        }

        const lc = clone(loanCreate);
        lc.extra = {
          ownership: data.ownership,
          propertyType: data.propertyType,
          monthlyMortgagePayment: data.monthlyMortgagePayment && parseFloat(data.monthlyMortgagePayment.toString()),
        } as EnerBankExtra;

        return lc;
      })
    );
  }
}
