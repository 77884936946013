import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { type BeltInformation } from '@models/cards/belt-information';

@Pipe({
  name: 'beltInfo',
  standalone: true,
})
@Injectable()
export class BeltInfoPipe implements PipeTransform {
  transform(belt: BeltInformation): string {
    if (!belt) {
      return '';
    }

    return '#' + (belt.localId || '').toString();
  }
}
