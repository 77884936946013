import { Injectable, inject } from '@angular/core';
import { type FileInformation } from '@models/cards/invoice-information';
import { type Observable } from 'rxjs';
import { type ExtraOptionsJson, HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { type PreparedFile } from '@models/upload';

@Injectable()
export class FilesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<FileInformation> {
    return this.http.get<FileInformation>(this.url.fileSingle.replace('$0', id.toString()));
  }

  prepare(options?: ExtraOptionsJson): Observable<PreparedFile> {
    return this.http.post<PreparedFile>(this.url.filePrepare, null, options);
  }

  validate(obj: FileInformation, options?: ExtraOptionsJson): Observable<FileInformation> {
    return this.http.post<FileInformation>(this.url.fileValidate, obj, options);
  }

  post(obj: Partial<FileInformation>): Observable<FileInformation> {
    return this.http.post<FileInformation>(this.url.filePost, obj);
  }

  /*
    delete(id: Id): Observable<void> {
        return this.http.delete<void>(this.url.fileDelete.replace('$0', id.toString()));
    }
    */
}
