import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, input, output, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { ButtonComponent } from '@controls/button/button.component';
import { TimepickerComponent } from '@controls/timepicker/timepicker.component';
import { addDays, getDayOfYear, getYear, setDayOfYear, setYear } from 'date-fns';
import { DatepickerComponent } from '../datepicker/datepicker.component';

const noop = (): void => {};

const DATEPICKER_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MomentPickerComponent),
  multi: true,
};

// Angular 18
@Component({
  selector: 'wm-moment-picker',
  templateUrl: 'moment-picker.component.html',
  styleUrls: ['moment-picker.component.scss'],
  providers: [DATEPICKER_COMPONENT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DatepickerComponent, ButtonComponent, TimepickerComponent, FormsModule],
})
export class MomentPickerComponent implements ControlValueAccessor {
  readonly showDate = input(true);
  readonly showTime = input(true);
  readonly minDate = input<Date>(new Date(2014, 0, 0));
  readonly maxDate = input<Date>(new Date(new Date().getFullYear() + 6, 0, 0));

  readonly valueChange = output<number>();

  // The internal data model
  readonly innerValue = signal<Date | null>(null);

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  get value(): Date | null {
    return this.innerValue();
  }

  // set accessor including call the onchange callback
  set value(v: Date | null) {
    // At this point, if we get 1970 as a date we will set the date as of today.
    if (v && v.getFullYear() === 1970) {
      const currentDate = new Date();
      v.setFullYear(currentDate.getFullYear());
      v.setMonth(currentDate.getMonth());
      v.setDate(currentDate.getDate());
    }

    if (v !== this.innerValue()) {
      this.innerValue.set(v);
      this.onChangeCallback(v);
    }
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    value ??= null;
    if (value !== this.innerValue()) {
      this.innerValue.set(value as Date | null);
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  todayClick(): void {
    const today = new Date();
    this.value = setDayOfYear(setYear(this.innerValue() ?? new Date(), getYear(today)), getDayOfYear(today));
  }

  tomorrowClick(): void {
    const today = new Date();
    const tomorrow = addDays(today, 1);
    this.value = setDayOfYear(setYear(this.innerValue() ?? new Date(), getYear(tomorrow)), getDayOfYear(tomorrow));
  }
}
