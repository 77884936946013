import { Injectable, inject } from '@angular/core';
import { type TagEntityInformation } from '@models/tag-models';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class TagEntitiesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<TagEntityInformation> {
    return this.http.get<TagEntityInformation>(this.url.tagEntitySingle.replace('$0', id.toString()));
  }

  save(tagEntityInformation: TagEntityInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.tagEntitySave, tagEntityInformation);
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.tagEntityDelete.replace('$0', id.toString()));
  }
}
