import { Injectable, inject } from '@angular/core';
import { type Mutable } from '@models/mutable';
import { convertTaxItemEntry } from '@models/pricing-models';
import { type Resource } from '@models/resource';
import { BlobContainer } from '@models/upload';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select, type Action, type ActionCreator, type Creator } from '@ngrx/store';
import { FilesService } from '@services/live/files.service';
import { SalesProposalsService } from '@services/live/sales-proposals.service';
import { from, of, type Observable } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as SalesProposalActions from './sales-proposal.actions';
import { type SalesProposalState } from './sales-proposal.reducer';
import * as SalesProposalSelectors from './sales-proposal.selectors';

@Injectable()
export class SalesProposalEffects {
  private readonly actions$ = inject(Actions<SalesProposalActions.Actions>);
  private readonly store = inject(Store<SalesProposalState>);
  private readonly salesProposalsService = inject(SalesProposalsService);
  private readonly filesService = inject(FilesService);

  markAsNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.markSalesProposalAsNew),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposal$))))),
      map(([action, salesProposal]) => SalesProposalActions.markSalesProposalChildrenAsNew({ salesProposalId: salesProposal.id }))
    )
  );

  markPackageAsNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.markSalesProposalChildrenAsNew),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalPackages$))))),
      concatMap(([action, salesProposalPackages]) => {
        return from(
          Object.values(salesProposalPackages).map(salesProposalPackage =>
            SalesProposalActions.markSalesProposalPackageChildrenAsNew({
              salesProposalPackage,
            })
          )
        );
      })
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.save),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalAndTaxItemEntry))))),
      mergeMap(([action, { salesProposal, taxItemEntry }]) => {
        const getPayload = () => {
          const newTaxItemEntry = taxItemEntry && taxItemEntry.updated ? convertTaxItemEntry(taxItemEntry) || {} : {};
          return { ...salesProposal, ...newTaxItemEntry };
        };

        if (salesProposal.created || salesProposal.updated || (taxItemEntry && taxItemEntry.updated)) {
          return this.salesProposalsService.save(getPayload()).pipe(
            map(newSalesProposal => SalesProposalActions.saveSalesProposalSuccess({ resource: newSalesProposal })),
            catchError(error => of(SalesProposalActions.saveSalesProposalFail({ error })))
          );
        }

        return of(SalesProposalActions.saveSalesProposalSuccess({ resource: salesProposal }));
      })
    )
  );

  saveSalesProposalSuccess1$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      SalesProposalActions.saveSalesProposalSuccess,
      () => select(SalesProposalSelectors.salesProposalLoanApplicationsById$),
      SalesProposalActions.saveLoanDelete,
      SalesProposalActions.saveLoanSave,
      false
    )
  );

  saveSalesProposalSuccess2$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      SalesProposalActions.saveSalesProposalSuccess,
      () => select(SalesProposalSelectors.salesProposalPackages$),
      SalesProposalActions.savePackageDelete,
      SalesProposalActions.savePackageSave,
      true
    )
  );

  saveSalesProposalSuccess3$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      SalesProposalActions.saveSalesProposalSuccess,
      () => select(SalesProposalSelectors.salesProposalPayments$),
      SalesProposalActions.savePaymentDelete,
      SalesProposalActions.savePaymentSave,
      false
    )
  );

  saveSalesProposalSuccess4$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      SalesProposalActions.saveSalesProposalSuccess,
      () => select(SalesProposalSelectors.fileInformations$),
      null,
      SalesProposalActions.validateFileInformation, // We validate first then we save
      false
    )
  );

  saveSalesProposalSuccess5$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveSalesProposalSuccess),
      map(_ => SalesProposalActions.saveFileInformationsDelete())
    )
  );

  saveLoanDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveLoanDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalLoanWithParentId$, { id: action.id }))))
      ),
      mergeMap(([action, { salesProposalId, resource }]) =>
        this.salesProposalsService.hideLoanApplication(salesProposalId, resource.id).pipe(
          map(() => SalesProposalActions.saveLoanDeleteSuccess({ resource })),
          catchError(error => of(SalesProposalActions.saveLoanFail({ error })))
        )
      )
    )
  );

  saveLoanSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveLoanSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalLoanWithParentId$, { id: action.id }))))
      ),
      mergeMap(([action, { salesProposalId, resource }]) =>
        this.salesProposalsService.saveLoanApplication(salesProposalId, resource).pipe(
          map(newResource => SalesProposalActions.saveLoanSaveSuccess({ oldId: resource.id, newId: newResource.id })),
          catchError(error => of(SalesProposalActions.saveLoanFail({ error })))
        )
      )
    )
  );

  savePackageDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.savePackageDelete),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalPackageWithParentId$, { id: action.id })))
        )
      ),
      mergeMap(([action, { salesProposalId, resource }]) =>
        this.salesProposalsService.hidePackage(salesProposalId, resource.id).pipe(
          map(() => SalesProposalActions.savePackageDeleteSuccess({ resource })),
          catchError(error => of(SalesProposalActions.savePackageFail({ error })))
        )
      )
    )
  );

  savePackageSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.savePackageSave),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalPackageWithParentId$, { id: action.id })))
        )
      ),
      mergeMap(([action, { salesProposalId, resource }]) =>
        (resource.updated || resource.created ? this.salesProposalsService.savePackage(salesProposalId, resource) : of(resource)).pipe(
          map(newResource => SalesProposalActions.savePackageSaveSuccess({ oldId: resource.id, newId: newResource.id })),
          catchError(error => of(SalesProposalActions.savePackageFail({ error })))
        )
      )
    )
  );

  savePackageSaveSuccess1$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      SalesProposalActions.savePackageSaveSuccess,
      // Having some trouble with the typing.
      action => select(SalesProposalSelectors.salesProposalDiscountsByPackage$, { packageId: (action as any).newId }),
      SalesProposalActions.saveDiscountDelete,
      SalesProposalActions.saveDiscountSave,
      false
    )
  );

  savePackageSaveSuccess2$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      SalesProposalActions.savePackageSaveSuccess,
      // Having some trouble with the typing.
      action => select(SalesProposalSelectors.salesProposalRebatesByPackage$, { packageId: (action as any).newId }),
      SalesProposalActions.saveRebateDelete,
      SalesProposalActions.saveRebateSave,
      false
    )
  );

  savePackageSaveSuccess3$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      SalesProposalActions.savePackageSaveSuccess,
      // Having some trouble with the typing.
      action => select(SalesProposalSelectors.salesProposalDetailsByPackage$, { packageId: (action as any).newId }),
      SalesProposalActions.saveDetailDelete,
      SalesProposalActions.saveDetailSave,
      false
    )
  );

  saveDiscountDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveDiscountDelete),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalDiscountWithParentId$, { id: action.id })))
        )
      ),
      mergeMap(([action, { salesProposalId, packageId, resource }]) =>
        this.salesProposalsService.hidePackageDiscount(salesProposalId, packageId, resource.id).pipe(
          map(() => SalesProposalActions.saveDiscountDeleteSuccess({ resource })),
          catchError(error => of(SalesProposalActions.saveDiscountFail({ error })))
        )
      )
    )
  );

  saveDiscountSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveDiscountSave),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalDiscountWithParentId$, { id: action.id })))
        )
      ),
      mergeMap(([action, { salesProposalId, packageId, resource }]) =>
        this.salesProposalsService.savePackageDiscount(salesProposalId, packageId, resource).pipe(
          map(newResource => SalesProposalActions.saveDiscountSaveSuccess({ oldId: resource.id, newId: newResource.id })),
          catchError(error => of(SalesProposalActions.saveDiscountFail({ error })))
        )
      )
    )
  );

  saveRebateDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveRebateDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalRebateWithParentId$, { id: action.id }))))
      ),
      mergeMap(([action, { salesProposalId, packageId, resource }]) =>
        this.salesProposalsService.hidePackageRebate(salesProposalId, packageId, resource.id).pipe(
          map(() => SalesProposalActions.saveRebateDeleteSuccess({ resource })),
          catchError(error => of(SalesProposalActions.saveRebateFail({ error })))
        )
      )
    )
  );

  saveRebateSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveRebateSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalRebateWithParentId$, { id: action.id }))))
      ),
      mergeMap(([action, { salesProposalId, packageId, resource }]) =>
        this.salesProposalsService.savePackageRebate(salesProposalId, packageId, resource).pipe(
          map(newResource => SalesProposalActions.saveRebateSaveSuccess({ oldId: resource.id, newId: newResource.id })),
          catchError(error => of(SalesProposalActions.saveRebateFail({ error })))
        )
      )
    )
  );

  savePaymentDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.savePaymentDelete),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalPaymentWithParentId$, { id: action.id })))
        )
      ),
      mergeMap(([action, { salesProposalId, resource }]) =>
        this.salesProposalsService.hidePayment(salesProposalId, resource.id).pipe(
          map(() => SalesProposalActions.savePaymentDeleteSuccess({ resource })),
          catchError(error => of(SalesProposalActions.savePaymentFail({ error })))
        )
      )
    )
  );

  savePaymentSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.savePaymentSave),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalPaymentWithParentId$, { id: action.id })))
        )
      ),
      mergeMap(([action, { salesProposalId, resource }]) => {
        // Because the parentId might be null in the resource, we will remove it if we have it.
        const payment = { ...resource };
        if (!payment.salesProposalId) {
          delete payment.salesProposalId;
        }

        return this.salesProposalsService.savePayment(salesProposalId, payment).pipe(
          map(newResource => SalesProposalActions.savePaymentSaveSuccess({ oldId: resource.id, newId: newResource.id })),
          catchError(error => of(SalesProposalActions.savePaymentFail({ error })))
        );
      })
    )
  );

  validateFileInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.validateFileInformation),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalFileInformationWithParentId$, { id: action.id })))
        )
      ),
      mergeMap(([action, { salesProposalId, resource }]) =>
        this.filesService
          .validate({
            ...resource,
            blobContainer: BlobContainer.ContractorFiles,
          })
          .pipe(
            map(newResource => SalesProposalActions.validateFileInformationSuccess({ salesProposalId, oldId: resource.id, newResource })),
            catchError(error => of(SalesProposalActions.validateFileInformationFail({ error })))
          )
      )
    )
  );

  validateFileInformationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.validateFileInformationSuccess),
      map(action =>
        SalesProposalActions.saveFileInformationSave({
          salesProposalId: action.salesProposalId,
          oldId: action.oldId,
          newResource: action.newResource,
        })
      )
    )
  );

  saveFileInformationsDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveFileInformationsDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalFileInformationsToDeleteWithParentId$))))
      ),
      mergeMap(([action, { salesProposalId, resources }]) =>
        this.salesProposalsService
          .deleteFiles(
            salesProposalId,
            resources.map(x => x.id)
          )
          .pipe(
            map(() => SalesProposalActions.saveFileInformationsDeleteSuccess({ resources })),
            catchError(error => of(SalesProposalActions.saveFileInformationFail({ error })))
          )
      )
    )
  );

  saveFileInformationSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveFileInformationSave),
      mergeMap(action => {
        return this.salesProposalsService.addFile(action.salesProposalId, action.newResource.id).pipe(
          map(newResource => SalesProposalActions.saveFileInformationSaveSuccess({ oldId: action.oldId, newResource: action.newResource })),
          catchError(error => of(SalesProposalActions.saveFileInformationFail({ error })))
        );
      })
    )
  );

  saveDetailDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveDetailDelete),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalDetailWithParentId$, { id: action.id }))))
      ),
      mergeMap(([action, { salesProposalId, packageId, resource }]) =>
        this.salesProposalsService.hidePackageDetail(salesProposalId, packageId, resource.id).pipe(
          map(() => SalesProposalActions.saveDetailDeleteSuccess({ resource })),
          catchError(error => of(SalesProposalActions.saveDetailFail({ error })))
        )
      )
    )
  );

  saveDetailSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.saveDetailSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposalDetailWithParentId$, { id: action.id }))))
      ),
      mergeMap(([action, { salesProposalId, packageId, resource }]) =>
        this.salesProposalsService.savePackageDetail(salesProposalId, packageId, resource).pipe(
          map(newResource => SalesProposalActions.saveDetailSaveSuccess({ oldId: resource.id, newId: newResource.id })),
          catchError(error => of(SalesProposalActions.saveDetailFail({ error })))
        )
      )
    )
  );

  selectPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesProposalActions.selectPackage),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(SalesProposalSelectors.salesProposal$))))),
      mergeMap(([action, salesProposal]) =>
        this.salesProposalsService.selectPackage(salesProposal.id, action.packageId, action.packageSelectionOptions).pipe(
          map(result => SalesProposalActions.selectPackageSuccess({ packageId: action.packageId, workOrderId: result.id })),
          catchError(error => of(SalesProposalActions.savePackageFail({ error })))
        )
      )
    )
  );

  private triggerDeleteAndSaveActions(
    action: ActionCreator<string, Creator>,
    selector: (action: Action) => (source$: Observable<any>) => Observable<Record<number, Resource & Mutable>>,
    deleteAction: ActionCreator<string, (props: { id: Id }) => { id: Id } & Action> | null,
    createOrUpdateAction: ActionCreator<string, (props: { id: Id }) => { id: Id } & Action>,
    triggerAnyway: boolean
  ) {
    return this.actions$.pipe(
      ofType(action),
      concatMap((act: Action) => of(act).pipe(withLatestFrom(this.store.pipe(selector(act))))),
      mergeMap(([act, resourcesByIds]) => {
        const newActions: Action[] = [];
        const values = Object.values(resourcesByIds);

        if (deleteAction !== null) {
          newActions.push(...values.filter(m => m.deleted && m.id > 0).map(m => deleteAction({ id: m.id })));
        }

        newActions.push(
          ...values.filter(m => !m.deleted && (m.created || m.updated || triggerAnyway)).map(m => createOrUpdateAction({ id: m.id }))
        );

        return from(newActions);
      })
    );
  }
}
