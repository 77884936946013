// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  display: block;
}

.col.col-id {
  width: 120px;
}
.col.col-date {
  width: 160px;
}
.col.col-short-date {
  width: 100px;
}
.col.col-short-time {
  width: 100px;
}
.col.col-phone {
  width: 180px;
}
.col.col-100 {
  width: 100px;
}
.col.col-150 {
  width: 150px;
}
.col.col-200 {
  width: 200px;
}
.col.col-status {
  width: 100px;
}
.col.col-name {
  width: 160px;
}
.col.col-email {
  width: 160px;
}
.col.col-processed {
  width: 140px;
}
.col.col-address {
  min-width: 200px;
}

.no-entry {
  text-align: center;
  position: relative;
}
.no-entry .loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}
.no-entry .loading .spinner {
  border: 2px solid #ffffff; /* Light grey */
  border-top: 2px solid #7ca8f2; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
}
.no-entry .loading .spinner {
  margin-top: 2px;
  zoom: 6;
}
.no-entry .robot img {
  width: 40%;
  max-width: 200px;
}
.no-entry .robot.big img {
  max-width: 500px;
}
.no-entry > * {
  margin-bottom: 16px;
}
.no-entry > *:last-child {
  margin-bottom: 0;
}

.template-text {
  white-space: pre-wrap;
}

.full-width {
  width: 100%;
}

.small-icon {
  width: 10px;
  height: 10px;
}

.tiny-icon {
  width: 8px;
  height: 8px;
}

.filter-size {
  display: inline-block;
  white-space: nowrap;
}

wm-error {
  margin-bottom: 16px;
}

.beacon-box {
  background-color: #ffddd6;
  color: #e52217;
  margin-left: 0 !important;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  line-height: normal !important;
  margin-bottom: 16px;
}
.beacon-box .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.beacon-box .content .take-charge {
  margin-top: 16px;
}
.beacon-box::before {
  content: " ";
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
  background-image: url(/assets/images/late-snail-circle.svg);
  background-repeat: no-repeat;
  margin: 0;
  margin-right: 4px;
  vertical-align: sub;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
