import { type EventEmitter } from '@angular/core';
import { type ScheduleEventInformation } from '@models/dashboard-event';
import { type SalesProposalViewData } from '@models/sales-proposal-models';
import { type TechnicianInformation } from '@models/technician';
import { type Observable } from 'rxjs';
import { type DutyInformation } from '../duty-models';
import { type AgreementSiteSystemInformation } from './agreement-site-system-information';
import { type BeltInformation } from './belt-information';
import { type CallInformation } from './call-information';
import { type CustomerInformation } from './customer-information';
import { type FilterInformation } from './filter-information';
import { type SiteInformation } from './site-information';
import { type SiteSystemInformation, type SiteSystemWording } from './site-system-information';
import { type WorkOrderInformation } from './work-order-information';
import { type WorkOrderViewData } from './work-order-view-data';

export interface CreateViewData {
  from: CreateFromPossibility | null;
  id: Id | null;
  context: CreateContext;
}

export enum CreateViewType {
  Start,
  Customer,
  Call,
  Site,
  SiteSystem,
  Filter,
  Belt,
  CallSchedule,
  AddItems,
  WorkOrder,
  Duty,
  Agreement,
  Block,
  BlockSchedule,
  Technician,
  SalesProposal,
  SalesProposalTemplate,
}

export enum CreateFromPossibility {
  Customer = 1,
  Site,
  SiteSystem,
  Call,
  Duty,
  Block,
  TechnicianAccounts,
  SalesProposalTemplate,
  WorkOrder,
}

interface CreatableOptionBase {
  /**
   * Used to display the correct name when passing the cursor on the Create button.
   * This is only for display. If not available, it uses the "from".
   */
  displayFrom?: CreateFromPossibility;
  type: CreateViewType;
  text?: string;
  extraParams?: Record<string, string>;
  navigationGuard?: () => Observable<boolean>;
}

interface CreatableOptionWithoutFrom extends CreatableOptionBase {
  from?: never;
}

interface CreatableOptionWithFrom extends CreatableOptionBase {
  from: CreateFromPossibility;
  id: Id;
}

export type CreatableOption = CreatableOptionWithoutFrom | CreatableOptionWithFrom;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getCreateViewTypeNames(siteSystemWording: SiteSystemWording) {
  return {
    [CreateViewType.Start]: 'Create',
    [CreateViewType.Customer]: 'Customer Information',
    [CreateViewType.Call]: 'Appointment Information',
    [CreateViewType.Site]: 'Site Information',
    [CreateViewType.SiteSystem]: `${siteSystemWording.singular} Information`,
    [CreateViewType.Filter]: 'Filters/Mini Split Heads/IAQ Information',
    [CreateViewType.Belt]: 'Belt Information',
    [CreateViewType.CallSchedule]: 'Schedule',
    [CreateViewType.WorkOrder]: 'Work Order Information',
    [CreateViewType.Duty]: 'Task Information',
    [CreateViewType.AddItems]: 'Add Items',
    [CreateViewType.Agreement]: 'Agreement Information',
    [CreateViewType.Block]: 'Block Time Information',
    [CreateViewType.BlockSchedule]: 'Schedule',
    [CreateViewType.Technician]: 'User Profile',
    [CreateViewType.SalesProposal]: 'Sales Proposal Information',
    [CreateViewType.SalesProposalTemplate]: 'Sales Proposal Template',
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getCreateViewTypeShortNames(siteSystemWording: SiteSystemWording) {
  return {
    [CreateViewType.Customer]: 'Customer',
    [CreateViewType.Call]: 'Appointment',
    [CreateViewType.Site]: 'Site',
    [CreateViewType.SiteSystem]: siteSystemWording.singular,
    [CreateViewType.Filter]: 'Filters/Mini Split Heads/IAQ',
    [CreateViewType.Belt]: 'Belt',
    [CreateViewType.CallSchedule]: 'Schedule',
    [CreateViewType.WorkOrder]: 'Work Order',
    [CreateViewType.Duty]: 'Task',
    [CreateViewType.Agreement]: 'Agreement',
    [CreateViewType.Block]: 'Block Time',
    [CreateViewType.BlockSchedule]: 'Schedule',
    [CreateViewType.Technician]: 'Account',
    [CreateViewType.SalesProposal]: 'Sales Proposal',
    [CreateViewType.SalesProposalTemplate]: 'Sales Proposal Template',
  };
}

export const entryPoints = {
  call: CreateViewType.Call,
  customer: CreateViewType.Customer,
  site: CreateViewType.Site,
  filter: CreateViewType.Filter,
  belt: CreateViewType.Belt,
  equipment: CreateViewType.SiteSystem,
  duty: CreateViewType.Duty,
  agreement: CreateViewType.Agreement,
  block: CreateViewType.Block,
  technician: CreateViewType.Technician,
  workOrder: CreateViewType.WorkOrder,
  salesProposal: CreateViewType.SalesProposal,
  salesProposalTemplate: CreateViewType.SalesProposalTemplate,
};

export interface CreateContextChange {
  change: EventEmitter<void>;
}

export interface CreateContext {
  settings?: CreateContextSettings;
  start?: CreateContextStart;
  customer?: CreateContextCustomer;
  site?: CreateContextSite;
  call?: CreateContextCall;
  siteSystem?: CreateContextSiteSystem;
  siteSystems?: SiteSystemInformation[];
  addItems?: CreateContextAddItems;
  filter?: CreateContextFilter;
  belt?: CreateContextBelt;
  schedule?: CreateContextSchedule;
  duty?: CreateContextDuty;
  agreement?: CreateContextAgreement;
  block?: CreateContextBlock;
  technician?: CreateContextTechnician;
  workOrder?: CreateContextWorkOrder;
  salesProposal?: CreateContextSalesProposal;
  salesProposalTemplate?: CreateContextSalesProposal;
}

export interface CreateContextStart {
  viewType?: CreateViewType;
  condensed?: boolean;
}

export interface CreateContextSettings {
  shouldHaveEmail?: boolean;
  appointmentDefaultDuration?: number;
}

export interface CreateContextCustomer extends CreateContextChange {
  customerInformation?: CustomerInformation;
}

export interface CreateContextSite extends CreateContextChange {
  siteInformation?: SiteInformation;
}

export interface CreateContextCall extends CreateContextChange {
  callInformation?: CallInformation;
}

export interface CreateContextSiteSystem extends CreateContextChange {
  siteSystemInformation?: SiteSystemInformation;
}

export interface CreateContextAddItems {}

export interface CreateContextFilter extends CreateContextChange {
  filterInformation?: FilterInformation;
}

export interface CreateContextBelt extends CreateContextChange {
  beltInformation?: BeltInformation;
}

export interface CreateContextSchedule extends CreateContextChange {
  scheduleInformation?: ScheduleEventInformation;
}

export interface CreateContextDuty extends CreateContextChange {
  dutyInformation?: DutyInformation;
}

export interface CreateContextAgreement extends CreateContextChange {
  agreementSiteSystemInformation?: AgreementSiteSystemInformation;
}

export interface CreateContextBlock extends CreateContextChange {
  blockInformation?: ScheduleEventInformation;
}

export interface CreateContextTechnician extends CreateContextChange {
  technician?: TechnicianInformation;
}

export interface CreateContextWorkOrder extends CreateContextChange {
  workOrderInformation?: WorkOrderInformation;
  hasEditedWorkOrderLineItems?: boolean;
  fromWorkOrder?: WorkOrderViewData;
}

export interface CreateContextSalesProposal extends CreateContextChange {
  salesProposalViewData?: SalesProposalViewData;
}

export function getCreateFromPossibility(createFromPossibility: CreateFromPossibility, siteSystemWording: SiteSystemWording): string {
  switch (createFromPossibility) {
    case CreateFromPossibility.Block:
      return 'Block Event';
    case CreateFromPossibility.Call:
      return 'Appointment';
    case CreateFromPossibility.Customer:
      return 'Customer';
    case CreateFromPossibility.Duty:
      return 'Task';
    case CreateFromPossibility.Site:
      return 'Site';
    case CreateFromPossibility.SiteSystem:
      return siteSystemWording.singular;
    case CreateFromPossibility.TechnicianAccounts:
      return 'Technician';
    case CreateFromPossibility.SalesProposalTemplate:
      return 'Sales Proposal Template';
    case CreateFromPossibility.WorkOrder:
      return 'Work Order';
  }
}
