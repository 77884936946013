import { type Creatable, type Deletable, type Mutable, type Updatable } from '@models/mutable';
import { type TaxItemEntry } from '@models/pricing-models';
import { type OrderedResource, type Resource } from '@models/resource';
import {
  type SalesProposalInformation,
  type SalesProposalLoanApplicationInformation,
  type SalesProposalPackageDetailInformation,
  type SalesProposalPackageDiscountInformation,
  type SalesProposalPackageInformation,
  type SalesProposalPackageRebateInformation,
  type SalesProposalPaymentInformation,
  SalesProposalStatus,
} from '@models/sales-proposal-models';
import { type TaxItemSection } from '@models/tax-item-section';
import { type FileInformation } from '@models/upload';
import { type Action, createReducer, on } from '@ngrx/store';
import { indexBy } from '@utility/array';
import { clone } from '@utility/object';
import { sortFnc } from '@utility/sort-fnc';
import { type SaveState } from '../save-store/save.actions';
import * as SalesProposalActions from './sales-proposal.actions';

const counters = {
  salesProposal: 0,
  salesProposalPackages: 0,
  salesProposalDetails: 0,
  salesProposalDiscounts: 0,
  salesProposalRebates: 0,
  salesProposalLoans: 0,
  salesProposalPayments: 0,
  fileInformations: 0,
};

export type SalesProposalInformationState = SalesProposalInformation & Creatable & Updatable;
export type SalesProposalLoanApplicationState = (SalesProposalLoanApplicationInformation & Mutable)[];
export type SalesProposalPackageState = Record<string, SalesProposalPackageInformation & Mutable>;
export type SalesProposalDetailState = Record<string, SalesProposalPackageDetailInformation & Mutable>;
export type SalesProposalDiscountState = Record<string, SalesProposalPackageDiscountInformation & Mutable>;
export type SalesProposalRebateState = Record<string, SalesProposalPackageRebateInformation & Mutable>;
export type SalesProposalPaymentState = Record<string, SalesProposalPaymentInformation & Mutable>;
export type FileInformationState = Record<string, FileInformation & Mutable>;
export type TaxItemEntryState = (TaxItemEntry & Mutable) | null;
export interface EntitiesState {
  salesProposal: SalesProposalInformationState;
  salesProposalLoanApplications: SalesProposalLoanApplicationState;
  salesProposalPackages: SalesProposalPackageState;
  salesProposalDetails: SalesProposalDetailState;
  salesProposalDiscounts: SalesProposalDiscountState;
  salesProposalRebates: SalesProposalRebateState;
  salesProposalPayments: SalesProposalPaymentState;
  fileInformations: FileInformationState;
  internalTaxItemEntry: TaxItemEntryState;
}

export interface SalesProposalState extends SaveState {
  edit: boolean;
  entities: EntitiesState;
  initialSalesProposalLoanApplications: SalesProposalLoanApplicationInformation[];
  entitiesSnapshot: EntitiesState | null;
  entitiesSnapshot2: EntitiesState | null;
  taxItemSections: TaxItemSection[];
  workOrderId: Id | null;
  selectingPackage: boolean;
}

export const initialState: SalesProposalState = {
  edit: false,
  entities: {
    salesProposal: null,
    salesProposalLoanApplications: [],
    salesProposalPackages: {},
    salesProposalDetails: {},
    salesProposalDiscounts: {},
    salesProposalRebates: {},
    salesProposalPayments: {},
    fileInformations: {},
    internalTaxItemEntry: null,
  },
  initialSalesProposalLoanApplications: [],
  entitiesSnapshot: null,
  entitiesSnapshot2: null,
  taxItemSections: null,
  workOrderId: null,
  selectingPackage: false,
  _submitCounter: 0,
  _error: null,
  _cacheCorrelationId: null,
  _correlationId: null,
};

const loadReducer = function (arg: { data: Resource[] }) {
  return indexBy(arg.data, 'id');
};

const addReducer = function (key: keyof typeof counters, arg: { data: Partial<Resource> }, order: number) {
  const newId = --counters[key];
  return {
    [newId]: { ...arg.data, id: newId, order, created: true },
  };
};

const updateReducer = function (stateResource: Record<number, Resource>, arg: { id: Id; patch: Partial<Resource> }) {
  return {
    [arg.id]: { ...stateResource[arg.id], ...arg.patch, updated: true },
  };
};

const deleteReducer = function (stateResource: Record<number, Resource>, arg: { id: Id }) {
  return {
    [arg.id]: { ...stateResource[arg.id], deleted: true },
  };
};

const entityReducer = function (state: SalesProposalState, key: keyof SalesProposalState['entities'], value: any) {
  const obj = {};
  obj[key] = value;
  return { ...state, entities: { ...state.entities, ...obj } };
};

const markAsNewEntityReducer = function (
  state: SalesProposalState,
  key: keyof SalesProposalState['entities'],
  parentObj: Record<string, Id>
) {
  return entityReducer(
    state,
    key,
    indexBy(
      Object.values(state.entities[key]).map(obj => markSalesProposalChildrenStateAsNew(obj, --counters[key], parentObj)),
      'id'
    )
  );
};

const markSalesProposalChildrenStateAsNew = function <T extends Resource & Creatable>(
  state: T,
  counterId: Id,
  parentObj: Record<string, Id>
): T {
  return {
    ...state,
    _oldId: state.id,
    id: counterId,
    created: true,
    ...parentObj,
  };
};

const getFilteredEntityReducer = function <K extends keyof SalesProposalState['entities']>(
  state: SalesProposalState,
  key: K,
  oldId: Id,
  newId: Id
): SalesProposalState['entities'][K] & Mutable {
  return {
    ...state.entities[key],
    ...indexBy(
      Object.values(state.entities[key])
        .filter(m => m.salesProposalPackageId === oldId)
        .map(obj => markSalesProposalChildrenStateAsNew(obj, --(counters as any)[key], { salesProposalPackageId: newId })),
      'id'
    ),
  };
};

const reassignOrders = function <T extends OrderedResource & Deletable>(objects: Record<number, T>): Record<number, T> {
  const finalObject: Record<number, T> = {};
  let deletedCount = 0;
  Object.values(objects)
    .sort(sortFnc(false, 'order'))
    .forEach((m, index) => {
      deletedCount += m.deleted ? 1 : 0;
      finalObject[m.id] = { ...m, order: index - deletedCount };
    });
  return finalObject;
};

const salesProposalReducer = createReducer(
  initialState,
  on(SalesProposalActions.loadSalesProposal, (state, action) => {
    const data = entityReducer(state, 'salesProposal', action.data);
    data.entities.internalTaxItemEntry = null;
    return data;
  }),
  on(SalesProposalActions.loadPackages, (state, action) => entityReducer(state, 'salesProposalPackages', loadReducer(action))),
  on(SalesProposalActions.loadDetails, (state, action) => entityReducer(state, 'salesProposalDetails', loadReducer(action))),
  on(SalesProposalActions.loadDiscounts, (state, action) => entityReducer(state, 'salesProposalDiscounts', loadReducer(action))),
  on(SalesProposalActions.loadRebates, (state, action) => entityReducer(state, 'salesProposalRebates', loadReducer(action))),
  on(SalesProposalActions.loadPayments, (state, action) => entityReducer(state, 'salesProposalPayments', loadReducer(action))),
  on(SalesProposalActions.loadTaxItemSections, (state, action) => ({ ...state, taxItemSections: action.taxItemSections.slice(0) })),
  on(SalesProposalActions.loadLoans, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      salesProposalLoanApplications: clone(action.data) as (SalesProposalLoanApplicationInformation & Mutable)[],
    },
    initialSalesProposalLoanApplications: clone(action.data),
  })),
  on(SalesProposalActions.loadFileInformations, (state, action) => entityReducer(state, 'fileInformations', loadReducer(action))),
  on(SalesProposalActions.loadWorkOrderId, (state, action) => ({ ...state, workOrderId: action.data })),
  on(SalesProposalActions.duplicatePackage, (state, action) => {
    const existingPackage = state.entities.salesProposalPackages[action.id];

    const newPackage = addReducer(
      'salesProposalPackages',
      { data: { ...existingPackage, text: (existingPackage.text || '') + ' - Copy' } },
      Object.values(state.entities.salesProposalPackages).length
    );
    const salesProposalPackageId = +Object.keys(newPackage)[0];

    const existingDetails = Object.values(state.entities.salesProposalDetails)
      .filter(salesProposalDetail => salesProposalDetail.salesProposalPackageId === existingPackage.id)
      .filter(salesProposalDetail => !salesProposalDetail.deleted);

    let newDetails: SalesProposalDetailState = {};
    existingDetails.forEach((existingDetail, index) => {
      const data = { ...existingDetail, salesProposalPackageId } as SalesProposalPackageDetailInformation & Mutable;
      newDetails = {
        ...newDetails,
        ...addReducer(
          'salesProposalDetails',
          { data },
          data.order // We attach the order here, but we will recompute the order after.
        ),
      };
    });

    newDetails = reassignOrders(newDetails);

    const existingDiscounts = Object.values(state.entities.salesProposalDiscounts)
      .filter(salesProposalDiscount => salesProposalDiscount.salesProposalPackageId === existingPackage.id)
      .filter(salesProposalDiscount => !salesProposalDiscount.deleted);

    let newDiscounts: SalesProposalDiscountState = {};
    existingDiscounts.forEach((existingDiscount, index) => {
      const data = { ...existingDiscount, salesProposalPackageId } as SalesProposalPackageDiscountInformation & Mutable;
      newDiscounts = {
        ...newDiscounts,
        ...addReducer('salesProposalDiscounts', { data }, index),
      };
    });

    const existingRebates = Object.values(state.entities.salesProposalRebates)
      .filter(salesProposalRebate => salesProposalRebate.salesProposalPackageId === existingPackage.id)
      .filter(salesProposalRebate => !salesProposalRebate.deleted);

    let newRebates: SalesProposalRebateState = {};
    existingRebates.forEach((existingRebate, index) => {
      const data = { ...existingRebate, salesProposalPackageId } as SalesProposalPackageRebateInformation & Mutable;
      newRebates = {
        ...newRebates,
        ...addReducer('salesProposalRebates', { data }, index),
      };
    });

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalPackages: {
          ...state.entities.salesProposalPackages,
          ...newPackage,
        },
        salesProposalDetails: {
          ...state.entities.salesProposalDetails,
          ...newDetails,
        },
        salesProposalDiscounts: {
          ...state.entities.salesProposalDiscounts,
          ...newDiscounts,
        },
        salesProposalRebates: {
          ...state.entities.salesProposalRebates,
          ...newRebates,
        },
      },
    };
  }),
  on(SalesProposalActions.updateSalesProposal, (state, action) =>
    entityReducer(state, 'salesProposal', { ...state.entities.salesProposal, ...action.patch, updated: true })
  ),
  on(SalesProposalActions.addPackage, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      salesProposalPackages: {
        ...state.entities.salesProposalPackages,
        ...addReducer('salesProposalPackages', action, Object.values(state.entities.salesProposalPackages).filter(m => !m.deleted).length),
      },
    },
  })),
  on(SalesProposalActions.addDetail, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      salesProposalDetails: {
        ...state.entities.salesProposalDetails,
        ...addReducer(
          'salesProposalDetails',
          action,
          action.conserveOrder
            ? action.data.order
            : Object.values(state.entities.salesProposalDetails).filter(
                m => m.salesProposalPackageId === action.data.salesProposalPackageId
              ).length
        ),
      },
    },
  })),
  on(SalesProposalActions.addDiscount, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      salesProposalDiscounts: {
        ...state.entities.salesProposalDiscounts,
        ...addReducer(
          'salesProposalDiscounts',
          action,
          Object.values(state.entities.salesProposalDiscounts).filter(m => m.salesProposalPackageId === action.data.salesProposalPackageId)
            .length
        ),
      },
    },
  })),
  on(SalesProposalActions.addRebate, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      salesProposalRebates: {
        ...state.entities.salesProposalRebates,
        ...addReducer(
          'salesProposalRebates',
          action,
          Object.values(state.entities.salesProposalRebates).filter(m => m.salesProposalPackageId === action.data.salesProposalPackageId)
            .length
        ),
      },
    },
  })),
  on(SalesProposalActions.addPayment, (state, action) => {
    const entitiesSnapshot = { ...state.entitiesSnapshot };
    let salesProposalPayment: SalesProposalPaymentState = {};
    if (action.isAlreadySavedOnServer) {
      salesProposalPayment[action.data.id] = { ...(action.data as SalesProposalPaymentInformation) };

      // We also add to the copyToSnapshot
      entitiesSnapshot.salesProposalPayments = {
        ...entitiesSnapshot.salesProposalPayments,
        [action.data.id]: { ...salesProposalPayment[action.data.id] },
      };
    } else {
      salesProposalPayment = addReducer(
        'salesProposalPayments',
        action,
        Object.values(state.entities.salesProposalPayments).length
      ) as unknown as SalesProposalPaymentState;
    }

    return {
      ...state,
      entitiesSnapshot,
      entities: {
        ...state.entities,
        salesProposalPayments: {
          ...state.entities.salesProposalPayments,
          ...salesProposalPayment,
        },
      },
    };
  }),
  on(SalesProposalActions.addFileInformation, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      fileInformations: {
        ...state.entities.fileInformations,
        ...addReducer('fileInformations', action, Object.values(state.entities.fileInformations).filter(m => !m.deleted).length),
      },
    },
  })),
  on(SalesProposalActions.updatePackage, (state, action) =>
    entityReducer(state, 'salesProposalPackages', {
      ...state.entities.salesProposalPackages,
      ...updateReducer(state.entities.salesProposalPackages, action),
    })
  ),
  on(SalesProposalActions.updateDetail, (state, action) =>
    entityReducer(state, 'salesProposalDetails', {
      ...state.entities.salesProposalDetails,
      ...updateReducer(state.entities.salesProposalDetails, action),
    })
  ),
  on(SalesProposalActions.updateDiscount, (state, action) =>
    entityReducer(state, 'salesProposalDiscounts', {
      ...state.entities.salesProposalDiscounts,
      ...updateReducer(state.entities.salesProposalDiscounts, action),
    })
  ),
  on(SalesProposalActions.updateRebate, (state, action) =>
    entityReducer(state, 'salesProposalRebates', {
      ...state.entities.salesProposalRebates,
      ...updateReducer(state.entities.salesProposalRebates, action),
    })
  ),
  on(SalesProposalActions.updatePayment, (state, action) =>
    entityReducer(state, 'salesProposalPayments', {
      ...state.entities.salesProposalPayments,
      ...updateReducer(state.entities.salesProposalPayments, action),
    })
  ),
  on(SalesProposalActions.deletePackage, (state, action) =>
    entityReducer(
      state,
      'salesProposalPackages',
      reassignOrders({ ...state.entities.salesProposalPackages, ...deleteReducer(state.entities.salesProposalPackages, action) })
    )
  ),
  on(SalesProposalActions.deleteDetail, (state, action) =>
    entityReducer(state, 'salesProposalDetails', {
      ...state.entities.salesProposalDetails,
      ...deleteReducer(state.entities.salesProposalDetails, action),
    })
  ),
  on(SalesProposalActions.deleteDiscount, (state, action) =>
    entityReducer(state, 'salesProposalDiscounts', {
      ...state.entities.salesProposalDiscounts,
      ...deleteReducer(state.entities.salesProposalDiscounts, action),
    })
  ),
  on(SalesProposalActions.deleteRebate, (state, action) =>
    entityReducer(state, 'salesProposalRebates', {
      ...state.entities.salesProposalRebates,
      ...deleteReducer(state.entities.salesProposalRebates, action),
    })
  ),
  on(SalesProposalActions.deletePayment, (state, action) =>
    entityReducer(state, 'salesProposalPayments', {
      ...state.entities.salesProposalPayments,
      ...deleteReducer(state.entities.salesProposalPayments, action),
    })
  ),
  on(SalesProposalActions.deleteFileInformation, (state, action) =>
    entityReducer(state, 'fileInformations', {
      ...state.entities.fileInformations,
      ...deleteReducer(state.entities.fileInformations, action),
    })
  ),
  on(SalesProposalActions.setLoanRates, (state, action) =>
    entityReducer(
      state,
      'salesProposalLoanApplications',
      (() => {
        // We try to re-use the loanApplications, then we mark them as updated, otherwise we mark as deleted
        const loanRateIds = action.data.slice(0);
        const upForUpdates: SalesProposalLoanApplicationInformation[] = [];
        const finalList: SalesProposalLoanApplicationState = [];

        // 1. First we search for the no-change
        for (const initialSalesProposalLoanApplication of state.initialSalesProposalLoanApplications) {
          const foundIndex = loanRateIds.indexOf(initialSalesProposalLoanApplication.loanRateId);

          // No change!
          if (foundIndex >= 0) {
            finalList.push({ ...initialSalesProposalLoanApplication });
            loanRateIds.splice(foundIndex, 1);
          } else {
            // We didn't find it, this one is marked for available for update
            upForUpdates.push(initialSalesProposalLoanApplication);
          }
        }

        // 2. Now we might have extra loanRateIds that we could try to update with the upForUpdates
        // Otherwise, we will create a new entry
        for (const loanRateId of loanRateIds) {
          const upForUpdate = upForUpdates.splice(0, 1)[0];
          if (upForUpdate) {
            finalList.push({ ...upForUpdate, loanRateId, updated: true });
          } else {
            finalList.push({
              id: --counters.salesProposalLoans,
              loanRateId,
              salesProposalId: 0, // We can leave blank here...
              selected: false,
              created: true,
            });
          }
        }

        // 3. Anything left in upForUpdates need to be deleted
        finalList.push(...upForUpdates.map(toDelete => ({ ...toDelete, deleted: true })));

        return finalList;
      })()
    )
  ),

  on(SalesProposalActions.updateTax, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        internalTaxItemEntry: { ...action.taxItemEntry, updated: true },
      },
    };
  }),
  on(SalesProposalActions.markSalesProposalAsNew, (state, action) =>
    entityReducer(state, 'salesProposal', {
      ...state.entities.salesProposal,
      id: --counters.salesProposal,
      createdBy: null,
      createdDate: new Date(),
      updatedBy: null,
      updatedDate: new Date(),
      created: true,
    })
  ),
  on(SalesProposalActions.markSalesProposalChildrenAsNew, (state, action) => {
    const result = markAsNewEntityReducer(state, 'salesProposalPackages', { salesProposalId: action.salesProposalId });
    result.entities.salesProposalLoanApplications = result.entities.salesProposalLoanApplications.map(obj =>
      markSalesProposalChildrenStateAsNew(obj, --counters.salesProposalLoans, { salesProposalId: action.salesProposalId })
    );
    return result;
  }),
  on(SalesProposalActions.markSalesProposalPackageChildrenAsNew, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalDiscounts: getFilteredEntityReducer(
          state,
          'salesProposalDiscounts',
          action.salesProposalPackage._oldId,
          action.salesProposalPackage.id
        ),
        salesProposalRebates: getFilteredEntityReducer(
          state,
          'salesProposalRebates',
          action.salesProposalPackage._oldId,
          action.salesProposalPackage.id
        ),
        salesProposalDetails: getFilteredEntityReducer(
          state,
          'salesProposalDetails',
          action.salesProposalPackage._oldId,
          action.salesProposalPackage.id
        ),
      },
    };
  }),
  on(SalesProposalActions.editMode, state => ({ ...state, entitiesSnapshot: clone(state.entities), edit: true })),
  on(
    SalesProposalActions.cancel,
    state =>
      ({
        ...state,
        entities: state.entitiesSnapshot,
        entitiesSnapshot: null,
        edit: false,
      }) as SalesProposalState
  ),

  on(SalesProposalActions.selectPackage, (state, action) => ({ ...state, selectingPackage: true })),

  on(SalesProposalActions.selectPackageSuccess, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalPackages: {
          ...state.entities.salesProposalPackages,
          [action.packageId]: {
            ...state.entities.salesProposalPackages[action.packageId],
            selected: true,
          },
        },
      },
      workOrderId: action.workOrderId,
      selectingPackage: false,
    };
  }),
  on(SalesProposalActions.selectPackageFail, (state, action) => ({ ...state, selectingPackage: false })),

  on(SalesProposalActions.saveSalesProposalSuccess, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposal: { ...state.entities.salesProposal, id: action.resource.id, updated: false, created: false },
        internalTaxItemEntry: { ...state.entities.internalTaxItemEntry, updated: false },
      },
    };
  }),
  on(SalesProposalActions.saveLoanSaveSuccess, (state, action) => {
    const newSalesProposalLoanApplications = state.entities.salesProposalLoanApplications.slice(0);
    const changedSalesProposalLoan = newSalesProposalLoanApplications.find(m => m.id === action.oldId);
    changedSalesProposalLoan.id = action.newId;

    const newInitialSalesProposalLoanApplications = state.initialSalesProposalLoanApplications.slice(0);

    if (action.oldId <= 0) {
      newInitialSalesProposalLoanApplications.push(clone(changedSalesProposalLoan));
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalLoanApplications: newSalesProposalLoanApplications,
      },
      initialSalesProposalLoanApplications: newInitialSalesProposalLoanApplications,
    };
  }),
  on(SalesProposalActions.saveLoanDeleteSuccess, (state, action) => {
    const newSalesProposalLoanApplications = state.entities.salesProposalLoanApplications.slice(0).filter(m => m.id !== action.resource.id);

    let newInitialSalesProposalLoanApplications = state.initialSalesProposalLoanApplications.slice(0);

    if (action.resource.id > 0) {
      newInitialSalesProposalLoanApplications = newInitialSalesProposalLoanApplications.filter(m => m.id !== action.resource.id);
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalLoanApplications: newSalesProposalLoanApplications,
      },
      initialSalesProposalLoanApplications: newInitialSalesProposalLoanApplications,
    };
  }),
  on(SalesProposalActions.savePackageSaveSuccess, (state, action) => {
    const newSalesProposalPackages = { ...state.entities.salesProposalPackages };
    newSalesProposalPackages[action.newId] = {
      ...newSalesProposalPackages[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newSalesProposalPackages[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalPackages: newSalesProposalPackages,
        salesProposalDetails: {
          ...state.entities.salesProposalDetails,
          ...indexBy(
            Object.values(state.entities.salesProposalDetails)
              .filter(details => details.salesProposalPackageId === action.oldId)
              .map(detail => ({ ...detail, salesProposalPackageId: action.newId })),
            'id'
          ),
        },
        salesProposalDiscounts: {
          ...state.entities.salesProposalDiscounts,
          ...indexBy(
            Object.values(state.entities.salesProposalDiscounts)
              .filter(discount => discount.salesProposalPackageId === action.oldId)
              .map(discount => ({ ...discount, salesProposalPackageId: action.newId })),
            'id'
          ),
        },
        salesProposalRebates: {
          ...state.entities.salesProposalRebates,
          ...indexBy(
            Object.values(state.entities.salesProposalRebates)
              .filter(rebate => rebate.salesProposalPackageId === action.oldId)
              .map(rebate => ({ ...rebate, salesProposalPackageId: action.newId })),
            'id'
          ),
        },
      },
    };
  }),
  on(SalesProposalActions.savePackageDeleteSuccess, (state, action) => {
    const newSalesProposalPackages = { ...state.entities.salesProposalPackages };
    delete newSalesProposalPackages[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalPackages: newSalesProposalPackages,
      },
    };
  }),
  on(SalesProposalActions.saveDiscountSaveSuccess, (state, action) => {
    const newSalesProposalDiscounts = { ...state.entities.salesProposalDiscounts };
    newSalesProposalDiscounts[action.newId] = {
      ...newSalesProposalDiscounts[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newSalesProposalDiscounts[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalDiscounts: newSalesProposalDiscounts,
      },
    };
  }),
  on(SalesProposalActions.saveDiscountDeleteSuccess, (state, action) => {
    const newSalesProposalDiscounts = { ...state.entities.salesProposalDiscounts };
    delete newSalesProposalDiscounts[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalDiscounts: newSalesProposalDiscounts,
      },
    };
  }),
  on(SalesProposalActions.saveRebateSaveSuccess, (state, action) => {
    const newSalesProposalRebates = { ...state.entities.salesProposalRebates };
    newSalesProposalRebates[action.newId] = {
      ...newSalesProposalRebates[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newSalesProposalRebates[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalRebates: newSalesProposalRebates,
      },
    };
  }),
  on(SalesProposalActions.saveRebateDeleteSuccess, (state, action) => {
    const newSalesProposalRebates = { ...state.entities.salesProposalRebates };
    delete newSalesProposalRebates[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalRebates: newSalesProposalRebates,
      },
    };
  }),
  on(SalesProposalActions.savePaymentSaveSuccess, (state, action) => {
    const newSalesProposalPayments = { ...state.entities.salesProposalPayments };
    newSalesProposalPayments[action.newId] = {
      ...newSalesProposalPayments[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newSalesProposalPayments[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalPayments: newSalesProposalPayments,
      },
    };
  }),
  on(SalesProposalActions.savePaymentDeleteSuccess, (state, action) => {
    const newSalesProposalPayments = { ...state.entities.salesProposalPayments };
    delete newSalesProposalPayments[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalPayments: newSalesProposalPayments,
      },
    };
  }),
  on(SalesProposalActions.saveFileInformationSaveSuccess, (state, action) => {
    const newFileInformations = { ...state.entities.fileInformations };
    newFileInformations[action.newResource.id] = {
      ...newFileInformations[action.oldId],
      ...action.newResource,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newResource.id) {
      delete newFileInformations[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        fileInformations: newFileInformations,
      },
    };
  }),
  on(SalesProposalActions.saveFileInformationsDeleteSuccess, (state, action) => {
    const newFileInformations = { ...state.entities.fileInformations };

    action.resources.forEach(resource => {
      delete newFileInformations[resource.id];
    });

    return {
      ...state,
      entities: {
        ...state.entities,
        fileInformations: newFileInformations,
      },
    };
  }),
  on(SalesProposalActions.saveDetailSaveSuccess, (state, action) => {
    const newSalesProposalDetails = { ...state.entities.salesProposalDetails };
    newSalesProposalDetails[action.newId] = {
      ...newSalesProposalDetails[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newSalesProposalDetails[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalDetails: newSalesProposalDetails,
      },
    };
  }),
  on(SalesProposalActions.saveDetailDeleteSuccess, (state, action) => {
    const newSalesProposalDetails = { ...state.entities.salesProposalDetails };
    delete newSalesProposalDetails[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalDetails: newSalesProposalDetails,
      },
    };
  }),
  on(SalesProposalActions.copyToSnapshot, state => {
    return {
      ...state,
      entitiesSnapshot2: clone(state.entities),
    };
  }),
  on(SalesProposalActions.restoreFromSnapshot, state => {
    return {
      ...state,
      entities: state.entitiesSnapshot2,
    };
  }),
  on(SalesProposalActions.makeTemplate, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposal: {
          ...state.entities.salesProposal,
          text: action.text,
          siteId: null,
          status: SalesProposalStatus.Created,
          taxItemId: null,
          taxOverride: null,
          salesTaxPercentage: null,
          isTemplate: true,
        },
      },
    };
  }),
  on(SalesProposalActions.makeReal, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposal: {
          ...state.entities.salesProposal,
          isTemplate: false,
        },
      },
    };
  })
);

export function reducer(state: SalesProposalState | undefined, action: Action) {
  return salesProposalReducer(state, action);
}
