import { Injectable, inject } from '@angular/core';
import { type HelpJsonImpl, type HelpModel, type HelpModelData } from '@models/help';
import { skipBubbleError } from '@utility/angular';
import { notEmpty } from '@utility/array';
import { of, type Observable } from 'rxjs';
import { catchError, concatMap, filter, map, shareReplay } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { HttpClientService } from './http-client.service';

@Injectable()
export class HelpJsonService implements HelpJsonImpl {
  private readonly http = inject(HttpClientService);
  private readonly configService = inject(ConfigService);

  private _cache$: Observable<HelpModel | null> | null = null;

  getHelpId(page: string, section: string): string {
    return `${page}.${section}`;
  }

  getJson$(): Observable<HelpModel | null> {
    return (
      this._cache$ ??
      (this._cache$ = this.configService.loadConfig().pipe(
        concatMap(() => {
          return this.http.get<HelpModel>('/assets/help.json', skipBubbleError()).pipe(
            catchError(() => {
              console.error('The help file does not format properly.');
              return of(null);
            })
          );
        }),
        shareReplay(1)
      ))
    );
  }

  getHelpData$(helpId: string | null | undefined): Observable<HelpModelData | undefined> {
    if (helpId) {
      return this.getJson$()
        .pipe(filter(notEmpty))
        .pipe(
          map(helpModel => {
            return helpModel.help.find(m => m.locations.includes(helpId));
          })
        );
    }

    return of(undefined);
  }
}

