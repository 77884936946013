import { type OrderedResource, type StateResource } from './resource';

export interface LoanRate extends OrderedResource {
  loanCompany: LoanCompany;
  bankName: string;
  loanCode: string;
  termsInMonths: number;
  loanAmountMin: Money;
  loanAmountMax: Money;
  interestRateMin: Percentage | null;
  interestRateMax: Percentage | null;
  interestRatePenalty: Percentage | null;
}

export enum LoanCompany {
  Manual = 0,
  EnerBank = 1,
}

export enum LoanStatus {
  Selected = 0,
  New = 1,
  Prequalified,
  Approved,
  Submitted,
  QandARequired,
  Denied,
  Completed,
}

export interface WorkOrderLoanApplicationInformation extends StateResource {
  workOrderId: Id;
  loanCompany: LoanCompany;
  bankName: string;
  loanCode: string;
  referenceNumber: string | null;
  status: LoanStatus;
  termsInMonths: number;
  interestRateMin: Percentage | null;
  interestRateMax: Percentage | null;
  interestRatePenalty: Percentage | null;
  extra: any;
  estimatedCost: Money;
}

export interface LoanCreate {
  loanRateId?: Id;
  workOrderId: Id;
  estimatedCost: Money;
  workOrderLoanApplicationId?: Id;

  extra: Dictionary<string, string> | any;
}

export interface LoanSelection {
  loanRateId?: Id;
  workOrderLoanApplicationId?: Id;
}

export function getLoanStatus(loanStatus: LoanStatus): { code: string; text: string } {
  switch (loanStatus) {
    case LoanStatus.Selected:
      return { code: 'start', text: 'Selected' };
    case LoanStatus.Approved:
      return { code: 'green', text: 'Approved' };
    case LoanStatus.Denied:
      return { code: 'red', text: 'Denied' };
    case LoanStatus.New:
      return { code: 'yellow', text: 'New' };
    case LoanStatus.Prequalified:
      return { code: 'green', text: 'Pre-Qualified' };
    case LoanStatus.QandARequired:
      return { code: 'yellow', text: 'Q&A Required' };
    case LoanStatus.Submitted:
      return { code: 'yellow', text: 'Submitted' };
    case LoanStatus.Completed:
      return { code: 'green', text: 'Completed' };
  }
}
