import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, output, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { format, set } from 'date-fns';

const noop = (): void => {};

const DATEPICKER_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimepickerComponent),
  multi: true,
};

@Component({
  selector: 'wm-timepicker',
  templateUrl: 'timepicker.component.html',
  styleUrls: ['timepicker.component.scss'],
  providers: [DATEPICKER_COMPONENT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class TimepickerComponent implements ControlValueAccessor {
  readonly valueChange = output<number>();

  // We don't want to use computed because otherwise the user can't type easily
  // in the boxes.
  readonly hour = signal<string>('');
  readonly minute = signal<string>('');
  readonly ampm = signal<string>('');

  // The internal data model
  readonly innerValue = signal<Date | null>(null);

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  get value(): any {
    return this.innerValue();
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue()) {
      this.innerValue.set(v);
      this.updateBoxes();
      this.onChangeCallback(v);
    }
  }

  dateSelected(date: Date): void {
    this.value = date;
  }

  updateBoxes(): void {
    const m = this.innerValue();
    if (m) {
      this.hour.set(format(m, 'hh'));
      this.minute.set(format(m, 'mm'));
      this.ampm.set(format(m, 'a'));
    }
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    value ??= null;
    if (value !== this.innerValue()) {
      this.innerValue.set(value);
      this.updateBoxes();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  hourChange(value: string): void {
    const h = parseInt(value, 10);
    if (!isNaN(h)) {
      this.setHour(h);
    } else {
      this.updateBoxes();
    }
  }

  minuteChange(value: string): void {
    const m = parseInt(value, 10);
    if (!isNaN(m)) {
      this.setMinute(m);
    } else {
      this.updateBoxes();
    }
  }

  ampmChange(value: string): void {
    const ampm = value.toLowerCase();
    if (ampm === 'am' || ampm === 'pm') {
      //  Did we change it?
      if (format(this.innerValue() ?? new Date(), 'a').toLowerCase() !== ampm) {
        this.changeAmPm();
      }

      //  Update so we get the correct casing.
      this.updateBoxes();
    } else {
      this.updateBoxes();
    }
  }

  changeHour(diff: number): void {
    let hour = this.getValidInnerValue().getHours();
    hour = this.magicMod(hour + diff, 24);
    this.setHour(hour);
  }

  private setHour(hour: number): void {
    hour = this.magicMod(hour, 24);
    this.value = set(this.innerValue() ?? new Date(), {
      hours: hour,
    });
  }

  changeMinute(diff: number): void {
    let minute = this.getValidInnerValue().getMinutes();

    minute = this.magicMod(minute + diff, 60);

    const mod = minute % 15;
    //  We round up or down depending if we ask for higher or lower.
    if (diff < 0 && mod > 0) {
      minute += 15 - mod;
    } else {
      minute -= mod;
    }

    this.setMinute(minute);
  }

  private setMinute(minute: number): void {
    minute = this.magicMod(minute, 60);
    this.value = set(this.innerValue() ?? new Date(), {
      minutes: minute,
    });
  }

  changeAmPm(): void {
    this.changeHour(12);
  }

  private magicMod(x: number, m: number): number {
    const r = x % m;
    return r < 0 ? r + m : r;
  }

  private getValidInnerValue(): Date {
    if (!this.innerValue()) {
      this.innerValue.set(new Date(1970, 0, 1, 0, 0, 0, 0));
    }

    return this.innerValue() ?? new Date();
  }
}
