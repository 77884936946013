import {
  type BookType,
  type PartItemInformation,
  type PriceBookCategoryInformation,
  type PriceBookInformation,
  type ServiceRepairInformation,
} from '@models/price-book-models';
import { createAction, props } from '@ngrx/store';
import {
  createSubmitAction,
  createSubmitFailAction,
  createSubmitInitialAction,
  createSubmitSuccessAction,
} from '@services/save-store/save.actions';
import { type PriceBookDetailFilter } from './price-book-detail.reducer';
// All the save actions are only for the "non-dialog" one.
import { PRICE_BOOK_DETAIL_STORE_KEY } from './price-book-detail.selectors';

export const clear = createAction('[PriceBookDetail] clear');

export const loadPriceBookData = createAction(
  '[PriceBookDetail] load',
  props<{
    priceBooks: PriceBookInformation[];
    priceBookCategories: PriceBookCategoryInformation[];
    serviceRepairs: ServiceRepairInformation[];
    partItems: PartItemInformation[];
    group: string;
    bookType?: BookType;
  }>()
);
export const selectPriceBook = createAction('[PriceBookDetail] selectPriceBook', props<{ id: Id; group: string }>());

export const changeFilter = createAction(
  '[PriceBookDetail] changeFilter',
  props<{ data: Partial<PriceBookDetailFilter>; group: string }>()
);

export const editMode = createAction('[PriceBookDetail] edit', props<{ group: string }>());
export const cancel = createAction('[PriceBookDetail] cancel', props<{ group: string }>());

export const save = createSubmitInitialAction(PRICE_BOOK_DETAIL_STORE_KEY, '[PriceBookDetail] save', props<{ group: string }>());
export const saveSuccess = createSubmitSuccessAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] save success',
  props<{ data: PriceBookCategoryInformation[]; group: string }>()
);
export const saveFailure = createSubmitFailAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] save failure',
  props<{ error: string }>()
);

export const noop2 = createAction('[PriceBookDetail] noop2', props<{ id: Id; group: string }>());

export const setSelections = createAction(
  '[PriceBookDetail] setSelections',
  props<{ selections: { serviceRepairId: Id; partItemIds: Id[] }[]; group: string }>()
);
export const setPartItemIdsSelections = createAction(
  '[PriceBookDetail] setPartItemIdsSelections',
  props<{ selections: Id[]; group: string }>()
);

// categories
export const addCategory = createAction(
  '[PriceBookDetail] add category',
  props<{ data: Partial<PriceBookCategoryInformation>; group: string }>()
);
export const updateCategory = createAction(
  '[PriceBookDetail] update category',
  props<{ id: Id; patch: Partial<PriceBookCategoryInformation>; group: string }>()
);
export const deleteCategory = createAction('[PriceBookDetail] delete category', props<{ id: Id; group: string }>());

// service repairs
export const addServiceRepair = createAction(
  '[PriceBookDetail] add service repair',
  props<{ categoryId: Id; data: Partial<ServiceRepairInformation>; group: string }>()
);
export const updateServiceRepair = createAction(
  '[PriceBookDetail] update service repair',
  props<{ id: Id; patch: Partial<ServiceRepairInformation>; group: string }>()
);
export const changeServiceRepairOrder = createAction(
  '[PriceBookDetail] change service repair order',
  props<{ categoryId: Id; id: Id; order: number; group: string }>()
);
export const deleteServiceRepair = createAction(
  '[PriceBookDetail] delete service repair',
  props<{ categoryId: Id; id: Id; group: string }>()
);

export const saveServiceRepair = createSubmitAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] saveServiceRepair',
  props<{ id: Id; group: string }>()
);
export const saveServiceRepairSuccess = createSubmitSuccessAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] saveServiceRepairSuccess',
  props<{ oldId: Id; newId: Id; group: string }>()
);
export const saveServiceRepairFailure = createSubmitFailAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] saveServiceRepairFailure',
  props<{ error: string }>()
);

export const savePriceBookCategoryServiceRepair = createSubmitAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePriceBookCategoryServiceRepair',
  props<{ id: Id; group: string }>()
);
export const savePriceBookCategoryServiceRepairSuccess = createSubmitSuccessAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePriceBookCategoryServiceRepairSuccess',
  props<{ id: Id; group: string }>()
);
export const savePriceBookCategoryServiceRepairFailure = createSubmitFailAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePriceBookCategoryServiceRepairFailure',
  props<{ error: string }>()
);

// Part Items
export const addPartItem = createAction(
  '[PriceBookDetail] add part item',
  props<{ categoryId: Id; data: Partial<PartItemInformation>; group: string }>()
);
export const updatePartItem = createAction(
  '[PriceBookDetail] update part item',
  props<{ id: Id; patch: Partial<PartItemInformation>; group: string }>()
);
export const changePartItemOrder = createAction(
  '[PriceBookDetail] change part item order',
  props<{ categoryId: Id; id: Id; order: number; group: string }>()
);
export const deletePartItem = createAction('[PriceBookDetail] delete part item', props<{ categoryId: Id; id: Id; group: string }>());

export const savePartItem = createSubmitAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePartItem',
  props<{ id: Id; group: string }>()
);
export const savePartItemSuccess = createSubmitSuccessAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePartItemSuccess',
  props<{ oldId: Id; newId: Id; group: string }>()
);
export const savePartItemEmptySuccess = createSubmitSuccessAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePartItemEmptySuccess',
  props<{ id: Id; group: string }>()
);
export const savePartItemFailure = createSubmitFailAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePartItemFailure',
  props<{ error: string }>()
);

export const savePriceBookCategoryPartItem = createSubmitAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePriceBookCategoryPartItem',
  props<{ id: Id; group: string }>()
);
export const savePriceBookCategoryPartItemSuccess = createSubmitSuccessAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePriceBookCategoryPartItemSuccess',
  props<{ id: Id; group: string }>()
);
export const savePriceBookCategoryPartItemFailure = createSubmitFailAction(
  PRICE_BOOK_DETAIL_STORE_KEY,
  '[PriceBookDetail] savePriceBookCategoryPartItemFailure',
  props<{ error: string }>()
);

export const noop = createAction('[PriceBookDetail] noop');

export type Actions = ReturnType<
  | typeof clear
  | typeof loadPriceBookData
  | typeof selectPriceBook
  | typeof changeFilter
  | typeof editMode
  | typeof cancel
  | typeof save
  | typeof saveSuccess
  | typeof saveFailure
  | typeof noop2
  | typeof setSelections
  | typeof setPartItemIdsSelections
  | typeof addCategory
  | typeof updateCategory
  | typeof deleteCategory
  | typeof addServiceRepair
  | typeof updateServiceRepair
  | typeof deleteServiceRepair
  | typeof saveServiceRepair
  | typeof saveServiceRepairSuccess
  | typeof saveServiceRepairFailure
  | typeof savePriceBookCategoryServiceRepair
  | typeof savePriceBookCategoryServiceRepairSuccess
  | typeof savePriceBookCategoryServiceRepairFailure
  | typeof addPartItem
  | typeof updatePartItem
  | typeof deletePartItem
  | typeof savePartItem
  | typeof savePartItemSuccess
  | typeof savePartItemEmptySuccess
  | typeof savePartItemFailure
  | typeof savePriceBookCategoryPartItem
  | typeof savePriceBookCategoryPartItemSuccess
  | typeof savePriceBookCategoryPartItemFailure
  | typeof noop
>;
