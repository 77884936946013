import { Injectable, inject } from '@angular/core';
import { type PriceBookCategoryInformation, type PriceBookInformation, type ServiceRepairInformation } from '@models/price-book-models';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { HttpClientService } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { skipBubbleError } from '@utility/angular';
import { type Observable, share, tap } from 'rxjs';

@Injectable()
export class PriceBooksService {
  private readonly http = inject(HttpClientService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);

  list(): Observable<PriceBookInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.PriceBooks, this.url.priceBooksList);
  }

  save(priceBookInformation: PriceBookInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.priceBooksCreate, priceBookInformation).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.priceBooksDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  saveServiceRepair(priceBookId: Id, serviceRepair: ServiceRepairInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(
      this.url.priceBooksSaveServiceRepairs.replace('$0', priceBookId.toString()),
      serviceRepair,
      skipBubbleError()
    );
  }

  hideServiceRepair(priceBookId: Id, serviceRepairId: Id): Observable<void> {
    return this.http.delete<void>(
      this.url.priceBooksDeleteServiceRepairs.replace('$0', priceBookId.toString()).replace('$1', serviceRepairId.toString())
    );
  }

  setServiceRepairCategories(priceBookId: Id, serviceRepairId: Id, categoryIds: Id[]): Observable<void> {
    return this.http.post<void>(
      this.url.priceBooksSetServiceRepairCategories.replace('$0', priceBookId.toString()).replace('$1', serviceRepairId.toString()),
      { input: categoryIds }
    );
  }

  saveCategories(priceBookId: Id, categories: PriceBookCategoryInformation[]): Observable<PriceBookCategoryInformation[]> {
    return this.http.post<PriceBookCategoryInformation[]>(
      this.url.priceBooksSaveCategories.replace('$0', priceBookId.toString()),
      categories
    );
  }

  /**
   * Gets unflatten price book categories.
   */
  getPriceBookCategories(): Observable<PriceBookCategoryInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.ServiceRepairCategories, this.url.priceBooksGetCategories);
  }

  setServiceRepairPartItems(priceBookId: Id, serviceRepairId: Id, partItemIds: Id[]): Observable<void> {
    return this.http.post<void>(
      this.url.priceBooksSetServiceRepairPartItemIds.replace('$0', priceBookId.toString()).replace('$1', serviceRepairId.toString()),
      { input: partItemIds }
    );
  }

  setCategoriesPartItems(categoryId: Id, idWithOrders: { id: Id; order: number }[]): Observable<void> {
    return this.http.post<void>(this.url.priceBooksSetCategoryPartItemIds.replace('$0', categoryId.toString()), idWithOrders);
  }

  setCategoriesServiceRepairs(categoryId: Id, idWithOrders: { id: Id; order: number }[]): Observable<void> {
    return this.http.post<void>(this.url.priceBooksSetCategoryServiceRepairIds.replace('$0', categoryId.toString()), idWithOrders);
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.PriceBooks);
    }
  }
}
