import { Injectable, inject } from '@angular/core';
import { attachBlobErrorDetection } from '@models/error-models';
import { type BusinessValuation } from '@models/web-marketing';
import { type ExtraOptionsJson, HttpClientService } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { type Observable } from 'rxjs';

@Injectable()
export class WebMarketingService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  businessValuation(businessValuation: BusinessValuation, extraOptions?: ExtraOptionsJson): Observable<Blob> {
    return this.http
      .post(this.url.webMarketingBusinessValuation, businessValuation, {
        ...extraOptions,
        responseType: 'blob',
      })
      .pipe(attachBlobErrorDetection());
  }
}
