import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { type Address } from '@models/address';
import { type HitHighlights } from '@models/result';

@Pipe({
  name: 'address',
  standalone: true,
})
@Injectable()
export class AddressPipe implements PipeTransform {
  transform(address: Address | null | undefined): string {
    if (!address) {
      return '';
    }

    const list: string[] = [];

    if (address.street) {
      list.push(address.street);
    }

    if (address.city) {
      list.push(address.city);
    }

    const subList: string[] = [];
    if (address.state) {
      subList.push(address.state);
    }

    if (address.zip) {
      subList.push(address.zip);
    }

    if (subList.length > 0) {
      list.push(subList.join(' ')); // LANG
    }

    return list.join(', ');
  }

  public cityStateZip(address: Address): string {
    if (!address) {
      return '';
    }

    const list: string[] = [];

    if (address.city) {
      list.push(address.city);
    }

    const subList: string[] = [];
    if (address.state) {
      subList.push(address.state);
    }

    if (address.zip) {
      subList.push(address.zip);
    }

    if (subList.length > 0) {
      list.push(subList.join(' ')); // LANG
    }

    return list.join(', ');
  }

  public cityStateZipTransformWithHighlights(address: Address, highlights: HitHighlights) {
    if (!address) {
      return '';
    }

    const list: string[] = [];

    if (address.city) {
      list.push((highlights && highlights.AddressCity && highlights.AddressCity[0]) || address.city);
    }

    const subList: string[] = [];
    if (address.state) {
      subList.push((highlights && highlights.AddressState && highlights.AddressState[0]) || address.state);
    }

    if (address.zip) {
      subList.push((highlights && highlights.AddressPostalCode && highlights.AddressPostalCode[0]) || address.zip);
    }

    if (subList.length > 0) {
      list.push(subList.join(' ')); // LANG
    }

    return list.join(', ');
  }
}
