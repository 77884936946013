import { Component, input } from '@angular/core';
import { DeletedPipe } from '@pipes/deleted.pipe';

// Angular 18
@Component({
  template: '<span [innerHTML]="show() | deleted"></span>',
  imports: [DeletedPipe],
  standalone: true,
})
export class TableDynamicNodeDeletedComponent {
  readonly show = input(false);
}
