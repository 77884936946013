import { ChangeDetectorRef, Directive, computed, contentChild, inject, input } from '@angular/core';
import { type ExtraResource, type Resource1, type StateResource } from '@models/resource';
import { InputDropdownComponent } from '../input-dropdown/input-dropdown.component';

const noop = (): void => {};

// Angular 18
@Directive()
export class InputResourceBase<T, TKey, U extends Resource1<TKey>> {
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);

  // The internal data model
  // TODO Angular 17.3.1 doesn't work with signal here.
  innerValue: T | null = null;

  readonly clearable = input(false);
  readonly changeEmptyTo = input<TKey | null>(null);
  readonly closeOnSelection = input(false);
  readonly options = input.required<U[]>();

  readonly dropdownParent = input<'body' | undefined>(undefined);

  readonly dropdown = contentChild.required('dropdown', { read: InputDropdownComponent });

  readonly visibleOptions = computed(() => {
    return this.options().filter(m => {
      // If we have a StateResource, don't display the hidden resources
      if ((m as StateResource).hidden) {
        return false;
      }

      return true;
    });
  });

  // get accessor
  get value(): T | null {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: T | null) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.updateUI();
      this.onChangeCallback(v);
    }
  }

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    value ??= null;
    if (value !== this.innerValue) {
      this.innerValue = value as T | null;
      this.changeDetectorRef.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  updateUI(): void {
    this.changeDetectorRef.markForCheck();
  }

  searchFn(term: string, item: ExtraResource): boolean {
    term = term?.toLocaleLowerCase();
    return (item?.text?.toLocaleLowerCase()?.indexOf(term) ?? 0) >= 0 || (item?.secondary?.toLocaleLowerCase()?.indexOf(term) ?? 0) >= 0;
  }
}
