import { type HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, inject, type OnDestroy } from '@angular/core';
import { type Token, UserToken } from '@models/user';
import { AppInsightsService } from '@services/app-insights.service';
import { BehaviorSubject, Observable, of, type Subscriber } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { HttpClientBaseService } from './http-client-base.service';
import { TimerService } from './timer.service';
import { UrlService } from './url.service';
import { isCypress } from '@utility/cypress';
import { WindowRefService } from './window-ref.service';
import { allowRetries } from '@utility/angular';

const clientId = '3CConnect';
const RETRY_COUNT = 2;

function standardEncoding(v: string): string {
  return encodeURIComponent(v)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/gi, '$')
    .replace(/%2C/gi, ',')
    .replace(/%3B/gi, ';')
    .replace(/%2B/gi, '+')
    .replace(/%3D/gi, '=')
    .replace(/%3F/gi, '?')
    .replace(/%2F/gi, '/');
}

/*
 * CustomQueryEncoderHelper
 * Fix plus sign (+) not encoding, so sent as blank space
 * See: https://github.com/angular/angular/issues/11058#issuecomment-247367318
 */
class CustomQueryEncoder {
  encodeKey(k: string): string {
    k = standardEncoding(k);
    return k.replace(/\+/gi, '%2B');
  }

  encodeValue(v: string): string {
    v = standardEncoding(v);
    return v.replace(/\+/gi, '%2B');
  }
}

@Injectable()
export class AuthService implements OnDestroy {
  private readonly LOGIN_KEY = 'WM-Login';

  private readonly http = inject(HttpClientBaseService);
  private readonly url = inject(UrlService);
  private readonly cache = inject(CacheService);
  private readonly appinsightsService = inject(AppInsightsService);
  private readonly timerService = inject(TimerService);
  private readonly configService = inject(ConfigService);
  private readonly windowRef = inject(WindowRefService);

  isRenewing = false;

  private internalHandler?: number;
  private readonly _currentTokenSubject = new BehaviorSubject<UserToken | undefined>(undefined);

  constructor() {
    const obj = this.cache?.getObject<string>(this.LOGIN_KEY);
    if (obj) {
      this.currentToken = UserToken.fromSerialization(obj);
    }

    // We set the context every 5 minutes, it seems that Azure
    // loses its context after a while. This does not make any extra requests.
    if (this.timerService) {
      this.internalHandler = this.timerService.setInterval(
        () => {
          this.assignAI();
        },
        1000 * 60 * 5
      );
    }
  }

  init() {
    // This is needed to wait for ConfigurationService.config to be set.
    this.currentToken = this.currentToken;
  }

  ngOnDestroy(): void {
    if (this.internalHandler) {
      this.timerService.clearInterval(this.internalHandler);
      this.internalHandler = undefined;
    }
  }

  private get currentToken(): UserToken | undefined {
    return this._currentTokenSubject.value;
  }

  private set currentToken(token: UserToken | undefined) {
    this._currentTokenSubject.next(token);

    if (!isCypress()) {
      this.assignAI();
    }
  }

  private assignAI(): void {
    if (this.currentToken) {
      this.appinsightsService.setAuthenticatedUserContext((this.currentToken.token.technicianId ?? 0).toString());
    } else {
      this.appinsightsService.clearAuthenticatedUserContext();
    }
  }

  get isLoggedIn(): boolean {
    return !!this.currentToken && !!this.currentToken.isStillValid();
  }

  private handleTokenRequest(httpParams: HttpParams, storeToken = true, requestHeaders = {}): Observable<Token> {
    return new Observable((observer: Subscriber<Token>) => {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...requestHeaders,
      };

      let { authUrl } = this.configService.config;
      if (isCypress() && this.configService.config.cypressLoginUrl) {
        authUrl = this.configService.config.cypressLoginUrl;
      }

      this.handleObservableToken(
        this.http.post<Token>(authUrl, httpParams.toString(), { headers, ...allowRetries(RETRY_COUNT) }),
        observer,
        storeToken
      );
    }).pipe(share());
  }

  private handleObservableToken(token: Observable<Token>, observer: Subscriber<Token>, storeToken = true): void {
    const requestedMoment = new Date();
    token.subscribe({
      next: (data: Token) => {
        if (data.access_token) {
          data.technicianId = parseInt((data.technicianId ?? 0).toString(), 10);

          if (storeToken) {
            this.cache.init(data.technicianId);
            this.currentToken = new UserToken(data, requestedMoment);
            this.cache.setObject(this.LOGIN_KEY, this.currentToken.serialize());
          }

          observer.next(data);
          observer.complete();
        } else {
          observer.error("Can't find a token.");
        }
      },
      error: (response: HttpErrorResponse) => {
        observer.error(response);
      },
    });
  }

  isImpersonating(): boolean {
    return this.currentToken?.token.impersonating === '1';
  }

  getImpersonator(): string | null {
    return this.currentToken?.token?.impersonator ?? null;
  }

  refreshToken(): Observable<boolean> {
    if (!this.isRenewing) {
      this.isRenewing = true;
      if (this.currentToken?.token.refresh_token) {
        const urlSearchParams = this.createHttpParams({
          fromObject: {
            client_id: clientId,
            grant_type: 'refresh_token',
            refresh_token: this.currentToken.token.refresh_token,
          },
        });

        const response = this.handleTokenRequest(urlSearchParams);
        response.subscribe(
          () => {
            this.isRenewing = false;
          },
          () => {
            this.isRenewing = false;
          }
        );

        return response.pipe(map(m => !!m));
      }
    }

    return of(false);
  }

  loginWithToken(accessToken: string): Observable<Token> {
    return new Observable((observer: Subscriber<Token>) => {
      // To verify if the token is valid, we will make a query.
      const headers = {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      };
      this.handleObservableToken(
        this.http.post<Token>(this.url.accountTokenInfo, `"${accessToken}"`, { headers, ...allowRetries(RETRY_COUNT) }),
        observer
      );
    }).pipe(share());
  }

  login(username: string, password: string, impersonatingUser?: string): Observable<Token> {
    let urlSearchParams = this.createHttpParams({
      fromObject: {
        client_id: clientId,
        grant_type: 'password',
        username,
        password,
      },
    });

    if (impersonatingUser) {
      urlSearchParams = urlSearchParams.append('impersonated_username', impersonatingUser);
    }

    return this.handleTokenRequest(urlSearchParams);
  }

  loginFromSandbox(handleRedirect = true): Observable<UserToken> {
    const urlSearchParams = this.createHttpParams({
      fromObject: {
        client_id: clientId,
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange:sandbox',
      },
    });

    const token = this.getCurrentToken();

    return this.handleTokenRequest(urlSearchParams, false, { Authorization: `Bearer ${token.token.access_token}` }).pipe(
      map(token => {
        const userToken = new UserToken(token, new Date());

        if (!userToken.isStillValid()) {
          throw new Error('Invalid token');
        }

        return userToken;
      }),
      tap({
        next: userToken => {
          if (!handleRedirect) {
            return;
          }

          this.windowRef.nativeWindow.location.href = `${this.configService.config.appUrl}/login;token=${userToken.token.access_token};fromSandbox=1`;
        },
      })
    );
  }

  getCurrentToken(): UserToken | undefined {
    return this.currentToken;
  }

  getCurrentToken$() {
    return this._currentTokenSubject.asObservable();
  }

  logout(): void {
    this.currentToken = undefined;
    this.cache.remove(this.LOGIN_KEY);
  }

  private createHttpParams(options: any): HttpParams {
    return new HttpParams(
      Object.assign({}, options, {
        encoder: new CustomQueryEncoder(),
      })
    );
  }
}

