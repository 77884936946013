import { Injectable, inject } from '@angular/core';
import { type RecommendationInformation } from '@models/cards/recommendation-information';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class RecommendationsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  save(recommendationInformations: RecommendationInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.recommendationSave, recommendationInformations);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.recommendationHide.replace('$0', id.toString()));
  }
}
