import { HttpResponse, type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SimpleErrorMessage } from '@models/error-models';
import { AppInsightsService } from '@services/app-insights.service';
import { ErrorService } from '@services/error.service';
import { tap } from 'rxjs/operators';
import semver from 'semver';

export const MinimumVersionInterceptor: HttpInterceptorFn = (
  req,
  next,
  errorService = inject(ErrorService),
  appInsightsService = inject(AppInsightsService)
) => {
  return next(req).pipe(
    tap(event => {
      if (event instanceof HttpResponse && event.headers) {
        const minimumVersionHeader = event.headers.get('X-Minimum-Version');

        if (minimumVersionHeader && process.env.NG_APP_BUILD_VERSION) {
          const buildNumber = semver.valid(semver.coerce(process.env.NG_APP_BUILD_VERSION));
          const minimumBuildNumber = semver.valid(semver.coerce(minimumVersionHeader));
          const isOutdated = !!buildNumber && !!minimumBuildNumber && !semver.satisfies(buildNumber, `>=${minimumBuildNumber}`);

          if (isOutdated) {
            let errorMessage = '3C Connect website has received an update. ';
            errorMessage += 'Please finish what you are doing and refresh this page.';

            const error = new SimpleErrorMessage(errorMessage);
            errorService.trigger(error);

            if (appInsightsService) {
              appInsightsService.trackEvent('git-differ');
            }
          }
        }
      }
    })
  );
};

