import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, type OnInit } from '@angular/core';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { InputImageComponent } from '@controls/input-image/input-image.component';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { FILTER_DIALOG_DATA } from '@models/dialog';
import { FilterData } from '@models/filter-models';
import { type Resource } from '@models/resource';
import { type TechnicianInformation } from '@models/technician';
import { SharedModule } from '@modules/shared.module';
import { StaticDataService } from '@services/static-data.service';
import { endOfDay } from 'date-fns';
import { FilterComponent } from './filter.component';

/** @deprecated */
@Component({
  templateUrl: 'filter-report.component.html',
  styleUrls: ['filter-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, InputImageComponent, InputMomentPickerComponent, InputDropdownComponent],
})
export class FilterReportComponent extends FilterComponent implements OnInit {
  private readonly staticData = inject(StaticDataService);
  private readonly cd = inject(ChangeDetectorRef);

  _datePipe: DatePipe;
  technicians: TechnicianInformation[] = [];
  _date = 0;
  appointmentStatusOptions: Resource[] = [
    { id: 0, text: this.getAppointmentStatusDisplay(0) },
    { id: 1, text: this.getAppointmentStatusDisplay(1) },
    { id: 2, text: this.getAppointmentStatusDisplay(2) },
  ];

  constructor(@Inject(FILTER_DIALOG_DATA) data: FilterData) {
    super(data);
    this._datePipe = new DatePipe('en-US');
  }

  ngOnInit(): void {
    this.staticData.getTechnicians().subscribe(m => {
      this.technicians = m;
      this.technician = this.technician; // We assign the correct data.
      this.cd.markForCheck();
    });
  }

  get appointmentStatus(): number {
    return this.getValue('appointmentStatus') || 0;
  }

  set appointmentStatus(value: number) {
    if (value) {
      this.setValue('appointmentStatus', `${this.getAppointmentStatusDisplay(value)}`, value);
    } else {
      this.removeValue('appointmentStatus');
    }
  }

  get date(): number {
    return this._date;
  }

  set date(value: number) {
    this._date = value;
    if (value === 1 && (this.dateFrom || this.dateEnd)) {
      this.setValue('date', this.getDateDisplay(this.dateFrom, this.dateEnd), 1);
    } else {
      this.removeValue('date');
    }
  }

  get dateFrom(): Date | undefined {
    return this.getValue('dateFrom');
  }

  set dateFrom(value: Date | undefined) {
    if (value) {
      this.setValue('dateFrom', undefined, value);
    } else {
      this.removeValue('dateFrom');
    }

    this.date = 1;
  }

  get dateEnd(): Date | undefined {
    return this.getValue('dateEnd');
  }

  set dateEnd(value: Date | undefined) {
    if (value) {
      this.setValue('dateEnd', undefined, endOfDay(value));
    } else {
      this.removeValue('dateEnd');
    }

    this.date = 1;
  }

  get technician(): number | undefined {
    return this.getValue('technicianId');
  }

  set technician(value: number | undefined) {
    if (value) {
      this.setValue('technicianId', `Technician: ${this.getTechnicianName(value)}`, value);
    } else {
      this.removeValue('technicianId');
    }
  }

  getDateDisplay(dateFrom: Date | undefined, dateEnd: Date | undefined): string | undefined {
    let range = '';
    if (dateFrom && dateEnd) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')} to ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    } else if (dateFrom) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')}`;
    } else if (dateEnd) {
      range = `until ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    }

    return `Range: ${range}`;
  }

  getTechnicianName(value: Id): string {
    if (this.technicians) {
      const technician = this.technicians.find(m => m.id === value);
      if (technician) {
        return technician.text ?? '';
      }
    }

    return '';
  }

  getAppointmentStatusDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'With Appointment';
      case 2:
        return 'Without Appointment';
    }
  }

  filtersChanged(): void {
    const date = this.getValue('date');
    if (typeof date === 'number') {
      this._date = date;
    } else if (date == null) {
      this._date = 0;
    }

    this.cd.markForCheck();
  }
}
