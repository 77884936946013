import { Injectable, inject } from '@angular/core';
import { type CallDepartmentTypeInformation } from '@models/call-department-type-models';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class CallDepartmentTypesService {
  private readonly http = inject(HttpClientService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);

  list(id?: Id): Observable<CallDepartmentTypeInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.CallDepartmentTypes, this.url.callDepartmentTypesList, id);
  }

  save(callDepartmentType: CallDepartmentTypeInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(callDepartmentType, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.callDepartmentTypesSave, obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.callDepartmentTypesHide.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.callDepartmentTypesRestore.replace('$0', id.toString()), null);
  }
}
