import { Injectable, inject } from '@angular/core';
import {
  type ReplenishmentInformationForList,
  type InventoryReportData,
  type ReplenishModel,
  type ReplenishmentInformation,
} from '@models/cards/replenishment-information';
import { type SettingReplenishmentViewData } from '@models/cards/setting-view-data';
import { type Filter, type PagedResult } from '@models/filter-models';
import { PubSubService } from '@services/pub-sub.service';
import { allowRetries } from '@utility/angular';
import { type Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

export type PossibleFilter = { dateFrom?: Date; dateEnd?: Date };

@Injectable()
export class ReplenishmentsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly pubSubService = inject(PubSubService);

  list(filter: Filter<PossibleFilter>): Observable<PagedResult<ReplenishmentInformationForList>> {
    return this.http.post<PagedResult<ReplenishmentInformationForList>>(this.url.replenishmentList, filter, allowRetries());
  }

  get(id: Id): Observable<ReplenishmentInformation> {
    return this.http.get<ReplenishmentInformation>(this.url.replenishmentSingle.replace('$0', id.toString()));
  }

  getUnreplenishedList(): Observable<InventoryReportData[]> {
    return this.http.get<InventoryReportData[]>(this.url.replenishmentUnreplenishedList);
  }

  getUnreplenishedCount(): Observable<number> {
    return this.http.get<number>(this.url.replenishmentUnreplenishedCount);
  }

  replenish(replenishModel: ReplenishModel): Observable<boolean> {
    return this.http
      .post<boolean>(this.url.replenishmentReplenish, replenishModel)
      .pipe(
        tap(() => {
          this.pubSubService.publish('inventoryReplenished');
        })
      )
      .pipe(shareReplay(1));
  }

  getSettings(): Observable<SettingReplenishmentViewData> {
    return this.http.get<SettingReplenishmentViewData>(this.url.replenishmentGetSettings);
  }

  saveSettings(settings: SettingReplenishmentViewData): Observable<{ id: Id }> {
    this.http.trimStringProperties(settings);

    return this.http
      .post<{ id: Id }>(this.url.replenishmentSaveSettings, settings)
      .pipe(
        tap(() => {
          this.pubSubService.publish('inventoryAutoReplenishSaved');
        })
      )
      .pipe(shareReplay(1));
  }
}
