import { Injectable, inject } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import { type StripeWorkOrderChargeConfirmation } from '@models/charges';
import { PaymentGateway } from '@models/payments';
import { type SubscriptionDetail } from '@models/subscriptions';
import { ConfigService } from '@services/config.service';
import { type PaymentGatewayHandler } from './payment-gateway-handler';

export interface StripeSubscriptionDetail {
  active: boolean;
  subscription_id: string | null;
  subscription_schedule_id: string;
  customer_id: string;
}

@Injectable()
export class StripePaymentGatewayHandlerService implements PaymentGatewayHandler {
  paymentGateway = PaymentGateway.Stripe;

  private readonly sanitizer = inject(DomSanitizer);
  private readonly configService = inject(ConfigService);

  getErrorMessage(confirmation: string | null): string | null {
    return null;
  }

  hasCustomConfirmationMessage(): boolean {
    return true;
  }

  getConfirmationMessage(confirmation: string): SafeHtml {
    const stripeConfirmation = JSON.parse(confirmation) as StripeWorkOrderChargeConfirmation;
    const url = `${this.configService.config.stripe.dashboardUrl}/payments/${stripeConfirmation.id}`;
    const isSuccess = this.isSuccess(stripeConfirmation);
    const linkUrl = isSuccess ? 'link-blue.svg' : 'link-red.svg';

    return this.sanitizer.bypassSecurityTrustHtml(`<a href="${url}" target="_blank" class="inline text-with-icon ${
      isSuccess ? '' : 'confirmation-error'
    }">
<img src="/assets/images/${linkUrl}" alt="">
<span>${stripeConfirmation.id}</span>${isSuccess ? '' : '<img src="/assets/images/red-warning.svg" alt="">'}
</a>
`);
  }

  getSubscriptionInformation(subscriptionExtra: string): SubscriptionDetail | null {
    const stripeSubscriptionDetail = JSON.parse(subscriptionExtra) as StripeSubscriptionDetail;
    let url = null as string | null;
    const code = stripeSubscriptionDetail?.subscription_id ?? stripeSubscriptionDetail?.subscription_schedule_id;
    if (stripeSubscriptionDetail?.subscription_id) {
      url = `${this.configService.config.stripe.dashboardUrl}/subscriptions/${stripeSubscriptionDetail.subscription_id}`;
    } else if (stripeSubscriptionDetail?.subscription_schedule_id) {
      url = `${this.configService.config.stripe.dashboardUrl}/subscription_schedules/${stripeSubscriptionDetail.subscription_schedule_id}`;
    }

    if (url) {
      return {
        name: stripeSubscriptionDetail.subscription_id,
        link: this.sanitizer.bypassSecurityTrustHtml(`<a href="${url}" target="_blank">${code}</a>`),
        active: true,
      };
    }

    return null;
  }

  private isSuccess(confirmation: StripeWorkOrderChargeConfirmation): boolean {
    return confirmation.status === 'invoice.paid';
  }
}
