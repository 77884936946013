import {
  Overlay,
  type HorizontalConnectionPos,
  type OriginConnectionPosition,
  type OverlayConfig,
  type OverlayConnectionPosition,
  type VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal, type ComponentType } from '@angular/cdk/portal';
import { EventEmitter, Injectable, Injector, inject, type ElementRef, type Provider, type TemplateRef } from '@angular/core';
import { MatDialog, type MatDialogConfig, type MatDialogRef } from '@angular/material/dialog';
import {
  BackdropType,
  MENU_OVERLAY_DATA,
  type MenuOverlayData,
  POPOVER_OVERLAY_DATA,
  PopoverRef,
  type Dialog2ServiceImpl,
  type DialogConfig,
  type DialogConfirmConfig,
  type DialogConfirmData,
  type DialogFilterConfig,
  type DialogFilterDataInternal,
  type DialogFilterImpl,
  type DialogIntakeConfig,
  type DialogIntakeDataInternal,
  type DialogIntakeImpl,
  type PopoverButton,
  type PopoverConfig,
  type PopoverOverlayData,
  type TooltipPosition,
} from '@models/dialog';
import { type DialogFilter2Impl } from '@models/dialog-models';
import { type DynamicFilterData, type FilterData, type FilterResult } from '@models/filter-models';
import { asyncScheduler, type Subscription } from 'rxjs';
import { filter, observeOn, take } from 'rxjs/operators';
import { DialogBackdropComponent } from './dialog-backdrop.component';
import { DialogConfirmComponent, injectionToken } from './dialog-confirm.component';
import { DialogFilterComponent } from './dialog-filter.component';
import { DialogIntakeComponent } from './dialog-intake.component';
import { DialogMenuComponent } from './dialog-menu.component';
import { DialogPopoverComponent } from './dialog-popover.component';

@Injectable()
export class Dialog2Service implements Dialog2ServiceImpl {
  private readonly overlay = inject(Overlay);
  private readonly matDialog = inject(MatDialog);
  private readonly _defaultInjector = inject(Injector);

  public dialogOpened = new EventEmitter<boolean>();

  menu(
    buttons: PopoverButton[],
    elementRef: ElementRef<unknown> | HTMLElement,
    position: TooltipPosition,
    backdrop?: BackdropType,
    groups?: MenuOverlayData['groups']
  ): PopoverRef<DialogMenuComponent, unknown> {
    return this.internalPopover(DialogMenuComponent, elementRef, { position, backdrop }, injectionTokens => {
      injectionTokens.push({ provide: MENU_OVERLAY_DATA, useValue: { buttons, backdrop, groups } });
    });
  }

  popover<T, D = unknown, R = unknown>(
    componentRef: ComponentType<T>,
    elementRef: ElementRef<unknown> | HTMLElement,
    popoverConfig?: PopoverConfig<D>
  ): PopoverRef<T, R> {
    return this.internalPopover(componentRef, elementRef, popoverConfig);
  }

  confirm<R = unknown>(config: DialogConfirmConfig): MatDialogRef<DialogConfirmComponent, R> {
    const dialogConfirmConfig = config.confirm;
    const defaultConfig: { minWidth: string; maxWidth?: string } = { minWidth: '300px' };
    if (config && !config.width) {
      defaultConfig.maxWidth = '500px';
    }

    config.disableClose = config.confirm.disableExit;

    const dialogConfig: DialogConfig<DialogConfirmData> = {
      ...defaultConfig,
      ...config,
      dialogData: {
        injectionToken,
        data: dialogConfirmConfig,
      },
    };
    const dialogRef = this.openInternal<DialogConfirmComponent, unknown, R>(DialogConfirmComponent, dialogConfig, ['confirm-dialog']);

    return dialogRef;
  }

  intake<T extends DialogIntakeImpl, D = unknown, R = unknown>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: DialogIntakeConfig<D>
  ): MatDialogRef<DialogIntakeComponent<T>, R> {
    const defaultConfig: { minWidth: string; maxWidth?: string } = { minWidth: '300px' };
    if (config && !config.width) {
      defaultConfig.maxWidth = '500px';
    }

    const dialogIntakeConfig = Object.assign({}, config.intake, {
      component: componentOrTemplateRef,
    }) as DialogIntakeDataInternal<T>;
    const dialogConfig = Object.assign({}, defaultConfig, config, { data: dialogIntakeConfig });

    const dialogRef = this.openInternal(DialogIntakeComponent, dialogConfig, ['intake-dialog']) as MatDialogRef<
      DialogIntakeComponent<T>,
      R
    >;

    return dialogRef;
  }

  /**
   * @deprecated
   */
  filter<T extends DialogFilterImpl>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: DialogFilterConfig<FilterData>
  ): MatDialogRef<DialogFilterComponent<T, FilterData>, FilterResult<FilterData>> {
    const dialogIntakeConfig = Object.assign({}, config.filter, {
      component: componentOrTemplateRef,
    }) as DialogFilterDataInternal<T, FilterData>;
    const dialogConfig = Object.assign({}, config, { data: dialogIntakeConfig });

    const dialogRef = this.openInternal(DialogFilterComponent, dialogConfig, ['filter-dialog']) as MatDialogRef<
      DialogFilterComponent<T, FilterData>,
      FilterResult<FilterData>
    >;

    return dialogRef;
  }

  /**
   * @deprecated
   */
  filter2<FILTER_WINDOW_TYPE extends DialogFilter2Impl<FILTER_OBJECT>, FILTER_OBJECT>(
    componentOrTemplateRef: ComponentType<FILTER_WINDOW_TYPE> | TemplateRef<FILTER_WINDOW_TYPE>,
    config: DialogFilterConfig<DynamicFilterData<FILTER_OBJECT>>
  ): MatDialogRef<DialogFilterComponent<FILTER_WINDOW_TYPE, FILTER_OBJECT>, FilterResult<DynamicFilterData<FILTER_OBJECT>>> {
    const dialogIntakeConfig = Object.assign({}, config.filter, {
      component: componentOrTemplateRef,
    }) as DialogFilterDataInternal<FILTER_WINDOW_TYPE, DynamicFilterData<FILTER_OBJECT>>;
    const dialogConfig = Object.assign({}, config, { data: dialogIntakeConfig });

    const dialogRef = this.openInternal(DialogFilterComponent, dialogConfig, ['filter-dialog']) as MatDialogRef<
      DialogFilterComponent<FILTER_WINDOW_TYPE, FILTER_OBJECT>,
      FilterResult<DynamicFilterData<FILTER_OBJECT>>
    >;

    return dialogRef;
  }

  open<T, D = unknown, R = unknown>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig<D>
  ): MatDialogRef<T, R> {
    return this.openInternal(componentOrTemplateRef, config, ['confirm-dialog']);
  }

  private openInternal<T, D = unknown, R = unknown>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig<D>,
    panelClass?: string[]
  ): MatDialogRef<T, R> {
    return this.createPrettyBackdropIfNecessary(() => {
      const injectionTokens: Provider[] = [];

      if (config?.dialogData) {
        injectionTokens.push({ provide: config.dialogData.injectionToken, useValue: config.dialogData.data });
      }

      const portalInjector = Injector.create({
        providers: Array.from(injectionTokens),
        parent: config?.injector ?? this._defaultInjector,
      });

      const dialogConfig: MatDialogConfig<D> = {
        panelClass: ['base-dialog'].concat(panelClass ?? []),
        ...config,
        injector: portalInjector,
      };
      const dialogRef = this.matDialog.open(componentOrTemplateRef, dialogConfig);
      dialogRef.afterClosed().subscribe(() => {});

      return dialogRef;
    });
  }

  private createPrettyBackdropIfNecessary<A, B>(createDialog: () => MatDialogRef<A, B>): MatDialogRef<A, B> {
    // We attach our own overlay because we want to have a nice gradient.
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
    const dialogBackdropComponent = new ComponentPortal(DialogBackdropComponent);
    const backdropRef = overlayRef.attach(dialogBackdropComponent);

    const dialogRef = createDialog();
    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe(() => {
        backdropRef.instance.animationStateChanged
          .pipe(
            filter(event => event.phaseName === 'done' && event.toState === 'fadeOut'),
            take(1)
          )
          .subscribe(() => {
            overlayRef.dispose();
          });

        if (this.matDialog.openDialogs.length === 1) {
          this.dialogOpened.emit(false);
        }

        backdropRef.instance.startExitAnimation();
      });

    this.dialogOpened.emit(true);

    return dialogRef;
  }

  // From MAT Tooltip
  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  _getOrigin(position: TooltipPosition): {
    main: OriginConnectionPosition;
    fallback: OriginConnectionPosition;
  } {
    const isLtr = true;
    let originPosition: OriginConnectionPosition;

    if (position === 'above' || position === 'below') {
      originPosition = {
        originX: 'center',
        originY: position === 'above' ? 'top' : 'bottom',
      };
    } else if (position === 'before' || (position === 'left' && isLtr) || (position === 'right' && !isLtr)) {
      originPosition = { originX: 'start', originY: 'center' };
    } else if (position === 'after' || (position === 'right' && isLtr) || (position === 'left' && !isLtr)) {
      originPosition = { originX: 'end', originY: 'center' };
    } else {
      throw new Error('Incorrect Position');
    }

    const { x, y } = this._invertPosition(position, originPosition.originX, originPosition.originY);

    return {
      main: originPosition,
      fallback: { originX: x, originY: y },
    };
  }

  /** Returns the overlay position and a fallback position based on the user's preference */
  _getOverlayPosition(position: TooltipPosition): {
    main: OverlayConnectionPosition;
    fallback: OverlayConnectionPosition;
  } {
    const isLtr = true;
    let overlayPosition: OverlayConnectionPosition;

    if (position === 'above') {
      overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
    } else if (position === 'below') {
      overlayPosition = { overlayX: 'center', overlayY: 'top' };
    } else if (position === 'before' || (position === 'left' && isLtr) || (position === 'right' && !isLtr)) {
      overlayPosition = { overlayX: 'end', overlayY: 'center' };
    } else if (position === 'after' || (position === 'right' && isLtr) || (position === 'left' && !isLtr)) {
      overlayPosition = { overlayX: 'start', overlayY: 'center' };
    } else {
      throw new Error('Incorrect Position');
    }

    const { x, y } = this._invertPosition(position, overlayPosition.overlayX, overlayPosition.overlayY);

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y },
    };
  }

  private internalPopover<T, D = unknown, R = unknown>(
    componentRef: ComponentType<T>,
    elementRef: ElementRef<unknown> | HTMLElement,
    popoverConfig?: PopoverConfig<D>,
    addToInjectionToken?: (providers: Provider[]) => void
  ): PopoverRef<T, R> {
    const position: TooltipPosition = popoverConfig?.position || 'below';

    const origin = this._getOrigin(position);
    const overlay = this._getOverlayPosition(position);
    const backdrop = popoverConfig
      ? popoverConfig.backdrop === undefined
        ? BackdropType.Gray
        : popoverConfig.backdrop
      : BackdropType.Gray;

    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withPositions([
        { ...origin.main, ...overlay.main },
        { ...origin.fallback, ...overlay.fallback },
      ]);
    const dialogConfig: OverlayConfig = {
      hasBackdrop: backdrop !== BackdropType.None,
      backdropClass: backdrop === BackdropType.Transparent ? 'cdk-overlay-transparent-backdrop' : undefined,
      positionStrategy: strategy,
    };

    const overlayRef = this.overlay.create(dialogConfig);

    const injectionTokens: Provider[] = [];

    const popoverRef = new PopoverRef<T, R>(overlayRef);

    // Set custom injection tokens
    injectionTokens.push({ provide: PopoverRef, useValue: popoverRef });
    const popoverData = popoverConfig?.popoverData;
    if (popoverData) {
      injectionTokens.push({ provide: popoverData.injectionToken, useValue: popoverData.data });
    }

    injectionTokens.push({
      provide: POPOVER_OVERLAY_DATA,
      useValue: {
        component: componentRef,
        overlayRef,
        requestClose: popoverConfig?.requestClose,
        origin: elementRef,
      } satisfies PopoverOverlayData,
    });
    if (addToInjectionToken) {
      addToInjectionToken(injectionTokens);
    }

    const portalInjector = Injector.create({
      providers: Array.from(injectionTokens),
      parent: popoverConfig?.injector ?? this._defaultInjector,
    });
    const componentPortal = new ComponentPortal(DialogPopoverComponent, null, portalInjector);
    const componentPortalRef = overlayRef.attach(componentPortal);

    // This is used to place the arrow at the right spot before we reposition.
    let subscription: Subscription | null = strategy.positionChanges.pipe(observeOn(asyncScheduler)).subscribe(() => {
      componentPortalRef.instance.isReady = true;
      componentPortalRef.changeDetectorRef.detectChanges();
    });

    componentPortalRef.onDestroy(() => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
    });

    overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        overlayRef.dispose();
      });

    overlayRef
      .keydownEvents()
      .pipe(filter(m => m.keyCode === 27))
      .pipe(take(1))
      .subscribe(() => {
        overlayRef.dispose();
      });

    return popoverRef;
  }

  /** Inverts an overlay position. */
  private _invertPosition(
    position: TooltipPosition,
    x: HorizontalConnectionPos,
    y: VerticalConnectionPos
  ): { x: HorizontalConnectionPos; y: VerticalConnectionPos } {
    if (position === 'above' || position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }
}
