import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  template: '@if (date()) { <ng-container>{{date() | date:format() }}</ng-container> }',
  imports: [DatePipe],
  standalone: true,
})
export class TableDynamicNodeDateComponent {
  readonly date = input<Date | undefined>();
  readonly format = input<string>('');
}
