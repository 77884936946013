// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
ul,
li {
  margin: 0;
  padding: 0;
}

.content {
  background-color: #f1f2f3;
  border-radius: 4px;
  padding: 8px;
  min-width: 100px;
}
.content.with-transparent-background {
  border: 1px solid #d1d5d8;
}

ul li {
  margin-bottom: 8px;
}
ul li:last-child {
  margin-bottom: 0;
}

button {
  line-height: 24px;
  background-color: #ffffff;
  color: #464b4f;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
  width: 100%;
  text-align: left;
}
button .text {
  flex-grow: 1;
  text-align: left;
}
button .selected-icon {
  display: none;
}
button .selected-icon img {
  width: 12px;
  height: 12px;
}
button.selected {
  background-color: #246ee9;
  color: #ffffff;
}
button.selected .selected-icon {
  display: flex;
  margin-left: 16px;
}

.title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-size: 12px;
  color: #8c959d;
}

.section ~ .section {
  margin-top: 12px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
