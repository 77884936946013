import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, type OnInit } from '@angular/core';
import { FILTER_DIALOG_DATA } from '@models/dialog';
import { FilterData } from '@models/filter-models';
import { type Resource } from '@models/resource';
import { endOfDay } from 'date-fns';
import { FilterComponent } from './filter.component';
import { SharedModule } from '@modules/shared.module';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';

/** @deprecated */
@Component({
  templateUrl: 'filter-customer.component.html',
  styleUrls: ['filter-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, CheckboxComponent, InputMomentPickerComponent],
})
export class FilterCustomerComponent extends FilterComponent implements OnInit {
  _datePipe: DatePipe;
  _businessDate = 0;
  _createdDate = 0;
  agreements: Resource[] = [];

  constructor(@Inject(FILTER_DIALOG_DATA) data: FilterData) {
    super(data);
    this._datePipe = new DatePipe('en-US');
  }

  ngOnInit(): void {
    this.agreements = [
      { id: -1, text: this.getAgreementDisplay(0) },
      { id: 1, text: this.getAgreementDisplay(1) },
      { id: 2, text: this.getAgreementDisplay(2) },
      { id: 3, text: this.getAgreementDisplay(3) },
    ];
  }

  get businessDate(): number {
    return this._businessDate;
  }

  set businessDate(value: number) {
    this._businessDate = value;
    if (value === 1 && (this.businessDateFrom || this.businessDateEnd)) {
      this.setValue('businessDate', this.getDateDisplay('Business Between Range', this.businessDateFrom, this.businessDateEnd), 1);
    } else {
      this.removeValue('businessDate');
    }
  }

  get businessDateFrom(): Date | undefined {
    return this.getValue('businessDateFrom');
  }

  set businessDateFrom(value: Date | undefined) {
    if (value) {
      this.setValue('businessDateFrom', undefined, value);
    } else {
      this.removeValue('businessDateFrom');
    }

    this.businessDate = 1;
  }

  get businessDateEnd(): Date | undefined {
    return this.getValue('businessDateEnd');
  }

  set businessDateEnd(value: Date | undefined) {
    if (value) {
      this.setValue('businessDateEnd', undefined, endOfDay(value));
    } else {
      this.removeValue('businessDateEnd');
    }

    this.businessDate = 1;
  }

  get createdDate(): number {
    return this._createdDate;
  }

  set createdDate(value: number) {
    this._createdDate = value;
    if (value === 1 && (this.createdDateFrom || this.createdDateEnd)) {
      this.setValue('createdDate', this.getDateDisplay('Created Between Range', this.createdDateFrom, this.createdDateEnd), 1);
    } else {
      this.removeValue('createdDate');
    }
  }

  get createdDateFrom(): Date | undefined {
    return this.getValue('createdDateFrom');
  }

  set createdDateFrom(value: Date | undefined) {
    if (value) {
      this.setValue('createdDateFrom', undefined, value);
    } else {
      this.removeValue('createdDateFrom');
    }

    this.createdDate = 1;
  }

  get createdDateEnd(): Date | undefined {
    return this.getValue('createdDateEnd');
  }

  set createdDateEnd(value: Date | undefined) {
    if (value) {
      this.setValue('createdDateEnd', undefined, endOfDay(value));
    } else {
      this.removeValue('createdDateEnd');
    }

    this.createdDate = 1;
  }

  get deleted(): boolean {
    return !!this.getValue('deleted');
  }

  set deleted(value: boolean) {
    if (value) {
      this.setValue('deleted', 'Show Deleted', value);
    } else {
      this.removeValue('deleted');
    }
  }

  get agreement(): number {
    return this.getValue('agreement') || -1;
  }

  set agreement(value: number) {
    if (value > 0) {
      this.setValue('agreement', this.getAgreementDisplay(value), value);
    } else {
      this.removeValue('agreement');
    }
  }

  getAgreementDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Current';
      case 2:
        return 'Expiring';
      case 3:
        return 'Expired';
      case 4:
        return 'No Agreement';
    }
  }

  getDateDisplay(words: string, dateFrom: Date | undefined, dateEnd: Date | undefined): string | undefined {
    let range = '';
    if (dateFrom && dateEnd) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')} to ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    } else if (dateFrom) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')}`;
    } else if (dateEnd) {
      range = `until ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    }

    return `${words}: ${range}`;
  }

  filtersChanged(): void {
    let date = this.getValue('businessDate');
    if (typeof date === 'number') {
      this._businessDate = date;
    } else if (date == null) {
      this._businessDate = 0;
    }

    date = this.getValue('createdDate');
    if (typeof date === 'number') {
      this._createdDate = date;
    } else if (date == null) {
      this._createdDate = 0;
    }
  }
}
