import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'status',
  standalone: true,
})
export class StatusPipe implements PipeTransform {
  transform(status: boolean): string {
    if (status) {
      return 'Yes'; // LANG
    }

    return 'No'; // LANG
  }
}
