import { Directive, EventEmitter, Output } from '@angular/core';
import { type RowSelection } from '@models/row-selection';
import { type Sort } from '@models/sort';

@Directive({
  selector: '[wm-table]',
  exportAs: 'wmTable',
  standalone: true,
})
export class TableDirective {
  _rowSelection: any[] = [];

  @Output()
  sort = new EventEmitter<Sort>();

  @Output()
  rowSelect = new EventEmitter<RowSelection>();
}
