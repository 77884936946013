import { DatePipe } from '@angular/common';
import { inject, type Type } from '@angular/core';
import { FilterDynamicCheckboxComponent } from '@controls/filter-dynamic/filter-dynamic-checkbox.component';
import { FilterDynamicDropdownComponent } from '@controls/filter-dynamic/filter-dynamic-dropdown.component';
import { FilterDynamicEasyDateComponent } from '@controls/filter-dynamic/filter-dynamic-easydate.component';
import { TableDynamicNodeAgreementExpirationComponent } from '@controls/table-dynamic/table-dynamic-node-agreement-expiration.component';
import { TableDynamicNodeAgreementNextTuneUpComponent } from '@controls/table-dynamic/table-dynamic-node-agreement-next-tune-up.component';
import { TableDynamicNodeAgreementRemainingTuneUpComponent } from '@controls/table-dynamic/table-dynamic-node-agreement-remaining-tune-up.component';
import { TableDynamicNodeAgreementVisitsComponent } from '@controls/table-dynamic/table-dynamic-node-agreement-visits.component';
import { TableDynamicNodeDeletedComponent } from '@controls/table-dynamic/table-dynamic-node-deleted.component';
import { TableDynamicNodeLinkComponent } from '@controls/table-dynamic/table-dynamic-node-link.component';
import { TableDynamicNodeNotesComponent } from '@controls/table-dynamic/table-dynamic-node-notes.component';
import { TableDynamicNodeTextComponent } from '@controls/table-dynamic/table-dynamic-node-text.component';
import { AddressPipe } from '@pipes/address.pipe';
import { EntityPipe } from '@pipes/entity.pipe';
import { AgreementsService } from '@services/live/agreements.service';
import { firstValueFrom } from 'rxjs';
import { type AgreementInformation } from './cards/agreement-information';
import { type AgreementSiteSystemInformationForList } from './cards/agreement-site-system-information';
import {
  combineData,
  getReversedFilterConfig,
  LogicalOperator,
  type TableColumnDefinition,
  type FancyFilterConfig,
  type FancyFilterDateRange,
  type FancyFilterImpl,
  type LogicalOperatorData,
} from './filter-models';
import { type ExtraResource1, type Resource } from './resource';

export enum FilterAgreementStatusData {
  Grandfathered = 1,
  Unprocessed = 2,
  Ready = 3,
  Activated = 4,
  Current = 5,
}

interface FilterAgreementData {
  date?: FancyFilterDateRange;
  status?: FilterAgreementStatusData | null;
  recurringOnly?: boolean;
  inactiveSubscriptions?: boolean;
  processed?: boolean;
  expiring?: boolean;
}

export class FilterAgreement implements FancyFilterImpl<FilterAgreementData, any> {
  private readonly datePipe = inject(DatePipe);

  readonly code = 'Agreement';

  readonly options: Resource[] = [
    { id: FilterAgreementStatusData.Grandfathered, text: 'Grandfathered' },
    { id: FilterAgreementStatusData.Unprocessed, text: 'Unprocessed Work Order Agreements' },
    { id: FilterAgreementStatusData.Ready, text: 'Ready for Set Up' },
    { id: FilterAgreementStatusData.Activated, text: 'Activated' },
    { id: FilterAgreementStatusData.Current, text: 'Current Active Agreements' },
  ];

  getUIConfig(): FancyFilterConfig<FilterAgreementData, any>[] {
    const config1: FancyFilterConfig<FilterAgreementData, FilterAgreementStatusData> = {
      get: controlValue => {
        return controlValue?.status ?? null;
      },
      set: (value, context) => {
        return combineData('status', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Status',
          code: this.code,
          clearable: true,
          name: `${this.code}-status`,
          options: this.options,
        },
      },
    };

    const config2: FancyFilterConfig<FilterAgreementData, FancyFilterDateRange> = {
      get: controlValue => {
        return controlValue?.date ?? null;
      },
      set: (value, context) => {
        return combineData('date', value?.from == null && value?.to == null ? null : value, context);
      },
      ui: {
        type: FilterDynamicEasyDateComponent,
        closeAfterSelection: false,
        inputs: {
          label: 'Created Date',
          code: this.code,
          name: `${this.code}-created`,
          fromLabel: 'After',
          toLabel: 'Before',
        },
      },
    };
    const config3: FancyFilterConfig<FilterAgreementData, [boolean?]> = {
      get: controlValue => {
        return controlValue?.recurringOnly ? [true] : [];
      },
      set: (value, context) => {
        return combineData('recurringOnly', value?.[0] || null, context);
      },
      ui: {
        type: FilterDynamicCheckboxComponent,
        closeAfterSelection: false,
        inputs: {
          code: this.code,
          name: `${this.code}-options-3`,
          options: [
            {
              id: true,
              text: 'Recurring Only',
            },
          ],
        },
      },
    };
    const config4: FancyFilterConfig<FilterAgreementData, [boolean?]> = {
      get: controlValue => {
        return controlValue?.inactiveSubscriptions ? [true] : [];
      },
      set: (value, context) => {
        return combineData('inactiveSubscriptions', value?.[0] || null, context);
      },
      ui: {
        type: FilterDynamicCheckboxComponent,
        closeAfterSelection: false,
        inputs: {
          code: this.code,
          name: `${this.code}-options-4`,
          options: [
            {
              id: true,
              text: 'Inactive Subscriptions',
            },
          ],
        },
      },
    };
    const config5: FancyFilterConfig<FilterAgreementData, boolean> = {
      get: controlValue => {
        return controlValue?.processed ?? null;
      },
      set: (value, context) => {
        return combineData('processed', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: true,
        inputs: {
          code: this.code,
          name: `${this.code}-options-5`,
          clearable: true,
          options: [
            { id: false, text: 'Non-Agreement Customers' },
            { id: true, text: 'Agreement Customers' },
          ],
        },
      },
    };
    const config6: FancyFilterConfig<FilterAgreementData, [boolean?]> = {
      get: controlValue => {
        return controlValue?.expiring ? [true] : [];
      },
      set: (value, context) => {
        return combineData('expiring', value?.[0] || null, context);
      },
      ui: {
        type: FilterDynamicCheckboxComponent,
        closeAfterSelection: false,
        inputs: {
          code: this.code,
          name: `${this.code}-options-6`,
          options: [
            {
              id: true,
              text: 'Expiring or Expired',
            },
          ],
        },
      },
    };

    return [config1, config2, config3, config4, config5, config6];
  }

  getUISummary(value: FilterAgreementData | null): string {
    let createdMoment: string | null = null;
    if (value?.date) {
      if (value?.date.from && value?.date.to) {
        createdMoment = `created between ${this.datePipe.transform(value?.date.from)} and ${this.datePipe.transform(value?.date.to)}`;
      } else if (value?.date.from) {
        createdMoment = `created after ${this.datePipe.transform(value?.date.from)}`;
      } else if (value?.date.to) {
        createdMoment = `created before ${this.datePipe.transform(value?.date.to)}`;
      }
    }

    const isRecurringOnly = value?.recurringOnly ? 'recurring only' : '';
    const isInactiveSubscription = value?.inactiveSubscriptions ? 'with inactive subscription' : '';
    const processed = value?.processed != null ? (value.processed ? 'processed' : 'unprocessed') : '';
    const expiring = value?.expiring ? 'expiring or expired' : '';

    let status = '';
    if (value?.status) {
      status = `Agreements are ${this.options.find(m => m.id === value.status)?.text ?? ''}`;
    }

    if (!status) {
      status = 'Any agreements';
    }

    return [status, isRecurringOnly, isInactiveSubscription, processed, expiring, createdMoment].filter(m => !!m).join(', ');
  }

  getSimpleName(): ExtraResource1<string> {
    return {
      id: this.code,
      text: 'Agreement',
    };
  }

  getIcon() {
    return { matIcon: 'edit_document' };
  }
}

interface FilterAgreementTypeData extends LogicalOperatorData {
  value: Id;
}

export class FilterAgreementType implements FancyFilterImpl<FilterAgreementTypeData> {
  readonly agreementsService = inject(AgreementsService);

  readonly code = 'Agreements';

  private options: AgreementInformation[] = [];

  async load(): Promise<void> {
    const agreements = await firstValueFrom(this.agreementsService.list());
    this.options = agreements;
  }

  getUIConfig(): FancyFilterConfig<FilterAgreementTypeData, any>[] {
    const config1: FancyFilterConfig<FilterAgreementTypeData, Id> = {
      get: controlValue => {
        return controlValue?.value ?? null;
      },
      set: (value, context) => {
        return combineData('value', value, context);
      },
      ui: {
        type: FilterDynamicDropdownComponent,
        closeAfterSelection: true,
        inputs: {
          code: this.code,
          name: `${this.code}`,
          clearable: true,
          options: this.options,
        },
      },
    };
    return [config1, getReversedFilterConfig(this.code)];
  }

  getUISummary(value: FilterAgreementTypeData | null): string {
    const notWord = value?.logicalOperator === LogicalOperator.Not ? 'NOT ' : '';

    if (value?.value) {
      return `Agreement Type is ${notWord}${this.options.find(m => m.id === value.value)?.text ?? ''}`;
    }

    return 'Agreement Type is any';
  }

  getSimpleName(): ExtraResource1<string> {
    return {
      id: this.code,
      text: 'Agreement Type',
    };
  }

  getIcon() {
    return { matIcon: 'local_police' };
  }
}

export class ColumnAgreementSiteSystemNextTuneUp implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'next-tune-up';

  readonly text = 'Next Tune-Up';

  readonly required = true;

  readonly order = Number.NEGATIVE_INFINITY;

  readonly classes = ['col-date'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeAgreementNextTuneUpComponent,
      inputs: {
        line: item,
      },
    };
  }
}

export class ColumnAgreementSiteSystemNameType implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'name-type';

  readonly text = 'Name/Type';

  readonly classes = ['col-type'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.siteSystemType,
      },
    };
  }
}

export class ColumnAgreementSiteSystemNotes implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'notes';

  readonly text = 'Notes';

  readonly classes = ['col-notes'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeNotesComponent,
      inputs: {
        text: item.data.notes,
      },
    };
  }
}

export class ColumnAgreementSiteSystemStatus implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'processed';

  readonly text = 'Status';

  readonly classes = ['col-processed'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeNotesComponent,
      inputs: {
        text: item.data.processed ? '' : 'Unprocessed',
        icon: '/assets/images/status-deleted.svg',
      },
    };
  }
}

export class ColumnAgreementSiteSystemLocation implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'location';

  readonly text = 'Location';

  readonly classes = ['col-location'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.locations?.join(', '),
      },
    };
  }
}

export class ColumnAgreementSiteSystemAgreement implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly agreementsService = inject(AgreementsService);

  readonly id = 'agreement';

  readonly text = 'Agreement';

  readonly classes = ['col-agreement'];

  private agreements: AgreementInformation[] = [];

  async load(): Promise<void> {
    this.agreements = await firstValueFrom(this.agreementsService.list());
  }

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeTextComponent,
      inputs: {
        text: item.data.agreementId ? this.agreements.find(m => m.id === item.data.agreementId)?.text : '',
      },
    };
  }
}

export class ColumnAgreementSiteSystemRemainingTuneUps implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'remaining';

  readonly text = 'Remaining Tune-Ups';

  readonly classes = ['col-remaining'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeAgreementRemainingTuneUpComponent,
      inputs: {
        line: item,
      },
    };
  }
}

export class ColumnAgreementSiteSystemExpiration implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'expiration';

  readonly text = 'Expiration';

  readonly classes = ['col-date'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeAgreementExpirationComponent,
      inputs: {
        line: item,
      },
    };
  }
}

export class ColumnAgreementSiteSystemVisits implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'visits';

  readonly text = 'Visits';

  readonly classes = ['col-visits', 'col-100'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeAgreementVisitsComponent,
      inputs: {
        line: item,
      },
    };
  }
}

export class ColumnAgreementSiteSystemDeleted implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  readonly id = 'deleted';

  readonly text = 'Status';

  readonly classes = ['col-status'];

  readonly required = true;

  readonly order = Number.POSITIVE_INFINITY;

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeDeletedComponent,
      inputs: {
        show: item.data.hidden,
      },
    };
  }
}

export class ColumnAgreementSiteSystemCustomer implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  private readonly entity = inject(EntityPipe);

  readonly id = 'customer';

  readonly text = 'Customer';

  readonly classes = ['col-customer', 'col-200'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: this.entity.transform(item.customerEntity),
        routerLink: ['/customers', item.customerId],
      },
    };
  }
}

export class ColumnAgreementSiteSystemSite implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  private readonly address = inject(AddressPipe);

  readonly id = 'site';

  readonly text = 'Site';

  readonly classes = ['col-site'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: item.siteAddress ? this.address.transform(item.siteAddress) : 'No Address',
        routerLink: item.siteAddress ? ['/customers', item.customerId, 'sites', item.siteId] : [],
      },
    };
  }
}

export class ColumnAgreementSiteSystemSiteSystem implements TableColumnDefinition<AgreementSiteSystemInformationForList> {
  private readonly address = inject(AddressPipe);

  readonly id = 'equipment';

  readonly text = 'Equipment';

  readonly classes = ['col-equipment'];

  renderComponent(item: AgreementSiteSystemInformationForList) {
    return {
      component: TableDynamicNodeLinkComponent,
      inputs: {
        text: `${item.siteSystemType} #${item.siteSystemLocalId}`,
        routerLink: item.siteAddress ? ['/customers', item.customerId, 'sites', item.siteId, 'equipments', item.data.siteSystemId] : [],
      },
    };
  }
}

export const supportedAgreementSiteSystemFilterTypes: readonly Type<FancyFilterImpl<any>>[] = [
  FilterAgreement,
  FilterAgreementType,
] as const;

export const allSupportedAgreementSiteSystemColumns: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnAgreementSiteSystemNextTuneUp,
  ColumnAgreementSiteSystemNameType,
  ColumnAgreementSiteSystemNotes,
  ColumnAgreementSiteSystemStatus,
  ColumnAgreementSiteSystemLocation,
  ColumnAgreementSiteSystemCustomer,
  ColumnAgreementSiteSystemSite,
  ColumnAgreementSiteSystemSiteSystem,
  ColumnAgreementSiteSystemAgreement,
  ColumnAgreementSiteSystemRemainingTuneUps,
  ColumnAgreementSiteSystemExpiration,
  ColumnAgreementSiteSystemVisits,
] as const;

export const supportedAgreementSiteSystemColumnsForCustomer: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnAgreementSiteSystemNextTuneUp,
  ColumnAgreementSiteSystemNameType,
  ColumnAgreementSiteSystemNotes,
  ColumnAgreementSiteSystemStatus,
  ColumnAgreementSiteSystemLocation,
  ColumnAgreementSiteSystemSite,
  ColumnAgreementSiteSystemAgreement,
  ColumnAgreementSiteSystemRemainingTuneUps,
  ColumnAgreementSiteSystemExpiration,
  ColumnAgreementSiteSystemVisits,
];

export const supportedAgreementSiteSystemColumnsForSite: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnAgreementSiteSystemNextTuneUp,
  ColumnAgreementSiteSystemNameType,
  ColumnAgreementSiteSystemNotes,
  ColumnAgreementSiteSystemStatus,
  ColumnAgreementSiteSystemLocation,
  ColumnAgreementSiteSystemSiteSystem,
  ColumnAgreementSiteSystemAgreement,
  ColumnAgreementSiteSystemRemainingTuneUps,
  ColumnAgreementSiteSystemExpiration,
  ColumnAgreementSiteSystemVisits,
];

export const supportedAgreementSiteSystemColumnsForSiteSystem: readonly Type<TableColumnDefinition<any>>[] = [
  ColumnAgreementSiteSystemNextTuneUp,
  ColumnAgreementSiteSystemNameType,
  ColumnAgreementSiteSystemNotes,
  ColumnAgreementSiteSystemStatus,
  ColumnAgreementSiteSystemLocation,
  ColumnAgreementSiteSystemRemainingTuneUps,
  ColumnAgreementSiteSystemExpiration,
  ColumnAgreementSiteSystemVisits,
];

export const providers = [...supportedAgreementSiteSystemFilterTypes, ...allSupportedAgreementSiteSystemColumns];
