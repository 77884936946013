// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flex {
  display: flex;
}
.flex > * {
  flex-basis: 50%;
}
.flex > :first-child {
  margin-right: 16px;
}

.wm-description:not([hidden]) ~ .wm-description {
  margin-top: 12px;
}

.read {
  margin-left: 8px;
  line-height: 24px;
  min-height: 24px;
}

label {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #232628;
  margin-bottom: 4px;
  display: block;
}

wm-input-image,
wm-input-moment-picker,
wm-input-dropdown {
  width: 100%;
}

.range {
  margin-top: 4px;
}
.range > * {
  display: flex;
  justify-content: flex-end;
}
.range > div:first-child {
  margin-bottom: 8px;
}
.range label {
  margin-right: 4px;
  flex-basis: 30px;
  margin-bottom: 0;
  line-height: 32px;
  white-space: nowrap;
}
.range wm-input-moment-picker,
.range wm-input-text {
  flex-grow: 1;
}

.priority .read {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.priority wm-checkbox {
  margin-right: 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
