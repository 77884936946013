import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  computed,
  forwardRef,
  inject,
  signal,
  type AfterViewInit,
  type OnDestroy,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, NgModel, type ControlValueAccessor } from '@angular/forms';
import { Observable, Subject, skip, takeUntil } from 'rxjs';
import { ErrorHelperService } from '../../services/error-helper.service';

const noop = (): void => {};

@Component({
  selector: 'wm-input-text',
  templateUrl: 'input-text.component.html',
  styleUrls: ['input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class InputTextComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly errorHelperService = inject(ErrorHelperService, { optional: true });

  private readonly internalHelper = signal<string | undefined>(undefined);

  @Input()
  public get helper(): string | undefined {
    return this.internalHelper();
  }

  public set helper(value: string | undefined) {
    this.internalHelper.set(value);
  }

  private readonly error = signal<string | null>(null);
  readonly helperUI = computed(() => {
    const error = this.error();
    if (error) {
      return error;
    }

    const internalHelper = this.internalHelper();
    if (internalHelper) {
      return internalHelper;
    }

    return null;
  });

  @Input() autoComplete?: string;
  @Input() autoCorrect?: string;
  @Input() autoCapitalize?: string;
  @Input() autoFocus = false;
  @Input() disabled = false;
  @Input() id = '';
  @Input() list?: string;
  @Input() max?: string | number;
  @Input() maxLength?: number;
  @Input() min?: string | number;
  @Input() minLength?: number;
  @Input() placeholder = '';
  @Input() readOnly = false;
  @Input() required = false;
  @Input() spellCheck = false;
  @Input() step?: number;
  @Input() tabIndex?: number;
  @Input() type = 'text';
  @Input() name?: string;
  @Input() useCorrectTyping = false;

  private readonly destroy$ = new Subject<void>();

  private readonly _blurEmitter = new EventEmitter<FocusEvent>();
  private readonly _focusEmitter = new EventEmitter<FocusEvent>();

  @Output('blur')
  get onBlur(): Observable<FocusEvent> {
    return this._blurEmitter.asObservable();
  }

  @Output('focus')
  get onFocus(): Observable<FocusEvent> {
    return this._focusEmitter.asObservable();
  }

  // The internal data model
  private innerValue: string | number | null = null;

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    v ??= null;
    if (v !== this.innerValue) {
      this.innerValue = this.transformValue(v);
      this.changeDetectorRef.markForCheck();
      this.onChangeCallback(this.innerValue);
    }
  }

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @ViewChild('input', { static: true }) _inputElement!: ElementRef<HTMLElement>;
  @ViewChild('input', { read: NgModel, static: true }) _inputModel!: NgModel;

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    value ??= null;
    if (value !== this.innerValue) {
      this.innerValue = this.transformValue(value);
      this.changeDetectorRef.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  /** Set focus on input */
  focus(): void {
    this._inputElement.nativeElement.focus();
  }

  _handleFocus(event: FocusEvent): void {
    this._focusEmitter.emit(event);
  }

  _handleBlur(event: FocusEvent): void {
    this.onTouchedCallback();
    this._blurEmitter.emit(event);
  }

  _handleChange(event: Event): void {
    this.value = (event.target as HTMLInputElement).value;
    this.onTouchedCallback();
  }

  ngAfterViewInit(): void {
    // By default, we get 1 call here, so we skip the first one.
    this._inputModel.valueChanges?.pipe(skip(1), takeUntil(this.destroy$)).subscribe(() => {
      const errorMessage = this.errorHelperService?.getError(this._inputModel.errors) ?? null;
      this.error.set(errorMessage);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private transformValue(v: any | undefined): any {
    if (this.type === 'number') {
      if (v === '') {
        return this.useCorrectTyping ? null : undefined;
      } else if (!isNaN(v)) {
        return parseFloat(v);
      }
    }

    return v;
  }
}
