import { Component, input } from '@angular/core';
import { QuickBooksSynchronizationStatus } from '@models/quick-books';
import { QuickBooksPipe } from '@pipes/quick-books.pipe';

@Component({
  template: '<div><span [innerHTML]="quickBooks() | quickBooks"></span></div>',
  styles: ['div{text-align: center;}'],
  imports: [QuickBooksPipe],
  standalone: true,
})
export class TableDynamicNodeQuickBooksComponent {
  readonly quickBooks = input<QuickBooksSynchronizationStatus>(QuickBooksSynchronizationStatus.None);
}
