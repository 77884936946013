import { Component, effect, inject, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { type DialogIntakeComponent } from '@dialogs/dialog-intake.component';
import { INTAKE_DIALOG_DATA_TYPED, buttons, type Dialog2ServiceImpl, type DialogIntakeImpl } from '@models/dialog';
import { type TableColumnDefinition } from '@models/filter-models';
import { SharedModule } from '@modules/shared.module';
import { notEmpty } from '@utility/array';
import { getObjectKeys } from '@utility/object';

export interface ColumnChooserData {
  availableColumns: TableColumnDefinition<unknown>[];
  selectedColumns: string[];
}

export const injectionToken = INTAKE_DIALOG_DATA_TYPED<ColumnChooserData>();

@Component({
  selector: 'wm-column-chooser',
  templateUrl: 'column-chooser.component.html',
  // styleUrls: ['column-chooser.component.scss'],
  standalone: true,
  imports: [SharedModule, CheckboxComponent],
})
export class ColumnChooserComponent implements DialogIntakeImpl {
  readonly data = inject(injectionToken);
  private readonly dialogRef = inject(MatDialogRef<DialogIntakeComponent<ColumnChooserComponent>>);

  readonly result = signal<Record<string, boolean>>({});
  readonly disabled = signal<Record<string, boolean>>({});

  _ = effect(
    () => {
      const availableColumns = this.data.availableColumns;
      const selectedColumns = this.data.selectedColumns.length
        ? this.data.selectedColumns
        : availableColumns.map(availableColumn => availableColumn.id);

      const result = availableColumns.reduce(
        (o, availableColumn) => ({ ...o, ...{ [availableColumn.id]: selectedColumns.includes(availableColumn.id) } }),
        {}
      );
      this.result.set(result);

      const disabled = availableColumns.reduce(
        (o, availableColumn) => ({ ...o, ...{ [availableColumn.id]: availableColumn.required } }),
        {}
      );
      this.disabled.set(disabled);
    },
    { allowSignalWrites: true }
  );

  changeResult(key: string, value: boolean): void {
    const newResult = { ...this.result(), ...{ [key]: value } };
    this.result.set(newResult);
  }

  save(): void {
    const result = this.result();
    const finalResult = getObjectKeys(result)
      .map(key => (result[key] ? key : null))
      .filter(notEmpty);
    this.dialogRef.close(finalResult);
  }

  static open(dialog: Dialog2ServiceImpl, data: ColumnChooserData) {
    return dialog
      .intake<ColumnChooserComponent, ColumnChooserData, string[]>(ColumnChooserComponent, {
        intake: {
          title: 'Choose Columns',
          text: 'Choose the columns you want to display on this view.',
          buttons: buttons.confirmButtons({
            withCancel: true,
            text: 'Save',
          }),
          intakeData: {
            injectionToken,
            data,
          },
        },
      })
      .afterClosed();
  }
}
