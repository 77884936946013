import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHelperService {
  getError(errors: Record<string, any> | null): string | null {
    if (errors) {
      if (errors.required) {
        return 'This field is required.';
      }
    }

    return null;
  }
}
