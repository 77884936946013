import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'deleted',
  standalone: true,
})
export class DeletedPipe implements PipeTransform {
  transform(status: boolean | undefined, name: string = 'Deleted'): string {
    if (status) {
      const img = '<img src="/assets/images/status-deleted.svg" alt="" />';
      return `<span class="status-dot">${img}${name}</span>`;
    }

    return '';
  }
}
