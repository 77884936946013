import { Injectable, inject } from '@angular/core';
import { type SiteSystemInformation } from '@models/cards/site-system-information';
import { type Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SystemTypesService } from './live/system-types.service';

export enum DeletedType {
  Hide,
  Show,
  MarkAsDeleted,
}

@Injectable()
export class CustomerDataHelperService {
  private readonly systemTypesService = inject(SystemTypesService);

  getSiteSystemText$(siteSystem: SiteSystemInformation | null): Observable<string | null> {
    return this.systemTypesService
      .list()
      .pipe(first())
      .pipe(
        map(systemTypes => {
          if (systemTypes) {
            let siteSystemText = 'Miscellaneous';
            if (siteSystem) {
              const localId = siteSystem.localId > 0 ? ` #${siteSystem.localId}` : '';

              siteSystemText = siteSystem.systemTypeOther + localId;
              if (!siteSystem.systemTypeOther) {
                const systemType = systemTypes.find(m => m.id === siteSystem.systemTypeId);
                if (systemType) {
                  siteSystemText = systemType.text + localId;
                }
              }
            }

            return siteSystemText;
          }

          return null;
        })
      );
  }
}
