import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input } from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  type AbstractControl,
  type ControlValueAccessor,
  type ValidationErrors,
  type Validator,
} from '@angular/forms';
import { InputTextComponent } from '@controls/input-text/input-text.component';
import { MoneyPipe } from '@pipes/money.pipe';

const noop = (): void => {};

const INPUT_MONEY_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputMoneyComponent),
  multi: true,
};

const INPUT_MONEY_COMPONENT_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => InputMoneyComponent),
  multi: true,
};

@Component({
  selector: 'wm-input-money',
  templateUrl: 'input-money.component.html',
  styleUrls: ['input-money.component.scss'],
  providers: [INPUT_MONEY_COMPONENT_VALUE_ACCESSOR, INPUT_MONEY_COMPONENT_VALIDATORS, MoneyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, InputTextComponent],
})
export class InputMoneyComponent implements ControlValueAccessor, Validator {
  private readonly cd = inject(ChangeDetectorRef);
  private readonly moneyPipe = inject(MoneyPipe);

  @Input() required = false;
  @Input() max?: string | number;
  @Input() min?: string | number;

  private _facade: string = '';
  public get facade(): string {
    return this._facade;
  }

  public set facade(value: string) {
    this._facade = value;

    if (value === '') {
      this.value = null;
    } else if (value) {
      this.value = this.getFloatValue(value);
    }
  }

  // The internal data model
  private innerValue: number | null = null;

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    v ??= null;
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.cd.markForCheck();
      this.onChangeCallback(v);
    }
  }

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    value ??= null;
    if (value !== this.innerValue) {
      this.innerValue = value;
      const facadeValue = value === null ? null : this.moneyPipe.transform(value, true);
      this.facade = facadeValue ?? '';
      this.cd.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  private getFloatValue(value: string): number {
    // We are in the US, so we strip the , or space. We keep the period.
    return parseFloat(value?.replace(/[ ,]/g, '')) ?? null;
  }

  blur(): void {
    this.facade = this.moneyPipe.transform(this.getFloatValue(this.facade), true).toString();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (isNaN(control.value)) {
      return { money: true };
    }

    return null;
  }
}
