import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type TaxItem } from '@models/tax-item';
import { type TaxItemSection } from '@models/tax-item-section';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { type Observable, of } from 'rxjs';
import { concatMap, map, share, tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class TaxItemSectionsService {
  private readonly http = inject(HttpClientService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);

  list(id?: Id): Observable<TaxItemSection[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.TaxItemSections, this.url.taxItemSections, id);
  }

  getTaxItems(id?: Id): Observable<TaxItem[]> {
    return this.list()
      .pipe(
        map(taxItemSections => {
          return taxItemSections.reduce((accum: TaxItem[], taxItemSection) => {
            return accum.concat(taxItemSection.items);
          }, []);
        })
      )
      .pipe(
        concatMap(taxItems => {
          // If we don't find our taxItems, we search on the server.
          const found = taxItems.some(taxItem => taxItem.id === id);
          if (!found && !!id) {
            const params = new HttpParams().set('id', id.toString());
            const newObs = this.http.get<TaxItem[]>(this.url.taxItems, { params });
            return newObs;
          }

          return of(taxItems);
        })
      );
  }

  save(taxItemSection: TaxItemSection): Observable<Id> {
    return this.http.post<Id>(this.url.taxItemsSectionsSave, taxItemSection).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  delete(id: Id): Observable<Id> {
    if (id <= 0) {
      return of(id);
    }

    return this.http.delete<Id>(`${this.url.taxItemSectionDelete}/${id}`).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.TaxItemSections);
    }
  }
}
