import { Injectable } from '@angular/core';
import { type ScheduleEventInformation } from '@models/dashboard-event';
import { type TechnicianInformation } from '@models/technician';
import { isBefore } from 'date-fns';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { first, map, share } from 'rxjs/operators';
import { type Point } from './../models/point';

const WHITE_PUSHPIN_SRC = '/assets/images/pin-white.svg';
const BLUE_PUSHPIN_SRC = '/assets/images/pin-blue.svg';
const RED_PUSHPIN_SRC = '/assets/images/pin-red.svg';
const GREY_PUSHPIN_SRC = '/assets/images/pin-grey.svg';
const BLUE_SELECTOR_SRC = '/assets/images/location-circle-blue.svg';
const GREY_SELECTOR_SRC = '/assets/images/location-circle-grey.svg';
const DEFAULT_TECHNICIAN_IMG_SRC = '/assets/images/avatars/technician.svg';
const PUSHPIN_PADDING = 8;

export interface CallImageResult {
  call: ScheduleEventInformation;
  image: string;
}

export interface PushpinCallImageResult extends CallImageResult {
  anchor: Point;
}

@Injectable()
export class TechnicianMapImageService {
  private readonly redPushpinImage$: Observable<HTMLImageElement>;
  private readonly whitePushpinImage$: Observable<HTMLImageElement>;
  private readonly bluePushpinImage$: Observable<HTMLImageElement>;
  private readonly greyPushpinImage$: Observable<HTMLImageElement>;
  private readonly blueSelectorImage$: Observable<HTMLImageElement>;
  private readonly greySelectorImage$: Observable<HTMLImageElement>;

  constructor() {
    this.redPushpinImage$ = this.createBaseImageSource$(RED_PUSHPIN_SRC);
    this.whitePushpinImage$ = this.createBaseImageSource$(WHITE_PUSHPIN_SRC);
    this.bluePushpinImage$ = this.createBaseImageSource$(BLUE_PUSHPIN_SRC);
    this.greyPushpinImage$ = this.createBaseImageSource$(GREY_PUSHPIN_SRC);
    this.blueSelectorImage$ = this.createBaseImageSource$(BLUE_SELECTOR_SRC);
    this.greySelectorImage$ = this.createBaseImageSource$(GREY_SELECTOR_SRC);
  }

  public generateTechnicianPushpinImage$(
    technician: TechnicianInformation,
    stale: boolean,
    options: { scale?: number } = {}
  ): Observable<{ image: string; anchor: { x: number; y: number } }> {
    options = Object.assign({ scale: 1 }, options);

    const technicianImg = new Image();
    technicianImg.crossOrigin = 'anonymous';
    const technicianImgOnload$ = fromEvent(technicianImg, 'load').pipe(first());

    technicianImg.src = technician.image || DEFAULT_TECHNICIAN_IMG_SRC;

    return combineLatest([stale ? this.redPushpinImage$ : this.whitePushpinImage$, technicianImgOnload$]).pipe(
      first(),
      map(([pushpinImg, _]) => {
        const c = document.createElement('canvas');
        const width = pushpinImg.width * options.scale;
        const height = pushpinImg.height * options.scale;

        c.width = width;
        c.height = height;

        technicianImg.width = width - PUSHPIN_PADDING * 2 * options.scale;
        technicianImg.height = technicianImg.width;

        const context = c.getContext('2d');
        context.drawImage(pushpinImg, 0, 0, width, height);
        context.drawImage(
          technicianImg,
          PUSHPIN_PADDING * options.scale,
          PUSHPIN_PADDING * options.scale,
          technicianImg.width,
          technicianImg.height
        );

        return {
          image: c.toDataURL(),
          anchor: { x: width / 2, y: height },
        };
      })
    );
  }

  public generateTechnicianCallsPushpinImages$(
    technicianCalls: ScheduleEventInformation[],
    focusedCallId: Id,
    hoveredCallId: Id
  ): Observable<PushpinCallImageResult[]> {
    return combineLatest([this.bluePushpinImage$, this.greyPushpinImage$]).pipe(
      map(([bluePushpinImage, greyPushpinImage]) => {
        return technicianCalls.map((call, index) => {
          const c = document.createElement('canvas');
          const hoveredOrSelected = new Set([focusedCallId, hoveredCallId]).has(call.id);
          const pushpinImage = hoveredOrSelected ? bluePushpinImage : greyPushpinImage;
          const scale = hoveredOrSelected ? 1.33 : 1;
          const width = pushpinImage.width;
          const height = pushpinImage.height;
          c.width = width * scale;
          c.height = height * scale;

          const context = c.getContext('2d');
          context.scale(scale, scale);

          if (isBefore(call.end, new Date())) {
            context.globalAlpha = 0.7;
          }

          context.drawImage(pushpinImage, 0, 0, width, height);
          this.writeText(context, (index + 1).toString(), {
            x: width / 2,
            y: width / 2 - 1,
          });

          return {
            call,
            image: c.toDataURL(),
            anchor: { x: c.width / 2, y: c.height },
          };
        });
      })
    );
  }

  public generateTechnicianCallsSelectorImages$(
    technicianCalls: ScheduleEventInformation[],
    focusedCallId: Id,
    hoveredCallId: Id
  ): Observable<CallImageResult[]> {
    return combineLatest([this.blueSelectorImage$, this.greySelectorImage$]).pipe(
      map(([blueSelectorImage, greySelectorImage]) => {
        return technicianCalls.map((call, index) => {
          const c = document.createElement('canvas');
          const hoveredOrSelected = new Set([focusedCallId, hoveredCallId]).has(call.id);
          const selectorImage = hoveredOrSelected ? blueSelectorImage : greySelectorImage;
          const width = selectorImage.width;
          const height = selectorImage.height;
          c.width = width;
          c.height = height;

          const context = c.getContext('2d');

          context.drawImage(selectorImage, 0, 0, width, height);
          this.writeText(context, (index + 1).toString(), { x: width / 2, y: height / 2 - 1 });

          return {
            call,
            image: c.toDataURL(),
          };
        });
      })
    );
  }

  private writeText(context, text: string, position: Point): void {
    context.font = 'bold 32px Source Sans Pro';
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.fillStyle = '#FFFFFF';
    context.fillText(text, position.x, position.y);
  }

  private createBaseImageSource$(src: string): Observable<HTMLImageElement> {
    const pushpinImage = new Image();
    let loaded = false;

    return new Observable<HTMLImageElement>(observer => {
      if (loaded) {
        observer.next(pushpinImage);
      } else {
        pushpinImage.src = src;
        pushpinImage.addEventListener('load', () => {
          loaded = true;
          observer.next(pushpinImage);
        });
      }
    }).pipe(first(), share());
  }
}
