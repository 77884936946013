import { ChangeDetectorRef, Pipe, inject, type OnDestroy, type PipeTransform } from '@angular/core';
import { DomSanitizer, type SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, type Observable, type Subscription } from 'rxjs';
import { UrlHelperService } from '../services/url-helper.service';

// Using similarity from AsyncPipe to avoid having to pipe |secure|async in HTML.
@Pipe({
  name: 'secure',
  pure: false,
  standalone: true,
})
export class SecurePipe implements PipeTransform, OnDestroy {
  private readonly _ref = inject(ChangeDetectorRef);
  private readonly urlHelperService = inject(UrlHelperService);
  private readonly sanitizer = inject(DomSanitizer);

  private _latestValue: any = null;
  private _latestReturnedValue: any = null;
  private _subscription: Subscription | null = null;
  private _obj: Observable<any> | null = null;

  private previousUrl?: string;
  private readonly _result = new BehaviorSubject<SafeUrl>('');
  private readonly result: Observable<SafeUrl> = this._result.asObservable();
  private _internalSubscription: Subscription | null = null;

  ngOnDestroy(): void {
    if (this._subscription) {
      this._dispose();
    }
  }

  transform(url: string): any {
    const obj = this.internalTransform(url);
    return this.asyncTrasnform(obj);
  }

  private internalTransform(url: string): Observable<SafeUrl> {
    if (!url) {
      return this.result;
    }

    if (this.previousUrl !== url) {
      this.previousUrl = url;
      this._internalSubscription = this.urlHelperService.get(url).subscribe(m => {
        const sanitized = this.sanitizer.bypassSecurityTrustUrl(m.blobUrl);
        this._result.next(sanitized);
      });
    }

    return this.result;
  }

  private asyncTrasnform(obj: Observable<any>): any {
    if (!this._obj) {
      if (obj) {
        this._subscribe(obj);
      }
      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }
    if (obj !== this._obj) {
      this._dispose();
      return this.asyncTrasnform(obj);
    }
    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }
    this._latestReturnedValue = this._latestValue;

    // TODO Angular14
    return this._latestValue;
    // return WrappedValue.wrap(this._latestValue);
  }

  private _subscribe(obj: Observable<any>) {
    const _this = this;
    this._obj = obj;

    this._subscription = obj.subscribe({
      next(value) {
        _this._updateLatestValue(obj, value);
      },
      error: (e: any) => {
        throw e;
      },
    });
  }

  private _dispose() {
    this._subscription?.unsubscribe();
    this._internalSubscription?.unsubscribe();
    this._internalSubscription = null;
    this._latestValue = null;
    this._latestReturnedValue = null;
    this._subscription = null;
    this._obj = null;
  }

  private _updateLatestValue(async: any, value: Object) {
    if (async === this._obj) {
      this._latestValue = value;
      this._ref.markForCheck();
    }
  }
}
