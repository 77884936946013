import { Injectable, inject } from '@angular/core';
import { type DashboardEvents, type ScheduleEventInformation } from '@models/dashboard-event';
import { formatRFC3339, getHours, getMilliseconds, getMinutes, getSeconds, set } from 'date-fns';
import { type Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { type ExtraOptionsJson, HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

/*
let INT_BITS = 7;
function leftRotate(n: number, d: number): number {
    // In n<<d, last d bits are 0. To put first 3 bits of n at
    // last, do bitwise or of n<<d with n >>(INT_BITS - d)
    return ((n << d) | (n >> (INT_BITS - d))) & ((1 << INT_BITS) - 1);
}

function rightRotate(n: number, d: number): number {
    //In n>>d, first d bits are 0. To put last 3 bits of at
    // first, do bitwise or of n>>d with n <<(INT_BITS - d)
    return ((n >> d) | (n << (INT_BITS - d))) & ((1 << INT_BITS) - 1);
}
*/

@Injectable()
export class EventsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  private static copyHour(from: Date, to: Date): Date {
    const mFrom = from;
    let mTo = to;

    mTo = set(mTo, {
      hours: getHours(mFrom),
      minutes: getMinutes(mFrom),
      seconds: getSeconds(mFrom),
      milliseconds: getMilliseconds(mFrom),
    });

    return mTo;
  }

  get(id: Id): Observable<ScheduleEventInformation> {
    return this.http.get<ScheduleEventInformation>(this.url.dashboardEventUrl.replace('$0', id.toString()));
  }

  list(start: Date, end: Date): Observable<ScheduleEventInformation[]> {
    return this.http.get<ScheduleEventInformation[]>(this.url.dashboardEventsUrl.replace('$0', start.toJSON()).replace('$1', end.toJSON()));
  }

  sendConfirmationMessage(id: Id, extraOptions?: ExtraOptionsJson): Observable<void> {
    return this.http.post<void>(this.url.dashboardEventSendConfirmationMessage.replace('$0', id.toString()), null, extraOptions);
  }

  split(id: Id, start: Date, end: Date): Observable<DashboardEvents> {
    return this.http.post<DashboardEvents>(
      this.url.dashboardEventSplit.replace('$0', id.toString()).replace('$1', formatRFC3339(start)).replace('$2', formatRFC3339(end)),
      null
    );
  }

  save(scheduleEventInformation: ScheduleEventInformation, extraOptions?: ExtraOptionsJson): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(scheduleEventInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.dashboardEventPost, obj, extraOptions);
  }

  patch(id: Id, obj: Partial<ScheduleEventInformation>, extraOptions?: ExtraOptionsJson): Observable<void> {
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.patch<void>(this.url.dashboardEventPatch.replace('$0', id.toString()), obj, extraOptions);
  }

  patchEventTime(
    id: Id,
    obj: Partial<ScheduleEventInformation> & { start: Date; end: Date },
    extraOptions?: ExtraOptionsJson
  ): Observable<void> {
    // First we get the original event, then we patch its time
    return this.get(id).pipe(
      concatMap(scheduleEvent => {
        const newStart = EventsService.copyHour(obj.start, scheduleEvent.start);
        const newEnd = EventsService.copyHour(obj.end, scheduleEvent.end);

        return this.patch(id, {
          ...obj,
          start: newStart,
          end: newEnd,
        });
      })
    );
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.dashboardEventDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.dashboardEventRestore.replace('$0', id.toString()), null);
  }
}
