import { Component, effect, forwardRef, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { RwTechnician2Component } from '@controls/rw-technician/rw-technician2.component';
import { Mode } from '@models/form';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import { combineLatest, concatMap, filter } from 'rxjs';

@Component({
  template: '<wm-rw-technician2 [name]="name()" [clearable]="true" [formControl]="control" [mode]="Mode.Write" />',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDynamicTechnicianComponent),
      multi: true,
    },
  ],
  imports: [ReactiveFormsModule, RwTechnician2Component],
  standalone: true,
})
export class FilterDynamicTechnicianComponent extends ElementBaseWithFormControl<Id | null> {
  readonly Mode = Mode;

  private readonly dynamicFilterService = inject(DynamicFilterService2);

  readonly filterId = input<string>('');
  readonly code = input<string>('');
  readonly name = input<string>('');

  private readonly valueChanges = toSignal(this.control.valueChanges);
  private readonly serviceChanges = toSignal(
    combineLatest([toObservable(this.code), toObservable(this.name)]).pipe(
      filter(([code, name]) => !!code && !!name),
      concatMap(([code, name]) => this.dynamicFilterService.changes<Id | null>(this.filterId(), code, name))
    ),
    { initialValue: null }
  );

  _ = effect(() => {
    this.dynamicFilterService.patch(this.filterId(), this.code(), this.name(), this.valueChanges());
  });

  __ = effect(
    () => {
      this.control.patchValue(this.serviceChanges());
    },
    { allowSignalWrites: true }
  );

  constructor() {
    super(() => null);
  }
}
