import { Injectable } from '@angular/core';

@Injectable()
export class UrlService {
  public readonly agreementConfigurationsUrl = '/static/agreementconfigurations';
  public readonly techniciansUrl = '/static/technicians';
  public readonly partServiceTypesUrl = '/static/partservicetypes';
  public readonly propertyTypesUrl = '/static/propertytypes';
  public readonly rateTypesUrl = '/static/ratetypes';
  public readonly timeZonesUrl = '/static/timezones';
  public readonly surveysUrl = '/static/surveys';
  public readonly quickBooksSettings = '/static/quickbookssettings';
  public readonly serviceRepairsUrl = '/static/servicerepairs';
  public readonly contractorLaborPricingsUrl = '/static/laborpricings';
  public readonly avatarsUrl = '/static/avatars';
  public readonly loanRatesUrl = '/api/loans/settings';
  public readonly partDeliveryMethods = '/static/partdeliverymethods';
  public readonly replenishmentTypes = '/static/replenishmenttypes';
  public readonly dropdownsList = '/static/dropdowns';
  public readonly dropdownSave = '/static/dropdowns';
  public readonly staticDefaultSystems = '/static/defaults/systems';

  public readonly features = '/api/features';

  public readonly billingAvailables = '/api/billings/availables';
  public readonly billingCurrent = '/api/billings/current';
  public readonly billingUpdate = '/api/billings/update';
  public readonly billingRequested = '/api/billings/requested';

  public readonly sandboxContractorGet = '/api/sandbox/contractor';

  public readonly accountRegister = '/api/sandbox/accounts/register';
  public readonly accountSendConfirmation = '/api/sandbox/accounts/register/confirmation';

  public readonly accountRegisterGetCoreData = '/api/accounts/register/core';
  public readonly accountRegisterPostCoreData = '/api/accounts/register/core';
  public readonly accountRegisterGetRequirements = '/api/accounts/register/requirements';
  public readonly accountRegisterFinish = '/api/accounts/register/finish';
  public readonly accountRegisterConfirm = '/api/accounts/confirm';
  public readonly accountRoles = '/api/accounts/roles';
  public readonly accountRecover = '/api/accounts/recover';
  public readonly accountReset = '/api/accounts/reset';
  public readonly accountView = '/api/accounts';
  public readonly accountSave = '/api/accounts';
  public readonly accountChangePassword = '/api/accounts/password';
  public readonly accountTokenInfo = '/api/accounts/tokeninfo';
  public readonly accountIntercomHash = '/api/accounts/intercom/hash';

  public readonly customerList = '/api/customers/list';
  public readonly customerTableList = '/api/customers/table/list';
  public readonly customerGetTableSegments = '/api/customers/table/segments';
  public readonly customerSaveTableSegment = '/api/customers/table/segments';
  public readonly customerDeleteTableSegment = '/api/customers/table/segments/$0';
  public readonly customerDefaultTableSegments = '/api/customers/table/segments/defaults';
  public readonly customerSingle = '/api/customers/$0';
  public readonly customerSites = '/api/customers/$0/sites';
  public readonly customerCalls = '/api/customers/$0/calls';
  public readonly customerWorkOrders = '/api/customers/$0/workorders';
  public readonly customerSalesProposals = '/api/customers/$0/salesproposals';
  public readonly customerDuties = '/api/customers/$0/tasks';
  public readonly customerListFiles = '/api/customers/$0/files';
  public readonly customerLoanApplications = '/api/customers/$0/loanapplications';
  public readonly customerAddFile = '/api/customers/$0/files/$1';
  public readonly customerDeleteFile = '/api/customers/$0/files/$1';
  public readonly customerDeleteFiles = '/api/customers/$0/files';
  public readonly customerSave = '/api/customers';
  public readonly customerPatch = '/api/customers/$0';
  public readonly customerDelete = '/api/customers/$0';
  public readonly customerRestore = '/api/customers/$0';
  public readonly customerSearchWithSites = '/api/customers/searchwithsites';
  public readonly customerSearch = '/api/customers/search';
  public readonly customerLastVisits = '/api/customers/$0/lastvisits?skip=$1';
  public readonly customerExtendedAgreements = '/api/customers/$0/extendedagreements';
  public readonly customerAgreements = '/api/customers/$0/agreements';
  public readonly customerExport = '/api/customers/export';

  public readonly customFieldsList = '/api/customfields/list';
  public readonly customFieldsCreate = '/api/customfields';
  public readonly customFieldsDelete = '/api/customfields/$0';

  public readonly smsMessageList = '/api/sms';

  public readonly synchronizerQbSearch = '/api/synchronizer/qbsearch';
  public readonly synchronizerWmSearch = '/api/synchronizer/wmsearch';
  public readonly synchronizerLink = '/api/synchronizer/link';
  public readonly synchronizerUnlink = '/api/synchronizer/unlink';
  public readonly synchronizerImport = '/api/synchronizer/import';
  public readonly synchronizerExport = '/api/synchronizer/export';
  public readonly synchronizerImportAll = '/api/synchronizer/importall';
  public readonly synchronizerResults = '/api/synchronizer/results';
  public readonly synchronizerQwcRegenerate = '/api/synchronizer/qwcregenerate';
  public readonly synchronizerQwcDownload = '/api/synchronizer/qwcdownload';
  public readonly synchronizerInitial = '/api/synchronizer/initial';
  public readonly synchronizerGetSettings = '/api/synchronizer/settings';
  public readonly synchronizerSaveSettings = '/api/synchronizer/settings';

  public readonly enerBankGetInitial = '/api/enerbank/initial';
  public readonly enerBankSetInitial = '/api/enerbank/initial';
  public readonly loansGetSettings = '/api/loans/settings';
  public readonly loansSaveSettings = '/api/loans/settings';
  public readonly loansGetApplicationsForWorkOrder = '/api/loans/workorder/$0';
  public readonly loansGetApplicationsForCustomer = '/api/loans/customer/$0';
  public readonly loansGetResults = '/api/loans/results';
  public readonly loanCodeSearch = '/api/loans/searchcode';
  public readonly loanCreate = '/api/loans/create';
  public readonly loanDeleteWorkOrderApplicationId = '/api/loans/workorderloanapplications/$0';

  public readonly siteSingle = '/api/sites/$0';
  public readonly siteSave = '/api/sites';
  public readonly sitePatch = '/api/sites/$0';
  public readonly siteMove = '/api/sites/$0/move';
  public readonly siteDelete = '/api/sites/$0';
  public readonly siteRestore = '/api/sites/$0';
  public readonly siteSiteSystems = '/api/sites/$0/sitesystems';
  public readonly siteLastVisits = '/api/sites/$0/lastvisits?skip=$1';
  public readonly siteExtendedAgreements = '/api/sites/$0/extendedagreements';
  public readonly siteAgreements = '/api/sites/$0/agreements';
  public readonly siteDuties = '/api/sites/$0/tasks';
  public readonly siteCalls = '/api/sites/$0/calls';
  public readonly siteListFiles = '/api/sites/$0/files';
  public readonly siteAddFile = '/api/sites/$0/files/$1';
  public readonly siteDeleteFile = '/api/sites/$0/files/$1';
  public readonly siteDeleteFiles = '/api/sites/$0/files';
  public readonly siteWorkOrders = '/api/sites/$0/workorders';
  public readonly siteSalesProposals = '/api/sites/$0/salesproposals';
  public readonly siteCopyCustomer = '/api/sites/$0/copycustomer';

  public readonly siteSystemSingle = '/api/sitesystems/$0';
  public readonly siteSystemSave = '/api/sitesystems';
  public readonly siteSystemDelete = '/api/sitesystems/$0';
  public readonly siteSystemRestore = '/api/sitesystems/$0';
  public readonly siteSystemWorkOrders = '/api/sitesystems/$0/workorders';
  public readonly siteSystemFilters = '/api/sitesystems/$0/filters';
  public readonly siteSystemBelts = '/api/sitesystems/$0/belts';
  public readonly siteSystemAgreements = '/api/sitesystems/$0/agreements';
  public readonly siteSystemListFiles = '/api/sitesystems/$0/files';
  public readonly siteSystemAddFile = '/api/sitesystems/$0/files/$1';
  public readonly siteSystemDeleteFile = '/api/sitesystems/$0/files/$1';
  public readonly siteSystemDeleteFiles = '/api/sitesystems/$0/files';

  public readonly filterSingle = '/api/filters/$0';
  public readonly filterSave = '/api/filters';
  public readonly filterDelete = '/api/filters/$0';
  public readonly filterRestore = '/api/filters/$0';

  public readonly beltSingle = '/api/belts/$0';
  public readonly beltSave = '/api/belts';
  public readonly beltDelete = '/api/belts/$0';
  public readonly beltRestore = '/api/belts/$0';

  public readonly callSingle = '/api/calls/$0';
  public readonly callType = '/api/calls/$0/type';
  public readonly callSave = '/api/calls';
  public readonly callDelete = '/api/calls/$0';
  public readonly callRestore = '/api/calls/$0';
  public readonly callPatch = '/api/calls/$0';
  public readonly callTakeCharge = '/api/calls/$0/takecharge';

  public readonly workOrderList = '/api/workorders/list';
  public readonly workOrderSingle = '/api/workorders/$0';
  public readonly workOrderCreate = '/api/workorders/create';
  public readonly workOrderSave = '/api/workorders';
  public readonly workOrderFinishUpdate = '/api/workorders/$0/finishupdate';
  public readonly workOrderDelete = '/api/workorders/$0';
  public readonly workOrderRestore = '/api/workorders/$0';
  public readonly workOrderDetails = '/api/workorders/$0/details';
  public readonly workOrderDiscounts = '/api/workorders/$0/discounts';
  public readonly workOrderRebates = '/api/workorders/$0/rebates';
  public readonly workOrderAgreements = '/api/workorders/$0/agreements';
  public readonly workOrderCreateViewData = '/api/workorders/viewdata';
  public readonly workOrderViewData = '/api/workorders/$0/viewdata';
  public readonly workOrderToggleProcess = '/api/workorders/$0/process';
  public readonly workOrderQuickBooksUnlink = '/api/workorders/$0/quickbooks/unlink';
  public readonly workOrderToggleQuickBooksStatus = '/api/workorders/$0/quickbooks2';
  public readonly workOrderSearch = '/api/workorders/search';
  public readonly workOrderGetEmailMessages = '/api/workorders/$0/emailmessages';
  public readonly workOrderGetEmailStates = '/api/workorders/$0/emailstates/$1';
  public readonly workOrderGetAttachment = '/api/workorders/$0/attachment/$1';
  public readonly workOrderDetailSave = '/api/workorders/$0/details';
  public readonly workOrderDiscountSave = '/api/workorders/$0/discounts';
  public readonly workOrderRebateSave = '/api/workorders/$0/rebates';
  public readonly workOrderRepairPartSave = '/api/workorders/$0/details/$1/repairparts';
  public readonly workOrderAgreementSave = '/api/workorders/$0/agreements';
  public readonly workOrderListFiles = '/api/workorders/$0/files';
  public readonly workOrderAddFile = '/api/workorders/$0/files/$1';
  public readonly workOrderDeleteFile = '/api/workorders/$0/files/$1';
  public readonly workOrderGetDiscounts = '/api/workorders/$0/discounts';
  public readonly workOrderSaveDiscounts = '/api/workorders/$0/discounts';
  public readonly workOrderDeleteDiscounts = '/api/workorders/$0/discounts/$1';
  public readonly workOrderGetRebates = '/api/workorders/$0/rebates';
  public readonly workOrderSaveRebates = '/api/workorders/$0/rebates';
  public readonly workOrderDeleteRebates = '/api/workorders/$0/rebates/$1';
  public readonly workOrderGetDetails = '/api/workorders/$0/details';
  public readonly workOrderSaveDetails = '/api/workorders/$0/details';
  public readonly workOrderDeleteDetails = '/api/workorders/$0/details/$1';
  public readonly workOrderSaveNonStockItem = '/api/workorders/$0/nonstockitems';
  public readonly workOrderGetPayments = '/api/workorders/$0/payments';
  public readonly workOrderSavePayments = '/api/workorders/$0/payments';
  public readonly workOrderDeletePayments = '/api/workorders/$0/payments/$1';
  public readonly workOrderGetRepairParts = '/api/workorders/$0/details/$1/repairparts';
  public readonly workOrderSaveRepairParts = '/api/workorders/$0/details/$1/repairparts';
  public readonly workOrderDeleteRepairParts = '/api/workorders/$0/details/$1/repairparts/$2';
  public readonly workOrderGetAgreements = '/api/workorders/$0/agreements';
  public readonly workOrderSaveAgreements = '/api/workorders/$0/agreements';
  public readonly workOrderDeleteAgreements = '/api/workorders/$0/agreements/$1';
  public readonly workOrdersGetOrCreatePaymentToken = '/api/workorders/$0/paymenttoken';
  public readonly workOrdersForceComplete = '/api/workorders/$0/forcecomplete';

  public readonly salesProposalCreateViewData = '/api/salesproposals/viewdata';
  public readonly salesProposalsList = '/api/salesproposals/list';
  public readonly salesProposalsTableList = '/api/salesproposals/table/list';
  public readonly salesProposalsGetTableSegments = '/api/salesproposals/table/segments';
  public readonly salesProposalsSaveTableSegment = '/api/salesproposals/table/segments';
  public readonly salesProposalsDeleteTableSegment = '/api/salesproposals/table/segments/$0';
  public readonly salesProposalsExport = '/api/salesproposals/export';
  public readonly salesProposalsTemplateList = '/api/salesproposals/templatelist';
  public readonly salesProposalsGet = '/api/salesproposals/$0';
  public readonly salesProposalsGetViewData = '/api/salesproposals/$0/viewdata';
  public readonly salesProposalCreate = '/api/salesproposals/create';
  public readonly salesProposalSave = '/api/salesproposals';
  public readonly settingsGetSalesProposalsSettings = '/api/salesproposals/settings';
  public readonly settingsSaveSalesProposalsSettings = '/api/salesproposals/settings';
  public readonly salesProposalDelete = '/api/salesproposals/$0';
  public readonly salesProposalRestore = '/api/salesproposals/$0';
  public readonly salesProposalGetPackageDiscounts = '/api/salesproposals/$0/packages/$1/discounts';
  public readonly salesProposalSaveDiscounts = '/api/salesproposals/$0/packages/$1/discounts';
  public readonly salesProposalDeletePackageDiscounts = '/api/salesproposals/$0/packages/$1/discounts/$2';
  public readonly salesProposalGetPackageRebates = '/api/salesproposals/$0/packages/$1/rebates';
  public readonly salesProposalSavePackageRebates = '/api/salesproposals/$0/packages/$1/rebates';
  public readonly salesProposalDeletePackageRebates = '/api/salesproposals/$0/packages/$1/rebates/$2';
  public readonly salesProposalGetPayments = '/api/salesproposals/$0/payments';
  public readonly salesProposalSavePayments = '/api/salesproposals/$0/payments';
  public readonly salesProposalDeletePayments = '/api/salesproposals/$0/payments/$1';
  public readonly salesProposalGetLoanApplications = '/api/salesproposals/$0/loanapplications';
  public readonly salesProposalSaveLoanApplications = '/api/salesproposals/$0/loanapplications';
  public readonly salesProposalDeleteLoanApplications = '/api/salesproposals/$0/loanapplications/$1';
  public readonly salesProposalGetPackages = '/api/salesproposals/$0/packages';
  public readonly salesProposalSavePackages = '/api/salesproposals/$0/packages';
  public readonly salesProposalDeletePackages = '/api/salesproposals/$0/packages/$1';
  public readonly salesProposalGetPackageDetails = '/api/salesproposals/$0/packages/$1/details';
  public readonly salesProposalSavePackageDetails = '/api/salesproposals/$0/packages/$1/details';
  public readonly salesProposalDeletePackageDetails = '/api/salesproposals/$0/packages/$1/details/$2';
  public readonly salesProposalSelectPackage = '/api/salesproposals/$0/packages/$1/select';
  public readonly salesProposalPreview = '/api/salesproposals/$0/preview';
  public readonly salesProposalAddFile = '/api/salesproposals/$0/files/$1';
  public readonly salesProposalDeleteFile = '/api/salesproposals/$0/files/$1';
  public readonly salesProposalDeleteFiles = '/api/salesproposals/$0/files';

  public readonly priceBooksList = '/api/pricebooks/list';
  public readonly priceBooksCreate = '/api/pricebooks';
  public readonly priceBooksDelete = '/api/pricebooks/$0';
  public readonly priceBooksSaveServiceRepairs = '/api/pricebooks/$0/servicerepairs';
  public readonly priceBooksDeleteServiceRepairs = '/api/pricebooks/$0/servicerepairs/$1';
  public readonly priceBooksSetServiceRepairCategories = '/api/pricebooks/$0/servicerepairs/$1/categories';
  public readonly priceBooksSaveCategories = '/api/pricebooks/$0/categories';
  public readonly priceBooksGetCategories = '/static/servicerepaircategories';
  public readonly priceBooksSetServiceRepairPartItemIds = '/api/pricebooks/$0/servicerepairs/$1/partitems';
  public readonly priceBooksSetCategoryPartItemIds = '/api/pricebooks/categories/$0/partitems';
  public readonly priceBooksSetCategoryServiceRepairIds = '/api/pricebooks/categories/$0/servicerepairs';

  public readonly agreementSiteSystemList = '/api/agreementsitesystems/list';
  public readonly agreementSiteSystemTableList = '/api/agreementsitesystems/table/list';
  public readonly agreementSiteSystemGetTableSegments = '/api/agreementsitesystems/table/segments';
  public readonly agreementSiteSystemSaveTableSegment = '/api/agreementsitesystems/table/segments';
  public readonly agreementSiteSystemDeleteTableSegment = '/api/agreementsitesystems/table/segments/$0';
  public readonly agreementSiteSystemExport = '/api/agreementsitesystems/export';
  public readonly agreementSiteSystemViewData = '/api/agreementsitesystems/$0/viewdata';
  public readonly agreementSiteSystemSingle = '/api/agreementsitesystems/$0';
  public readonly agreementSiteSystemSave = '/api/agreementsitesystems';
  public readonly agreementSiteSystemPatch = '/api/agreementsitesystems/$0';
  public readonly agreementSiteSystemDelete = '/api/agreementsitesystems/$0';
  public readonly agreementSiteSystemRestore = '/api/agreementsitesystems/$0';
  public readonly agreementSiteSystemToggleProcess = '/api/agreementsitesystems/$0/process';
  public readonly agreementSiteSystemGetSubscriptionViewData = '/api/agreementsitesystems/$0/subscription';
  public readonly agreementSiteSystemSetupSubscription = '/api/agreementsitesystems/$0/subscription/setup';
  public readonly agreementSiteSystemCancelSubscription = '/api/agreementsitesystems/$0/subscription/cancel';

  public readonly dutyList = '/api/tasks/list';
  public readonly dutyTableList = '/api/tasks/table/list';
  public readonly dutyGetTableSegments = '/api/tasks/table/segments';
  public readonly dutySaveTableSegment = '/api/tasks/table/segments';
  public readonly dutyDeleteTableSegment = '/api/tasks/table/segments/$0';
  public readonly dutyExport = '/api/tasks/export';
  public readonly dutyExport2 = '/api/tasks/export2';
  public readonly dutySingle = '/api/tasks/$0';
  public readonly dutySave = '/api/tasks';
  public readonly dutyDelete = '/api/tasks/$0';
  public readonly dutyRestore = '/api/tasks/$0';
  public readonly dutyOverdueCountForCurrentUser = '/api/tasks/currentuseroverduecount';

  public readonly featureFlagsGet = '/api/featureflags';

  public readonly agreementList = '/api/agreements/list';
  public readonly agreementsCreate = '/api/agreements';
  public readonly agreementsDelete = '/api/agreements/$0';
  public readonly setAgreementSettings = '/api/agreements/settings';

  public readonly dashboardEventUrl = '/api/events/$0';
  public readonly dashboardEventsGetBlockedUrl = '/api/events/blocked/$0';
  public readonly dashboardEventsUrl = '/api/events?start=$0&end=$1';
  public readonly dashboardBoards = '/api/settings/boards';
  public readonly dashboardEventPatch = '/api/events/$0';
  public readonly dashboardEventPost = '/api/events';
  public readonly dashboardEventSplit = '/api/events/$0/split?start=$1&end=$2';
  public readonly dashboardEventDelete = '/api/events/$0';
  public readonly dashboardEventRestore = '/api/events/$0';
  public readonly dashboardEventSendConfirmationMessage = '/api/events/$0/sendconfirmationmessage';

  public readonly contractorImportsList = '/api/contractorimports';
  public readonly contractorImportsListDefinitions = '/api/contractorimports/definitions';
  public readonly contractorImportsCreate = '/api/contractorimports/create';
  public readonly contractorImportsGet = (contractorImportId: Id): string => `/api/contractorimports/${contractorImportId}`;
  public readonly contractorImportsDelete = (contractorImportId: Id): string => `/api/contractorimports/${contractorImportId}`;
  public readonly contractorImportsStart = (contractorImportId: Id): string => `/api/contractorimports/${contractorImportId}/start`;
  public readonly contractorImportsGetResult = (contractorImportId: Id): string => `/api/contractorimports/${contractorImportId}/result`;
  public readonly contractorImportsComplete = (contractorImportId: Id): string => `/api/contractorimports/${contractorImportId}/complete`;

  public readonly workOrderDetailDelete = '/api/workorderdetails/$0';
  public readonly workOrderDetailRestore = '/api/workorderdetails/$0';

  public readonly workOrderRepairPartDelete = '/api/workorderrepairparts/$0';
  public readonly workOrderRepairPartRestore = '/api/workorderrepairparts/$0';

  public readonly workOrderPaymentDelete = '/api/workorderpayments/$0';
  public readonly workOrderPaymentRestore = '/api/workorderpayments/$0';

  public readonly zonesList = '/api/zones/list';
  public readonly zonesCreate = '/api/zones';
  public readonly zonesDelete = '/api/zones/$0';

  public readonly zapierGet = '/api/zapier';
  public readonly zapierEnable = '/api/zapier/enable';
  public readonly zapierDisable = '/api/zapier/disable';

  public readonly workOrderAgreementDelete = '/api/workorderagreements';
  public readonly workOrderAgreementRestore = '/api/workorderagreements';

  public readonly recommendationSave = '/api/recommendations';
  public readonly recommendationPatch = '/api/recommendations/$0';
  public readonly recommendationHide = '/api/recommendations/$0';

  public readonly recommendationPhotoSave = '/api/recommendationphotos';
  public readonly recommendationPhotoHide = '/api/recommendationphotos/$0';

  public readonly inspectionAnswerSave = '/api/inspectionanswers';

  public readonly replenishmentList = '/api/replenishments/list';
  public readonly replenishmentSingle = '/api/replenishments/$0';
  public readonly replenishmentUnreplenishedList = '/api/replenishments/unreplenished';
  public readonly replenishmentUnreplenishedCount = '/api/replenishments/unreplenishedcount';
  public readonly replenishmentReplenish = '/api/replenishments/replenish';
  public readonly replenishmentGetSettings = '/api/replenishments/settings';
  public readonly replenishmentSaveSettings = '/api/replenishments/settings';

  public readonly contractorSettingsSave = '/api/contractorsettings/contractor';
  public readonly contractorSettingGetMultiple = '/api/contractorsettings/getmultiple';
  public readonly contractorSettingGetSettings = '/api/contractorsettings/contractor';
  public readonly contractorSettingPostProcessLogo = '/api/contractorsettings/postprocesslogo/$0';
  public readonly contractorSettingGetMarkups = '/api/contractorsettings/markups';
  public readonly contractorSettingSaveMarkups = '/api/contractorsettings/markups';

  public readonly errorLog = '/api/errors';

  public readonly settingsViewData = '/api/settings/viewdata';
  public readonly settingsGetCurrentLaborRates = '/api/settings/laborrates';
  public readonly settingsSaveCurrentLaborRates = '/api/settings/laborrates';
  public readonly settingsGetInvoices = '/api/settings/invoices';
  public readonly settingsSaveInvoices = '/api/settings/invoices';
  public readonly settingsGetWorkOrders = '/api/settings/workorders';
  public readonly settingsSaveWorkOrders = '/api/settings/workorders';
  public readonly settingsGetCustomers = '/api/settings/customers';
  public readonly settingsSaveCustomers = '/api/settings/customers';
  public readonly settingsGetLocation = '/api/settings/location';
  public readonly settingsSaveLocation = '/api/settings/location';
  public readonly settingsGetSms = '/api/settings/sms';
  public readonly settingsSaveSms = '/api/settings/sms';
  public readonly settingsGetSmsSettings = '/api/settings/sms/settings';
  public readonly settingsSaveSmsSettings = '/api/settings/sms/settings';

  public readonly settingsEmailGet = '/api/settings/email';
  public readonly settingsEmailSave = '/api/settings/email';
  public readonly settingsEmailPatch = '/api/settings/email';
  public readonly settingsEmailDelete = '/api/settings/email';
  public readonly settingsEmailGetResults = '/api/settings/email/results/filter';
  public readonly settingsEmailValidate = '/api/settings/email/validate';
  public readonly settingsEmailGetUnsubscribes = '/api/settings/email/unsubscribes/filter';
  public readonly settingsEmailDeleteUnsubscribes = '/api/settings/email/unsubscribes';

  public readonly settingsGetIPad = '/api/settings/ipad';
  public readonly settingsSaveIPad = '/api/settings/ipad';
  public readonly settingsGetPodium = '/api/settings/podium';
  public readonly settingsSavePodium = '/api/settings/podium';
  public readonly settingGetBoards = '/api/settings/boards';
  public readonly settingSaveBoards = '/api/settings/boards';
  public readonly settingsGetCostRecoveryReport = '/api/settings/costrecovery/report?from=$0&to=$1';
  public readonly settingsGetCostRecoverySettings = '/api/settings/costrecovery/settings';
  public readonly settingsSaveCostRecovery = '/api/settings/costrecovery/settings?activate=$0';
  public readonly settingsDeleteInvoiceFile = '/api/settings/invoicefiles/$0';

  public readonly technicianSettingList = '/api/techniciansettings';
  public readonly technicianSettingGetMultiple = '/api/techniciansettings/getmultiple';
  public readonly technicianSettingSave = '/api/techniciansettings';

  public readonly techniciansList = '/api/technicians';
  public readonly technicianSingle = '/api/technicians/$0';
  public readonly technicianSave = '/api/technicians';
  public readonly technicianUnblock = '/api/technicians/$0/unblock';
  public readonly technicianHasOpenedObjects = '/api/technicians/$0/hasopenedobjects';
  public readonly technicianDelete = '/api/technicians/$0';
  public readonly technicianRestore = '/api/technicians/$0';
  public readonly technicianTransfer = '/api/technicians/$0/transfer/$1';
  public readonly technicianPostProcessPhoto = '/api/technicians/$0/postprocessphoto';

  public readonly debriefAnswerGet = '/api/debriefanswers/$0';
  public readonly debriefAnswerPost = '/api/debriefanswers';
  public readonly debriefAnswerDelete = '/api/debriefanswers/$0';

  public readonly debriefCategoryList = '/api/debriefcategories/list';
  public readonly debriefCategoryGet = '/api/debriefcategories/$0';
  public readonly debriefCategoryPost = '/api/debriefcategories';
  public readonly debriefCategoryDelete = '/api/debriefcategories/$0';
  public readonly debriefCategoryHide = '/api/debriefcategories/$0/hide';
  public readonly debriefCategoryRestore = '/api/debriefcategories/$0';

  public readonly inspectionCategoryList = '/api/inspectioncategories/list';
  public readonly inspectionCategoryGet = '/api/inspectioncategories/$0';
  public readonly inspectionCategoryPost = '/api/inspectioncategories';
  public readonly inspectionCategoryDelete = '/api/inspectioncategories/$0';
  public readonly inspectionCategoryHide = '/api/inspectioncategories/$0/hide';
  public readonly inspectionCategoryRestore = '/api/inspectioncategories/$0';

  public readonly leaderboardCategoryGetList = '/api/leaderboardcategories/list';
  public readonly leaderboardCategoryGet = '/api/leaderboardcategories/$0';
  public readonly leaderboardCategoryPost = '/api/leaderboardcategories';
  public readonly leaderboardCategoryHide = '/api/leaderboardcategories/$0/hide';
  public readonly leaderboardCategoryRestore = '/api/leaderboardcategories';

  public readonly reportCategoryGetList = '/api/reportcategories/list';
  public readonly reportCategoryGet = '/api/reportcategories/$0';
  public readonly reportCategoryPost = '/api/reportcategories';
  public readonly reportCategoryDelete = '/api/reportcategories/$0';
  public readonly reportCategoryHide = '/api/reportcategories/$0/hide';
  public readonly reportCategoryRestore = '/api/reportcategories';

  public readonly debriefEntryDelete = '/api/debriefentries/$0';

  public readonly inspectionEntryDelete = '/api/inspectionentries/$0';

  public readonly reportEntryDelete = '/api/reportentries/$0';

  public readonly reportsOverview = '/api/reports/overview?from=$from$&to=$to$';
  public readonly reportsPerformance =
    '/api/reports/performance?technicianId=$technicianId$&from=$from$&to=$to$&appointmentStatus=$appointmentStatus$';

  public readonly reportsIncentive = '/api/reports/incentive?technicianId=$technicianId$&from=$from$&to=$to$';
  public readonly reportsLeaderboardAvailable = '/api/reports/leaderboards/available';
  public readonly reportsLeaderboardDetails =
    '/api/reports/leaderboards/$id$/details?range1.from=$from$&range1.to=$to$&range2.from=$from2$&range2.to=$to2$';

  public readonly tagEntitySingle = '/api/tagentities/$0';
  public readonly tagEntitySave = '/api/tagentities';
  public readonly tagEntityDelete = '/api/tagentities/$0';

  public readonly tagList = '/api/tags/list';
  public readonly tagSingle = '/api/tags/$0';
  public readonly tagSave = '/api/tags';
  public readonly tagHide = '/api/tags/$0';
  public readonly tagRestore = '/api/tags/$0';

  public readonly taxItemSections = '/api/taxitemsections/list';
  public readonly taxItemsSectionsSave = '/api/taxitemsections';
  public readonly taxItemSectionDelete = '/api/taxitemsections';

  public readonly taxItems = '/api/taxitems/list';

  public readonly billingPeriods = '/api/contractorbillings/periods';

  public readonly azureUploaderStart = '/api/azureuploader';
  public readonly azureUploaderFinish = '/api/azureuploader/$0';
  public readonly azureUploaderDelete = '/api/azureuploader/$0';

  public readonly invoiceSingle = '/api/invoices/$0';
  public readonly invoicePrepare = '/api/invoices/$0/prepare';
  public readonly invoiceSave = '/api/invoices';
  public readonly invoicePreview = '/api/invoices/preview';

  public readonly fileSingle = '/api/files/$0';
  public readonly filePrepare = '/api/files/prepare';
  public readonly fileValidate = '/api/files/validate';
  public readonly filePost = '/api/files';
  public readonly fileDelete = '/api/files/$0';

  public readonly videoCategoryGetList = '/api/videos/categories';

  public readonly locationTechnicianPositions = '/api/locations/technicians';
  public readonly locationSiteLocations = '/api/locations/sites';

  public readonly chargesGetSettings = '/api/payments/settings';
  public readonly chargesSetSettings = '/api/payments/settings';
  public readonly chargesDisconnect = '/api/payments/disconnect';
  public readonly chargesGetChargeViewData = '/api/payments/viewdata/$0';
  public readonly chargesProcessCharge = '/api/payments/process';
  public readonly chargesDeleteCard = '/api/payments/cards/$0';
  public readonly chargesGetViewData = '/api/payments/viewdata/$0';

  public readonly subscriptionsGetSettings = '/api/subscriptions/settings';
  public readonly subscriptionsSetSettings = '/api/subscriptions/settings';
  public readonly subscriptionsDisconnect = '/api/subscriptions/disconnect';
  public readonly subscriptionsDeleteCard = '/api/subscriptions/cards/$0';

  public readonly rebatesList = '/api/rebates/list';
  public readonly rebatesCreate = '/api/rebates';
  public readonly rebatesDelete = '/api/rebates/$0';

  public readonly discountsList = '/api/discounts/list';
  public readonly discountsCreate = '/api/discounts';
  public readonly discountsDelete = '/api/discounts/$0';

  public readonly distributorsList = '/api/distributors/list';
  public readonly distributorsCreate = '/api/distributors';
  public readonly distributorsPatch = '/api/distributors/$0';
  public readonly distributorsDelete = '/api/distributors/$0';
  public readonly distributorsSaveLocation = '/api/distributors/$0/locations';
  public readonly distributorsDeleteLocation = '/api/distributors/$0/locations/$1';
  public readonly distributorsSetDefaultLocation = '/api/distributors/$0/locations/$1';

  public readonly systemTypesSingle = '/api/systemtypes/$0';
  public readonly systemTypesList = '/api/systemtypes/list';
  public readonly systemTypesSave = '/api/systemtypes';
  public readonly systemTypesHide = '/api/systemtypes/$0';
  public readonly systemTypesRestore = '/api/systemtypes/$0';

  public readonly dutyCategoriesSingle = '/api/dutycategories/$0';
  public readonly dutyCategoriesList = '/api/dutycategories/list';
  public readonly dutyCategoriesSave = '/api/dutycategories';
  public readonly dutyCategoriesHide = '/api/dutycategories/$0';
  public readonly dutyCategoriesRestore = '/api/dutycategories/$0';

  public readonly callReasonsSingle = '/api/callreasons/$0';
  public readonly callReasonsList = '/api/callreasons/list';
  public readonly callReasonsSave = '/api/callreasons';
  public readonly callReasonsHide = '/api/callreasons/$0';
  public readonly callReasonsRestore = '/api/callreasons/$0';

  public readonly callWorkOrderTypesSingle = '/api/callworkordertypes/$0';
  public readonly callWorkOrderTypesList = '/api/callworkordertypes/list';
  public readonly callWorkOrderTypesSave = '/api/callworkordertypes';
  public readonly callWorkOrderTypesHide = '/api/callworkordertypes/$0';
  public readonly callWorkOrderTypesRestore = '/api/callworkordertypes/$0';

  public readonly filterTypesSingle = '/api/filtertypes/$0';
  public readonly filterTypesList = '/api/filtertypes/list';
  public readonly filterTypesSave = '/api/filtertypes';
  public readonly filterTypesHide = '/api/filtertypes/$0';
  public readonly filterTypesRestore = '/api/filtertypes/$0';

  public readonly paymentMethodsSingle = '/api/paymentmethods/$0';
  public readonly paymentMethodsList = '/api/paymentmethods/list';
  public readonly paymentMethodsSave = '/api/paymentmethods';
  public readonly paymentMethodsHide = '/api/paymentmethods/$0';
  public readonly paymentMethodsRestore = '/api/paymentmethods/$0';

  public readonly paymentTypesSingle = '/api/paymenttypes/$0';
  public readonly paymentTypesList = '/api/paymenttypes/list';
  public readonly paymentTypesSave = '/api/paymenttypes';
  public readonly paymentTypesHide = '/api/paymenttypes/$0';
  public readonly paymentTypesRestore = '/api/paymenttypes/$0';

  public readonly referralTypesSingle = '/api/referraltypes/$0';
  public readonly referralTypesList = '/api/referraltypes/list';
  public readonly referralTypesSave = '/api/referraltypes';
  public readonly referralTypesHide = '/api/referraltypes/$0';
  public readonly referralTypesRestore = '/api/referraltypes/$0';

  public readonly callDepartmentTypesSingle = '/api/calldepartmenttypes/$0';
  public readonly callDepartmentTypesList = '/api/calldepartmenttypes/list';
  public readonly callDepartmentTypesSave = '/api/calldepartmenttypes';
  public readonly callDepartmentTypesHide = '/api/calldepartmenttypes/$0';
  public readonly callDepartmentTypesRestore = '/api/calldepartmenttypes/$0';

  public readonly partItemsList = '/api/partitems/list';
  public readonly partItemsSave = '/api/partitems';
  public readonly partItemsDelete = '/api/partitems/$0';
  public readonly partItemsSetCategories = '/api/partitems/$0/categories';
  public readonly partItemsSetServiceRepairs = '/api/partitems/$0/servicerepairs';

  public readonly stripeGetSubscriptionAccountId = '/api/stripe/account/subscription';
  public readonly stripeGetChargeAccountId = '/api/stripe/account/charge';

  public readonly squareGetLocationId = '/api/square/location';

  public readonly webMarketingBusinessValuation = '/api/webmarketing/businessvaluation';
}

