export function getChromeVersion(): number | false {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
}

export function isIE(): boolean {
  return navigator.userAgent.includes('MSIE ');
}

export function isOldBrowser(): boolean {
  if (isIE()) {
    return true;
  }

  const chromeVersion = getChromeVersion();
  if (chromeVersion !== false && chromeVersion <= 49) {
    return true;
  }

  return false;
}
