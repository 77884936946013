import {
  HttpClient,
  type HttpContext,
  HttpErrorResponse,
  type HttpEvent,
  type HttpHeaders,
  type HttpParams,
  type HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppInsightsService } from '@services/app-insights.service';
import { type HttpOptions } from '@utility/angular';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorService } from './error.service';

export type HttpHeader = Record<string, string | string[]>;

export interface AngularOptions {
  headers?: HttpHeaders | Record<string, string | string[]>;
  observe?: 'body';
  context?: HttpContext;
  params?: HttpParams | Record<string, string | string[]>;
  reportProgress?: boolean;
  withCredentials?: boolean;
}

export interface AngularOptionsJson extends AngularOptions {
  responseType?: 'json';
}

export interface AngularOptionsBlob extends AngularOptions {
  responseType?: 'blob';
}

export interface ExtraOptionsJson extends AngularOptionsJson {
  noLogOut?: boolean;
}

export interface ExtraOptionsBlob extends AngularOptionsBlob {
  noLogOut?: boolean;
}

@Injectable()
export class HttpClientBaseService {
  protected http = inject(HttpClient);
  protected errorService = inject(ErrorService);
  protected appInsightsService = inject(AppInsightsService);

  get(url: string, options: HttpOptions<'body', 'arraybuffer'>): Observable<ArrayBuffer>;
  get(url: string, options: HttpOptions<'body', 'blob'>): Observable<Blob>;
  get(url: string, options: HttpOptions<'body', 'text'>): Observable<string>;
  get(url: string, options?: HttpOptions<'body', 'json'>): Observable<unknown>;
  get<T>(url: string, options?: HttpOptions<'body', 'json'>): Observable<T>;
  get(url: string, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpEvent<ArrayBuffer>>;
  get(url: string, options: HttpOptions<'events', 'blob'>): Observable<HttpEvent<Blob>>;
  get(url: string, options: HttpOptions<'events', 'text'>): Observable<HttpEvent<string>>;
  get(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<unknown>>;
  get<T>(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<T>>;
  get(url: string, options: HttpOptions<'response', 'arraybuffer'>): Observable<HttpResponse<ArrayBuffer>>;
  get(url: string, options: HttpOptions<'response', 'blob'>): Observable<HttpResponse<Blob>>;
  get(url: string, options: HttpOptions<'response', 'text'>): Observable<HttpResponse<string>>;
  get(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<unknown>>;
  get<T>(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<T>>;
  get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options);
  }

  post(url: string, body: any | null, options: HttpOptions<'body', 'arraybuffer'>): Observable<ArrayBuffer>;
  post(url: string, body: any | null, options: HttpOptions<'body', 'blob'>): Observable<Blob>;
  post(url: string, body: any | null, options: HttpOptions<'body', 'text'>): Observable<string>;
  post(url: string, body: any | null, options?: HttpOptions<'body', 'json'>): Observable<unknown>;
  post<T>(url: string, body: any | null, options?: HttpOptions<'body', 'json'>): Observable<T>;
  post(url: string, body: any | null, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpEvent<ArrayBuffer>>;
  post(url: string, body: any | null, options: HttpOptions<'events', 'blob'>): Observable<HttpEvent<Blob>>;
  post(url: string, body: any | null, options: HttpOptions<'events', 'text'>): Observable<HttpEvent<string>>;
  post(url: string, body: any | null, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<unknown>>;
  post<T>(url: string, body: any | null, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<T>>;
  post(url: string, body: any | null, options: HttpOptions<'response', 'arraybuffer'>): Observable<HttpResponse<ArrayBuffer>>;
  post(url: string, body: any | null, options: HttpOptions<'response', 'blob'>): Observable<HttpResponse<Blob>>;
  post(url: string, body: any | null, options: HttpOptions<'response', 'text'>): Observable<HttpResponse<string>>;
  post(url: string, body: any | null, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<unknown>>;
  post<T>(url: string, body: any | null, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<T>>;
  post(url: string, body: any | null, options?: any): Observable<any> {
    return this.log(this.http.post(url, body, options), body);
  }

  delete(url: string, options: HttpOptions<'body', 'arraybuffer'>): Observable<ArrayBuffer>;
  delete(url: string, options: HttpOptions<'body', 'blob'>): Observable<Blob>;
  delete(url: string, options: HttpOptions<'body', 'text'>): Observable<string>;
  delete(url: string, options?: HttpOptions<'body', 'json'>): Observable<unknown>;
  delete<T>(url: string, options?: HttpOptions<'body', 'json'>): Observable<T>;
  delete(url: string, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpEvent<ArrayBuffer>>;
  delete(url: string, options: HttpOptions<'events', 'blob'>): Observable<HttpEvent<Blob>>;
  delete(url: string, options: HttpOptions<'events', 'text'>): Observable<HttpEvent<string>>;
  delete(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<unknown>>;
  delete<T>(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<T>>;
  delete(url: string, options: HttpOptions<'response', 'arraybuffer'>): Observable<HttpResponse<ArrayBuffer>>;
  delete(url: string, options: HttpOptions<'response', 'blob'>): Observable<HttpResponse<Blob>>;
  delete(url: string, options: HttpOptions<'response', 'text'>): Observable<HttpResponse<string>>;
  delete(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<unknown>>;
  delete<T>(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<T>>;
  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(url, options);
  }

  head(url: string, options: HttpOptions<'body', 'arraybuffer'>): Observable<ArrayBuffer>;
  head(url: string, options: HttpOptions<'body', 'blob'>): Observable<Blob>;
  head(url: string, options: HttpOptions<'body', 'text'>): Observable<string>;
  head(url: string, options?: HttpOptions<'body', 'json'>): Observable<unknown>;
  head<T>(url: string, options?: HttpOptions<'body', 'json'>): Observable<T>;
  head(url: string, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpEvent<ArrayBuffer>>;
  head(url: string, options: HttpOptions<'events', 'blob'>): Observable<HttpEvent<Blob>>;
  head(url: string, options: HttpOptions<'events', 'text'>): Observable<HttpEvent<string>>;
  head(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<unknown>>;
  head<T>(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<T>>;
  head(url: string, options: HttpOptions<'response', 'arraybuffer'>): Observable<HttpResponse<ArrayBuffer>>;
  head(url: string, options: HttpOptions<'response', 'blob'>): Observable<HttpResponse<Blob>>;
  head(url: string, options: HttpOptions<'response', 'text'>): Observable<HttpResponse<string>>;
  head(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<unknown>>;
  head<T>(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<T>>;
  head(url: string, options?: any): Observable<any> {
    return this.http.head(url, options);
  }

  options(url: string, options: HttpOptions<'body', 'arraybuffer'>): Observable<ArrayBuffer>;
  options(url: string, options: HttpOptions<'body', 'blob'>): Observable<Blob>;
  options(url: string, options: HttpOptions<'body', 'text'>): Observable<string>;
  options(url: string, options?: HttpOptions<'body', 'json'>): Observable<unknown>;
  options<T>(url: string, options?: HttpOptions<'body', 'json'>): Observable<T>;
  options(url: string, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpEvent<ArrayBuffer>>;
  options(url: string, options: HttpOptions<'events', 'blob'>): Observable<HttpEvent<Blob>>;
  options(url: string, options: HttpOptions<'events', 'text'>): Observable<HttpEvent<string>>;
  options(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<unknown>>;
  options<T>(url: string, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<T>>;
  options(url: string, options: HttpOptions<'response', 'arraybuffer'>): Observable<HttpResponse<ArrayBuffer>>;
  options(url: string, options: HttpOptions<'response', 'blob'>): Observable<HttpResponse<Blob>>;
  options(url: string, options: HttpOptions<'response', 'text'>): Observable<HttpResponse<string>>;
  options(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<unknown>>;
  options<T>(url: string, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<T>>;
  options(url: string, options?: any): Observable<any> {
    return this.http.options(url, options);
  }

  patch(url: string, body: any | null, options: HttpOptions<'body', 'arraybuffer'>): Observable<ArrayBuffer>;
  patch(url: string, body: any | null, options: HttpOptions<'body', 'blob'>): Observable<Blob>;
  patch(url: string, body: any | null, options: HttpOptions<'body', 'text'>): Observable<string>;
  patch(url: string, body: any | null, options?: HttpOptions<'body', 'json'>): Observable<unknown>;
  patch<T>(url: string, body: any | null, options?: HttpOptions<'body', 'json'>): Observable<T>;
  patch(url: string, body: any | null, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpEvent<ArrayBuffer>>;
  patch(url: string, body: any | null, options: HttpOptions<'events', 'blob'>): Observable<HttpEvent<Blob>>;
  patch(url: string, body: any | null, options: HttpOptions<'events', 'text'>): Observable<HttpEvent<string>>;
  patch(url: string, body: any | null, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<unknown>>;
  patch<T>(url: string, body: any | null, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<T>>;
  patch(url: string, body: any | null, options: HttpOptions<'response', 'arraybuffer'>): Observable<HttpResponse<ArrayBuffer>>;
  patch(url: string, body: any | null, options: HttpOptions<'response', 'blob'>): Observable<HttpResponse<Blob>>;
  patch(url: string, body: any | null, options: HttpOptions<'response', 'text'>): Observable<HttpResponse<string>>;
  patch(url: string, body: any | null, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<unknown>>;
  patch<T>(url: string, body: any | null, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<T>>;
  patch(url: string, body: any | null, options?: any): Observable<any> {
    return this.log(this.http.patch(url, body, options), body);
  }

  put(url: string, body: any | null, options: HttpOptions<'body', 'arraybuffer'>): Observable<ArrayBuffer>;
  put(url: string, body: any | null, options: HttpOptions<'body', 'blob'>): Observable<Blob>;
  put(url: string, body: any | null, options: HttpOptions<'body', 'text'>): Observable<string>;
  put(url: string, body: any | null, options?: HttpOptions<'body', 'json'>): Observable<unknown>;
  put<T>(url: string, body: any | null, options?: HttpOptions<'body', 'json'>): Observable<T>;
  put(url: string, body: any | null, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpEvent<ArrayBuffer>>;
  put(url: string, body: any | null, options: HttpOptions<'events', 'blob'>): Observable<HttpEvent<Blob>>;
  put(url: string, body: any | null, options: HttpOptions<'events', 'text'>): Observable<HttpEvent<string>>;
  put(url: string, body: any | null, options: HttpOptions<'events', 'json'>): Observable<HttpEvent<unknown>>;
  put<T>(url: string, body: any | null, options: HttpOptions<'events', 'json'>): Observable<HttpResponse<T>>;
  put(url: string, body: any | null, options: HttpOptions<'events', 'arraybuffer'>): Observable<HttpResponse<ArrayBuffer>>;
  put(url: string, body: any | null, options: HttpOptions<'response', 'blob'>): Observable<HttpResponse<Blob>>;
  put(url: string, body: any | null, options: HttpOptions<'response', 'text'>): Observable<HttpResponse<string>>;
  put(url: string, body: any | null, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<unknown>>;
  put<T>(url: string, body: any | null, options: HttpOptions<'response', 'json'>): Observable<HttpResponse<T>>;
  put(url: string, body: any | null, options?: any): Observable<any> {
    return this.log(this.http.put(url, body, options), body);
  }

  private log<T, OBS extends Observable<T>>(obs: OBS, data: any): OBS {
    return obs.pipe(
      tap({
        error: (err: HttpErrorResponse | any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 400 || err.status >= 500) {
              const forbiddenProperties = ['password', 'token'];

              // If data is shaped as a query string and not an object, extract properties into an object.
              data = typeof data === 'object' ? data : Object.fromEntries(new URLSearchParams(data).entries());

              const cloneData = JSON.stringify(data, (k, v) =>
                forbiddenProperties.some(x => k.toLowerCase().includes(x)) ? '<redacted>' : v
              );

              this.appInsightsService.trackTrace(cloneData);
            }
          }
        },
      })
    ) as OBS;
  }
}
