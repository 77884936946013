import { Injectable, inject } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store, createFeatureSelector, select } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, debounceTime, filter, groupBy, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { OperationType, createSubmitFinishAction, isSaveAction, type SaveAction, type SaveState } from './save.actions';

@Injectable()
export class SaveEffects {
  private readonly anyActions$ = inject(Actions);
  private readonly store = inject(Store<any>);

  correlationId$ = createEffect(() =>
    this.anyActions$.pipe(
      filter(action => isSaveAction(action)),
      filter(
        (action: SaveAction<string>) =>
          action.operationType === OperationType.ServerRespondsSuccess || action.operationType === OperationType.ServerRespondsFail
      ),
      groupBy(action => action.storeCode),
      mergeMap(grp =>
        grp.pipe(
          concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(createFeatureSelector<SaveState>(action.storeCode)))))),
          filter(([action, store]) => store._submitCounter === 0),
          debounceTime(500)
        )
      ),
      concatMap(([action, store]) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(createFeatureSelector<SaveState>(action.storeCode)))))
      ),
      filter(([action, store]) => store._submitCounter === 0),
      map(([action, store]) => {
        return createSubmitFinishAction(action.storeCode)();
      })
    )
  );
}
