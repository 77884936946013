import { Injectable, inject } from '@angular/core';
import { type SiteSystemBehavior, type SiteSystemWording } from '@models/cards/site-system-information';
import { map, type Observable } from 'rxjs';
import { ContractorSetting, ContractorSettingsService } from './contractor-settings.service';

@Injectable()
export class SiteSystemBehaviorService {
  private readonly contractorSettingsService = inject(ContractorSettingsService);

  getSiteSystemBehavior(): Observable<SiteSystemBehavior> {
    return this.contractorSettingsService.getMultiple(ContractorSetting.SITESYSTEM_BEHAVIOR).pipe(
      map(settings => {
        if (settings?.[ContractorSetting.SITESYSTEM_BEHAVIOR]) {
          return JSON.parse(settings[ContractorSetting.SITESYSTEM_BEHAVIOR]);
        }

        throw new Error("We don't have a site system behavior.");
      })
    );
  }

  getLocalizedSiteSystemBehavior(): Observable<{ wording: SiteSystemWording }> {
    const defaultLanguage = 'en';
    const currentLanguage = 'en'; // Change when we support multiple languages.
    return this.getSiteSystemBehavior().pipe(
      map(siteSystemBehavior => ({
        wording: siteSystemBehavior.wordings[currentLanguage] ?? siteSystemBehavior.wordings[defaultLanguage],
      }))
    );
  }
}
