import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  template: '<a [ngClass]="className()" [routerLink]="routerLink()">{{ text() }}</a>',
  styles: `
    @import 'variables';
    a.important {
      color: $color-tertiary-warm-accent;
      @include font-semibold();
    }
  `,
  imports: [CommonModule, RouterModule],
  standalone: true,
})
export class TableDynamicNodeLinkComponent {
  readonly routerLink = input<string | any[]>([]);
  readonly text = input<string>('');
  readonly className = input<Record<string, boolean>>();
}
