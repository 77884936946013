import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { type EmailAddress } from '@models/email-models';

@Pipe({
  name: 'email',
  standalone: true,
})
@Injectable()
export class EmailPipe implements PipeTransform {
  transform(email: EmailAddress | string | null | undefined): string {
    if (!email) {
      return '';
    }

    if (this.isEmailAddress(email)) {
      if (email.displayName) {
        return `${email.displayName} <${email.text}>`;
      }

      return email.text ?? '';
    }

    return email;
  }

  private isEmailAddress(email: EmailAddress | string): email is EmailAddress {
    return !!(email as any).text;
  }
}
