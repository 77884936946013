import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

// Angular 18
@Component({
  selector: 'button[wm-button]',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
  host: {
    '[disabled]': 'disabled() || loading()',
    '[class.round]': 'round()',
  },
})
export class ButtonComponent {
  readonly disabled = input(false);
  readonly loading = input(false);
  readonly imageUrl = input<string | undefined>();
  readonly matIcon = input<string | undefined>();
  readonly fontSet = input<string>('material-symbols-rounded');
  readonly round = input(false);
  readonly imageWidth = input<number | undefined>();
  readonly imageHeight = input<number | undefined>();
  readonly textMaxWidth = input<number>();
}
