import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BackdropType, MENU_OVERLAY_DATA, POPOVER_OVERLAY_DATA, type PopoverButton } from '@models/dialog';
import { SharedModule } from '@modules/shared.module';
import { newGuid } from '@utility/string';
import { sortBy } from 'lodash';

interface Group {
  id: string;
  name: string;
  order: number;
  buttons: PopoverButton[];
}

@Component({
  templateUrl: 'dialog-menu.component.html',
  styleUrls: ['dialog-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MatDialogModule],
})
export class DialogMenuComponent {
  readonly BackdropType = BackdropType;

  private readonly popoverOverlayData = inject(POPOVER_OVERLAY_DATA);
  readonly data = inject(MENU_OVERLAY_DATA);

  readonly groups = computed<Group[]>(() => {
    const groups = new Map<string, Group>();
    for (const button of this.data.buttons) {
      const groupCode = button.groupCode ?? '';
      let group = groups.get(groupCode);
      if (!group) {
        const foundGroup = this.data.groups?.find(n => n.code === groupCode);
        group = {
          id: newGuid(),
          name: foundGroup?.name ?? '',
          order: foundGroup?.order ?? 0,
          buttons: [],
        };
        groups.set(groupCode, group);
      }

      group.buttons.push(button);
    }

    const finalGroups = sortBy(
      Array.from(groups.values()),
      m => m.order,
      m => m.name
    );
    return finalGroups;
  });

  internalClick(button: PopoverButton): void {
    button.click();
    this.popoverOverlayData.overlayRef.dispose();
  }
}
