import { type PartItemInformationState } from '@store/part-item-detail/part-item-detail.reducer';
import { type AgreementInformation } from './cards/agreement-information';
import { type ContractorServiceRepairOverride } from './contractor-service-repair-override';
import { type Resource, type ResourceWithChildren, type StateResource } from './resource';

export enum BookType {
  Service = 1,
  Sales = 2,
  Consumable = 3,
  Distributors = 4,
}

export enum DropdownEntryType {
  CallReason = 1,
  CallWorkOrderType = 2,
  FilterType = 3,
  PaymentMethod = 4,
  ReferralType = 5,
  CallDepartmentType = 6,
  PaymentType = 7,
  DutyCategory = 8,
  SystemType = 9,
}

export function isWebBook(bookType: BookType): boolean {
  return bookType === BookType.Service || bookType === BookType.Sales || bookType === BookType.Consumable;
}

export function isDistributorBook(bookType: BookType): boolean {
  return bookType === BookType.Distributors;
}

export interface PriceBookInformation extends Resource {
  type: BookType;
  distributorId: Id | null;
  rate: Fraction;
  availableOnIPad: boolean;
}

export interface IdWithOrder {
  id: Id;
  order: number | null;
}

export interface PriceBookCategoryInformation extends ResourceWithChildren<PriceBookCategoryInformation> {
  parentPriceBookCategoryId: number | null;
  priceBookId: Id;
  guid?: string;
  serviceRepairIds: IdWithOrder[];
  partItemIds: IdWithOrder[];
}

export enum PricingMethod {
  Price = 1,
  Labor,
  Custom,
}

export interface ServiceRepairInformation extends StateResource {
  laborTime: number | null;
  price: Money | null;
  priceBookId: Id;
  useNonStockPricing: boolean;
  catalogUrl: string | null;
  partItemIds: Id[];
  contractorId: number | null;
  contractorServiceRepairOverrides: ContractorServiceRepairOverride[] | null;
}

export interface PartItemInformation extends StateResource {
  originalName: string;
  manufacturer: string;
  description: string;
  catalogUrl: string;
  costPrice: Money | null; // This is in sync with the computedPrice.
  computedPrice: Money | null; // Not editable, this is the price after it went through the matrix.
  retailPrice: Money | null;
  manualReplenishment: boolean;
  distributorId: Id;
  code: string;
}

export interface PriceBookReadViewModel {
  priceBook: PriceBookViewModel;
  agreementOptions: AgreementInformation[];
  propertyTypeOptions: StateResource[];
  rateTypeOptions: StateResource[];
  hasAgreements: boolean;
}

export interface PriceBookViewModel extends PriceBookInformation {
  categories: PriceBookCategoryInformation[];
  serviceRepairs: ServiceRepairInformation[];
  partItems: PartItemInformation[];
  displayModifiers: boolean;
}

export interface ServiceRepairDetailViewData {
  serviceRepair: ServiceRepairInformation;
  categoryIds: Id[];
}

export interface PartItemDetailViewData {
  partItem: PartItemInformationState;
  categoryIds: Id[];
}

export function getPricingMethodText(pricingMethod: PricingMethod): 'Labor Time' | 'Price' | 'Custom' {
  switch (pricingMethod) {
    case PricingMethod.Labor:
      return 'Labor Time';
    case PricingMethod.Price:
      return 'Price';
    case PricingMethod.Custom:
      return 'Custom';
  }
}

export function createEmpty(): PriceBookInformation {
  return {
    id: 0,
    availableOnIPad: false,
    distributorId: null,
    rate: 0,
    type: BookType.Service,
  };
}

export function createEmptyServiceRepair(): ServiceRepairInformation {
  return {
    id: 0,
    laborTime: null,
    price: null,
    priceBookId: 0,
    useNonStockPricing: false,
    catalogUrl: null,
    partItemIds: [],
    contractorId: null,
    contractorServiceRepairOverrides: null,
  };
}
export function createEmptyPartItem(): PartItemInformation {
  return {
    id: 0,
    originalName: '',
    manufacturer: '',
    description: '',
    catalogUrl: '',
    costPrice: null,
    computedPrice: null,
    retailPrice: null,
    manualReplenishment: false,
    distributorId: 0,
    code: '',
  };
}

export function createEmptyPriceBookCategory(): PriceBookCategoryInformation {
  return {
    id: 0,
    parentPriceBookCategoryId: null,
    priceBookId: 0,
    serviceRepairIds: [],
    partItemIds: [],
  };
}
