import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, type OnInit } from '@angular/core';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { InputImageComponent } from '@controls/input-image/input-image.component';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { type AgreementInformation } from '@models/cards/agreement-information';
import { FILTER_DIALOG_DATA } from '@models/dialog';
import { FilterData } from '@models/filter-models';
import { type Resource } from '@models/resource';
import { SharedModule } from '@modules/shared.module';
import { AgreementsService } from '@services/live/agreements.service';
import { getVisibleOnly } from '@utility/observable';
import { endOfDay } from 'date-fns';
import { FilterComponent } from './filter.component';

/** @deprecated */
@Component({
  templateUrl: 'filter-agreement-site-system.component.html',
  styleUrls: ['filter-agreement-site-system.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, InputMomentPickerComponent, InputDropdownComponent, CheckboxComponent, InputImageComponent],
})
export class FilterAgreementSiteSystemComponent extends FilterComponent implements OnInit {
  private readonly agreementsService = inject(AgreementsService);
  private readonly cd = inject(ChangeDetectorRef);

  _datePipe: DatePipe;

  constructor(
    @Inject(FILTER_DIALOG_DATA)
    data: FilterData
  ) {
    super(data);
    this._datePipe = new DatePipe('en-US');
  }

  statuses: Resource[] = [
    { id: 5, text: this.getStatusDisplay(5) },
    { id: 1, text: this.getStatusDisplay(1) },
    { id: 2, text: this.getStatusDisplay(2) },
    { id: 3, text: this.getStatusDisplay(3) },
    { id: 4, text: this.getStatusDisplay(4) },
  ];

  processedOptions: Resource[] = [
    { id: -1, text: this.getProcessedDisplay(0) },
    { id: 1, text: this.getProcessedDisplay(1) },
    { id: 2, text: this.getProcessedDisplay(2) },
  ];

  agreements: AgreementInformation[] = [];
  _createdDate = 0;
  _nextDate = 0;

  ngOnInit(): void {
    this.agreementsService
      .list()
      .pipe(getVisibleOnly())
      .subscribe(m => {
        this.agreements = m;
        this.agreementId = this.agreementId; // We assign the correct data.
        this.cd.markForCheck();
      });
  }

  get createdDate(): number {
    return this._createdDate;
  }

  set createdDate(value: number) {
    this._createdDate = value;
    if (value === 1 && (this.createdDateFrom || this.createdDateEnd)) {
      this.setValue('createdDate', this.getDateDisplay('Created Between Range', this.createdDateFrom, this.createdDateEnd), 1);
    } else {
      this.removeValue('createdDate');
    }
  }

  get createdDateFrom(): Date | undefined {
    return this.getValue('createdDateFrom');
  }

  set createdDateFrom(value: Date | undefined) {
    if (value) {
      this.setValue('createdDateFrom', undefined, value);
    } else {
      this.removeValue('createdDateFrom');
    }

    this.createdDate = 1;
  }

  get createdDateEnd(): Date | undefined {
    return this.getValue('createdDateEnd');
  }

  set createdDateEnd(value: Date | undefined) {
    if (value) {
      this.setValue('createdDateEnd', undefined, endOfDay(value));
    } else {
      this.removeValue('createdDateEnd');
    }

    this.createdDate = 1;
  }

  get nextDate(): number {
    return this._nextDate;
  }

  set nextDate(value: number) {
    this._nextDate = value;
    if (value === 1 && (this.nextDateFrom || this.nextDateEnd)) {
      this.setValue('nextDate', this.getDateDisplay('Next Tune-Up Between Range', this.nextDateFrom, this.nextDateEnd), 1);
    } else {
      this.removeValue('nextDate');
    }
  }

  get nextDateFrom(): Date | undefined {
    return this.getValue('nextDateFrom');
  }

  set nextDateFrom(value: Date | undefined) {
    if (value) {
      this.setValue('nextDateFrom', undefined, value);
    } else {
      this.removeValue('nextDateFrom');
    }

    this.nextDate = 1;
  }

  get nextDateEnd(): Date | undefined {
    return this.getValue('nextDateEnd');
  }

  set nextDateEnd(value: Date | undefined) {
    if (value) {
      this.setValue('nextDateEnd', undefined, endOfDay(value));
    } else {
      this.removeValue('nextDateEnd');
    }

    this.nextDate = 1;
  }

  get status(): number {
    return this.getValue('status') || -1;
  }

  set status(value: number) {
    if (value > 0) {
      this.setValue('status', this.getStatusDisplay(value), value);
    } else {
      this.removeValue('status');
    }
  }

  get processed(): number {
    return this.getValue('processed') || -1;
  }

  set processed(value: number) {
    if (value > 0) {
      this.setValue('processed', this.getProcessedDisplay(value), value);
    } else {
      this.removeValue('processed');
    }
  }

  get expiring(): boolean {
    return !!this.getValue('expiring');
  }

  set expiring(value: boolean) {
    if (value) {
      this.setValue('expiring', 'Expiring', value);
    } else {
      this.removeValue('expiring');
    }
  }

  get deleted(): boolean {
    return !!this.getValue('deleted');
  }

  set deleted(value: boolean) {
    if (value) {
      this.setValue('deleted', 'Show Deleted', value);
    } else {
      this.removeValue('deleted');
    }
  }

  get recurring(): boolean {
    return !!this.getValue('recurring');
  }

  set recurring(value: boolean) {
    if (value) {
      this.setValue('recurring', 'Recurring Only', value);
    } else {
      this.removeValue('recurring');
    }
  }

  get inactiveSubscriptions(): boolean {
    return !!this.getValue('inactiveSubscriptions');
  }

  set inactiveSubscriptions(value: boolean) {
    if (value) {
      this.setValue('inactiveSubscriptions', 'Show Inactive Subscriptions', value);
    } else {
      this.removeValue('inactiveSubscriptions');
    }
  }

  get agreementId(): number | undefined {
    return this.getValue('agreementId');
  }

  set agreementId(value: number | undefined) {
    if (value) {
      this.setValue('agreementId', `Agreement: ${this.getAgreementName(value)}`, value);
    } else {
      this.removeValue('agreementId');
    }
  }

  getStatusDisplay(value: number): string | undefined {
    switch (value) {
      case 1:
        return 'Grandfathered';
      case 2:
        return 'Unprocessed Work Order Agreements';
      case 3:
        return 'Ready for Set Up';
      case 4:
        return 'Activated';
      case 5:
        return 'Current Active Agreements';
    }
  }

  getProcessedDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Unprocessed';
      case 2:
        return 'Processed';
    }
  }

  getDateDisplay(words: string, dateFrom: Date | undefined, dateEnd: Date | undefined): string | undefined {
    let range = '';
    if (dateFrom && dateEnd) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')} to ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    } else if (dateFrom) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')}`;
    } else if (dateEnd) {
      range = `until ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    }

    return `${words}: ${range}`;
  }

  getAgreementName(value: Id): string {
    if (this.agreements) {
      const agreement = this.agreements.find(m => m.id === value);
      if (agreement) {
        return agreement.text ?? '';
      }
    }

    return '';
  }

  filtersChanged(): void {
    let date = this.getValue('nextDate');
    if (typeof date === 'number') {
      this._nextDate = date;
    } else if (date == null) {
      this._nextDate = 0;
    }

    date = this.getValue('createdDate');
    if (typeof date === 'number') {
      this._createdDate = date;
    } else if (date == null) {
      this._createdDate = 0;
    }
  }
}
