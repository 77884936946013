import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { ButtonComponent } from '@controls/button/button.component';
import { DIALOG_SERVICE_IMPL } from '@models/dialog';
import { type FilterTable2 } from '@services/pager.service';
import { ColumnChooserComponent } from './column-chooser.component';

@Component({
  selector: 'wm-column-dynamic',
  templateUrl: 'column-dynamic.component.html',
  styleUrl: 'column-dynamic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ButtonComponent],
})
export class ColumnDynamicComponent<T> {
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);

  readonly columns = input.required<FilterTable2<T>['columns']>();

  columnChangeClick(): void {
    ColumnChooserComponent.open(this.dialog2, {
      availableColumns: this.columns().available(),
      selectedColumns: this.columns()
        .requested()
        .map(column => column.id),
    }).subscribe(result => {
      if (result) {
        this.columns().change(result);
      }
    });
  }
}
