import { Component, input } from '@angular/core';
import { getAgreementBadgeTitle, getAgreementBadgeUrl, getVisitLeft } from '@cards/agreements/agreement-site-system-list.component';
import { type AgreementSiteSystemInformationForList } from '@models/cards/agreement-site-system-information';

@Component({
  template: `@if (line(); as line) {
    @if (getVisitLeft(line) !== null) {
      <span class="status-dot">
        <img src="{{ getAgreementBadgeUrl(line) }}" alt="" title="{{ getAgreementBadgeTitle(line) }}" />
      </span>
      {{ getVisitLeft(line) }} left
    }
  }`,
  standalone: true,
})
export class TableDynamicNodeAgreementRemainingTuneUpComponent {
  readonly getVisitLeft = getVisitLeft;
  readonly getAgreementBadgeUrl = getAgreementBadgeUrl;
  readonly getAgreementBadgeTitle = getAgreementBadgeTitle;

  readonly line = input<AgreementSiteSystemInformationForList>();
}
