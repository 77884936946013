import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { type ActionReducer, StoreModule } from '@ngrx/store';
import { saveReducerWithCode } from '@services/save-store/save.actions';
import { PartItemDetailEffects } from './part-item-detail.effects';
import { reducer as partItemDetailReducer } from './part-item-detail.reducer';
import { PART_ITEM_DETAIL_STORE_KEY } from './part-item-detail.selectors';

export function reducer(r: ActionReducer<any>): ActionReducer<any> {
  return saveReducerWithCode(PART_ITEM_DETAIL_STORE_KEY)(r);
}

export const metaReducers = [reducer];

@NgModule({
  imports: [
    StoreModule.forFeature(PART_ITEM_DETAIL_STORE_KEY, partItemDetailReducer, { metaReducers }),
    EffectsModule.forFeature([PartItemDetailEffects]),
  ],
})
export class PartItemDetailStoreModule {}
