import { Injectable, Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: true,
})
@Injectable()
export class PhonePipe implements PipeTransform {
  private readonly commonPhone: RegExp = /^\(?[0-9]{3}\)?[- ]*[0-9]{3}-?[0-9]{4}$/;

  transform(phone: string | null | undefined): string {
    // Strips the spaces
    if (phone && this.commonPhone.test(phone)) {
      const phoneNumbersOnly = phone.replace(/[() -]/g, '');

      return '(' + phoneNumbersOnly.substr(0, 3) + ') ' + phoneNumbersOnly.substr(3, 3) + '-' + phoneNumbersOnly.substr(6);
    }

    return phone ?? '';
  }
}
