import { Directive, ElementRef, Input, inject, type OnDestroy, type OnInit } from '@angular/core';

interface Focusable {
  focus: () => any;
}

@Directive({
  selector: '[initialFocus]',
  standalone: true,
})
export class InitialFocusDirective implements OnInit, OnDestroy {
  private readonly ref = inject(ElementRef);

  @Input() initialFocus: Focusable | null = null;

  private destroyed = false;

  ngOnInit(): void {
    if (!this.destroyed) {
      // we focus after we ran the layout.
      try {
        setTimeout(() => {
          (this.initialFocus || this.ref.nativeElement).focus();
        }, 100);
      } catch (e) {}
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }
}
