import { CommonModule } from '@angular/common';
import { Component, type Provider, TemplateRef, computed, contentChild, forwardRef, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputImageComponent } from '@controls/input-image/input-image.component';
import { Rw2Component } from '@controls/rw/rw2.component';
import { Mode } from '@models/form';
import { AsyncSafePipe } from '@pipes/async-safe.pipe';
import { StaticDataService } from '@services/static-data.service';
import { shareReplay } from 'rxjs/operators';

const RW_TECHNICIAN_COMPONENT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RwTechnician2Component),
  multi: true,
};

// Angular 18
@Component({
  selector: 'wm-rw-technician2',
  templateUrl: 'rw-technician2.component.html',
  styleUrls: ['rw-technician.component.scss'],
  providers: [RW_TECHNICIAN_COMPONENT_VALUE_ACCESSOR],
  exportAs: 'rwTechnician',
  standalone: true,
  host: {
    '[class.rw]': 'true',
  },
  imports: [CommonModule, ReactiveFormsModule, Rw2Component, InputImageComponent, AsyncSafePipe],
})
export class RwTechnician2Component extends ElementBaseWithFormControl<Id | null> {
  readonly Mode = Mode;

  private readonly staticData = inject(StaticDataService);

  readonly name = input<string | undefined>('Technician');
  readonly mode = input(Mode.Read);
  readonly required = input(false);
  readonly clearable = input(false);

  readonly options = toSignal(this.staticData.getTechnicians().pipe(shareReplay(1)), { initialValue: [] });
  private readonly signalValue = toSignal(this.innerValue);
  readonly selectedOptions = computed(() => {
    const signalValue = this.signalValue();
    return this.options().find(m => m.id === signalValue);
  });

  readonly helperTemplate2 = contentChild('helperTemplate', { read: TemplateRef });

  constructor() {
    super(() => null);
  }
}
