import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Common from '@microsoft/applicationinsights-common';
import { ApplicationInsights, type IConfiguration, type SeverityLevel } from '@microsoft/applicationinsights-web';
import AppInsightAngularPlugin from './app-insight-angular-plugin';
import { isSandbox } from '@utility/sandbox';

interface PaveVewTelemetryProperties {
  duration?: number;
  [key: string]: any;
}

const sandbox = isSandbox();

@Injectable()
export class AppInsightsService {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  private appInsights?: ApplicationInsights;
  private userId?: string;
  private impersonator?: string;

  Init(config: IConfiguration & Common.IConfig): void {
    const appInsightAngularPlugin = new AppInsightAngularPlugin();

    if (config?.instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          ...config,
          extensions: (config?.extensions ?? []).slice(0).concat(appInsightAngularPlugin),
          extensionConfig: {
            ...config?.extensionConfig,
            [appInsightAngularPlugin.identifier]: { router: this.router, activatedRoute: this.activatedRoute },
          },
        },
      });
      this.appInsights.loadAppInsights();

      // We drop the telemetry for the negotiate
      const negotiatePaths = ['/notifications/negotiate', '/serverpush/negotiate'];
      this.appInsights.appInsights.addTelemetryInitializer(item => {
        if (Common.RemoteDependencyData.dataType === item.baseType) {
          if (item.baseData) {
            for (const negotiatePath of negotiatePaths) {
              if (item.baseData.name.indexOf(negotiatePath) >= 0) {
                return false;
              }
            }
          }
        }

        return true;
      });

      // We mark the page view if we are impersonating.
      this.appInsights.appInsights.addTelemetryInitializer(item => {
        if (Common.PageView.dataType === item.baseType) {
          if (this.impersonator) {
            item.data = {
              ...item.data,
              isImpersonating: true,
              impersonator: this.impersonator,
            };
          }
        }

        item.data = {
          ...item.data,
          sandbox,
        };

        return true;
      });

      if (this.userId) {
        this.setAuthenticatedUserContext(this.userId);
      }
    }
  }

  trackPageView(name?: string, uri?: string, properties?: PaveVewTelemetryProperties): void {
    this.appInsights?.trackPageView({
      name,
      uri,
      properties,
    });
  }

  trackEvent(name: string, properties?: Record<string, any>): void {
    this.appInsights?.trackEvent({ name }, properties);
  }

  trackMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number, properties?: Record<string, any>): void {
    this.appInsights?.trackMetric(
      {
        name,
        average,
        sampleCount,
        min,
        max,
      },
      properties
    );
  }

  trackException(exception: Error, properties?: Record<string, any>, severityLevel?: SeverityLevel | number): void {
    this.appInsights?.trackException({
      exception,
      severityLevel,
      properties,
    });
  }

  trackTrace(message: string, properties?: Record<string, any>, severityLevel?: SeverityLevel | number): void {
    this.appInsights?.trackTrace(
      {
        message,
        severityLevel,
      },
      properties
    );
  }

  flush(): void {
    this.appInsights?.flush();
  }

  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string): void {
    this.userId = authenticatedUserId;

    this.appInsights?.setAuthenticatedUserContext(authenticatedUserId, accountId, false);
  }

  setImpersonator(impersonator: string | null | undefined): void {
    this.impersonator = impersonator ?? undefined;
  }

  clearAuthenticatedUserContext(): void {
    this.appInsights?.clearAuthenticatedUserContext();
  }
}

