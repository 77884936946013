import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, type OnInit } from '@angular/core';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { InputImageComponent } from '@controls/input-image/input-image.component';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { RwDropdown2Component } from '@controls/rw-dropdown/rw-dropdown2.component';
import { type CallDepartmentTypeInformation } from '@models/call-department-type-models';
import { type CallWorkOrderTypeInformation } from '@models/call-work-order-type-models';
import { FILTER_DIALOG_DATA } from '@models/dialog';
import { FilterData } from '@models/filter-models';
import { type Resource } from '@models/resource';
import { type TechnicianInformation } from '@models/technician';
import { SharedModule } from '@modules/shared.module';
import { CallDepartmentTypesService } from '@services/live/call-department-types.service';
import { CallWorkOrderTypesService } from '@services/live/call-work-order-types.service';
import { StaticDataService } from '@services/static-data.service';
import { endOfDay } from 'date-fns';
import { FilterComponent } from './filter.component';

/** @deprecated */
@Component({
  templateUrl: 'filter-work-order.component.html',
  styleUrls: ['filter-work-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, InputMomentPickerComponent, InputImageComponent, InputDropdownComponent, RwDropdown2Component, CheckboxComponent],
})
export class FilterWorkOrderComponent extends FilterComponent implements OnInit {
  private readonly staticData = inject(StaticDataService);
  private readonly callDepartmentTypesService = inject(CallDepartmentTypesService);
  private readonly callWorkOrderTypesService = inject(CallWorkOrderTypesService);
  private readonly cd = inject(ChangeDetectorRef);

  _datePipe: DatePipe;
  invoiceOptions: Resource[] = [
    { id: -1, text: this.getInvoiceDisplay(0) },
    { id: 1, text: this.getInvoiceDisplay(1) },
    { id: 2, text: this.getInvoiceDisplay(2) },
  ];

  happyCallOptions: Resource[] = [
    { id: -1, text: this.getHappyCallDisplay(0) },
    { id: 1, text: this.getHappyCallDisplay(1) },
    { id: 2, text: this.getHappyCallDisplay(2) },
  ];

  debriefOptions: Resource[] = [
    { id: -1, text: this.getDebriefDisplay(0) },
    { id: 1, text: this.getDebriefDisplay(1) },
    { id: 2, text: this.getDebriefDisplay(2) },
  ];

  completionOptions: Resource[] = [
    { id: -1, text: this.getCompletionDisplay(0) },
    { id: 1, text: this.getCompletionDisplay(1) },
    { id: 2, text: this.getCompletionDisplay(2) },
  ];

  agreementSoldOptions: Resource[] = [
    { id: -1, text: this.getAgreementSoldDisplay(0) },
    { id: 1, text: this.getAgreementSoldDisplay(1) },
    { id: 2, text: this.getAgreementSoldDisplay(2) },
  ];

  statuses: Resource[] = [
    { id: -1, text: this.getStatusDisplay(0) },
    { id: 1, text: this.getStatusDisplay(1) },
    { id: 2, text: this.getStatusDisplay(2) },
    { id: 3, text: this.getStatusDisplay(3) },
  ];

  balanceTypeOptions: Resource[] = [
    { id: -1, text: this.getBalanceTypeDisplay(0) },
    { id: 1, text: this.getBalanceTypeDisplay(1) },
    { id: 2, text: this.getBalanceTypeDisplay(2) },
    { id: 3, text: this.getBalanceTypeDisplay(3) },
  ];

  technicians: TechnicianInformation[] = [];
  callDepartmentTypes: CallDepartmentTypeInformation[] = [];
  callWorkOrderTypes: CallWorkOrderTypeInformation[] = [];
  _date = 0;

  constructor(@Inject(FILTER_DIALOG_DATA) data: FilterData) {
    super(data);
    this._datePipe = new DatePipe('en-US');
  }

  ngOnInit(): void {
    this.staticData.getTechnicians().subscribe(m => {
      this.technicians = m;
      // eslint-disable-next-line no-self-assign
      this.technician = this.technician; // We assign the correct data.
      this.cd.markForCheck();
    });

    this.callDepartmentTypesService.list().subscribe(m => {
      this.callDepartmentTypes = [{ id: -1, text: 'All' }, ...m];

      // eslint-disable-next-line no-self-assign
      this.callDepartmentType = this.callDepartmentType; // We assign the correct data.
      this.cd.markForCheck();
    });

    this.callWorkOrderTypesService.list().subscribe(m => {
      this.callWorkOrderTypes = [{ id: -1, order: -1, text: 'All' }, ...m];

      // eslint-disable-next-line no-self-assign
      this.callWorkOrderType = this.callWorkOrderType; // We assign the correct data.
      this.cd.markForCheck();
    });
  }

  get date(): number {
    return this._date;
  }

  set date(value: number) {
    this._date = value;
    if (value === 1 && (this.dateFrom || this.dateEnd)) {
      this.setValue('date', this.getDateDisplay(this.dateFrom, this.dateEnd), 1);
    } else {
      this.removeValue('date');
    }
  }

  get dateFrom(): Date | undefined {
    return this.getValue('dateFrom');
  }

  set dateFrom(value: Date | undefined) {
    if (value) {
      this.setValue('dateFrom', undefined, value);
    } else {
      this.removeValue('dateFrom');
    }

    this.date = 1;
  }

  get dateEnd(): Date | undefined {
    return this.getValue('dateEnd');
  }

  set dateEnd(value: Date | undefined) {
    if (value) {
      this.setValue('dateEnd', undefined, endOfDay(value));
    } else {
      this.removeValue('dateEnd');
    }

    this.date = 1;
  }

  get invoice(): number {
    return this.getValue('invoice') || -1;
  }

  set invoice(value: number) {
    if (value > 0) {
      this.setValue('invoice', `Invoice ${this.getInvoiceDisplay(value)}`, value);
    } else {
      this.removeValue('invoice');
    }
  }

  get happyCall(): number {
    return this.getValue('happyCall') || -1;
  }

  set happyCall(value: number) {
    if (value > 0) {
      this.setValue('happyCall', this.getHappyCallDisplay(value), value);
    } else {
      this.removeValue('happyCall');
    }
  }

  get debrief(): number {
    return this.getValue('debrief') || -1;
  }

  set debrief(value: number) {
    if (value > 0) {
      this.setValue('debrief', this.getDebriefDisplay(value), value);
    } else {
      this.removeValue('debrief');
    }
  }

  get completion(): number {
    return this.getValue('completion') || -1;
  }

  set completion(value: number) {
    if (value > 0) {
      this.setValue('completion', this.getCompletionDisplay(value), value);
    } else {
      this.removeValue('completion');
    }
  }

  get agreementSold(): number {
    return this.getValue('agreementSold') || -1;
  }

  set agreementSold(value: number) {
    if (value > 0) {
      this.setValue('agreementSold', `Agreement ${this.getAgreementSoldDisplay(value)}`, value);
    } else {
      this.removeValue('agreementSold');
    }
  }

  get balanceType(): number {
    return this.getValue('balanceType') || -1;
  }

  set balanceType(value: number) {
    if (value > 0) {
      this.setValue('balanceType', this.getBalanceTypeDisplay(value), value);
    } else {
      this.removeValue('balanceType');
    }
  }

  get excludeRecurringInvoice(): boolean {
    return !!this.getValue('excludeRecurringInvoice');
  }

  set excludeRecurringInvoice(value: boolean) {
    if (value) {
      this.setValue('excludeRecurringInvoice', 'Hide Recurring Invoices', value);
    } else {
      this.removeValue('excludeRecurringInvoice');
    }
  }

  get status(): number {
    return this.getValue('status') || -1;
  }

  set status(value: number) {
    if (value > 0) {
      this.setValue('status', this.getStatusDisplay(value), value);
    } else {
      this.removeValue('status');
    }
  }

  get technician(): number | undefined {
    return this.getValue('technicianId');
  }

  set technician(value: number | undefined) {
    if (value) {
      this.setValue('technicianId', `Technician: ${this.getTechnicianName(value)}`, value);
    } else {
      this.removeValue('technicianId');
    }
  }

  get callDepartmentType(): number | undefined {
    return this.getValue('callDepartmentTypeId') || -1;
  }

  set callDepartmentType(value: number | undefined) {
    if (value !== undefined && value > 0) {
      this.setValue('callDepartmentTypeId', `Department: ${this.getCallDepartmentTypeName(value)}`, value);
    } else {
      this.removeValue('callDepartmentTypeId');
    }
  }

  get callWorkOrderType(): number | undefined {
    return this.getValue('callWorkOrderTypeId') || -1;
  }

  set callWorkOrderType(value: number | undefined) {
    if (value !== undefined && value > 0) {
      this.setValue('callWorkOrderTypeId', `Type of Appointment: ${this.getCallWorkOrderTypeName(value)}`, value);
    } else {
      this.removeValue('callWorkOrderTypeId');
    }
  }

  getInvoiceDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Sent';
      case 2:
        return 'Not Sent';
    }
  }

  getDebriefDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Debriefed';
      case 2:
        return 'Not Debriefed';
    }
  }

  getHappyCallDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Made';
      case 2:
        return 'Avoided';
    }
  }

  getCompletionDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Complete';
      case 2:
        return 'Incomplete';
    }
  }

  getAgreementSoldDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Sold';
      case 2:
        return 'Not Sold';
    }
  }

  getBalanceTypeDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Empty';
      case 2:
        return 'Balance Due';
      case 3:
        return 'Paid in Full';
    }
  }

  getStatusDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Unprocessed';
      case 2:
        return 'Processed';
      case 3:
        return 'Unsubmitted';
    }
  }

  getDateDisplay(dateFrom: Date | undefined, dateEnd: Date | undefined): string | undefined {
    let range = '';
    if (dateFrom && dateEnd) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')} to ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    } else if (dateFrom) {
      range = `from ${this._datePipe.transform(dateFrom, 'shortDate')}`;
    } else if (dateEnd) {
      range = `until ${this._datePipe.transform(dateEnd, 'shortDate')}`;
    }

    return `Range: ${range}`;
  }

  getTechnicianName(value: Id): string {
    return this.technicians?.find(m => m.id === value)?.text ?? '';
  }

  getCallDepartmentTypeName(value: Id): string {
    return this.callDepartmentTypes?.find(m => m.id === value)?.text ?? '';
  }

  getCallWorkOrderTypeName(value: Id): string {
    return this.callWorkOrderTypes?.find(m => m.id === value)?.text ?? '';
  }

  filtersChanged(): void {
    const date = this.getValue('date');
    if (typeof date === 'number') {
      this._date = date;
    } else if (date == null) {
      this._date = 0;
    }

    this.cd.markForCheck();
  }
}
