// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  display: inline-block;
  min-height: 32px;
  background-color: #246ee9;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 12px;
  border-radius: 4px;
  border: 0;
  line-height: 24px;
  padding: 0 8px;
  cursor: pointer;
}
:host:hover {
  background-color: #1457c6;
}
:host:active {
  background-color: #0f4398;
}
:host[disabled] {
  opacity: 0.33;
  background-color: #246ee9;
}

:host.round {
  border-radius: 50%;
  width: 32px;
  padding: 0 4px;
}

:host.outline {
  color: #464b4f;
  background-color: #ffffff;
  border: 1px solid #d1d5d8;
}
:host.outline:hover {
  background-color: #f1f2f3;
}
:host.outline:active {
  background-color: #d6d9db;
}
:host.outline[disabled] {
  opacity: 0.33;
  background-color: #babfc4;
}

:host.secondary {
  background-color: #697076;
}
:host.secondary:hover {
  background-color: #51565b;
}
:host.secondary:active {
  background-color: #393d40;
}
:host.secondary[disabled] {
  opacity: 0.33;
  background-color: #697076;
}

:host.danger {
  background-color: #ff5633;
}
:host.danger:hover {
  background-color: #ff2c00;
}
:host.danger:active {
  background-color: #cc2300;
}
:host.danger[disabled] {
  opacity: 0.33;
  background-color: #ff5633;
}

:host.orange {
  background-color: #ff9926;
}
:host.orange:hover {
  background-color: #f28000;
}
:host.orange:active {
  background-color: #bf6500;
}
:host.orange[disabled] {
  opacity: 0.33;
  background-color: #ff9926;
}

:host > span {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
:host > span .text {
  flex-grow: 1;
}
:host > span .image,
:host > span mat-icon {
  margin-right: 6px;
}
:host > span mat-icon {
  font-size: 20px;
  min-width: 20px;
}
:host > span .image {
  width: 16px;
  height: 16px;
}
:host > span.image-only .image,
:host > span.image-only mat-icon {
  margin-right: 0;
}
:host > span.image-only .text {
  flex-grow: 0;
}

.spinner {
  border: 2px solid #ffffff; /* Light grey */
  border-top: 2px solid #7ca8f2; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

.spinner {
  margin-left: 4px;
}

.with-ellipsis .text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
