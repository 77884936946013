import { Injectable, inject } from '@angular/core';
import { type PartItemInformation } from '@models/price-book-models';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { HttpClientService } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';

@Injectable()
export class PartItemsService {
  private readonly http = inject(HttpClientService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);

  list(id?: Id): Observable<PartItemInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.PartItems, this.url.partItemsList, id);
  }

  save(partItem: PartItemInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.partItemsSave, partItem, skipBubbleError());
  }

  hide(partItemId: Id): Observable<void> {
    return this.http.delete<void>(this.url.partItemsDelete.replace('$0', partItemId.toString()));
  }

  setPartItemCategories(partItemId: Id, categoryIds: Id[]): Observable<void> {
    return this.http.post<void>(this.url.partItemsSetCategories.replace('$0', partItemId.toString()), { input: categoryIds });
  }

  setPartItemServiceRepairs(partItemId: Id, serviceRepairIds: Id[]): Observable<void> {
    return this.http.post<void>(this.url.partItemsSetServiceRepairs.replace('$0', partItemId.toString()), { input: serviceRepairIds });
  }
}
