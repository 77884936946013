import { addSeconds, differenceInMilliseconds } from 'date-fns';

export interface Token {
  access_token?: string;
  expires_in?: number;
  technicianId?: number;
  userName?: string;
  refresh_token?: string;
  impersonating?: string;
  impersonator?: string;

  error?: string;
  error_description?: string;
}

export class UserToken {
  private readonly willExpireAt: Date;
  private readonly ephemeralTicket: boolean;

  constructor(
    public token: Token,
    public requestedAt: Date
  ) {
    this.ephemeralTicket = true;
    if (token.expires_in > -1) {
      this.willExpireAt = addSeconds(requestedAt, token.expires_in ?? 3600);
      this.ephemeralTicket = false;
    }
  }

  public static fromSerialization(text: string): UserToken {
    const obj = JSON.parse(text);
    return new UserToken(obj.token, obj.requestedAt);
  }

  isStillValid(): boolean {
    if (!this.token.access_token) {
      return false;
    }

    // In case we have an ephemeral ticket, we don't know if we are still valid
    if (this.ephemeralTicket) {
      return true;
    }

    return differenceInMilliseconds(new Date(), this.willExpireAt) < 0;
  }

  isAboutToExpire(): boolean {
    if (this.ephemeralTicket) {
      return false;
    }

    const halfRequestMoment = addSeconds(this.willExpireAt, -(this.token.expires_in ?? 3600) / 2);
    return differenceInMilliseconds(new Date(), halfRequestMoment) > 0;
  }

  public serialize(): string {
    return JSON.stringify({
      requestedAt: this.requestedAt.toJSON(),
      token: this.token,
    });
  }
}
