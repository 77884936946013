import { Directive, ElementRef, Input, Renderer2, inject, type AfterViewInit, type OnDestroy, input } from '@angular/core';
import { type Subscription } from 'rxjs';
import { StickyService } from './sticky.service';

@Directive({
  selector: '[sticky]',
})
export class StickyDirective implements AfterViewInit, OnDestroy {
  private readonly ref = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly stickyService = inject(StickyService);

  readonly sticky = input.required<string>();
  readonly afterObjectHeigts = input<HTMLElement[]>();

  private subscription?: Subscription;

  ngAfterViewInit(): void {
    this.subscription = this.stickyService.registerAsSticky(this.sticky()).subscribe(position => {
      this.renderer.setStyle(this.ref.nativeElement, 'position', 'sticky');

      let addTop = 0;
      const afterObjectHeights = this.afterObjectHeigts();
      if (afterObjectHeights) {
        for (const object of afterObjectHeights) {
          addTop += object.offsetHeight;
        }
      }

      (['top', 'left', 'right', 'bottom'] as const).forEach(direction => {
        if (position?.[direction] !== undefined) {
          let value = position[direction];
          if (direction === 'top') {
            value = `calc(${value} + ${addTop}px)`;
          }

          this.renderer.setStyle(this.ref.nativeElement, direction, value);
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }
}
