import { type Mutable } from '@models/mutable';
import { BookType, type PriceBookCategoryInformation } from '@models/price-book-models';
import { type Resource } from '@models/resource';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flattenArray, flattenResource, unique } from '@utility/array';
import { type PriceBookDetailState } from './price-book-detail.reducer';

function buildArray<T extends Resource>(obj: Record<string, T & Mutable>, includeHidden?: boolean): T[] {
  const objArray = Object.values(obj);

  if (includeHidden) {
    return objArray;
  }

  return objArray.filter(m => !m.deleted);
}

export const PRICE_BOOK_DETAIL_STORE_KEY = 'priceBookDetail';

export const priceBookDetailStateSelector = createFeatureSelector<PriceBookDetailState>(PRICE_BOOK_DETAIL_STORE_KEY);

// price book detail
export const mode = createSelector(priceBookDetailStateSelector, state => state.edit);
export const priceBookId$ = createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState, props: { group: string }) => {
  return state.group[props.group]?.priceBookId;
});
export const priceBooks$ = createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState, props: { group: string }) => {
  return state.group[props.group]?.entities.priceBooks;
});
export const filter$ = createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState, props: { group: string }) => {
  return (
    (state.group[props.group] && state.group[props.group].filter) || { text: null, propertyTypeId: 1, rateTypeId: 1, agreementId: null }
  );
});
export const partItems$ = createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState, props: { group: string }) => {
  return state.group[props.group] && state.group[props.group].entities.partItems;
});
export const serviceRepairs$ = createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState, props: { group: string }) => {
  return state.group[props.group] && state.group[props.group].entities.serviceRepairs;
});
export const priceBookCategoryPartItemIds$ = createSelector(
  priceBookDetailStateSelector,
  (state: PriceBookDetailState, props: { group: string }) => {
    return state.group[props.group] && state.group[props.group].entities.priceBookCategoryPartItemIds;
  }
);
export const priceBookCategoryServiceRepairIds$ = createSelector(
  priceBookDetailStateSelector,
  (state: PriceBookDetailState, props: { group: string }) => {
    return state.group[props.group] && state.group[props.group].entities.priceBookCategoryServiceRepairIds;
  }
);
export const priceBookCategories$ = createSelector(
  priceBookDetailStateSelector,
  (state: PriceBookDetailState, props: { group: string }) => {
    return state.group[props.group] && state.group[props.group].entities.priceBookCategories;
  }
);
export const selections$ = createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState, props: { group: string }) => {
  return state.group[props.group] && state.group[props.group].selections;
});
export const partItemIdSelections$ = createSelector(
  priceBookDetailStateSelector,
  (state: PriceBookDetailState, props: { group: string }) => {
    return state.group[props.group] && state.group[props.group].partItemIdSelections;
  }
);
export const priceBookLoaded$ = createSelector(priceBookDetailStateSelector, (state: PriceBookDetailState, props: { group: string }) => {
  return !!(state.group[props.group] && state.group[props.group].entities);
});

export const cacheCorrelationId$ = createSelector(priceBookDetailStateSelector, state => state._cacheCorrelationId);
export const saveCorrelationId$ = createSelector(priceBookDetailStateSelector, state => state._correlationId);
export const error$ = createSelector(priceBookDetailStateSelector, state => state._error);

export const priceBookWithCategories$ = createSelector(priceBookCategories$, priceBookCategories => ({
  priceBookCategories: buildArray(priceBookCategories || {}),
}));

export const selectedPriceBook$ = createSelector(
  priceBookId$,
  priceBooks$,
  (priceBookId, priceBooks) => (priceBooks && priceBooks[priceBookId]) || null
);

export const flattenPriceBookCategoriesForCurrentPriceBook$ = createSelector(
  priceBookId$,
  priceBookWithCategories$,
  (priceBookId, priceBookCategories) =>
    (flattenResource(priceBookCategories.priceBookCategories) as PriceBookCategoryInformation[]).filter(
      m => m.priceBookId === priceBookId && !m.hidden
    )
);

export const priceBookInformations$ = createSelector(priceBooks$, priceBooks => buildArray(priceBooks || {}));

export const priceBooksWithServiceRepairsAllowingPartItems$ = createSelector(priceBookInformations$, priceBooks =>
  priceBooks.filter(m => m.type === BookType.Service)
);

export const serviceRepairInformations$ = createSelector(serviceRepairs$, serviceRepairs => buildArray(serviceRepairs || {}));

export const partItemInformations$ = createSelector(partItems$, partItems => buildArray(partItems || {}));

export const serviceRepairsForCurrentPriceBook$ = createSelector(
  flattenPriceBookCategoriesForCurrentPriceBook$,
  serviceRepairInformations$,
  (priceBookWithCategories, serviceRepairs) => {
    const flatten = flattenArray(priceBookWithCategories.map(m => m.serviceRepairIds));
    const serviceRepairIds = unique(flatten);
    return serviceRepairs.filter(m => serviceRepairIds.some(n => n.id === m.id));
  }
);

export const partItemsForCurrentPriceBook$ = createSelector(
  selectedPriceBook$,
  flattenPriceBookCategoriesForCurrentPriceBook$,
  serviceRepairInformations$,
  partItemInformations$,
  (selectedPriceBook, priceBookWithCategories, serviceRepairInformations, partItems) => {
    if (selectedPriceBook && selectedPriceBook.type === BookType.Distributors) {
      return partItems.filter(m => m.distributorId === selectedPriceBook.distributorId);
    }

    const flatten = flattenArray(priceBookWithCategories.map(m => m.partItemIds)).map(m => m.id);
    const partItemIdsInServiceRepairs = [].concat(...serviceRepairInformations.map(m => m.partItemIds)) as number[];
    const partItemIds = unique(flatten.concat(partItemIdsInServiceRepairs));
    return partItems.filter(m => partItemIds.includes(m.id));
  }
);

export const GROUP_PAGE = 'page';
export const GROUP_DIALOG = 'dialog';
