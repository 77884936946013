import { AuthService } from '@services/auth.service';
import { ErrorService, SimpleErrorMessage } from './../error.service';
import { type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

export const RenewalInterceptor: HttpInterceptorFn = (
  req,
  next,
  errorService = inject(ErrorService),
  authService = inject(AuthService)
) => {
  const token = authService.getCurrentToken();
  if (token) {
    const variable = false;
    if (variable || token.isAboutToExpire()) {
      authService.refreshToken().subscribe(
        () => {},
        err => {
          const message = 'We could not renew your authentication. Please save your work, log out and log back in again.';
          errorService.trigger(new SimpleErrorMessage(message));
        }
      );
    }
  }

  return next(req);
};
