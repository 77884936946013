// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  display: block;
  border: 1px solid #d1d5d8;
  border-radius: 4px;
  background-color: #f1f2f3;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
  background-color: #f1f2f3;
}
:host section.top {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 16px;
  border-bottom: 1px solid #d1d5d8;
}
:host section.top .text {
  display: flex;
  flex-direction: column;
  flex: 1;
}
:host section.top .title {
  font-size: 14px;
  color: #232628;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 16px;
}
:host section.top .subtitle {
  margin-top: 4px;
  font-size: 12px;
  color: #232628;
  line-height: 16px;
}
:host section.top img.type {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}
:host section.top .text {
  position: relative;
}
:host section.top .right-aligned {
  position: absolute;
  right: 0;
  top: 0;
}
:host section.content {
  margin: 16px;
  padding: 0;
  /* Not in used here?
    section {
        margin: 0 (-\$dialog-padding) 0 (-\$dialog-padding);
        border-bottom: 1px solid gray;
        padding: \$dialog-padding;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        &:first-child {
            padding-top: 0;
        }
    }
    */
}
:host section.content .wm-card2 {
  width: 100%;
  box-shadow: none;
}
:host section.actions {
  padding: 0;
  margin: 16px;
  display: flex;
  justify-content: flex-end;
  min-height: inherit;
}
:host section.actions > * {
  margin-right: 8px;
}
:host section.actions > *:last-child {
  margin-right: 0;
}
:host section.actions .buttons {
  justify-content: flex-end;
  flex: 1;
  display: flex;
  align-items: center;
}
:host section.actions .buttons > * {
  margin-right: 8px;
}
:host section.actions .buttons > *:last-child {
  margin-right: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
