import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  computed,
  input,
  output,
  signal,
  viewChild,
  type AfterViewInit,
  type DoCheck,
  type OnDestroy,
} from '@angular/core';
import { type PagedResult, type TableQueryResult } from '@models/filter-models';
import { PagerBubbleComponent } from './pager-bubble.component';

// Angular 18
@Component({
  selector: 'wm-pager',
  templateUrl: 'pager.component.html',
  styleUrls: ['pager.component.scss'],
  standalone: true,
  imports: [CommonModule, PagerBubbleComponent],
})
export class PagerComponent<T> implements DoCheck, AfterViewInit, OnDestroy {
  readonly pagedResult = input.required<PagedResult<T> | TableQueryResult<T>>();
  readonly showTotalCount = input(false);

  readonly lastPage = computed(() => Math.ceil(this.pagedResult().count / this.pagedResult().take));
  readonly results = computed(() => this.pagedResult().count);
  readonly currentPage = computed(() => this.pagedResult().skip / this.pagedResult().take + 1);
  readonly currentPageChange = output<number>();

  readonly showSlider = computed(() => this.pagedResult().count > this.pagedResult().take);
  readonly isHidden = computed(() => !this.showTotalCount() && !this.showSlider());
  readonly leftPosition = computed(() => `${Math.max(0, this.getPagePosition(this.currentPage(), this.currentPageObjElementWidth()))}px`);

  // this allows to not see an animation on loading
  readonly initialized = signal(false);

  private readonly currentPageObjElement = viewChild.required<ElementRef<HTMLElement>, ElementRef<HTMLElement>>('currentPageObj', {
    read: ElementRef<HTMLElement>,
  });

  private readonly highlightObjElement = viewChild.required<ElementRef<HTMLElement>, ElementRef<HTMLElement>>('highlightObj', {
    read: ElementRef<HTMLElement>,
  });

  private readonly slider = viewChild.required<ElementRef<HTMLElement>, ElementRef<HTMLElement>>('slider', {
    read: ElementRef<HTMLElement>,
  });

  readonly bubbleWidth = computed(() => {
    const _ = this.refreshFlag();
    return this.slider().nativeElement.offsetWidth / this.lastPage();
  });

  readonly currentPageObjElementWidth = computed(() => {
    const _ = this.refreshFlag();
    return this.currentPageObjElement().nativeElement.offsetWidth;
  });

  readonly highlightPage = signal(0);

  private readonly refreshFlag = signal(false);

  private ignoreNextSliderClick = false;

  private timerHandle?: NodeJS.Timeout;

  click(event: MouseEvent): void {
    if (this.ignoreNextSliderClick) {
      this.ignoreNextSliderClick = false;
      return;
    }

    const mouseX = this.getMouseXFromSlider(event.pageX);
    this.currentPageChange.emit(this.getPageByMousePosition(mouseX));
  }

  _mousemove(event: MouseEvent): void {
    const mouseX = this.getMouseXFromSlider(event.pageX);
    this.highlightPage.set(this.getPageByMousePosition(mouseX));
    this.highlightObjElement().nativeElement.style.left = `${this.getPagePosition(
      this.highlightPage(),
      this.highlightObjElement().nativeElement.offsetWidth
    )}px`;
  }

  previousClick(): void {
    this.ignoreNextSliderClick = true;
    this.currentPageChange.emit(this.currentPage() - 1);
  }

  nextClick(): void {
    this.ignoreNextSliderClick = true;
    this.currentPageChange.emit(this.currentPage() + 1);
  }

  ngAfterViewInit() {
    this.refreshFlag.set(!this.refreshFlag());
    this.timerHandle = setTimeout(() => {
      this.initialized.set(true);
    }, 100);
  }

  ngOnDestroy() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
    }
  }

  ngDoCheck(): void {
    this.refreshFlag.set(!this.refreshFlag());
  }

  private getMouseXFromSlider(pageX: number): number {
    return Math.max(0, pageX - this.slider().nativeElement.getBoundingClientRect().left);
  }

  private getPagePosition(page: number, pageObjectWidth: number): number {
    // What if the bubble is smaller than the text? We save it in extra
    // That offsetWidth will be wrong if we are doing an animation.
    // This is why we call this method only when the transition has finished.
    const bubbleSize = this.bubbleWidth();
    const extra = pageObjectWidth / 2 - bubbleSize / 2;
    return bubbleSize * (page - 1) - extra;
  }

  private getPageByMousePosition(mouseX: number): number {
    const bubbleSize = this.bubbleWidth();
    const pageNumber = parseInt((mouseX / bubbleSize).toString(), 10);

    return Math.min(pageNumber + 1, this.lastPage());
  }
}
