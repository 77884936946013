import { CommonModule } from '@angular/common';
import { NgModule, type ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@controls/button/button.component';
import { AfterViewInitDirective } from '@directives/after-view-init.directive';
import { DestroyDirective } from '@directives/destroy.directive';
import { EnterDirective } from '@directives/enter.directive';
import { FileDropDirective } from '@directives/file-drop.directive';
import { FileSelectDirective } from '@directives/file-select.directive';
import { InitDirective } from '@directives/init.directive';
import { InitialFocusDirective } from '@directives/initial-focus.directive';
import { MomentDateValidatorDirective } from '@directives/moment-date-validator.directive';
import { RemoveHostDirective } from '@directives/remove-host.directive';
import { TemplateRefDirective } from '@directives/template-ref.directive';
import { AsyncSafePipe } from '@pipes/async-safe.pipe';

const imports = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  ButtonComponent,
  AsyncSafePipe,
  AfterViewInitDirective,
  DestroyDirective,
  EnterDirective,
  FileDropDirective,
  FileSelectDirective,
  InitDirective,
  InitialFocusDirective,
  MomentDateValidatorDirective,
  RemoveHostDirective,
  TemplateRefDirective,
];

@NgModule({
  imports,
  exports: imports,
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
