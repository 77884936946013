import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { getAgreementLinkName, isNavigatable } from '@cards/agreements/agreement-site-system-list.component';
import {
  AgreementSiteSystemSetupStatus,
  type AgreementSiteSystemInformation,
  type AgreementSiteSystemInformationForList,
} from '@models/cards/agreement-site-system-information';

const isADate = (obj: any): boolean => {
  return obj instanceof Date;
};

@Component({
  template: `@if (line(); as line) {
    @if (isNavigatable(line.data)) {
      <a [class.important]="isSetupReady(line.data) || isDeleted(line.data)" [routerLink]="['/agreements', line.data.id]">
        @if (getAgreementLinkName(line); as agreementLinkName) {
          @if (isADate(agreementLinkName)) {
            {{ agreementLinkName | date: 'shortDate' }}
          } @else {
            {{ agreementLinkName }}
          }
        }
      </a>
    } @else {
      <span>Not Ready.</span>
      <div>
        Activate by marking as Processed:
        <a [routerLink]="['/workorders', line.data.workOrderId]">Go to Work Order</a>
      </div>
    }
  }`,
  styles: [
    `
      @import 'variables';
      .important a {
        color: $color-tertiary-warm-accent;
        @include font-semibold();
      }
    `,
  ],
  imports: [CommonModule, RouterModule],
  standalone: true,
})
export class TableDynamicNodeAgreementNextTuneUpComponent {
  readonly isNavigatable = isNavigatable;
  readonly getAgreementLinkName = getAgreementLinkName;
  readonly isADate = isADate;

  readonly line = input<AgreementSiteSystemInformationForList>();

  isSetupReady(line: AgreementSiteSystemInformation): boolean {
    // TODO Confirm, in the resulting JavaScript this thing is not pointing to the correct object??
    return line.setupStatus === AgreementSiteSystemSetupStatus.ReadyForSetup && !line.hidden;
  }

  isDeleted(line: AgreementSiteSystemInformation): boolean {
    return line.hidden ?? false;
  }
}
