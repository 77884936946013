import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonComponent } from '@controls/button/button.component';

/**
 * @deprecated
 */
@Component({
  selector: 'wm-filter-button-deprecated',
  templateUrl: 'filter-button-deprecated.component.html',
  styleUrls: ['filter-button-deprecated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent],
})
export class FilterButtonDeprecatedComponent {
  @Input()
  amount = 0;

  @Input()
  open = false;

  @Output()
  openChange = new EventEmitter<boolean>();

  filterClick(): void {
    this.open = !this.open;
    this.openChange.emit(this.open);
  }

  @HostBinding('class.has-filter')
  get hasFilter(): boolean {
    return this.amount > 0;
  }

  readonly imageUrl = '/assets/images/filter-button.svg';

  @HostBinding('class.has-many')
  get hasMany(): boolean {
    return this.amount >= 10;
  }

  get amountString(): string {
    if (this.amount < 10) {
      return this.amount?.toString() || '';
    }

    return '9+';
  }
}
