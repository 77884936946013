import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  Optional,
  Self,
  TemplateRef,
  type AfterViewInit,
  type OnDestroy,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ElementBase3 } from '@controls/element-base';
import { Mode } from '@models/form';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const movingAnimation = '.2s ease-out';

@Component({
  selector: 'wm-rw',
  templateUrl: 'rw.component.html',
  styleUrls: ['rw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('rwContainer', [
      transition('r => w', [
        style({ height: '24px' }),
        group([
          animate(
            movingAnimation,
            style({
              height: '{{ expanded_size }}',
            })
          ),
          query('@wContainer', [animateChild()]),
        ]),
      ]),
      transition('w => r', [
        style({ height: '{{ expanded_size }}' }),
        group([
          animate(
            movingAnimation,
            style({
              height: '24px',
            })
          ),
          query('@rContainer', [animateChild()]),
        ]),
      ]),
    ]),
    trigger('rContainer', [
      state(
        'r',
        style({
          transform: 'translateY(0)',
        })
      ),
      // We want this animation to happen ONLY when we come back from W
      transition('w => r', [style({ transform: 'translateY(4px)' }), animate(movingAnimation)]),
    ]),
    trigger('wContainer', [
      state(
        'w',
        style({
          transform: 'translateY(0)',
        })
      ),
      transition('void => w', [style({ transform: 'translateY(-4px)' }), animate(movingAnimation)]),
    ]),
  ],
})
export class RwComponent extends ElementBase3<any> implements AfterViewInit, OnDestroy {
  @HostBinding('class.rw') rw = 'true';

  isReady = false;

  get modeStr(): string {
    return Mode.Read === this.mode ? 'r' : 'w';
  }

  @Input()
  expandedSize = '32px';

  @Input()
  name?: string | null = null;

  @Input()
  mode = Mode.Read;

  Mode = Mode;

  @HostBinding('class.read-mode')
  get readMode(): boolean {
    return this.mode === Mode.Read;
  }

  @HostBinding('class.write-mode')
  get writeMode(): boolean {
    return this.mode === Mode.Write;
  }

  @ContentChild('labelTemplate') labelTemplate2?: TemplateRef<any>;
  @ContentChild('helperTemplate') helperTemplate?: TemplateRef<any>;
  @ContentChild('readTemplate') readTemplate!: TemplateRef<any>;
  @ContentChild('writeTemplate') writeTemplate!: TemplateRef<any>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Self()
    @Optional()
    ngControl: NgControl,
    cd: ChangeDetectorRef
  ) {
    super(ngControl, cd);
    this._allowEmptyAbstractControl = true;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // We might be using the rw control but just to display read/write.
    // Without using a formControl or ngModel.
    if (this.abstractControl) {
      // This allows to forward the event higher when using [(ngModel)].
      this.abstractControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(m => {
        this.value = m;
      });
    }

    // Too common mistake, if the mode is undefined, let's warn
    if (this.mode === undefined) {
      throw new Error(`The mode is not specified for "${this.name}".`);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
