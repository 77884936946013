import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class StripeService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getSubscriptionAccountId(): Observable<string> {
    return this.http.get<string>(this.url.stripeGetSubscriptionAccountId);
  }

  getChargeAccountId(): Observable<string> {
    return this.http.get<string>(this.url.stripeGetChargeAccountId);
  }
}
