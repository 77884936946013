import { Directive, ElementRef, HostBinding, HostListener, Input, inject } from '@angular/core';
import { TableDirective } from './table.directive';

@Directive({
  selector: '[wm-row-select]',
  standalone: true,
})
export class RowSelectDirective {
  private readonly table = inject(TableDirective);

  @HostBinding('class.row-selected')
  get isSelected(): boolean {
    return this.getIndexSelection() !== -1;
  }

  @Input('wm-row-select')
  data: any;

  @HostListener('click')
  click(): void {
    const index = this.getIndexSelection();
    let selected = false;

    if (index === -1) {
      selected = true;
      this.table._rowSelection.push(this.data);
    } else {
      this.table._rowSelection.splice(index, 1);
    }

    this.table.rowSelect.emit({ data: this.data, selected });
  }

  private getIndexSelection(): number {
    return this.table._rowSelection.indexOf(this.data);
  }
}
