import { Injectable, inject } from '@angular/core';
import { type ZapierAccountKey, type ZapierConfig } from '@models/zapier';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class ZapierService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get() {
    return this.http.get<ZapierConfig>(this.url.zapierGet);
  }

  enable() {
    return this.http.post<{ accountKey: ZapierAccountKey }>(this.url.zapierEnable, null);
  }

  disable() {
    return this.http.post<void>(this.url.zapierDisable, null);
  }
}
