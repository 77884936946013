import { type ConnectionPositionPair, type FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { CdkPortalOutlet, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  ViewChild,
  inject,
  type AfterViewInit,
  type ComponentRef,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { POPOVER_OVERLAY_DATA, PopoverRef } from '@models/dialog';
import { SharedModule } from '@modules/shared.module';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, skipWhile, startWith, take } from 'rxjs/operators';
import { SubscriptionManagerService } from './../services/ephemerals/subscription-manager.service';

@Component({
  templateUrl: 'dialog-popover.component.html',
  styleUrls: ['dialog-popover.component.scss'],
  standalone: true,
  imports: [SharedModule, MatDialogModule, PortalModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionManagerService],
})
export class DialogPopoverComponent<T, R> implements AfterViewInit {
  private readonly popoverData = inject(POPOVER_OVERLAY_DATA);

  arrowLeftPosition: number;
  arrowTopPosition: number;
  arrowRightPosition: number;
  arrowBottomPosition: number;
  arrowVisible = true;

  popoverPortal: ComponentPortal<T>;

  connectionPair: ConnectionPositionPair;
  test = 0;

  @HostBinding('class.is-date-picker')
  isDatePicker = false;

  @ViewChild('portal', { static: true })
  portal!: CdkPortalOutlet;

  @HostBinding('class.position-bottom')
  get positionBottom() {
    return this.connectionPair && this.connectionPair.originY === 'bottom';
  }

  @HostBinding('class.position-top')
  get positionTop() {
    return this.connectionPair && this.connectionPair.originY === 'top';
  }

  @HostBinding('class.position-left')
  get positionLeft() {
    return this.connectionPair && this.connectionPair.originX === 'start';
  }

  @HostBinding('class.position-right')
  get positionRight() {
    return this.connectionPair && this.connectionPair.originX === 'end';
  }

  @HostBinding('class.is-ready')
  isReady = false;

  @HostBinding('class.is-light')
  light = true;

  arrowSize = 14;

  previousOriginY: string = null;
  doReposition = false;
  private readonly mouseIsIn = new Subject<boolean>();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly sm: SubscriptionManagerService,
    private readonly cd: ChangeDetectorRef,
    private readonly popoverRef: PopoverRef<T, R>
  ) {
    this.popoverPortal = new ComponentPortal(this.popoverData.component) as ComponentPortal<T>;
    const positionStrategy = this.popoverData.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

    if (this.popoverData.requestClose) {
      this.sm.add(
        combineLatest([this.mouseIsIn.pipe(startWith(false)), this.popoverData.requestClose])
          .pipe(debounceTime(700))
          .pipe(skipWhile(([mouseIsIn, requestClose]) => mouseIsIn || !requestClose))
          .pipe(take(1))
          .subscribe(_ => {
            this.popoverRef.close();
          })
      );
    }

    // This subscription stops on dialog close
    positionStrategy.positionChanges.subscribe(m => {
      this.connectionPair = m.connectionPair;
      this.arrowTopPosition = null;
      this.arrowBottomPosition = null;
      // We are unable to get the origin point that CDK calculated
      // So we try on our own.
      const originBoundingRect = this.getHTMLElement(this.popoverData.origin).getBoundingClientRect();
      switch (m.connectionPair.originX) {
        case 'start':
          this.arrowTopPosition =
            originBoundingRect.top -
            parseInt(this.elementRef.nativeElement.parentNode.style.top, 10) + // This is playing with fire
            originBoundingRect.height / 2 -
            this.arrowSize / 2 -
            1;
          this.arrowRightPosition = 1;
          break;
        case 'end':
          this.arrowTopPosition =
            originBoundingRect.top -
            parseInt(this.elementRef.nativeElement.parentNode.style.top, 10) + // This is playing with fire
            originBoundingRect.height / 2 -
            this.arrowSize / 2 -
            1;
          this.arrowLeftPosition = 1;
          break;
      }

      switch (m.connectionPair.originY) {
        case 'bottom':
          this.arrowLeftPosition =
            originBoundingRect.left -
            parseInt(this.elementRef.nativeElement.parentNode.style.left, 10) + // This is playing with fire
            originBoundingRect.width / 2 -
            this.arrowSize / 2 -
            1;
          this.arrowTopPosition = 1;
          break;
        case 'top':
          this.arrowLeftPosition =
            originBoundingRect.left -
            parseInt(this.elementRef.nativeElement.parentNode.style.left, 10) + // This is playing with fire
            originBoundingRect.width / 2 -
            this.arrowSize / 2 -
            1;
          this.arrowBottomPosition = 1;
          break;
      }

      this.cd.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    const componentRef = this.portal.attachedRef as ComponentRef<T>;
    this.popoverRef.componentInstance = componentRef.instance;
    this.popoverRef._afterOpen.next();
    this.popoverRef._afterOpen.complete();
  }

  private getHTMLElement(element: ElementRef | HTMLElement): HTMLElement {
    return this.isElementRef(element) ? element.nativeElement : element;
  }

  private isElementRef(element: ElementRef | HTMLElement): element is ElementRef {
    return !!(element as any).nativeElement;
  }

  private getPositionClassName(name: string): string {
    return `position-${name.toLowerCase()}`;
  }

  private getAlignmentClassName(name: string): string {
    return `alignment-${name.toLowerCase()}`;
  }

  @HostListener('mouseenter')
  mouseEnter() {
    this.mouseIsIn.next(true);
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.mouseIsIn.next(false);
  }
}
