import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MomentPickerComponent } from '@controls/moment-picker/moment-picker.component';
import { type Dialog2ServiceImpl, POPOVER_DATA_TYPED, PopoverRef } from '@models/dialog';
import { SharedModule } from '@modules/shared.module';

export interface PopoverCalendarData {
  date: Date;
  dateChangeFnc: (date: Date) => void;
  showTime?: boolean;
  showDate?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
}

const injectionToken = POPOVER_DATA_TYPED<PopoverCalendarData>();

@Component({
  selector: 'wm-popover-calendar',
  templateUrl: 'popover-calendar.component.html',
  styleUrls: ['popover-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MomentPickerComponent],
})
export class PopoverCalendarComponent {
  readonly data = inject(injectionToken);
  private readonly popoverRef = inject(PopoverRef<PopoverCalendarComponent>);

  get title(): string {
    if (this.data.showTime && this.data.showDate) {
      return 'Select Date & Time';
    } else if (this.data.showTime) {
      return 'Select Time';
    }

    return 'Select Date';
  }

  _internalDateChange(date: Date): void {
    this.data.dateChangeFnc(date);
  }

  okClick(): void {
    this.popoverRef.close();
  }

  static open(dialog: Dialog2ServiceImpl, element: HTMLElement, data: PopoverCalendarData) {
    return dialog.popover(PopoverCalendarComponent, element, {
      popoverData: {
        injectionToken,
        data,
      },
    });
  }
}
