import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  inject,
  type ElementRef,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { SharedModule } from '@modules/shared.module';
import { type Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { TimerService } from '../services/timer.service';
import { TabDataWithNotes } from './popover-event-info.component';

@Component({
  selector: 'wm-event-note-metadata',
  templateUrl: 'event-note-metadata.component.html',
  styleUrls: ['event-note-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
})
export class EventNoteMetadataComponent implements OnInit, OnDestroy {
  private readonly cd = inject(ChangeDetectorRef);
  private readonly timerService = inject(TimerService);

  @Input()
  allowNoteEditing = false;

  @Input()
  noteMetadata!: TabDataWithNotes;

  private savedTimeout: number | null = null;
  private subscription: Subscription | null = null;

  ngOnInit(): void {
    this.registerNoteStream();
  }

  ngOnDestroy(): void {
    if (this.savedTimeout) {
      this.timerService.clearTimeout(this.savedTimeout);
      this.savedTimeout = null;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  goFocus(textarea: ElementRef) {
    textarea.nativeElement.focus();
  }

  editNotes(tabNoteMetadata: TabDataWithNotes) {
    if (this.allowNoteEditing) {
      this.debounce(() => {
        tabNoteMetadata.editing = true;
        this.cd.markForCheck();
      });
    }
  }

  onBlur(tabNoteMetadata: TabDataWithNotes, notes: string) {
    this.debounce(() => {
      tabNoteMetadata.editing = false;
      this.internalSaveNotes(tabNoteMetadata, notes);
      this.cd.markForCheck();
    });
  }

  private registerNoteStream() {
    this.subscription = this.noteMetadata.notesStream
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(skip(1))
      .subscribe(m => {
        this.internalSaveNotes(this.noteMetadata, m);
      });
  }

  private internalSaveNotes(tabNoteMetadata: TabDataWithNotes, notes: string) {
    if (tabNoteMetadata.notes !== notes) {
      this.debounce(() => {
        if (this.savedTimeout) {
          this.timerService.clearTimeout(this.savedTimeout);
          this.savedTimeout = null;
        }

        tabNoteMetadata.notes = notes;
        tabNoteMetadata.saveNote(notes).subscribe(
          () => {
            tabNoteMetadata.saved = true;
            this.cd.markForCheck();

            this.savedTimeout = this.timerService.setTimeout(() => {
              tabNoteMetadata.saved = false;
              this.cd.markForCheck();
            }, 1000);
          },
          () => {
            // TODO Error
          }
        );
      });
    }
  }

  private debounce(fnc: () => void) {
    // Be in a timeout because we don't want to trigger a "fncClose"
    // PopoverBase thinks we clicked outside of the popover because the HTML is changed
    // Before the click event is fully processed.
    setTimeout(fnc, 1);
  }
}
