import { Injectable, inject } from '@angular/core';
import { type AgreementSiteSystemInformation } from '@models/cards/agreement-site-system-information';
import { type CallInformation } from '@models/cards/call-information';
import { type CustomerInformation } from '@models/cards/customer-information';
import { type InspectionAnswerInformation } from '@models/cards/inspection-answer-information';
import { type PhotoOrTempFile, type RecommendationInformation } from '@models/cards/recommendation-information';
import { type SiteInformation } from '@models/cards/site-information';
import { hasWorkOrderDetailsOrAgreements, type SiteSystem, type SiteSystemInformation } from '@models/cards/site-system-information';
import { type WorkOrderDetailInformation } from '@models/cards/work-order-detail-information';
import { WorkOrderType, createEmpty, type WorkOrderInformation } from '@models/cards/work-order-information';
import { type WorkOrderSiteSystemInformation } from '@models/cards/work-order-site-system-information';
import { type WorkOrderBaseViewData, type WorkOrderViewData } from '@models/cards/work-order-view-data';
import { type WorkOrderFullViewModel, type WorkOrderSalesViewModel } from '@models/cards/work-order-view-model';
import { DIALOG_SERVICE_IMPL, DialogType } from '@models/dialog';
import { SitesService } from '@services/live/sites.service';
import { TaxItemSectionsService } from '@services/live/tax-item-sections.service';
import { WorkOrdersService } from '@services/live/work-orders.service';
import { StaticDataService } from '@services/static-data.service';
import { groupBy, indexBy, notEmpty, pluck, union } from '@utility/array';
import { Observable, combineLatest, forkJoin, of, type Subject } from 'rxjs';
import { defaultIfEmpty, filter, first, map, mergeMap, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { WorkOrderStateService } from './work-order-state.service';
import { sortFnc } from '@utility/sort-fnc';
import { clone } from 'lodash';

export interface WorkOrderViewModelExtras {
  recommendationPhotoPerSiteSystem: Record<number, PhotoOrTempFile[]>;
  inspectionAnswerPerSiteSystem: Record<number, InspectionAnswerInformation[]>;
  workOrderSiteSystemPerSiteSystem: Record<number, WorkOrderSiteSystemInformation[]>;
}

export type WorkOrderViewModel = (WorkOrderFullViewModel | WorkOrderSalesViewModel) & WorkOrderViewModelExtras;

export function isFull(workOrderViewModel: WorkOrderViewModel): workOrderViewModel is WorkOrderFullViewModel & WorkOrderViewModelExtras {
  return workOrderViewModel?.workOrder?.type === WorkOrderType.Full;
}

function hasWorkOrder(workOrder: WorkOrderBaseViewData): workOrder is WorkOrderViewData {
  return !!(workOrder as WorkOrderViewData)?.workOrderInformation;
}

@Injectable()
export class WorkOrderViewService {
  private readonly workOrderStateService = inject(WorkOrderStateService);
  private readonly taxItemSectionsService = inject(TaxItemSectionsService);
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly sitesService = inject(SitesService);
  private readonly workOrdersService = inject(WorkOrdersService);
  private readonly staticDataService = inject(StaticDataService);

  private workOrderViewData$: Observable<WorkOrderBaseViewData>;
  private siteInformation?: SiteInformation | null = null;
  private customerInformation: CustomerInformation;

  getWorkOrderViewModel$(
    workOrderViewData: WorkOrderViewData | null,
    customerInformation: CustomerInformation,
    siteInformation: SiteInformation | null,
    callInformation: CallInformation | null,
    destroySubject: Subject<void>
  ): Observable<WorkOrderViewModel> {
    this.workOrderViewData$ = workOrderViewData ? of(workOrderViewData) : this.workOrdersService.getBaseViewData();
    this.customerInformation = customerInformation;
    this.siteInformation = siteInformation;

    const workOrderViewModel = combineLatest([
      this.workOrderViewData$,
      this.workOrderStateService.workOrderDetails$,
      this.workOrderStateService.workOrderDiscounts$,
      this.workOrderStateService.workOrderRebates$,
      this.workOrderStateService.workOrderPayments$,
      this.workOrderStateService.workOrder$,
      this.workOrderStateService.workOrderRepairParts$,
      this.workOrderStateService.nonStockItems$,
      this.workOrderStateService.workOrderAgreements$,
      this.workOrderStateService.agreementSiteSystems$,
      this.workOrderStateService.workOrderLoanApplications$,
      this.workOrderStateService.taxItemEntry$,
      this.workOrderStateService.salesProposal$,
      this.workOrderStateService.salesProposalPackage$,
      !workOrderViewData && siteInformation
        ? this.sitesService.getSiteSystems(siteInformation.id).pipe(map(m => m.map(n => n.data)))
        : of([] as SiteSystemInformation[]),
      this.workOrderStateService.call$,
      this.workOrderStateService.debriefAnswers$,
      this.workOrderStateService.recommendations$,
      this.workOrderStateService.recommendationPhotos$,
      this.workOrderStateService.inspectionAnswers$,
      this.workOrderStateService.siteSystems$,
      this.workOrderStateService.workOrderSiteSystems$,
      this.workOrderStateService.tagEntities$,
      this.staticDataService.getContractorLaborPricings(),
    ]).pipe(
      map(
        ([
          workOrderViewData,
          workOrderDetails,
          workOrderDiscounts,
          workOrderRebates,
          workOrderPayments,
          workOrder,
          workOrderRepairParts,
          nonStockItems,
          workOrderAgreements,
          agreementSiteSystems,
          workOrderLoanApplications,
          taxItemEntry,
          salesProposal,
          salesProposalPackage,
          siteSystemInformations,
          call,
          debriefAnswers,
          recommendations,
          recommendationPhotos,
          inspectionAnswers,
          siteSystems,
          workOrderSiteSystems,
          tagEntities,
          laborPricings,
        ]) => {
          const siteSystemInvolved = this.getSiteSystemInvolved(
            workOrderDetails,
            recommendations,
            inspectionAnswers,
            hasWorkOrder(workOrderViewData) ? workOrderViewData.siteSystemInformations ?? [] : [],
            workOrderSiteSystems
          );

          const recommendationPhotoPerSiteSystem = this.getRecommendationPhotoPerSiteSystem(recommendations, recommendationPhotos);

          const workOrderSiteSystemPerSiteSystem = this.getWorkOrderSiteSystemPerSiteSystem(workOrderSiteSystems);
          const inspectionAnswerPerSiteSystem = this.getInspectionAnswerPerSiteSystem(inspectionAnswers);

          return {
            workOrder,
            call,
            workOrderDetails,
            workOrderRepairParts,
            workOrderDiscounts,
            workOrderRebates,
            workOrderPayments,
            recommendations,
            recommendationPhotos,
            debriefAnswers,
            tagEntities,
            inspectionAnswers,
            workOrderLoanApplications,
            workOrderAgreements,
            agreementSiteSystems,
            taxItemEntry,
            salesProposal,
            salesProposalPackage,
            siteSystems,
            siteSystemInvolved,
            recommendationPhotoPerSiteSystem,
            inspectionAnswerPerSiteSystem,
            workOrderSiteSystemPerSiteSystem,
            nonStockItems,
            staticData: {
              zoneId: siteInformation?.zoneId ?? 0,
              siteSystemInformations: hasWorkOrder(workOrderViewData)
                ? workOrderViewData.siteSystemInformations ?? []
                : siteSystemInformations,
              withClass: workOrderViewData.classMappingPerDetail,
              // We default to 1 for rate type and property type if we created the work order without a call or without a site.
              rateTypeId: callInformation?.rateTypeId || 1,
              propertyTypeId: siteInformation?.propertyTypeId || 1,
              groupPricing: workOrderViewData.groupPricing,
              allowOverrideTaxes: workOrderViewData.allowOverrideTaxes,
              taxWarning: workOrderViewData.taxWarning,
              displayTime: workOrderViewData.displayTime,
              chargeSettings: workOrderViewData.chargeSettings,
              customerInformation,
              siteInformation,
              laborPricings,
            },
          } as WorkOrderViewModel;
        }
      ),
      shareReplay(1)
    );

    this.loadData(destroySubject);

    destroySubject.subscribe(() => {
      this.workOrderStateService.reset();
    });

    return workOrderViewModel;
  }

  updateWorkOrder(workOrder: Partial<WorkOrderInformation>): void {
    this.workOrderStateService.updateWorkOrder(workOrder);
  }

  editMode(): void {
    this.workOrderStateService.editMode();
  }

  cancel(): void {
    this.workOrderStateService.cancel();
  }

  save(): Observable<Id> {
    return new Observable<Id>(resolver => {
      combineLatest([this.checkTaxWarning(), this.workOrderStateService.taxItemEntry$])
        .pipe(first())
        .subscribe(([$continue, taxItemEntry]) => {
          if (!$continue) {
            resolver.error();
            return;
          }

          this.workOrderStateService.save(this.customerInformation.id, this.siteInformation?.id ?? null).subscribe({
            next: workOrderId => {
              this.taxItemSectionsService
                .getTaxItems(taxItemEntry.taxItem?.id)
                .pipe(
                  switchMap(taxItems => {
                    const workOrderTaxItem = taxItemEntry.taxItem ? taxItems.find(taxItem => taxItem.id === taxItemEntry.taxItem.id) : null;

                    const operations: Observable<any>[] = [];

                    if (
                      workOrderId &&
                      workOrderTaxItem &&
                      this.siteInformation &&
                      this.siteInformation.taxItemSectionId !== workOrderTaxItem.taxItemSectionId
                    ) {
                      operations.push(
                        this.sitesService.patch(this.siteInformation.id, {
                          taxItemSectionId: workOrderTaxItem.taxItemSectionId,
                        })
                      );
                    }

                    return forkJoin(operations).pipe(defaultIfEmpty([]));
                  })
                )
                .subscribe({
                  next: () => {
                    resolver.next(workOrderId);
                    resolver.complete();
                  },
                  error: e => {
                    resolver.error(e);
                  },
                });
            },
            error: e => {
              resolver.error(e);
            },
          });
        });
    });
  }

  // Returns true to continue. False to stop.
  private checkTaxWarning(): Observable<boolean> {
    return combineLatest([this.workOrderViewData$, this.workOrderStateService.taxItemEntry$, this.workOrderStateService.hasVisibleData$])
      .pipe(first())
      .pipe(
        mergeMap(([workOrderViewData, taxItemEntry, hasVisibleData]) => {
          if (hasVisibleData && workOrderViewData.taxWarning) {
            if (
              taxItemEntry === null ||
              (taxItemEntry.taxOverride === null &&
                (taxItemEntry.taxItemManual == null || taxItemEntry.taxItemManual.toString() === '') &&
                !taxItemEntry.taxItem)
            ) {
              const dialogRef = this.dialog2.confirm<boolean>({
                disableClose: false,
                confirm: {
                  title: 'Missing Taxes?',
                  text: 'It seems that you forgot taxes for this work order.',
                  type: DialogType.Question,
                  buttons: [
                    {
                      id: 'cancel',
                      text: 'Edit the Work Order',
                      secondary: 'secondary',
                      click: () => {
                        dialogRef.close(false);
                      },
                      focusInitial: true,
                    },
                    {
                      id: 'save',
                      text: 'Ignore and Save',
                      click: () => {
                        dialogRef.close(true);
                      },
                    },
                  ],
                },
              });

              return dialogRef.afterClosed();
            }
          }

          return of(true);
        })
      );
  }

  private loadData(destroySubject: Subject<void>): void {
    combineLatest([
      this.workOrderViewData$.pipe(takeUntil(destroySubject), filter(notEmpty)),
      this.siteInformation
        ? this.sitesService.getAgreements(this.siteInformation.id).pipe(map(agreements => agreements.map(n => n.data)))
        : of([] as AgreementSiteSystemInformation[]),
      this.taxItemSectionsService.list(),
    ]).subscribe(([workOrderViewData, agreementSiteSystems, taxItemSections]) => {
      if (!hasWorkOrder(workOrderViewData)) {
        this.workOrderStateService.loadData(
          { ...createEmpty(), siteId: this.siteInformation?.id ?? null },
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          agreementSiteSystems,
          null,
          null,
          [],
          null,
          1,
          workOrderViewData.classMappingPerDetail
        );

        // Similar code in work-order.component
        this.workOrderStateService.loadTaxItemSections(taxItemSections);

        const taxItemSection = taxItemSections.find(m => m.id === this.siteInformation?.taxItemSectionId);

        // We change the tax item
        if (taxItemSection) {
          const taxItems = clone(taxItemSection.items).sort(sortFnc(true, 'effectiveDate'));

          if (taxItems[0]) {
            this.workOrderStateService.updateTax({
              taxItem: taxItems[0],
              taxItemManual: null,
              taxOverride: null,
            });
          }
        }
      } else {
        const siteSystems: SiteSystem[] = [...(workOrderViewData.siteSystemInformations ?? []), { id: null }].filter(x =>
          hasWorkOrderDetailsOrAgreements(
            x,
            workOrderViewData.workOrderDetailInformations,
            workOrderViewData.workOrderAgreementInformations,
            workOrderViewData.agreementSiteSystemInformations
          )
        );

        this.workOrderStateService.loadData(
          workOrderViewData.workOrderInformation,
          workOrderViewData.workOrderDetailInformations,
          workOrderViewData.workOrderRepairPartInformations,
          workOrderViewData.nonStockItemInformations,
          workOrderViewData.workOrderDiscountInformations,
          workOrderViewData.workOrderRebateInformations,
          workOrderViewData.workOrderPaymentInformations,
          workOrderViewData.recommendationInformations,
          workOrderViewData.recommendationInformations?.map(m => m.recommendationPhotos).reduce((a, b) => a.concat(b), []) ?? [],
          workOrderViewData.debriefAnswerInformations,
          workOrderViewData.inspectionAnswerInformations,
          siteSystems,
          workOrderViewData.workOrderSiteSystemInformations,
          workOrderViewData.workOrderLoanApplicationInformations,
          workOrderViewData.workOrderAgreementInformations,
          workOrderViewData.agreementSiteSystemInformations,
          workOrderViewData.salesProposalInformation,
          workOrderViewData.salesProposalPackageInformation,
          workOrderViewData.tagEntityInformations,
          workOrderViewData.callInformation,
          workOrderViewData.siteInformation?.propertyTypeId || 1,
          workOrderViewData.classMappingPerDetail
        );

        this.workOrderStateService.loadTaxItemSections(taxItemSections);
      }
    });
  }

  private getRecommendationPhotoPerSiteSystem(
    recommendations: RecommendationInformation[],
    recommendationPhotos: PhotoOrTempFile[]
  ): Record<number, PhotoOrTempFile[]> {
    const recommendationPerId = indexBy(recommendations, 'id');

    const results: Record<number, PhotoOrTempFile[]> = {};
    recommendationPhotos.forEach(recommendationPhoto => {
      // The recommendation could be null because we are currently changing the IDs.
      const recommendation = recommendationPerId[recommendationPhoto.recommendationId];
      if (recommendation) {
        const siteSystemId = recommendationPerId[recommendationPhoto.recommendationId].siteSystemId;
        results[siteSystemId] = results[siteSystemId] || [];
        results[siteSystemId].push(recommendationPhoto);
      }
    });

    return results;
  }

  private getInspectionAnswerPerSiteSystem(
    inspectionAnswers: InspectionAnswerInformation[]
  ): Record<number, InspectionAnswerInformation[]> {
    // The key is a number
    return groupBy(inspectionAnswers, 'siteSystemId');
  }

  private getWorkOrderSiteSystemPerSiteSystem(
    workOrderSiteSystems: WorkOrderSiteSystemInformation[]
  ): Record<number, WorkOrderSiteSystemInformation[]> {
    // The key is a number
    return groupBy(workOrderSiteSystems, 'siteSystemId');
  }

  private getSiteSystemInvolved(
    workOrderDetails: WorkOrderDetailInformation[],
    recommendations: RecommendationInformation[],
    inspectionAnswers: InspectionAnswerInformation[],
    siteSystems: SiteSystemInformation[],
    workOrderSiteSystems: WorkOrderSiteSystemInformation[]
  ): SiteSystemInformation[] {
    const involvedSiteSystems: SiteSystemInformation[] = [];

    const siteSystemIdsFromWorkOrderDetails = pluck(workOrderDetails || [], 'siteSystemId');
    const siteSystemIdsFromRecommendations = pluck(recommendations || [], 'siteSystemId');

    // This one is still on the old data model.
    const siteSystemIdsFromInspectionAnswers = pluck(inspectionAnswers || [], 'siteSystemId');

    // This is for old work orders.
    const siteSystemIdsFromSiteSystem = pluck(workOrderSiteSystems || [], 'siteSystemId');

    let finalValue = union(
      siteSystemIdsFromWorkOrderDetails,
      siteSystemIdsFromRecommendations,
      siteSystemIdsFromInspectionAnswers,
      siteSystemIdsFromSiteSystem
    );
    finalValue = finalValue.filter(m => m !== null);

    finalValue.forEach(m => {
      const siteSystem = siteSystems.find(n => n.id === m);
      if (siteSystem) {
        involvedSiteSystems.push(siteSystem);
      }
    });

    return involvedSiteSystems;
  }
}
