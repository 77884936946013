import { Injectable, inject } from '@angular/core';
import { type CustomFieldDisplay, type CustomFieldInformation } from '@models/cards/custom-field-information';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class CustomFieldsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(id?: Id): Observable<CustomFieldInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.CustomFields, this.url.customFieldsList, id);
  }

  save(customFieldInformation: CustomFieldInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.customFieldsCreate, customFieldInformation, skipBubbleError()).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.customFieldsDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.CustomFields);
    }
  }

  listFor(display: CustomFieldDisplay): Observable<CustomFieldInformation[]> {
    return this.list().pipe(
      map(customFields => {
        return customFields.filter(m => m.placements.some(n => n.display === display));
      })
    );
  }
}
