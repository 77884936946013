import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type AgreementSiteSystemInformationForList } from '@models/cards/agreement-site-system-information';
import { type BeltInformationForList } from '@models/cards/belt-information';
import { type FilterInformationForList } from '@models/cards/filter-information';
import { type SiteSystemInformation } from '@models/cards/site-system-information';
import { type WorkOrderInformationForList } from '@models/cards/work-order-information';
import { type FileInformation } from '@models/upload';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class SiteSystemsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<SiteSystemInformation> {
    return this.http.get<SiteSystemInformation>(this.url.siteSystemSingle.replace('$0', id.toString()));
  }

  save(siteSystemInformation: SiteSystemInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(siteSystemInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.siteSystemSave, obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.siteSystemDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.siteSystemRestore.replace('$0', id.toString()), null);
  }

  getWorkOrders(id: Id): Observable<WorkOrderInformationForList[]> {
    return this.http.get<WorkOrderInformationForList[]>(this.url.siteSystemWorkOrders.replace('$0', id.toString()));
  }

  getFilters(id: Id): Observable<FilterInformationForList[]> {
    return this.http.get<FilterInformationForList[]>(this.url.siteSystemFilters.replace('$0', id.toString()));
  }

  getBelts(id: Id): Observable<BeltInformationForList[]> {
    return this.http.get<BeltInformationForList[]>(this.url.siteSystemBelts.replace('$0', id.toString()));
  }

  getAgreements(id: Id): Observable<AgreementSiteSystemInformationForList[]> {
    return this.http.get<AgreementSiteSystemInformationForList[]>(this.url.siteSystemAgreements.replace('$0', id.toString()));
  }

  getFiles(id: Id): Observable<FileInformation[]> {
    return this.http.get<FileInformation[]>(this.url.siteSystemListFiles.replace('$0', id.toString()));
  }

  addFile(id: Id, fileId: Id): Observable<void> {
    return this.http.post<void>(this.url.siteSystemAddFile.replace('$0', id.toString()).replace('$1', fileId.toString()), null);
  }

  deleteFile(id: Id, fileId: Id): Observable<void> {
    return this.http.delete<void>(this.url.siteSystemDeleteFile.replace('$0', id.toString()).replace('$1', fileId.toString()));
  }

  deleteFiles(id: Id, fileIds: Id[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: fileIds,
    };

    return this.http.delete<void>(this.url.siteSystemDeleteFiles.replace('$0', id.toString()), options);
  }
}
