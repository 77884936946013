import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';

let nextId = 0;

const CHECKBOX_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};

export enum TransitionCheckState {
  /** The initial state of the component before any user interaction. */
  Init,
  /** The state representing the component when it's becoming checked. */
  Checked,
  /** The state representing the component when it's becoming unchecked. */
  Unchecked,
  /** The state representing the component when it's becoming indeterminate. */
  Indeterminate,
}

export class CheckboxChange {
  source!: CheckboxComponent;
  checked = false;
}

// Based of Material
@Component({
  selector: 'wm-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CHECKBOX_COMPONENT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class CheckboxComponent implements ControlValueAccessor {
  private readonly _renderer = inject(Renderer2);
  private readonly _elementRef = inject(ElementRef<HTMLElement>);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);

  @HostBinding('class.wm-checkbox')
  _wmCheckbox = 'true';

  @HostBinding('class.wm-checkbox-label-before')
  get labelPositionBefore(): boolean {
    return this.labelPosition === 'before';
  }

  get inputId(): string {
    return `input-${this.id}`;
  }

  @Input()
  required = false;

  /** Whether the checkbox is disabled. */
  @Input()
  @HostBinding('class.wm-checkbox-disabled')
  disabled = false;

  @Input()
  @HostBinding('class.wm-checkbox-checked')
  get checked(): boolean {
    return this._checked;
  }

  set checked(checked: boolean) {
    if (checked !== this.checked) {
      if (this._indeterminate) {
        this._indeterminate = false;
        this.indeterminateChange.emit(this._indeterminate);
      }
      this._checked = checked;
      this._transitionCheckState(this._checked ? TransitionCheckState.Checked : TransitionCheckState.Unchecked);
      this._changeDetectorRef.markForCheck();
      this.safariFix();
    }
  }

  @Input()
  @HostBinding('class.wm-checkbox-indeterminate')
  get indeterminate(): boolean {
    return this._indeterminate;
  }

  set indeterminate(indeterminate: boolean) {
    const changed = indeterminate !== this._indeterminate;
    this._indeterminate = indeterminate;
    if (this._indeterminate) {
      this._transitionCheckState(TransitionCheckState.Indeterminate);
    } else {
      this._transitionCheckState(this.checked ? TransitionCheckState.Checked : TransitionCheckState.Unchecked);
    }
    if (changed) {
      this.indeterminateChange.emit(this._indeterminate);
    }
  }

  @Input('aria-label') ariaLabel = '';
  @Input('aria-labelledby') ariaLabelledby: string | null = null;
  @Input() id = `wm-checkbox-${++nextId}`;

  @Input() labelPosition: 'before' | 'after' = 'after';

  @Input() tabIndex = 0;

  @Input() name: string | null = null;
  @Output() change = new EventEmitter<CheckboxChange>();
  @Output() indeterminateChange = new EventEmitter<boolean>();
  @ViewChild('input', { static: true }) _inputElement!: ElementRef<HTMLElement>;

  private _currentAnimationClass = '';

  private _currentCheckState: TransitionCheckState = TransitionCheckState.Init;

  private _checked = false;

  private _indeterminate = false;

  @HostBinding('class.wm-checkbox-focused')
  _hasFocus = false;

  @ViewChild('background', { read: ElementRef, static: true })
  background!: ElementRef<HTMLElement>;

  onTouched: () => any = () => {};

  private _controlValueAccessorChangeFn: (value: any) => void = _value => {};

  private safariFix(): void {
    if (this.isSafari()) {
      if (this.background) {
        this.background.nativeElement.style.display = 'none';
        setTimeout(() => {
          this.background.nativeElement.style.display = '';
        }, 0);
      }
    }
  }

  writeValue(value: any): void {
    this.checked = !!value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _transitionCheckState(newState: TransitionCheckState): void {
    const oldState = this._currentCheckState;
    const renderer = this._renderer;
    const elementRef = this._elementRef;

    if (oldState === newState) {
      return;
    }
    if (this._currentAnimationClass.length > 0) {
      renderer.removeClass(elementRef.nativeElement, this._currentAnimationClass);
    }

    this._currentAnimationClass = this._getAnimationClassForCheckStateTransition(oldState, newState);
    this._currentCheckState = newState;

    if (this._currentAnimationClass.length > 0) {
      renderer.addClass(elementRef.nativeElement, this._currentAnimationClass);
    }
  }

  private _emitChangeEvent(): void {
    const event = new CheckboxChange();
    event.source = this;
    event.checked = this.checked;

    this._controlValueAccessorChangeFn(this.checked);
    this.change.emit(event);
  }

  /** Informs the component when the input has focus so that we can style accordingly */
  _onInputFocus(): void {
    this._hasFocus = true;
  }

  /** Informs the component when we lose focus in order to style accordingly */
  _onInputBlur(): void {
    this._hasFocus = false;
    this.onTouched();
  }

  /** Toggles the `checked` state of the checkbox. */
  toggle(): void {
    this.checked = !this.checked;
  }

  /**
   * Event handler for checkbox input element.
   * Toggles checked state if element is not disabled.
   * @param event
   */
  _onInteractionEvent(event: Event): void {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();

    if (!this.disabled) {
      this.toggle();

      // Emit our custom change event if the native input emitted one.
      // It is important to only emit it, if the native input triggered one, because
      // we don't want to trigger a change event, when the `checked` variable changes for example.
      this._emitChangeEvent();
    }
  }

  /** Focuses the checkbox. */
  focus(): void {
    this._renderer.selectRootElement(this._inputElement.nativeElement);
    this._onInputFocus();
  }

  _onInputClick(event: Event): void {
    // We have to stop propagation for click events on the visual hidden input element.
    // By default, when a user clicks on a label element, a generated click event will be
    // dispatched on the associated input element. Since we are using a label element as our
    // root container, the click event on the `checkbox` will be executed twice.
    // The real click event will bubble up, and the generated click event also tries to bubble up.
    // This will lead to multiple click events.
    // Preventing bubbling for the second event will solve that issue.
    event.stopPropagation();
  }

  private _getAnimationClassForCheckStateTransition(oldState: TransitionCheckState, newState: TransitionCheckState): string {
    let animSuffix: string;

    switch (oldState) {
      case TransitionCheckState.Init:
        // Handle edge case where user interacts with checkbox that does not have [(ngModel)] or
        // [checked] bound to it.
        if (newState === TransitionCheckState.Checked) {
          animSuffix = 'unchecked-checked';
        } else {
          return '';
        }

        break;
      case TransitionCheckState.Unchecked:
        animSuffix = newState === TransitionCheckState.Checked ? 'unchecked-checked' : 'unchecked-indeterminate';
        break;
      case TransitionCheckState.Checked:
        animSuffix = newState === TransitionCheckState.Unchecked ? 'checked-unchecked' : 'checked-indeterminate';
        break;
      case TransitionCheckState.Indeterminate:
        animSuffix = newState === TransitionCheckState.Checked ? 'indeterminate-checked' : 'indeterminate-unchecked';
    }

    return `wm-checkbox-anim-${animSuffix}`;
  }

  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
}
