import { Injectable, inject, type OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { getWindow } from 'ssr-window';
import { PubSubService } from './pub-sub.service';

interface SimpleStorage {
  readonly length: number;
  clear: () => void;
  getItem: (key: string) => string | null;
  key: (index: number) => string | null;
  removeItem: (key: string) => void;
  setItem: (key: string, data: string) => void;
}

class MemoryStorage implements SimpleStorage {
  private object: any = {};
  private keys: string[] = [];

  get length(): number {
    return this.keys.length;
  }

  clear(): void {
    this.keys = [];
    this.object = {};
  }

  getItem(key: string): string | null {
    return this.object[key] || null;
  }

  key(index: number): string | null {
    return this.keys[index] || null;
  }

  removeItem(key: string): void {
    const pos = this.keys.indexOf(key);
    if (pos >= 0) {
      this.keys.splice(pos, 1);
      delete this.object[key];
    }
  }

  setItem(key: string, data: string): void {
    const pos = this.keys.indexOf(key);
    if (pos === -1) {
      this.keys.push(key);
    }

    this.object[key] = data;
  }
}

class WebStorage implements SimpleStorage {
  constructor(private readonly storage: Storage) {}

  static isAvailable(storage: Storage): boolean {
    try {
      storage.setItem('__testing_availability__', '1');
      storage.removeItem('__testing_availability__');
      return true;
    } catch (e) {}

    return false;
  }

  get length(): number {
    return this.storage.length;
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, data: string): void {
    this.storage.setItem(key, data);
  }
}

class SessionStorage extends WebStorage {
  constructor() {
    super(getWindow().sessionStorage);
  }

  static isAvailable(): boolean {
    try {
      return WebStorage.isAvailable(getWindow().sessionStorage);
    } catch (e) {}

    return false;
  }
}

class LocalStorage extends WebStorage {
  constructor() {
    super(getWindow().localStorage);
  }

  static isAvailable(): boolean {
    try {
      return WebStorage.isAvailable(getWindow().localStorage);
    } catch (e) {}

    return false;
  }
}

@Injectable()
export class CacheService implements OnDestroy {
  private readonly pubSubService = inject(PubSubService);

  private readonly cacheKeyPrefix = 'WM_Cache_Object:';
  private readonly cacheVersionKey = 'WM_Cache_Version';
  private readonly cacheUserKey = 'VM_Cache_User';
  private readonly cacheKeysKey = 'VM_Cache_Keys';
  private cacheKeys: string[] = [];

  private readonly cacheVersion = '1';

  private storage!: SimpleStorage;
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.initInternalStorage();

    if (this.pubSubService) {
      this.pubSubService
        .getEventEmitter('LOGIN')
        .pipe(takeUntil(this.destroy$))
        .pipe(
          tap(() => {
            console.log('Clearing CacheService.');
          })
        )
        .subscribe(() => {
          this.clear();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public init(userId: number): void {
    // If the version is different, then we clear.
    let shouldClear = false;

    if (this.storage.getItem(this.cacheVersionKey) !== this.cacheVersion) {
      shouldClear = true;
    }

    if (this.storage.getItem(this.cacheUserKey) !== userId.toString()) {
      shouldClear = true;
    }

    if (shouldClear) {
      this.clear();
    }

    this.storage.setItem(this.cacheUserKey, userId.toString());
  }

  get(key: string): string | null {
    return this.storage.getItem(this.getKey(key));
  }

  getObject<T>(key: string): T | null {
    const v = this.get(key);
    if (v) {
      return JSON.parse(v) as T;
    }

    return null;
  }

  set(key: string, value: string): void {
    if (!this.cacheKeys.includes(key)) {
      this.cacheKeys.push(key);
      this.storage.setItem(this.cacheKeysKey, JSON.stringify(this.cacheKeys));
    }

    this.storage.setItem(this.getKey(key), value);
  }

  setObject<T>(key: string, value: T): void {
    this.set(key, JSON.stringify(value));
  }

  remove(key: string): void {
    this.storage.removeItem(this.getKey(key));
  }

  clear(): void {
    for (const key of this.cacheKeys) {
      this.storage.removeItem(this.getKey(key));
    }

    this.storage.removeItem(this.cacheVersionKey);
    this.storage.removeItem(this.cacheUserKey);
    this.storage.removeItem(this.cacheKeysKey);

    this.initInternalStorage();
  }

  private getKey(key: string): string {
    return `${this.cacheKeyPrefix}${key}`;
  }

  private initInternalStorage(): void {
    if (LocalStorage.isAvailable()) {
      this.storage = new LocalStorage();
    } else if (SessionStorage.isAvailable()) {
      this.storage = new SessionStorage();
    } else {
      this.storage = new MemoryStorage();
    }

    this.cacheKeys = JSON.parse(this.storage.getItem(this.cacheKeysKey) ?? '[]') || [];
    if (!this.storage.getItem(this.cacheVersionKey)) {
      this.storage.setItem(this.cacheVersionKey, this.cacheVersion);
    }
  }
}
