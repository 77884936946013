/// <reference types="@types/google.maps" />

import { Injectable, inject } from '@angular/core';
import { ConfigService } from '@services/config.service';

const scriptId = 'google_map_script';
const callbackName = '__onGoogleMapLoaded';

@Injectable()
export class GoogleMapApiService {
  private readonly configService = inject(ConfigService);

  private _loadApi: Promise<void>;

  private readonly key = this.configService.config.google.apiKey;
  private readonly url = `https://maps.googleapis.com/maps/api/js?key=${this.key}&libraries=places&callback=${callbackName}`;

  public async loadMap(element: HTMLElement): Promise<google.maps.Map> {
    return await this.loadApi().then(
      () =>
        new google.maps.Map(element, {
          center: { lat: 40.007692, lng: -102.0618522 },
          zoom: 4,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_LEFT,
          },
        })
    );
  }

  public async loadApi(): Promise<void> {
    if (!this._loadApi) {
      this._loadApi = new Promise((resolve, reject) => {
        if (!window[callbackName]) {
          window[callbackName] = ev => {
            resolve();
          };

          this.loadScript();
        } else {
          resolve();
        }
      });
    }

    await this._loadApi;
  }

  public async getAutoSuggestManager(
    input: HTMLInputElement,
    map: google.maps.Map,
    options: google.maps.places.AutocompleteOptions = {}
  ): Promise<google.maps.places.Autocomplete> {
    return await new Promise((resolve, reject) => {
      const autoComplete = new google.maps.places.Autocomplete(input, {
        ...options,
        ...{
          fields: ['geometry'],
          types: ['address'],
          strictBounds: false,
          componentRestrictions: {
            country: 'us',
          },
        },
      });
      autoComplete.bindTo('bounds', map);

      resolve(autoComplete);
    });
  }

  private loadScript(): void {
    const node = document.createElement('script');
    node.src = this.url;
    node.id = scriptId;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
