import { ChangeDetectorRef, Pipe, inject, type OnDestroy, type PipeTransform } from '@angular/core';
import { type Resource } from '@models/resource';
import { AgreementsService } from '@services/live/agreements.service';
import { CallDepartmentTypesService } from '@services/live/call-department-types.service';
import { CallReasonsService } from '@services/live/call-reasons.service';
import { CallWorkOrderTypesService } from '@services/live/call-work-order-types.service';
import { FilterTypesService } from '@services/live/filter-types.service';
import { PaymentMethodsService } from '@services/live/payment-methods.service';
import { PaymentTypesService } from '@services/live/payment-types.service';
import { ReferralTypesService } from '@services/live/referral-types.service';
import { SystemTypesService } from '@services/live/system-types.service';
import { TaxItemSectionsService } from '@services/live/tax-item-sections.service';
import { ZonesService } from '@services/live/zones.service';
import { getById } from '@utility/observable';
import { addSeconds, isAfter } from 'date-fns';
import { BehaviorSubject, of, type Observable, type Subscription } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';
import { EntityPipe } from '../pipes/entity.pipe';
import { CustomersService } from '../services/live/customers.service';
import { StaticDataService } from '../services/static-data.service';

type LiveData = 'customer' | 'agreement' | 'zone' | 'taxItem' | 'taxItemSection';

type StaticData =
  | 'propertyType'
  | 'technician'
  | 'filterType'
  | 'systemType'
  | 'callReason'
  | 'paymentType'
  | 'paymentMethod'
  | 'callWorkOrderType'
  | 'callDepartmentType'
  | 'referralType'
  | 'rateType';

export type RefType = LiveData | StaticData | 'test';

// This info would leak to another person logging in, but we only keep it for 10 seconds.
const keepCacheForSeconds = 10;
const cacheResult: Record<string, { date: Date; result: Observable<any> }> = {};

// Using similarity from AsyncPipe to avoid having to pipe |ref|async in HTML.
@Pipe({
  name: 'ref',
  pure: false,
  standalone: true,
})
export class RefPipe implements PipeTransform, OnDestroy {
  private readonly _ref = inject(ChangeDetectorRef);
  private readonly staticData = inject(StaticDataService);
  private readonly customersService = inject(CustomersService);
  private readonly entityPipe = inject(EntityPipe);
  private readonly systemTypesService = inject(SystemTypesService);
  private readonly callReasonsService = inject(CallReasonsService);
  private readonly callWorkOrderTypesService = inject(CallWorkOrderTypesService);
  private readonly filterTypesService = inject(FilterTypesService);
  private readonly paymentMethodsService = inject(PaymentMethodsService);
  private readonly paymentTypesService = inject(PaymentTypesService);
  private readonly referralTypesService = inject(ReferralTypesService);
  private readonly callDepartmentTypesService = inject(CallDepartmentTypesService);
  private readonly agreementsService = inject(AgreementsService);
  private readonly zonesService = inject(ZonesService);
  private readonly taxItemSectionsService = inject(TaxItemSectionsService);

  private _latestValue: any = null;
  private _latestReturnedValue: any = null;
  private _subscription: Subscription | null = null;
  private _obj: Observable<any> | null = null;

  private previousId?: Id;
  private readonly _result = new BehaviorSubject<string>('');
  private readonly result: Observable<string> = this._result.asObservable();

  ngOnDestroy(): void {
    if (this._subscription) {
      this._dispose();
    }
  }

  transform(id: Id, type: string): any {
    const obj = this.refTransform(id, type);
    return this.asyncTrasnform(obj);
  }

  private asyncTrasnform(obj: Observable<any>): any {
    if (!this._obj) {
      if (obj) {
        this._subscribe(obj);
      }
      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }
    if (obj !== this._obj) {
      this._dispose();
      return this.asyncTrasnform(obj);
    }
    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }
    this._latestReturnedValue = this._latestValue;

    // TODO Angular14
    return this._latestValue;
    // return WrappedValue.wrap(this._latestValue);
  }

  private getCacheResult(key: string, obs: Observable<any>): Observable<any> {
    // Evict first
    const date = new Date();
    const keysToDelete: string[] = [];
    Object.keys(cacheResult).forEach(innerKey => {
      if (isAfter(date, addSeconds(cacheResult[innerKey].date, keepCacheForSeconds))) {
        keysToDelete.push(innerKey);
      }
    });

    for (const k of keysToDelete) {
      delete cacheResult[k];
    }

    // Set the result.
    if (!cacheResult[key]) {
      cacheResult[key] = {
        date,
        result: obs.pipe(shareReplay(1)),
      };
    }

    return cacheResult[key].result;
  }

  private refTransform(id: Id, type: string): Observable<string> {
    if (!id) {
      if (type === 'zone') {
        return of('Local');
      }

      return of('');
    }

    if (this.previousId !== id) {
      this.previousId = id;
      switch (type) {
        case 'propertyType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.staticData.getPropertyTypes(id)));
          break;
        case 'technician':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.staticData.getTechnicians(id)));
          break;
        case 'filterType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.filterTypesService.list(id)));
          break;
        case 'systemType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.systemTypesService.list(id)));
          break;
        case 'callReason':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.callReasonsService.list(id)));
          break;
        case 'paymentMethod':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.paymentMethodsService.list(id)));
          break;
        case 'paymentType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.paymentTypesService.list(id)));
          break;
        case 'callWorkOrderType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.callWorkOrderTypesService.list(id)));
          break;
        case 'callDepartmentType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.callDepartmentTypesService.list(id)));
          break;
        case 'referralType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.referralTypesService.list(id)));
          break;
        case 'rateType':
          this.saveResult(id, this.getCacheResult(`${type};${id}`, this.staticData.getRateTypes(id)));
          break;

        // Live data
        case 'customer':
          this.getCacheResult(`${type};${id}`, this.customersService.get(id)).subscribe(m => {
            this._result.next(this.entityPipe.transform(m.entity));
          });
          break;
        case 'zone':
          this.getCacheResult(`${type};${id}`, this.zonesService.list().pipe(getById(id))).subscribe(m => {
            this._result.next(m.text);
          });
          break;
        case 'taxItemSection':
          this.getCacheResult(`${type};${id}`, this.taxItemSectionsService.list(id).pipe(getById(id))).subscribe(m => {
            this._result.next(m.text);
          });
          break;
        case 'taxItem':
          this.getCacheResult(`${type};${id}`, this.taxItemSectionsService.getTaxItems(id).pipe(getById(id))).subscribe(m => {
            this._result.next(m.text);
          });
          break;
        case 'agreement':
          this.getCacheResult(`${type};${id}`, this.agreementsService.list(id).pipe(getById(id))).subscribe(m => {
            this._result.next(m.text);
          });
          break;

        // Test Data
        case 'test':
        default:
          this.saveResult(
            id,
            of([
              {
                id,
                text: '!!Missing Reference!!',
              },
            ] as Resource[])
          );
          break;
      }
    }

    return this.result;
  }

  private _subscribe(obj: Observable<any>): void {
    const _this = this;
    this._obj = obj;

    this._subscription = obj.subscribe({
      next(value) {
        _this._updateLatestValue(obj, value);
      },
      error: (e: any) => {
        throw e;
      },
    });
  }

  private _dispose(): void {
    this._subscription?.unsubscribe();
    this._latestValue = null;
    this._latestReturnedValue = null;
    this._subscription = null;
    this._obj = null;
  }

  private _updateLatestValue(async: any, value: Object): void {
    if (async === this._obj) {
      this._latestValue = value;
      this._ref.markForCheck();
    }
  }

  private saveResult(id: Id, observable: Observable<Resource[]>): void {
    observable.pipe(first()).subscribe(m => {
      const found = m.find(n => n.id === id);
      if (found) {
        this._result.next(found.text ?? '');
      }
    });
  }
}
