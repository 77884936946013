import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, type OnDestroy, type OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { InputImageComponent } from '@controls/input-image/input-image.component';
import { InputMomentPickerComponent } from '@controls/input-moment-picker/input-moment-picker.component';
import { InputTextComponent } from '@controls/input-text/input-text.component';
import { RadioButtonComponent, RadioGroupDirective } from '@controls/radio/radio.component';
import { FILTER_DIALOG_DATA } from '@models/dialog';
import { FilterData } from '@models/filter-models';
import { type Resource } from '@models/resource';
import { createEmpty, type TechnicianInformation } from '@models/technician';
import { SharedModule } from '@modules/shared.module';
import { DutyCategoriesService } from '@services/live/duty-categories.service';
import { StaticDataService } from '@services/static-data.service';
import { TimerService } from '@services/timer.service';
import { endOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterComponent } from './filter.component';

/** @deprecated */
@Component({
  templateUrl: 'filter-duty.component.html',
  styleUrls: ['filter-duty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    RadioGroupDirective,
    RadioButtonComponent,
    InputMomentPickerComponent,
    InputDropdownComponent,
    InputImageComponent,
    InputTextComponent,
    CheckboxComponent,
  ],
})
export class FilterDutyComponent extends FilterComponent implements OnInit, OnDestroy {
  dateControl = new FormControl();
  private readonly destroy$ = new Subject<void>();

  _datePipe: DatePipe;
  constructor(
    private readonly staticData: StaticDataService,
    private readonly dutyCategoriesService: DutyCategoriesService,
    private readonly cd: ChangeDetectorRef,
    private readonly timerService: TimerService,
    @Inject(FILTER_DIALOG_DATA) data: FilterData
  ) {
    super(data);
    this._datePipe = new DatePipe('en-US');
  }

  statuses: Resource[] = [
    { id: -1, text: this.getStatusDisplay(0) },
    { id: 1, text: this.getStatusDisplay(1) },
    { id: 2, text: this.getStatusDisplay(2) },
  ];

  technicians: TechnicianInformation[] = [];
  dutyCategories: Resource[] = [];

  ngOnInit(): void {
    this.staticData.getTechnicians().subscribe(m => {
      const emptyTechnician: TechnicianInformation = {
        ...createEmpty(),
        id: -1,
        text: 'No Assignee',
      };
      this.technicians = [emptyTechnician].concat(m);
      this.assignee = this.assignee; // We assign the correct data.
      this.cd.markForCheck();
    });

    this.dutyCategoriesService.list().subscribe(m => {
      this.dutyCategories = m;
      this.dutyCategoryId = this.dutyCategoryId;
      this.cd.markForCheck();
    });

    // Assign the radio button
    if (this.filters) {
      const dateFilter = this.filters.find(m => m.id === 'date');
      if (dateFilter) {
        this.dateControl.patchValue(dateFilter.value);
      }
    }

    this.zip = this.filters?.find(m => m.id === 'zip')?.value ?? undefined;

    this.dateControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: number) => {
      if (value === 1 || value === 2) {
        this.setValue('date', this.getDateDisplay(value, undefined, undefined), value);
        this.removeValue('dateFrom');
        this.removeValue('dateEnd');
      } else if (value === 3 && (this.dateFrom || this.dateEnd)) {
        this.setValue('date', this.getDateDisplay(value, this.dateFrom, this.dateEnd), 3);
      } else {
        this.removeValue('date');
        this.removeValue('dateFrom');
        this.removeValue('dateEnd');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get dateFrom(): Date | undefined {
    return this.getValue('dateFrom');
  }

  set dateFrom(value: Date | undefined) {
    this.setValue('dateFrom', undefined, value);
    if (value !== undefined) {
      this.dateControl.patchValue(3);
    }
  }

  get dateEnd(): Date | undefined {
    return this.getValue('dateEnd');
  }

  set dateEnd(value: Date | undefined) {
    this.setValue('dateEnd', undefined, value ? endOfDay(value) : undefined);
    if (value !== undefined) {
      this.dateControl.patchValue(3);
    }
  }

  get overdue(): boolean {
    return !!this.getValue('overdue');
  }

  set overdue(value: boolean) {
    if (value) {
      this.setValue('overdue', 'Overdue', value);
    } else {
      this.removeValue('overdue');
    }
  }

  get status(): number {
    return this.getValue('status') || -1;
  }

  set status(value: number) {
    if (value > 0) {
      this.setValue('status', this.getStatusDisplay(value), value);
    } else {
      this.removeValue('status');
    }
  }

  get deleted(): boolean {
    return !!this.getValue('deleted');
  }

  set deleted(value: boolean) {
    if (value) {
      this.setValue('deleted', 'Show Deleted', value);
    } else {
      this.removeValue('deleted');
    }
  }

  get priorityLow(): boolean {
    return !!this.getValue('priorityLow');
  }

  set priorityLow(value: boolean) {
    if (value) {
      this.setValue('priorityLow', 'Priority Low', value);
    } else {
      this.removeValue('priorityLow');
    }
  }

  get priorityNormal(): boolean {
    return !!this.getValue('priorityNormal');
  }

  set priorityNormal(value: boolean) {
    if (value) {
      this.setValue('priorityNormal', 'Priority Normal', value);
    } else {
      this.removeValue('priorityNormal');
    }
  }

  get priorityHigh(): boolean {
    return !!this.getValue('priorityHigh');
  }

  set priorityHigh(value: boolean) {
    if (value) {
      this.setValue('priorityHigh', 'Priority High', value);
    } else {
      this.removeValue('priorityHigh');
    }
  }

  get priorityBlocker(): boolean {
    return !!this.getValue('priorityBlocker');
  }

  set priorityBlocker(value: boolean) {
    if (value) {
      this.setValue('priorityBlocker', 'Priority End of Day', value);
    } else {
      this.removeValue('priorityBlocker');
    }
  }

  get assignee(): number | undefined {
    return this.getValue('assignee');
  }

  set assignee(value: number | undefined) {
    if (value) {
      this.setValue('assignee', `Assignee: ${this.getTechnicianName(value)}`, value);
    } else {
      this.removeValue('assignee');
    }
  }

  get zip(): string | undefined {
    return this.getValue('zip');
  }

  set zip(value: string | undefined) {
    if (value) {
      this.setValue('zip', `Zip: ${value}`, value);
    } else {
      this.removeValue('zip');
    }
  }

  get dutyCategoryId(): number | undefined {
    return this.getValue('dutyCategoryId');
  }

  set dutyCategoryId(value: number | undefined) {
    if (value) {
      this.setValue('dutyCategoryId', `Task Type: ${this.getCategoryName(value)}`, value);
    } else {
      this.removeValue('dutyCategoryId');
    }
  }

  getStatusDisplay(value: number): string | undefined {
    switch (value) {
      case 0:
        return 'All';
      case 1:
        return 'Active';
      case 2:
        return 'Completed';
    }
  }

  getDateDisplay(value: number, dateFrom: Date | undefined, dateEnd: Date | undefined): string | undefined {
    switch (value) {
      case 1:
        return 'Next 7 Days';
      case 2:
        return 'Next 30 Days';
      case 3:
        let range = '';
        if (dateFrom && dateEnd) {
          range = `from ${this._datePipe.transform(dateFrom, 'shortDate')} to ${this._datePipe.transform(dateEnd, 'shortDate')}`;
        } else if (dateFrom) {
          range = `from ${this._datePipe.transform(dateFrom, 'shortDate')}`;
        } else if (dateEnd) {
          range = `until ${this._datePipe.transform(dateEnd, 'shortDate')}`;
        }

        return `Range: ${range}`;
    }
  }

  getTechnicianName(value: Id): string {
    if (this.technicians) {
      if (value === -1) {
        return 'Nobody';
      }

      const technician = this.technicians.find(m => m.id === value);
      if (technician) {
        return technician.text ?? '';
      }
    }

    return '';
  }

  getCategoryName(value: Id): string {
    if (this.dutyCategories) {
      const category = this.dutyCategories.find(m => m.id === value);
      if (category) {
        return category.text ?? '';
      }
    }

    return '';
  }

  filtersChanged(): void {
    const date = this.getValue('date');
    if (typeof date === 'number') {
      this.dateControl.patchValue(date);
    } else if (date == null) {
      this.dateControl.patchValue(0);
    }

    this.cd.markForCheck();
  }

  changeStatus(): void {
    this.timerService.setTimeout(() => {
      if (this.overdue) {
        this.status = 1;
        this.cd.markForCheck();
      }
    }, 0);
  }
}
