import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { type SiteSystemInformation } from '@models/cards/site-system-information';

@Pipe({
  name: 'siteSystem',
  standalone: true,
})
@Injectable()
export class SiteSystemPipe implements PipeTransform {
  transform(siteSystem: SiteSystemInformation): string {
    if (!siteSystem) {
      return '';
    }

    return `#${siteSystem.localId.toString()}`;
  }
}
