import { Injectable, inject } from '@angular/core';
import { type RecommendationPhotoInformation } from '@models/cards/recommendation-information';
import { skipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class RecommendationPhotosService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  save(recommendationPhotoInformation: RecommendationPhotoInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.recommendationPhotoSave, recommendationPhotoInformation, skipBubbleError());
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.recommendationPhotoHide.replace('$0', id.toString()));
  }
}
