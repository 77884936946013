import { Injectable, inject } from '@angular/core';
import { type VideoCategory } from '@models/video-category';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class VideosService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getCategories(): Observable<VideoCategory[]> {
    return this.http.get<VideoCategory[]>(this.url.videoCategoryGetList);
  }
}
