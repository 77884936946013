import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * @deprecated
 */
@Component({
  selector: 'wm-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class FilterComponent {
  @Input()
  activeFilters: string[] = [];

  @Input()
  clearActive = false;

  @Input()
  pointerAt?: number;

  @Input()
  pageFilter = false;

  @Output()
  editClick = new EventEmitter<void>();

  @Output()
  clearAllClick = new EventEmitter<void>();

  get filterText(): string {
    let forThisPageText = '';
    if (this.pageFilter) {
      forThisPageText = ' for this page';
    }

    if (!this.activeFilters || this.activeFilters.length === 0) {
      return `No filters are currently selected${forThisPageText}.`;
    }

    if (this.activeFilters.length === 1) {
      return `1 filter is currently selected${forThisPageText}.`;
    }

    return `${this.activeFilters.length} filters are currently selected${forThisPageText}.`;
  }

  clearAll(): void {
    if (this.clearActive) {
      this.clearAllClick.emit();
    }
  }

  edit(): void {
    this.editClick.emit();
  }
}
