import { Injectable, inject } from '@angular/core';
import { type TechnicianInformation } from '@models/technician';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { HttpClientService } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface TechniciansListData {
  technicians: TechnicianInformation[];
  currentNumberOfActiveTechnicians: number;
  maximumNumberOfActiveTechnicians: number;
}

@Injectable()
export class TechniciansService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(): Observable<TechniciansListData> {
    return this.http.get<TechniciansListData>(this.url.techniciansList);
  }

  get(id: Id): Observable<TechnicianInformation> {
    return this.http.get<TechnicianInformation>(this.url.technicianSingle.replace('$0', id.toString()));
  }

  save(id: Id, patchData: Partial<TechnicianInformation>): Observable<{ id: Id }> {
    return this.http
      .post<{ id: Id }>(this.url.technicianSave, {
        ...patchData,
        id,
      })
      .pipe(
        tap(() => {
          this.clearCache();
        })
      );
  }

  createTechnician(
    technicianData: Partial<TechnicianInformation>,
    newPassword: string | null = null,
    deleteTechnicianId: Id | null = null
  ): Observable<{ id: Id }> {
    return this.http
      .post<{ id: Id }>(this.url.technicianSave, {
        ...technicianData,
        password: newPassword,
        deleteTechnicianId,
      })
      .pipe(
        tap(() => {
          this.clearCache();
        })
      );
  }

  unblockTechnician(id: Id): Observable<void> {
    return this.http.post<void>(this.url.technicianUnblock.replace('$0', id.toString()), {}).pipe(
      tap(() => {
        this.clearCache();
      })
    );
  }

  hasOpenedObjects(id: Id): Observable<boolean> {
    return this.http.get<boolean>(this.url.technicianHasOpenedObjects.replace('$0', id.toString()));
  }

  transfer(technicianId: Id, destinationTechnicianId: Id): Observable<void> {
    return this.http.post<void>(
      this.url.technicianTransfer.replace('$0', technicianId.toString()).replace('$1', destinationTechnicianId.toString()),
      {}
    );
  }

  postProcessPhoto(technicianId: Id): Observable<void> {
    return this.http.post<void>(this.url.technicianPostProcessPhoto.replace('$0', technicianId.toString()), {});
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.technicianDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.technicianRestore.replace('$0', id.toString()), null).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  private clearCache(): void {
    this.applicationCacheService.clearCategory(UpdateCategory.Technicians);
  }
}
