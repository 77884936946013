import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'int',
  standalone: true,
})
export class IntPipe implements PipeTransform {
  transform(value: string | number | undefined): any {
    return parseInt(value?.toString() ?? '', 10);
  }
}
