import { CommonModule } from '@angular/common';
import { Component, TemplateRef, contentChild, forwardRef, input, type Provider } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputMoneyComponent } from '@controls/input-money/input-money.component';
import { Rw2Component } from '@controls/rw/rw2.component';
import { Mode } from '@models/form';
import { MoneyPipe } from '@pipes/money.pipe';

const RW_MONEY_COMPONENT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RwMoney2Component),
  multi: true,
};

// Angular 18
@Component({
  selector: 'wm-rw-money2',
  templateUrl: 'rw-money2.component.html',
  styleUrls: ['rw-money.component.scss'],
  providers: [RW_MONEY_COMPONENT_VALUE_ACCESSOR],
  exportAs: 'rwMoney',
  host: {
    '[class.rw]': 'true',
  },
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, Rw2Component, InputMoneyComponent, MoneyPipe],
})
export class RwMoney2Component extends ElementBaseWithFormControl<Money | null> {
  readonly Mode = Mode;

  readonly name = input<string>();
  readonly mode = input(Mode.Read);
  readonly required = input(false);
  readonly min = input<Money>();
  readonly max = input<Money>();

  readonly helperTemplate2 = contentChild('helperTemplate', { read: TemplateRef });

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
