import { createFeatureSelector, createSelector } from '@ngrx/store';
import { type PartItemDetailState } from './part-item-detail.reducer';

export const PART_ITEM_DETAIL_STORE_KEY = 'partItemDetail';

export const partItemDetailStateSelector = createFeatureSelector<PartItemDetailState>(PART_ITEM_DETAIL_STORE_KEY);

export const mode = createSelector(partItemDetailStateSelector, state => state.edit);
export const partItem$ = createSelector(partItemDetailStateSelector, state => state.entities.partItem);
export const categoryIds$ = createSelector(partItemDetailStateSelector, state => state.entities.categoryIds);
export const serviceRepairs$ = createSelector(partItemDetailStateSelector, state => state.entities.serviceRepairs);

export const cacheCorrelationId$ = createSelector(partItemDetailStateSelector, state => state._cacheCorrelationId);
export const saveCorrelationId$ = createSelector(partItemDetailStateSelector, state => state._correlationId);
export const error$ = createSelector(partItemDetailStateSelector, state => state._error);

export const partItemWithCategoryIds = createSelector(partItem$, categoryIds$, (partItem, categoryIds) => ({ partItem, categoryIds }));

export const serviceRepairLines$ = createSelector(serviceRepairs$, serviceRepairs => {
  return serviceRepairs?.ids ? Object.values(serviceRepairs.ids) : [];
});

export const serviceRepairIdsWithParentId$ = createSelector(partItem$, serviceRepairs$, (partItem, serviceRepairs) => ({
  partItemId: partItem.id,
  serviceRepairIds: serviceRepairs.ids,
}));
