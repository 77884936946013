import { PercentPipe } from '@angular/common';
import { Injectable, LOCALE_ID, Pipe, inject, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'tax',
  standalone: true,
})
@Injectable()
export class TaxPipe implements PipeTransform {
  private readonly _locale = inject(LOCALE_ID);
  private readonly percentPipe: PercentPipe;

  constructor() {
    this.percentPipe = new PercentPipe(this._locale);
  }

  // Need to keep string|boolean to satisfy the PercentPipe
  transform(tax: Fraction | undefined | null): string | null {
    const digits = '1.0-8';
    return tax != null ? this.percentPipe.transform(tax, digits) : null;
  }
}
