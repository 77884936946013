import { Directive, ElementRef, EventEmitter, type OnInit, Output, inject } from '@angular/core';

@Directive({
  selector: '[init]',
  standalone: true,
})
export class InitDirective implements OnInit {
  private readonly ref = inject(ElementRef);

  @Output() init = new EventEmitter<ElementRef>();

  ngOnInit(): void {
    this.init.emit(this.ref);
  }
}
