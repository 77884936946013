import { Injectable } from '@angular/core';
import { type AppErrorHandler, ErrorMessage, SimpleErrorMessage } from '@models/error-models';

export { type AppErrorHandler, ErrorMessage, SimpleErrorMessage };

@Injectable()
export class ErrorService {
  errorHandlers: AppErrorHandler[] = [];

  register(handler: AppErrorHandler): void {
    this.errorHandlers.push(handler);
  }

  unregister(handler: AppErrorHandler): boolean {
    const index = this.errorHandlers.indexOf(handler);
    if (index >= 0) {
      this.errorHandlers.splice(index, 1);
      return true;
    }

    return false;
  }

  trigger(error: ErrorMessage<any>) {
    for (const errorHandler of this.errorHandlers.slice(0).reverse()) {
      if (errorHandler.onAppError(error)) {
        break;
      }
    }
  }
}
