import { Injectable } from '@angular/core';
import { type Subscription } from 'rxjs';

@Injectable()
export class SubscriptionManagerService {
  private subscriptions: Subscription[] = [];

  add(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  unsubscribe(subscription: Subscription): void {
    const indexPos = this.subscriptions.indexOf(subscription);
    if (indexPos >= 0) {
      subscription.unsubscribe();
      this.subscriptions.splice(indexPos, 1);
    }
  }

  destroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.subscriptions = [];
  }
}
