import { Injectable, inject } from '@angular/core';
import { type InspectionAnswerInformation } from '@models/cards/inspection-answer-information';
import { type Resource } from '@models/resource';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class InspectionAnswersService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  save(inspectionAnswerInformations: InspectionAnswerInformation[]): Observable<Resource[]> {
    return this.http.post<Resource[]>(this.url.inspectionAnswerSave, inspectionAnswerInformations);
  }
}
