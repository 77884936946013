import { type AppRole } from '@models/app-role';
import { type ScheduleEventInformation } from '@models/dashboard-event';
import { type Creatable, type Updatable } from '@models/mutable';
import { type PartItemInformation, type ServiceRepairInformation } from '@models/price-book-models';
import { type Action, createReducer, on } from '@ngrx/store';
import { type SaveState } from '@services/save-store/save.actions';
import { clone } from '@utility/object';
import { addMinutes, endOfDay, isAfter, isValid, isWithinInterval, startOfDay } from 'date-fns';
import * as DashboardActions from './dashboard.actions';
import { DASHBOARD_EVICT_MINUTES } from './dashboard.selectors';

export type ServiceRepairInformationState = ServiceRepairInformation & Creatable & Updatable;
export type PartItemState = { ids: { id: Id; isNew: boolean }[] } & Updatable;
export type PartItemInformationState = Record<string, PartItemInformation & Creatable & Updatable>;
export type ServiceRepairCategoryIdState = { ids: [] } & Updatable;

export interface EntitiesState {
  dashboardEvents: ServiceRepairInformationState;
  categoryIds: ServiceRepairCategoryIdState;
  partItemIds: PartItemState;
  partItems: PartItemInformationState;
}

export interface LoadedDate {
  date: Date;
  lastUse: Date;
}

export interface DashboardState extends SaveState {
  roles: AppRole[];
  requestedDate: Date | null;
  loading: boolean;
  loadedDates: LoadedDate[];
  scheduleEvents: ScheduleEventInformation[];
  active: boolean;
  connected: boolean;
  liveUpdateEnabled: boolean;
}

export const initialState: DashboardState = {
  roles: [],
  requestedDate: null,
  loading: false,
  loadedDates: [],
  scheduleEvents: [],
  active: false,
  connected: false,
  liveUpdateEnabled: false,

  _submitCounter: 0,
  _error: null,
  _cacheCorrelationId: null,
  _correlationId: null,
};

function clearUpCacheIfNecessary(
  state: DashboardState,
  clear: boolean
): { loadedDates: LoadedDate[]; scheduleEvents: ScheduleEventInformation[] } {
  let loadedDates = state.loadedDates;
  let scheduleEvents = state.scheduleEvents;
  if (clear) {
    // We keep our current date. So we don't get kicked out by the trackEviction
    const requestedMoment = state.requestedDate;
    loadedDates =
      (requestedMoment &&
        loadedDates.filter(m => {
          return m.date.getTime() === requestedMoment.getTime();
        })) ||
      [];

    if (state.requestedDate) {
      // We keep the scheduleEvents only in the view.
      scheduleEvents = scheduleEvents.filter(m =>
        isWithinInterval(requestedMoment, {
          start: startOfDay(m.start),
          end: endOfDay(m.end),
        })
      );
    }
  }

  return {
    loadedDates,
    scheduleEvents,
  };
}

// Start: dashboardReducer
const dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.clear, (state, action) => {
    return clone(initialState);
  }),
  on(DashboardActions.setRoles, (state, action) => {
    return {
      ...state,
      roles: action.roles,
    };
  }),
  on(DashboardActions.changeStatus, (state, action) => {
    return {
      ...state,
      ...clearUpCacheIfNecessary(state, !action.active),
      active: action.active,
    };
  }),
  on(DashboardActions.changeConnected, (state, action) => {
    return {
      ...state,
      ...clearUpCacheIfNecessary(state, !action.connected),
      connected: action.connected,
    };
  }),
  on(DashboardActions.changeLiveUpdate, (state, action) => {
    return {
      ...state,
      ...clearUpCacheIfNecessary(state, !action.enable),
      liveUpdateEnabled: action.enable,
    };
  }),
  on(DashboardActions.changeCurrentDate, (state, action) => {
    const requestedDate = startOfDay(action.date);

    let loadedDates = state.loadedDates;
    const loadedDatePos = loadedDates.findIndex(m => m.date.getTime() === requestedDate.getTime());
    if (loadedDatePos >= 0) {
      loadedDates = loadedDates.concat({
        date: requestedDate,
        lastUse: new Date(),
      });
      loadedDates.splice(loadedDatePos, 1);
    }

    return {
      ...state,
      loadedDates,
      requestedDate,
    };
  }),
  on(DashboardActions.loadEventsByDateSuccess, (state, action) => {
    const scheduleEventIds = action.scheduleEvents.map(m => m.id);
    const loadedMoment = startOfDay(action.date);
    const loadedDate = loadedMoment;
    const now = new Date();

    // For all scheduleEvents block events that we returned, we invalidate the loadedDates we found that blockEvent.
    const blockScheduleEventIds = action.scheduleEvents.filter(m => !m.callDetails).map(m => m.id);
    const newScheduleEvents = [];
    const toRemoveLoadedDates: Set<number> = new Set<number>();
    state.scheduleEvents.forEach(scheduleEvent => {
      // We have a block event. So we will track the loaded dates and remove them.
      if (blockScheduleEventIds.includes(scheduleEvent.id)) {
        toRemoveLoadedDates.add(startOfDay(scheduleEvent.start).getTime());
        toRemoveLoadedDates.add(startOfDay(scheduleEvent.end).getTime());
      } else {
        const shouldAdd = !(
          scheduleEventIds.includes(scheduleEvent.id) ||
          isWithinInterval(loadedMoment, {
            start: startOfDay(scheduleEvent.start),
            end: endOfDay(scheduleEvent.end),
          })
        );

        if (shouldAdd) {
          newScheduleEvents.push(scheduleEvent);
        }
      }
    });

    let loadedDates: LoadedDate[] = [];
    if (state.liveUpdateEnabled) {
      loadedDates = state.loadedDates
        .filter(m => m.date.getTime() !== loadedDate.getTime() && !toRemoveLoadedDates.has(m.date.getTime()))
        .concat([{ date: loadedDate, lastUse: now }]);
    }

    return {
      ...state,
      loadedDates,
      scheduleEvents: newScheduleEvents.concat(action.scheduleEvents),
    };
  }),
  on(DashboardActions.updateDashboardEvent, (state, action) => {
    const newScheduleEvents: ScheduleEventInformation[] = [];
    const toRemoveLoadedDates: Set<number> = new Set<number>();
    state.scheduleEvents.forEach(scheduleEvent => {
      if (scheduleEvent.id === action.scheduleEvent.id) {
        // We have a block event. So we will track the loaded dates and remove them.
        if (!action.scheduleEvent.callDetails) {
          toRemoveLoadedDates.add(startOfDay(scheduleEvent.start).getTime());
          toRemoveLoadedDates.add(startOfDay(scheduleEvent.end).getTime());
        }
      } else {
        newScheduleEvents.push(scheduleEvent);
      }
    });

    let loadedDates: LoadedDate[] = [];
    if (state.liveUpdateEnabled) {
      const currentlyWatchingTime = state.requestedDate ? state.requestedDate.getTime() : 0;
      toRemoveLoadedDates.delete(currentlyWatchingTime);
      loadedDates = state.loadedDates.filter(m => !toRemoveLoadedDates.has(m.date.getTime()));
    }

    return {
      ...state,
      loadedDates,
      scheduleEvents: newScheduleEvents.concat([action.scheduleEvent]),
    };
  }),
  on(DashboardActions.trackEviction, (state, action) => {
    // If the date is older than X minutes, we remove
    // But we don't remove our current date.
    const requestedMoment = state.requestedDate;
    const fiveMinutesAgo = addMinutes(new Date(), -DASHBOARD_EVICT_MINUTES);
    const loadedDates = state.loadedDates.filter(
      m => isAfter(m.lastUse, fiveMinutesAgo) || (isValid(requestedMoment) && m.date.getTime() === requestedMoment.getTime())
    );
    const loadedMoments = loadedDates.map(loadedDate => loadedDate.date);

    // We never delete our requested date.
    if (state.requestedDate) {
      loadedMoments.push(requestedMoment);
    }

    const scheduleEvents = state.scheduleEvents.filter(scheduleEvent =>
      loadedMoments.some(loadedMoment =>
        isWithinInterval(loadedMoment, {
          start: startOfDay(scheduleEvent.start),
          end: endOfDay(scheduleEvent.end),
        })
      )
    );

    return {
      ...state,
      loadedDates,
      scheduleEvents,
    };
  }),
  on(DashboardActions.evictSpecificEvents, (state, action) => {
    const loadedDateTimesToRemove = action.loadedDates.map(m => m.getTime());
    const loadedDates = state.loadedDates.filter(m => !loadedDateTimesToRemove.includes(m.date.getTime()));
    const loadedMoments = loadedDates.map(loadedDate => loadedDate.date);
    const scheduleEvents = state.scheduleEvents.filter(scheduleEvent =>
      loadedMoments.some(loadedMoment =>
        isWithinInterval(loadedMoment, {
          start: startOfDay(scheduleEvent.start),
          end: endOfDay(scheduleEvent.end),
        })
      )
    );

    return {
      ...state,
      loadedDates,
      scheduleEvents,
    };
  }),
  on(DashboardActions.signalRMessageEvent, (state, action) => {
    if (action.message.removed) {
      return {
        ...state,
        scheduleEvents: state.scheduleEvents.filter(m => m.id !== action.message.id),
      };
    }

    return state;
  })
);

export function reducer(state: DashboardState | undefined, action: Action) {
  return dashboardReducer(state, action);
}
