// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
wm-timepicker {
  display: inline-block;
}

.wm-card2 {
  width: 100%;
  display: flex;
  align-items: center;
}

.clock {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}

.timepicker {
  flex: 1;
  display: flex;
  align-items: center;
  color: #232628;
}
.timepicker > * {
  margin: 4px;
}
.timepicker .separator {
  margin: 4px 2px;
}
.timepicker .hour,
.timepicker .minute,
.timepicker .ampm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timepicker button {
  border: 0;
  background-color: transparent;
  background-size: 8px;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.timepicker button.plus {
  background-image: url(/assets/images/timepicker-up.svg);
}
.timepicker button.minus {
  background-image: url(/assets/images/timepicker-down.svg);
}
.timepicker img {
  display: block;
  width: 8px;
  height: 8px;
}
.timepicker input {
  min-width: 36px;
  max-width: 36px;
  text-align: center;
}
.timepicker input[type=number] {
  -moz-appearance: textfield;
}
.timepicker input[type=number]::-webkit-inner-spin-button,
.timepicker input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Removes leftover margin */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
