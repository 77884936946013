import { Injectable, inject } from '@angular/core';
import { type BillingPeriod } from '@models/cards/billing-information';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class ContractorBillingsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getPeriods(): Observable<BillingPeriod[]> {
    return this.http.get<BillingPeriod[]>(this.url.billingPeriods);
  }
}
