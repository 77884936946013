import { Injectable } from '@angular/core';
import { getWindow } from 'ssr-window';

@Injectable()
export class TimerService {
  setInterval(callback: () => any, time: number): number {
    return getWindow().setInterval(callback, time);
  }

  setTimeout(callback: () => any, time: number): number {
    return getWindow().setTimeout(callback, time);
  }

  clearInterval(handle: number | undefined): void {
    getWindow().clearInterval(handle);
  }

  clearTimeout(handle: number | undefined): void {
    getWindow().clearTimeout(handle);
  }
}

@Injectable()
export class TimerServiceMock extends TimerService {
  callbacks: Record<number, () => any> = {};

  setInterval(callback: () => any, time: number): number {
    const handle = Math.random();
    this.callbacks[handle] = callback;

    return handle;
  }

  setTimeout(callback: () => any, time: number): number {
    const handle = Math.random();
    this.callbacks[handle] = () => {
      callback();
      delete this.callbacks[handle];
    };

    return handle;
  }

  clearInterval(handle: number): void {
    delete this.callbacks[handle];
  }

  clearTimeout(handle: number): void {
    delete this.callbacks[handle];
  }

  tick(): void {
    for (const handle in this.callbacks) {
      if (this.callbacks.hasOwnProperty(handle)) {
        this.callbacks[handle]();
      }
    }
  }
}
