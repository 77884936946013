import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { type ActionReducer, StoreModule } from '@ngrx/store';
import { saveReducerWithCode } from '@services/save-store/save.actions';
import { WorkOrderEffects } from './work-order.effects';
import { reducer as workOrderReducer } from './work-order.reducer';
import { WORK_ORDER_STORE_KEY } from './work-order.selectors';

export function reducer(r: ActionReducer<any>): ActionReducer<any> {
  return saveReducerWithCode(WORK_ORDER_STORE_KEY)(r);
}

export const metaReducers = [reducer];

@NgModule({
  imports: [StoreModule.forFeature(WORK_ORDER_STORE_KEY, workOrderReducer, { metaReducers }), EffectsModule.forFeature([WorkOrderEffects])],
})
export class WorkOrderStoreModule {}
