import { Injectable, inject } from '@angular/core';
import {
  type SettingQuickBooksInitialViewData,
  type SettingQuickBooksResultsViewData,
  type SettingQuickBooksSettingsViewData,
} from '@models/cards/setting-view-data';
import { type EntitySearchResource } from '@models/entity-search';
import { attachBlobErrorDetection } from '@models/error-models';
import { StatusFilter } from '@models/filter-models';
import { type SearchResult } from '@models/result';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { allowRetries, skipBubbleError } from '@utility/angular';
import { type Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class SynchronizerService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  qbSearch(terms: Observable<string>, debounceDuration: number = 400): Observable<SearchResult<EntitySearchResource>[]> {
    return terms
      .pipe(debounceTime(debounceDuration))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(m => this.rawSearch(this.url.synchronizerQbSearch, m, StatusFilter.All)));
  }

  wmSearch(terms: Observable<string>, debounceDuration: number = 400): Observable<SearchResult<EntitySearchResource>[]> {
    return terms
      .pipe(debounceTime(debounceDuration))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(m => this.rawSearch(this.url.synchronizerWmSearch, m, StatusFilter.All)));
  }

  protected rawSearch(url: string, search: string, status: StatusFilter): Observable<SearchResult<EntitySearchResource>[]> {
    if (search) {
      return this.http.post<SearchResult<EntitySearchResource>[]>(
        url,
        {
          search,
          status,
        },
        allowRetries()
      );
    }

    return of([]);
  }

  link(qbItem: EntitySearchResource, wmItem: EntitySearchResource): Observable<boolean> {
    return this.http.post<boolean>(
      this.url.synchronizerLink,
      {
        qbItem: qbItem.id,
        wmItem: wmItem.id,
      },
      skipBubbleError()
    );
  }

  unlink(wmItem: EntitySearchResource): Observable<boolean> {
    return this.http.post<boolean>(this.url.synchronizerUnlink, {
      wmItem: wmItem.id,
    });
  }

  import(qbItems: EntitySearchResource[]): Observable<boolean> {
    return this.http.post<boolean>(
      this.url.synchronizerImport,
      qbItems.map(m => m.id)
    );
  }

  export(trItems: EntitySearchResource[]): Observable<boolean> {
    return this.http.post<boolean>(
      this.url.synchronizerExport,
      trItems.map(m => m.id)
    );
  }

  importAll(): Observable<boolean> {
    return this.http.post<boolean>(this.url.synchronizerImportAll, null);
  }

  getResults(): Observable<SettingQuickBooksResultsViewData> {
    return this.http.get<SettingQuickBooksResultsViewData>(this.url.synchronizerResults);
  }

  regenerateQwc(): Observable<void> {
    return this.http.post<void>(this.url.synchronizerQwcRegenerate, null);
  }

  downloadQwc(): Observable<Blob> {
    return this.http.get(this.url.synchronizerQwcDownload, { responseType: 'blob' }).pipe(attachBlobErrorDetection());
  }

  getInitial(): Observable<SettingQuickBooksInitialViewData> {
    return this.http.get<SettingQuickBooksInitialViewData>(this.url.synchronizerInitial);
  }

  getSettings(): Observable<SettingQuickBooksSettingsViewData> {
    return this.http.get<SettingQuickBooksSettingsViewData>(this.url.synchronizerGetSettings);
  }

  saveSettings(settings: Partial<SettingQuickBooksSettingsViewData>): Observable<void> {
    return this.http.post<void>(this.url.synchronizerSaveSettings, { ...settings }).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  private clearCache(): void {
    this.applicationCacheService.clearCategory(UpdateCategory.QuickBooksSettings);
  }
}
