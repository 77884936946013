import { Injectable, inject } from '@angular/core';
import { type FilterTypeInformation } from '@models/filter-type-models';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class FilterTypesService {
  private readonly http = inject(HttpClientService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);

  list(id?: Id): Observable<FilterTypeInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.FilterTypes, this.url.filterTypesList, id);
  }

  save(filterType: FilterTypeInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(filterType, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.filterTypesSave, obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.filterTypesHide.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.filterTypesRestore.replace('$0', id.toString()), null);
  }
}
