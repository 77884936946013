import { CommonModule } from '@angular/common';
import { Component, forwardRef, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOptionHighlightModule } from '@directives/ng-option-highlight.module';
import { type ExtraResource1, type Resource, type Resource1 } from '@models/resource';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputResourceBase } from '../input-resource/input-resource.component';

const INPUT_IMAGE_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputImageComponent),
  multi: true,
};

// Angular 18
@Component({
  selector: 'wm-input-image',
  templateUrl: 'input-image.component.html',
  styleUrls: ['input-image.component.scss'],
  providers: [INPUT_IMAGE_COMPONENT_VALUE_ACCESSOR],
  standalone: true,
  imports: [CommonModule, NgOptionHighlightModule, FormsModule, NgSelectModule],
})
export class InputImageComponent<T> extends InputResourceBase<Resource1<T>, T, ExtraResource1<T>> {
  readonly showTextInItem = input(false);
  readonly imageCenter = input(false);
  readonly required = input(false);
  readonly clearable = input(false);
  readonly dropdownParent = input<'body' | undefined>(undefined);
  readonly bindValue = input('id');
  readonly bindLabel = input('text');

  trackByFn(item: Resource): Id | null {
    return item?.id ?? null;
  }
}
