import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, type OnDestroy, type OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Dialog2Service } from '@dialogs/dialog2.service';
import { ANIMATION_TIMINGS } from '@models/animations';
import { AppInsightsService } from '@services/app-insights.service';
import { AuthService } from '@services/auth.service';
import { ConfigService } from '@services/config.service';
import { CustomTitleService } from '@services/custom-title.service';
import { SubscriptionManagerService } from '@services/ephemerals/subscription-manager.service';
import { PubSubService } from '@services/pub-sub.service';
import { WindowRefService } from '@services/window-ref.service';
import { isCypress } from '@utility/cypress';
import { isOldBrowser } from '@utility/user-agent';
import { merge } from 'rxjs';

@Component({
  selector: 'wm-app',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['div { height: 100%; }'],
  providers: [SubscriptionManagerService],
  animations: [
    trigger('blur', [
      state('clear', style({ filter: 'blur(0px)' })),
      state('active', style({ filter: 'blur(4px)' })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
  ],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class AppComponent implements OnInit, OnDestroy {
  blurState: 'clear' | 'active' = 'clear';

  private readonly appinsightsService = inject(AppInsightsService);
  private readonly sm = inject(SubscriptionManagerService);
  private readonly dialog2 = inject(Dialog2Service);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly pubSubService = inject(PubSubService);
  private readonly customTitleService = inject(CustomTitleService);
  private readonly authService = inject(AuthService);
  readonly configService = inject(ConfigService);
  readonly windowRefService = inject(WindowRefService);

  constructor() {
    if (!isCypress()) {
      this.appinsightsService.Init({
        url: 'https://js.monitor.azure.com/scripts/b/ai.2.min.js',
        enableAutoRouteTracking: false,
        enableAjaxErrorStatusText: true,
        loggingLevelConsole: 2,
        instrumentationKey: this.configService.config.appInsights.apiKey,
        maxAjaxCallsPerView: -1,
        disableExceptionTracking: false, // Otherwise, this tracks normal Error we throw.
      });
    }

    this.authService.init();
    this.checkForOldBrowsers();

    const appInsights = this.windowRefService.nativeWindow['appInsights'];
    if (appInsights) {
      appInsights.queue.push(() => {
        const ignorePaths = ['POST /notifications/negotiate', 'GET /static/lastchange'];
        appInsights.context.addTelemetryInitializer((envelope: any) => {
          if (
            envelope.name ===
            this.windowRefService.nativeWindow['Microsoft'].ApplicationInsights.Telemetry.RemoteDependencyData.envelopeType
          ) {
            const remoteData = envelope.data;
            if (remoteData?.baseData) {
              for (const ignorePath of ignorePaths) {
                if (remoteData.baseData.name.indexOf(ignorePath) >= 0) {
                  return false;
                }
              }
            }
          }

          return true;
        });
      });
    }
  }

  ngOnInit(): void {
    this.sm.add(
      merge(this.pubSubService.getEventEmitter('showBlur'), this.dialog2.dialogOpened).subscribe(opened => {
        this.blurState = opened ? 'active' : 'clear';
        this.cd.markForCheck();
      })
    );

    this.appinsightsService.trackEvent('Page Load', {
      'Screen Width': screen.width,
      'Screen Height': screen.height,
      'Browser Width': this.windowRefService.nativeWindow.innerWidth,
      'Browser Height': this.windowRefService.nativeWindow.innerHeight,
      'Screen Resolution': `${screen.width}x${screen.height}`,
      'Browser Resolution': `${this.windowRefService.nativeWindow.innerWidth}x${this.windowRefService.nativeWindow.innerHeight}`,
    });
  }

  ngOnDestroy(): void {
    this.sm.destroy();
    this.customTitleService.clear();
  }

  private checkForOldBrowsers(): void {
    if (isOldBrowser()) {
      alert(
        'Sorry, but your browser is not supported to run this site properly.' +
          '\nPlease use the latest version of Google Chrome, Firefox or Edge.'
      );

      this.appinsightsService.trackEvent('Old Browser Detected', { browser: navigator.userAgent });
    }
  }
}
