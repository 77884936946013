// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.datepicker-container {
  width: 224px;
  background-color: #fff;
  border: 1px solid #d1d5d8;
  border-radius: 4px;
}
.datepicker-container mat-calendar {
  --mat-datepicker-calendar-date-hover-state-background-color: #f1f5fe;
}
.datepicker-container mat-calendar .mat-calendar-header {
  background-color: #246ee9 !important;
  padding-top: 0;
}
.datepicker-container mat-calendar .mat-calendar-header .mat-calendar-controls {
  margin: 0;
}
.datepicker-container mat-calendar .mat-calendar-header .mat-mdc-button {
  --mdc-text-button-label-text-color: #fff;
  --mat-datepicker-calendar-period-button-icon-color: #fff;
}
.datepicker-container mat-calendar .mat-calendar-header .mdc-icon-button {
  color: #fff;
}
.datepicker-container mat-calendar .mat-calendar-content {
  padding-top: 8px;
}
.datepicker-container mat-calendar .mat-calendar-body-selected {
  --mat-datepicker-calendar-date-selected-state-background-color: #246ee9;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
