import { HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type InvoiceInformation, type WorkOrderInvoiceViewData } from '@models/cards/invoice-information';
import { attachBlobErrorDetection } from '@models/error-models';
import { withAllowRetries, withSkipBubbleError } from '@utility/angular';
import { type Observable } from 'rxjs';
import { HttpClientService, type ExtraOptionsJson } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class InvoicesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<InvoiceInformation> {
    return this.http.get<InvoiceInformation>(this.url.invoiceSingle.replace('$0', id.toString()));
  }

  prepare(id: Id): Observable<WorkOrderInvoiceViewData> {
    return this.http.get<WorkOrderInvoiceViewData>(this.url.invoicePrepare.replace('$0', id.toString()));
  }

  save(invoiceInformation: InvoiceInformation, extraOptions?: ExtraOptionsJson): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(invoiceInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.invoiceSave, obj, extraOptions);
  }

  preview(invoiceInformation: InvoiceInformation): Observable<Blob> {
    return this.http
      .post(this.url.invoicePreview, invoiceInformation, {
        responseType: 'blob',
        context: withAllowRetries(withSkipBubbleError(new HttpContext())),
      })
      .pipe(attachBlobErrorDetection());
  }
}
