import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import {
  type ContractorImportType,
  type ContractorImportInformation,
  type ContractorImportResult,
  type ContractorImportDefinitionInformation,
  type ContractorImportSheetFieldsBySheet,
} from '@models/cards/contractor-import-information';
import { HttpParams } from '@angular/common/http';
import { type PreparedFile } from '@models/upload';

@Injectable()
export class ContractorImportsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  list(forTechnician?: boolean, includeDeleted?: boolean): Observable<ContractorImportInformation[]> {
    return this.http.get<ContractorImportInformation[]>(this.url.contractorImportsList, {
      params: new HttpParams({ fromObject: { forTechnician: forTechnician ?? false, includeDeleted: includeDeleted ?? false } }),
    });
  }

  get(contractorImportId: Id): Observable<ContractorImportInformation> {
    return this.http.get<ContractorImportInformation>(this.url.contractorImportsGet(contractorImportId));
  }

  listDefinitions(): Observable<ContractorImportDefinitionInformation[]> {
    return this.http.get<ContractorImportDefinitionInformation[]>(this.url.contractorImportsListDefinitions);
  }

  create(
    importType: ContractorImportType,
    fileName: string | null,
    contentType: string | null
  ): Observable<{ import: ContractorImportInformation; importFile: PreparedFile }> {
    return this.http.post<{ import: ContractorImportInformation; importFile: PreparedFile }>(this.url.contractorImportsCreate, {
      importType,
      fileName,
      contentType,
    });
  }

  start(contractorImportId: Id, sheetsFields: ContractorImportSheetFieldsBySheet): Observable<void> {
    return this.http.post<void>(this.url.contractorImportsStart(contractorImportId), { sheetsFields });
  }

  getResult<T>(contractorImportId: Id): Observable<ContractorImportResult<T>> {
    return this.http.get<ContractorImportResult<T>>(this.url.contractorImportsGetResult(contractorImportId));
  }

  complete(contractorImportId: Id): Observable<void> {
    return this.http.post<void>(this.url.contractorImportsComplete(contractorImportId), null);
  }

  hide(contractorImportId: Id): Observable<void> {
    return this.http.delete<void>(this.url.contractorImportsDelete(contractorImportId));
  }
}
