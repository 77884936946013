import { type HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type DutyInformation, type DutyInformationForList, type DutySavedInfo } from '@models/duty-models';
import { type allSupportedDutyColumns, type supportedDutyFilterTypes } from '@models/filter-duty-models';
import {
  type TableSegment,
  type Filter,
  type Filter2,
  type PagedResult,
  type SupportedColumns,
  type SupportedFilters,
  type TableQueryResult,
} from '@models/filter-models';
import { type ExportToFileResponse } from '@services/pager.service';
import { PubSubService } from '@services/pub-sub.service';
import { allowRetries } from '@utility/angular';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

export type PossibleFilter = SupportedFilters<typeof supportedDutyFilterTypes>;
export type PossibleColumns = SupportedColumns<typeof allSupportedDutyColumns>;

@Injectable()
export class DutiesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly pubSubService = inject(PubSubService);

  list(filter: Filter<any>): Observable<PagedResult<DutyInformationForList>> {
    return this.http.post<PagedResult<DutyInformationForList>>(this.url.dutyList, filter, allowRetries());
  }

  tableList(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<TableQueryResult<DutyInformationForList>> {
    return this.http.post<TableQueryResult<DutyInformationForList>>(this.url.dutyTableList, filter, allowRetries());
  }

  exportToFile(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<ExportToFileResponse> {
    return this.http.post<ExportToFileResponse>(this.url.dutyExport2, filter);
  }

  getTableSegments(): Observable<TableSegment<PossibleFilter, PossibleColumns>[]> {
    return this.http.get<TableSegment<PossibleFilter, PossibleColumns>[]>(this.url.dutyGetTableSegments);
  }

  saveTableSegment(tableSegment: TableSegment<PossibleFilter, PossibleColumns>): Observable<Id> {
    return this.http.post<Id>(this.url.dutySaveTableSegment, tableSegment, allowRetries());
  }

  deleteTableSegment(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.customerDeleteTableSegment.replace('$0', id.toString()));
  }

  /** @deprecated */
  exportToExcel(filter: Filter<any>): Observable<HttpResponse<Blob>> {
    return this.http.post(this.url.dutyExport, filter, { responseType: 'blob', observe: 'response' });
  }

  get(id: Id): Observable<DutyInformation> {
    return this.http.get<DutyInformation>(this.url.dutySingle.replace('$0', id.toString()));
  }

  getCurrentUserOverdueCount(): Observable<number> {
    return this.http.get<number>(this.url.dutyOverdueCountForCurrentUser);
  }

  save(dutyInformation: DutyInformation): Observable<DutySavedInfo> {
    let obj = this.http.removeProperties(dutyInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id', 'customerId', 'siteId');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<DutySavedInfo>(this.url.dutySave, obj).pipe(
      tap(_ => {
        if (this.pubSubService) {
          this.pubSubService.publish('dutySaved');
        }
      })
    );
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.dutyDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.dutyRestore.replace('$0', id.toString()), null);
  }
}
