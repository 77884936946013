import { type FileInformation } from '@models/upload';
import { type Resource } from './resource';

export interface WorkOrderEmailMessageInformation extends Resource {
  workOrderId: Id;
  message: EmailMessage;
}

export interface EmailMessage extends Resource {
  createdBy: string;
  createdDate: Date;
  attachments: EmailAttachment[];
  messageStates: EmailMessageState[];
  recipients: EmailRecipient[];
}

export interface EmailAttachment extends Resource {
  file: FileInformation;
}

export interface EmailMessageState extends Resource {
  emailAddressId: Id;
  eventType: EmailEventType;
  createdDate: Date;
  emailMessageId: Id;
}

export interface EmailAddressState {
  email: string;
  type: EmailEventType;
  date: Date;
}

export interface UnsubscribedEmailAddress {
  email: string;
  date: Date;
}

export interface EmailRecipient extends Resource {
  type: RecipientType;
  address: EmailAddress;
}

export interface EmailAddress extends Resource {
  valid: boolean | null;
  displayName: string | null;
}

export enum EmailEventType {
  Processed = 0,
  Dropped = 1,
  Delivered = 2,
  Deferred = 3,
  Bounce = 4,
  Open = 5,
  Click = 6,
  SpamReport = 7,
  Unsubscribe = 8,
  GroupUnsubscribe = 9,
  GroupResubscribe = 10,
}

export enum RecipientType {
  To = 0,
  Cc = 1,
  Bcc = 2,
}

export enum FileType {
  Unknown,
  Pdf,
}

export function getFileType(attachment: EmailAttachment): FileType {
  const extension = attachment.text?.substr(-4);
  switch (extension) {
    case '.pdf':
      return FileType.Pdf;
  }

  return FileType.Unknown;
}

export function getFileTypeImage(attachment: EmailAttachment): string {
  const fileType = getFileType(attachment);
  switch (fileType) {
    case FileType.Pdf:
      return '/assets/images/filetypes/pdf.svg';
  }

  return '/assets/images/filetypes/unknown.svg';
}

export function getEmailEventTypeText(emailEventType: EmailEventType): string {
  switch (emailEventType) {
    case EmailEventType.Bounce:
      return 'Bounce';
    case EmailEventType.Click:
      return 'Click';
    case EmailEventType.Deferred:
      return 'Deferred';
    case EmailEventType.Delivered:
      return 'Delivered';
    case EmailEventType.Dropped:
      return 'Dropped';
    case EmailEventType.GroupResubscribe:
      return 'Group Resubscribe';
    case EmailEventType.GroupUnsubscribe:
      return 'Group Unsubscribe';
    case EmailEventType.Open:
      return 'Open';
    case EmailEventType.Processed:
      return 'Processed';
    case EmailEventType.SpamReport:
      return 'Spam Report';
    case EmailEventType.Unsubscribe:
      return 'Unsubscribe';
  }

  return 'Unknown;';
}
