import { type HttpEvent, HttpResponse, type HttpInterceptorFn } from '@angular/common/http';
import { map } from 'rxjs/operators';

// TODO Have a way to shortcircuit this.
export const DataInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.body && event.body.data !== undefined) {
          return event.clone({ body: event.body.data });
        }
      }

      return event;
    })
  );
};
