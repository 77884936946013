import { Injectable, inject } from '@angular/core';
import { type SandboxContractor } from '@models/sandbox';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class SandboxService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  getContractor(): Observable<SandboxContractor> {
    return this.http.get<SandboxContractor>(this.url.sandboxContractorGet);
  }
}
