import { Injectable, inject } from '@angular/core';
import { getDocument, getWindow } from 'ssr-window';
import { TimerService } from './timer.service';

function _window(): Window {
  // return the global native browser window object
  return getWindow();
}

@Injectable()
export class WindowRefService {
  private readonly timerService = inject(TimerService);

  get nativeWindow(): Window {
    return _window();
  }

  download(blob: Blob, filename: string): void {
    const a = getDocument().createElement('a');
    getDocument().body.appendChild(a);
    a.style.display = 'none';

    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
  }

  print(withTimer: boolean): void {
    const fnc = () => {
      this.nativeWindow.print();
    };

    if (withTimer) {
      this.timerService.setTimeout(fnc, 0);
    } else {
      fnc();
    }
  }
}
