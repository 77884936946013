import { Pipe, type PipeTransform } from '@angular/core';
import { type SafeUrl } from '@angular/platform-browser';
import { type FileData } from '@models/image';
import { type Upload } from '@models/upload';

type Combined = Upload | FileData | string | SafeUrl;

@Pipe({
  name: 'filename',
  standalone: true,
})
export class FilenamePipe implements PipeTransform {
  transform(file: Combined): string {
    let path: string;
    if (this.isString(file)) {
      path = file;
    } else if (this.isFileData(file)) {
      path = this.transform(file.originalFileName ? file.originalFileName : file.path);
    } else if (this.isUpload(file)) {
      path = this.transform(file.text ?? '');
    } else {
      // SafeUrl
      path = '';
    }

    const match = path.match(/([^\\\/]*)$/);
    return (match && match[0]) || '';
  }

  private isString(value: Combined): value is string {
    return typeof value === 'string';
  }

  private isFileData(value: Combined): value is FileData {
    if (value) {
      return !!(value as any).path;
    }

    return false;
  }

  private isUpload(value: Combined): value is Upload {
    if (value) {
      return typeof (value as any).id === 'number';
    }

    return false;
  }
}
