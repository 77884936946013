export enum AppRole {
  Unknown,
  Administrator,
  ContractorAdmin,
  ContractorDispatcher,
  DashboardView,
  ReportView,
  ReplenishmentView,
  AgreementSetup,
  Location,
  ContractorOwner,
  Leaderboard,
}
