import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { type ActionReducer, StoreModule } from '@ngrx/store';
import { saveReducerWithCode } from '@services/save-store/save.actions';
import { SalesProposalEffects } from './sales-proposal.effects';
import { reducer as salesProposalReducer } from './sales-proposal.reducer';
import { SALES_PROPOSAL_STORE_KEY } from './sales-proposal.selectors';

export function reducer(r: ActionReducer<any>): ActionReducer<any> {
  return saveReducerWithCode(SALES_PROPOSAL_STORE_KEY)(r);
}

export const metaReducers = [reducer];

@NgModule({
  imports: [
    StoreModule.forFeature(SALES_PROPOSAL_STORE_KEY, salesProposalReducer, { metaReducers }),
    EffectsModule.forFeature([SalesProposalEffects]),
  ],
})
export class SalesProposalStoreModule {}
