import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverEventInfoComponent, Tab } from '@dialogviews/popover-event-info.component';
import { CallType } from '@models/call-type';
import { CreateFromPossibility, CreateViewType } from '@models/cards/create-view-data';
import { type ScheduleEventConfirmationStatus, type ScheduleEventInformation } from '@models/dashboard-event';
import { DIALOG_SERVICE_IMPL, PopoverAlignment, PopoverPosition, type PopoverRef } from '@models/dialog';
import { EventsService } from '@services/live/events.service';
import { SitesService } from '@services/live/sites.service';
import { of } from 'rxjs';
import { type Point } from './../models/point';
import { CallsService } from './live/calls.service';
import { CustomersService } from './live/customers.service';

@Injectable()
export class PopoverEventInfoFactoryService {
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly eventsService = inject(EventsService);
  private readonly sitesService = inject(SitesService);
  private readonly customerService = inject(CustomersService);
  private readonly callsService = inject(CallsService);
  private readonly router = inject(Router);

  public openCallPopover(
    call: ScheduleEventInformation,
    parentElement: any,
    position: PopoverPosition = PopoverPosition.Bottom,
    alignment: PopoverAlignment = PopoverAlignment.Center,
    relativePosition: Point | null = null,
    lateBeaconActive: boolean,
    sendConfirmationAllowed: boolean,
    close: () => void = () => {}
  ): PopoverRef<PopoverEventInfoComponent, any> {
    const buttons = [
      {
        id: 1,
        text: 'View Appointment',
        click: () => {
          void this.goToCall(call.id);
        },
      },
    ];

    if (new Set([CallType.Scheduled, CallType.Released]).has(call.callDetails.callType)) {
      if (call.callDetails.canSendToIPad) {
        buttons.push({
          id: 2,
          text: call.callDetails.sendToIPad ? 'Remove From Mobile App' : 'Send to Mobile App',
          click: () => {
            this.sendToIPad(call, !call.callDetails.sendToIPad);
          },
        });
      }
    }

    if (new Set([CallType.Completed, CallType.Processed]).has(call.callDetails.callType)) {
      buttons.push({
        id: 3,
        text: 'View Work Order',
        click: () => {
          this.goToWorkOrder(call.callDetails.workOrderId);
        },
      });
    }

    const popoverRef = PopoverEventInfoComponent.open(this.dialog2, parentElement, {
      defaultTab: Tab.Call,
      call: {
        id: call.id,
        click: () => {
          popoverRef.close();
          this.goToSite(call);
        },
        dashboardEvent: call,
        saveNote: (notes: string) => {
          return this.eventsService.patch(call.id, {
            notes,
          });
        },
        lateBeaconActive,
        sendConfirmationAllowed,
        saveSmsConfirmationStatus: (status: ScheduleEventConfirmationStatus) => {
          return this.eventsService.patch(call.id, {
            confirmationStatus: status,
          });
        },
        takeCharge: () => of(),
      },
      site: {
        id: call.callDetails.siteId,
        click: () => {
          popoverRef.close();
          this.goToSite(call);
        },
        saveNote: (notes: string) => {
          return this.sitesService.patch(call.callDetails.siteId, { notes });
        },
      },
      customer: {
        id: call.callDetails.customerId,
        click: () => {
          popoverRef.close();
          this.goToCustomer(call);
        },
        saveNote: (notes: string) => {
          return this.customerService.patch(call.callDetails.customerId, {
            notes,
          });
        },
      },
      siteHistory: {
        id: call.callDetails.siteId,
        click: (workOrderId: Id) => {
          popoverRef.close();
          this.goToWorkOrder(workOrderId);
        },
      },
      buttons,
      useSiteMobilePhone: false,
      showActionButtons: true,
      allowNoteEditing: true,
      close,
    });

    return popoverRef;
  }

  private async goToSite(event: ScheduleEventInformation): Promise<void> {
    await this.router.navigate(['/customers', event.callDetails.customerId, 'sites', event.callDetails.siteId]);
  }

  private async goToWorkOrder(id: Id): Promise<void> {
    await this.router.navigate(['/workorders', id]);
  }

  private async goToCustomer(event: ScheduleEventInformation): Promise<void> {
    await this.router.navigate(['/customers', event.callDetails.customerId]);
  }

  private async goToCall(callId: Id): Promise<void> {
    await this.router.navigate(['/calls', callId]);
  }

  private async goToEvent(event: ScheduleEventInformation): Promise<void> {
    await this.router.navigate(['/events', event.id, { start: event.start.getTime(), end: event.end.getTime() }]);
  }

  private async goToAgreement(agreementId: Id): Promise<void> {
    await this.router.navigate(['/agreements', agreementId]);
  }

  private async goToCreateTask(event: ScheduleEventInformation): Promise<void> {
    const id = event.callDetails.customerId;
    const from = CreateFromPossibility.Customer;
    const type = CreateViewType.Duty;

    await this.router.navigate(['/create', { from, type, id }]);
  }

  private async goToCreateCall(event: ScheduleEventInformation): Promise<void> {
    const id = event.callDetails.customerId;
    const from = CreateFromPossibility.Site;
    const type = CreateViewType.Call;

    await this.router.navigate(['/create', { from, type, id }]);
  }

  private sendToIPad(call: ScheduleEventInformation, released: boolean): void {
    this.callsService.patch(call.callDetails.id, { released }).subscribe(m => {
      call.callDetails.sendToIPad = released;
      call.callDetails.callType = released ? CallType.Released : CallType.Scheduled;
    });
  }
}
