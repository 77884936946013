// Port from https://github.com/ng-select/ng-select/blob/88023334b09b61b4086af0536bb8a92bc383bd91/src/ng-option-highlight/lib/ng-option-highlight.directive.ts
// Once @ng-select/ng-option-highlight package is updated with Angular 14+ dependencies, we can remove this and use the package instead
import { Directive, ElementRef, Renderer2, inject, input, type AfterViewInit, type OnChanges } from '@angular/core';

@Directive({
  selector: '[ngOptionHighlight]',
  standalone: true,
})
export class NgOptionHighlightDirective implements OnChanges, AfterViewInit {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  readonly term = input.required<string | null>({ alias: 'ngOptionHighlight' });

  private readonly element: HTMLElement;
  private label?: string;

  constructor() {
    this.element = this.elementRef.nativeElement;
  }

  ngOnChanges() {
    if (this._canHighlight) {
      this._highlightLabel();
    }
  }

  ngAfterViewInit() {
    this.label = this.element.innerHTML;
    if (this._canHighlight) {
      this._highlightLabel();
    }
  }

  private _escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private _highlightLabel() {
    const label = this.label;
    if (!this.term()) {
      this._setInnerHtml(label);
      return;
    }

    const alternationString = this._escapeRegExp(this.term() ?? '').replace(' ', '|');
    const termRegex = new RegExp(alternationString, 'gi');
    this._setInnerHtml(label?.replace(termRegex, `<span class=\"highlighted\">$&</span>`));
  }

  private get _canHighlight() {
    return this._isDefined(this.term()) && this._isDefined(this.label);
  }

  private _setInnerHtml(html: string | undefined) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', html ?? '');
  }

  private _isDefined(value: any) {
    return value !== undefined && value !== null;
  }
}
