import { NavigationEnd, NavigationStart, type ActivatedRoute, type Router } from '@angular/router';
import {
  PropertiesPluginIdentifier,
  Util,
  type IAppInsights,
  type IConfig,
  type IPageViewTelemetry,
} from '@microsoft/applicationinsights-common';
import {
  BaseTelemetryPlugin,
  CoreUtils,
  LoggingSeverity,
  _InternalMessageId,
  getDocument,
  type IAppInsightsCore,
  type IConfiguration,
  type IPlugin,
  type IProcessTelemetryContext,
  type ITelemetryItem,
  type ITelemetryPlugin,
  type ITelemetryPluginChain,
} from '@microsoft/applicationinsights-core-js';
// For types only
import type * as properties from '@microsoft/applicationinsights-properties-js';

export interface AppInsightAngularConfig {
  router?: Router;
  activatedRoute?: ActivatedRoute;
}

// Inspiration from https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-angularplugin-js

export default class AppInsightAngularPlugin extends BaseTelemetryPlugin {
  public priority = 186;
  public identifier = 'AppInsightAngularPlugin';

  private _analyticsPlugin: IAppInsights;
  private _propertiesPlugin: properties.PropertiesPlugin;

  initialize(config: IConfiguration & IConfig, core: IAppInsightsCore, extensions: IPlugin[], pluginChain?: ITelemetryPluginChain): void {
    super.initialize(config, core, extensions, pluginChain);
    const ctx = this._getTelCtx();

    const extConfig = ctx.getExtCfg<AppInsightAngularConfig>(this.identifier, { router: null, activatedRoute: null });

    CoreUtils.arrForEach(extensions, ext => {
      const identifier = (ext as ITelemetryPlugin).identifier;
      if (identifier === 'ApplicationInsightsAnalytics') {
        this._analyticsPlugin = ext as any as IAppInsights;
      }
      if (identifier === PropertiesPluginIdentifier) {
        this._propertiesPlugin = ext as any as properties.PropertiesPlugin;
      }
    });

    if (extConfig.router) {
      let timerStart: number | null = null;
      extConfig.router.events.subscribe(o => {
        if (o instanceof NavigationStart) {
          timerStart = new Date().getTime();
        } else if (o instanceof NavigationEnd) {
          const timerEnd = new Date().getTime();
          const duration = timerStart ? timerEnd - timerStart : null;

          let currentRoute = extConfig.activatedRoute.root;
          let builtUrl = '';
          let trackName: string | null = null;
          do {
            const childrenRoutes = currentRoute.children;
            currentRoute = null;
            childrenRoutes.forEach(route => {
              if (route.outlet === 'primary') {
                const routeSnapshot = route.snapshot;
                builtUrl += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
                trackName = route.snapshot.data.trackName;

                currentRoute = route;
              }
            });
          } while (currentRoute);

          const pageViewTelemetry: IPageViewTelemetry = {
            uri: extConfig.router.url,
            name: trackName,
            properties: {
              duration,
            },
          };
          this.trackPageView(pageViewTelemetry);
        }
      });
    }
  }

  processTelemetry(event: ITelemetryItem, itemCtx?: IProcessTelemetryContext): void {
    this.processNext(event, itemCtx);
  }

  trackPageView(pageView: IPageViewTelemetry): void {
    if (this._analyticsPlugin) {
      if (this._propertiesPlugin?.context?.telemetryTrace) {
        this._propertiesPlugin.context.telemetryTrace.traceID = Util.generateW3CId();
      }

      const document = getDocument();
      if (document?.title) {
        pageView.properties.title = document.title;
      }

      this._analyticsPlugin.trackPageView(pageView);
    } else {
      this.diagLog().throwInternal(
        LoggingSeverity.CRITICAL,
        _InternalMessageId.TelemetryInitializerFailed,
        'Analytics plugin is not available, Angular plugin telemetry will not be sent: '
      );
    }
  }
}
