import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
  forwardRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';

const noop = (): void => {};

const DATEPICKER_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerComponent),
  multi: true,
};

@Component({
  selector: 'wm-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DATEPICKER_COMPONENT_VALUE_ACCESSOR, provideNativeDateAdapter()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDatepickerModule],
})
export class DatepickerComponent implements ControlValueAccessor {
  readonly minDate = input<Date>(new Date(2014, 1, 1));
  readonly maxDate = input<Date>(new Date(new Date().getFullYear() + 6, 0, 0));

  readonly valueChange = output<number>();

  readonly calendar = viewChild.required('calendar', { read: MatCalendar });

  // The internal data model
  readonly innerValue = signal<Date | null>(null);

  @HostBinding('class.has-focus')
  protected _focused = false;

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  setDate(date: Date): void {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let ms = 0;

    const innerValue = this.innerValue();
    if (innerValue) {
      hours = innerValue.getHours();
      minutes = innerValue.getMinutes();
      seconds = innerValue.getSeconds();
      ms = innerValue.getMilliseconds();
    }

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    date.setMilliseconds(ms);
    this.innerValue.set(date);
    this.onChangeCallback(date);
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    if (value !== this.innerValue()) {
      this.innerValue.set(value);

      // This puts the focus on the right month
      this.calendar().activeDate = value ?? new Date();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
