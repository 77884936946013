import { Injectable } from '@angular/core';
import { HttpClientBaseService, type ExtraOptionsJson } from './http-client-base.service';

export type { ExtraOptionsJson };

@Injectable()
export class HttpClientService extends HttpClientBaseService {
  removeProperties<T, K extends keyof T>(element: T, ...properties: K[]): Partial<T> {
    const object = Object.assign({}, element);
    for (const property of properties) {
      delete object[property];
    }

    return object;
  }

  removeFalsyProperties<T, K extends keyof T>(element: T, ...properties: K[]): Partial<T> {
    const object = Object.assign({}, element);
    for (const property of properties) {
      if (!object[property]) {
        delete object[property];
      }
    }

    return object;
  }

  setEmptyStringToNull<T, K extends keyof T>(element: Partial<T>, ...properties: K[]): Partial<T> {
    let props: (string | number | symbol)[] = properties;
    if (props.length === 0) {
      props = Object.keys(element);
    }

    for (const key of props) {
      if ((element as any)[key] === '') {
        (element as any)[key] = null;
      }
    }

    return element;
  }

  trimStringProperties(element: any): void {
    const properties = Object.keys(element);

    for (const property of properties) {
      if (typeof element[property] === 'string') {
        element[property] = element[property].trim();
      } else if (element[property] != null && typeof element[property] === 'object') {
        this.trimStringProperties(element[property]);
      }
    }
  }
}
