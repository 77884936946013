import { type Route } from '@angular/router';
import { LiveOnlyAuthGuard } from '@services/sandbox-auth-guard';

export const routes: Route[] = [
  {
    path: 'payments',
    loadChildren: () => import('./areas/payments/payments.routing').then(m => m.routes),
    data: { preload: 'payments' },
    canActivate: [LiveOnlyAuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./app-connect.routing').then(m => m.routes),
    data: { preload: 'connect' },
  },
];
