import { Injectable, inject } from '@angular/core';
import {
  type ChargeCustomerViewData,
  type ChargeInitialViewData,
  type ChargeSettings,
  type ChargeViewData,
  type ProcessChargeInput,
  type ProcessChargeResult,
} from '@models/charges';
import { type PaymentGateway } from '@models/payments';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { HttpClientService, type ExtraOptionsJson } from '@services/http-client.service';
import { UrlService } from '@services/url.service';
import { allowRetries } from '@utility/angular';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ChargesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  getViewData(token: string): Observable<ChargeCustomerViewData> {
    return this.http.get<ChargeCustomerViewData>(this.url.chargesGetViewData.replace('$0', token));
  }

  getSettings(): Observable<ChargeInitialViewData> {
    return this.applicationCacheService.getSettingsFromCache(UpdateCategory.Charges, this.url.chargesGetSettings);
  }

  setSettings(chargeSettings: ChargeSettings): Observable<void> {
    return this.http.post<void>(this.url.chargesSetSettings, chargeSettings).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  disconnect(paymentGateway: PaymentGateway): Observable<void> {
    return this.http.post<void>(this.url.chargesDisconnect, { input: paymentGateway }).pipe(
      tap(_ => {
        this.clearCache();
      })
    );
  }

  getChargeViewData(customerId: Id, workOrderId: Id | null): Observable<ChargeViewData> {
    return this.http.post<ChargeViewData>(
      this.url.chargesGetChargeViewData.replace('$0', customerId.toString()),
      {
        input: workOrderId,
      },
      allowRetries()
    );
  }

  deleteCard(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.chargesDeleteCard.replace('$0', id.toString()));
  }

  processCharge(processChargeInput: ProcessChargeInput, extraOptions?: ExtraOptionsJson): Observable<ProcessChargeResult> {
    return this.http.post<ProcessChargeResult>(this.url.chargesProcessCharge, processChargeInput, extraOptions);
  }

  clearCache(): void {
    this.applicationCacheService.clearCategory(UpdateCategory.Charges);
  }
}
