// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  position: relative;
  display: block;
}

.wm-card2 {
  display: flex;
}
.wm-card2 > *:not(.icon) {
  flex-grow: 1;
}

.icon {
  margin-right: 16px;
}
.icon img {
  width: 64px;
  height: 64px;
}

.filters {
  display: flex;
  min-height: 24px;
}
.filters li {
  margin-right: 8px;
}
.filters li:last-child {
  margin-right: 0;
}
.filters .filter {
  color: #ffffff;
  background-color: #464b4f;
  padding: 2px 8px;
  border-radius: 4px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.top {
  display: flex;
}
.top .text {
  flex-grow: 1;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}
.top .actions {
  display: flex;
  font-size: 12px;
}
.top .actions .clear {
  color: #babfc4;
}
.top .actions .clear.active {
  color: #246ee9;
}
.top .actions > * {
  background: none;
  border: none;
  color: #246ee9;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
}
.top .actions > *::after {
  content: "|";
  display: inline-block;
  margin: 0 4px;
  color: #babfc4;
}
.top .actions > *:last-child::after {
  content: "";
}

.arrow {
  position: absolute;
  bottom: -9px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #d1d5d8 transparent;
  display: block;
  width: 0;
}
.arrow::after {
  content: "";
  position: absolute;
  bottom: 1.5px;
  left: -8.5px;
  border-width: 8.5px 8.5px 0;
  border-style: solid;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
