import {
  Directive,
  EventEmitter,
  HostBinding,
  Injectable,
  Optional,
  Output,
  type AfterViewInit,
  type ChangeDetectorRef,
  type OnDestroy,
} from '@angular/core';
import { FormControl, type AbstractControl, type FormGroup, type NgControl, type ValidationErrors } from '@angular/forms';
import { type TimerService } from '@services/timer.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ValueAccessorBase2, ValueAccessorBase3 } from './value-accessor-base';

// Angular has two ways of creating a control, either we create it from
// FormControl or we create it from an ngModel.
// In both cases, the validation is not coming the same way.
// https://github.com/angular/angular/issues/19643
// We are following how Angular Material is doing, by using the NgControl
/**
 * @deprecated Use {@link ElementBaseWithFormGroup} or {@link ElementBaseWithFormControl}.
 */
@Directive()
export abstract class ElementBase3<T> extends ValueAccessorBase2<T> implements AfterViewInit {
  public _allowEmptyAbstractControl = false;

  // This is available at AfterViewInit
  get abstractControl(): FormControl | null {
    if (!this.ngControl && !this._allowEmptyAbstractControl) {
      throw new Error('The control has not been initialized properly.');
    }

    // this.control is either NgModel or FormControl
    return this.ngControl && (this.ngControl.control as FormControl);
  }

  protected _blurEmitter = new EventEmitter<FocusEvent>();
  protected _focusEmitter = new EventEmitter<FocusEvent>();

  @HostBinding('class.has-focus')
  protected _focused = false;

  @Output('blur')
  get onBlur(): Observable<FocusEvent> {
    return this._blurEmitter.asObservable();
  }

  @Output('focus')
  get onFocus(): Observable<FocusEvent> {
    return this._focusEmitter.asObservable();
  }

  constructor(
    protected ngControl: NgControl | null = null,
    protected cd: ChangeDetectorRef | null = null,

    // Uhoh :(
    // In order to handle the NG_VALIDATOR, it is not automatically validated.
    // So we need to validate it but if we do in the ngAfterViewInit, we get a component has changed.
    @Optional()
    protected timerService: TimerService | null = null
  ) {
    super();

    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    if (this.timerService) {
      this.registerOnChange(value => {
        this.abstractControl.setErrors(this.validate(new FormControl(value)));
      });

      this.timerService.setTimeout(() => {
        this.abstractControl.setErrors(this.validate(new FormControl(this.abstractControl.value)));
      }, 1);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  writeValue(value: T): void {
    super.writeValue(value);

    if (this.cd) {
      this.cd.markForCheck();
    }
  }
}

@Injectable()
export abstract class ElementBase4<T> extends ValueAccessorBase3<T> implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

abstract class ElementBaseWithControl<T, TControl extends AbstractControl> extends ElementBase4<T> {
  constructor(
    public control: TControl,
    createEmpty?: () => T
  ) {
    super(createEmpty);
  }
}

export abstract class ElementBaseWithFormGroup<
  T,
  TFG extends { [K in keyof TFG]: AbstractControl<any, any> },
> extends ElementBaseWithControl<T, FormGroup<TFG>> {
  constructor(formGroup: FormGroup<TFG>, createEmpty: () => T) {
    super(formGroup, createEmpty);
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(_ => {
      this.value = {
        ...this.value,
        ...this.control.getRawValue(),
      };
    });
  }

  writeValue(value: T | null): void {
    const finalValue = (value ?? this.createEmpty?.()) as any;
    this.control.patchValue(finalValue, { emitEvent: false });
    super.writeValue(finalValue);
  }
}

export abstract class ElementBaseWithFormControl<T> extends ElementBaseWithControl<T, FormControl<T>> {
  constructor(createEmpty?: () => T) {
    // /eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // /@ts-expect-error
    super(new FormControl<T>((createEmpty?.() ?? null) as unknown as T), createEmpty);

    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.value = value;
    });
  }

  writeValue(value: T | null): void {
    this.control.patchValue(value ?? this.createEmpty?.() ?? (null as any), { emitEvent: false });
    super.writeValue(value);
  }
}
