import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  type RegisterCoreDataIn,
  type RegisterCoreDataOut,
  type RegisterRequirements,
  type RegisterConfirmData,
  type RegisterData,
  type RegisterResult,
} from '@models/account';
import { type AppRole } from '@models/app-role';
import { type AccountSettings } from '@models/cards/setting-view-data';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { PubSubService } from '@services/pub-sub.service';
import { allowRetries } from '@utility/angular';
import { type Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';
import { TimerService } from './../timer.service';

@Injectable()
export class AccountsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly pubSubService = inject(PubSubService);
  private readonly timerService = inject(TimerService);

  register(data: RegisterData): Observable<RegisterResult> {
    return this.http.post<RegisterResult>(this.url.accountRegister, {
      ...data,
    });
  }

  sendConfirmation(email: string): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<void>(this.url.accountSendConfirmation, JSON.stringify(email), options);
  }

  getRegisterCoreData(): Observable<RegisterCoreDataOut> {
    return this.http.get<RegisterCoreDataOut>(this.url.accountRegisterGetCoreData);
  }

  getRegisterRequirements(): Observable<RegisterRequirements> {
    return this.http.get<RegisterRequirements>(this.url.accountRegisterGetRequirements);
  }

  postRegisterCoreData(data: RegisterCoreDataIn): Observable<RegisterResult> {
    return this.http.post<RegisterResult>(this.url.accountRegisterPostCoreData, {
      ...data,
    });
  }

  finishRegistration(): Observable<void> {
    return this.http.post<void>(this.url.accountRegisterFinish, {});
  }

  registerConfirm(data: RegisterConfirmData): Observable<void> {
    return this.http.post<void>(this.url.accountRegisterConfirm, {
      ...data,
    });
  }

  getRoles(): Observable<AppRole[]> {
    return this.http.get<AppRole[]>(this.url.accountRoles);
  }

  recoverPassword(email: string): Observable<void> {
    return this.http.post<void>(this.url.accountRecover, {
      email,
    });
  }

  resetPassword(email: string, password: string, code: string): Observable<void> {
    return this.http.post<void>(this.url.accountReset, {
      email,
      password,
      code,
    });
  }

  getAccount(): Observable<AccountSettings> {
    return this.http.get<AccountSettings>(this.url.accountView);
  }

  saveAccount(accountSettings: Omit<AccountSettings, 'id' | 'email'>): Observable<void> {
    return this.http.post<void>(this.url.accountSave, accountSettings).pipe(
      tap(_ => {
        this.clearCache();
      }),
      tap(m => {
        // We need to delay this, otherwise we get a wrong image from the storage.
        this.timerService.setTimeout(() => {
          this.pubSubService.publish('technicianChanged');
        }, 5000);
      }),
      share()
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this.http.post<void>(this.url.accountChangePassword, {
      currentPassword,
      newPassword,
    });
  }

  changeTechnicianPassword(email: string, newPassword: string): Observable<void> {
    return this.http.post<void>(this.url.accountChangePassword, {
      userName: email,
      newPassword,
    });
  }

  intercomHash(): Observable<string> {
    return this.http.post<string>(this.url.accountIntercomHash, {}, allowRetries());
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.Technicians);
    }
  }
}
