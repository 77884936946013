import { CurrencyPipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Injectable, LOCALE_ID, Pipe, inject, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'money',
  standalone: true,
})
@Injectable()
export class MoneyPipe implements PipeTransform {
  protected readonly _locale = inject(LOCALE_ID);
  protected readonly _defaultCurrencyCode = inject(DEFAULT_CURRENCY_CODE, { optional: true });

  private readonly currencyPipe: CurrencyPipe;

  constructor() {
    this.currencyPipe = new CurrencyPipe(this._locale, this._defaultCurrencyCode);
  }

  // Need to keep string|boolean to satisfy the CurrencyPipe
  transform(money: Money | null | undefined, hideSymbol?: string | boolean, hideDecimal?: string | 'HIDE_IF_00' | boolean): string {
    let digits: string | undefined;
    if (hideDecimal === true) {
      digits = '1.0-0';
    } else if (hideDecimal === 'HIDE_IF_00') {
      if ((money ?? 0) % 1 === 0) {
        digits = '1.0-0';
      } else {
        digits = '1.2-2';
      }
    }

    if (money == null || isNaN(parseFloat(money.toString()))) {
      return '';
    }

    const code = 'USD';
    if (hideSymbol) {
      return this.currencyPipe.transform(money, code, '', digits) ?? '';
    }

    return this.currencyPipe.transform(money, code, 'symbol-narrow', digits) ?? '';
  }
}
