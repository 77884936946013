import { type AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[afterViewInit]',
  standalone: true,
})
export class AfterViewInitDirective implements AfterViewInit {
  @Output() afterViewInit = new EventEmitter<any>();

  ngAfterViewInit(): void {
    this.afterViewInit.emit();
  }
}
