import { Injectable, Injector, computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { type HubConnection, HubConnectionBuilder, type IRetryPolicy, LogLevel, type RetryContext } from '@microsoft/signalr';
import { type EventGridSignalRMessage } from '@models/event-grid-models';
import { type NotificationMessage } from '@models/notification';
import { newGuid } from '@utility/string';
import {
  Subject,
  catchError,
  combineLatest,
  concatMap,
  filter,
  from,
  map,
  of,
  retry,
  switchMap,
  take,
  throwError,
  timer,
  type Observable,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ConfigService, type ThreeCConfig } from './config.service';
import { notEmpty } from '@utility/array';

interface InternalMessage<T> {
  retryCount: number;
  method: string;
  args: any[];
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

export class SignalRError extends Error {
  constructor(message: string) {
    super('SignalR Error: ' + message);
  }
}

@Injectable()
class SignalRBase<Message> {
  protected readonly authService = inject(AuthService);
  protected readonly injector = inject(Injector);
  private readonly configService = inject(ConfigService);
  private readonly retryPolicy = new IndefiniteReconnectPolicy((retryDelay: number) => {
    this.timerCount.set({ c: retryDelay / 1000 });
  });

  /**
   * Indicates that the user is active on the site.
   */
  private readonly active = signal(true);

  /**
   * Holds the listeners.
   */
  private readonly listeners = signal<string[]>([]);

  /**
   * Indicates that we need to run a connection.
   */
  private readonly requestRun = computed(() => !!this.listeners().length);

  /**
   * Indicates that we are connected to the server.
   */
  readonly connected = signal(false);

  readonly connected$ = toObservable(this.connected);

  /**
   * Indicates when we connected to the server.
   * Null means we never connected.
   */
  readonly lastSuccessfulConnection = signal<Date | null>(null);
  readonly lastSuccessfulConnection$ = toObservable(this.lastSuccessfulConnection);

  // Angular might not have loaded the config yet, we need to put this in a computed.
  private readonly config = toSignal<ThreeCConfig | undefined>(this.configService.loadConfig());

  // Don't make this a signal, we will loop.
  private reconnectDelayHandle: NodeJS.Timeout | undefined;

  // We are using an object because triggering 30 on it multiple time should go in the toObservable properly.
  private readonly timerCount = signal<{ c: number }>({ c: 0 });
  readonly retryConnectionTimer$ = toObservable(this.timerCount).pipe(
    switchMap(timerCount =>
      timer(0, 1000).pipe(
        take(timerCount.c + 1),
        map(i => timerCount.c - i)
      )
    )
  );

  /**
   * HubConnection to talk to the server.
   */
  protected hubConnection = computed<HubConnection | undefined>(() => {
    const config = this.config();
    if (config) {
      return new HubConnectionBuilder()
        .configureLogging(environment.production ? LogLevel.Warning : LogLevel.None)
        .withAutomaticReconnect(this.retryPolicy)
        .withUrl(this.signalRConfig(config).url, {
          accessTokenFactory: () => {
            return this.authService.getCurrentToken()?.token.access_token ?? '';
          },
        })
        .build();
    }
  });

  // This is used to callback our effect if necessary.
  private readonly retryCounter = signal(1);

  // Don't make the abort a signal, we don't want to loop
  private abortController: AbortController | null = null;

  private readonly _message$: Subject<Message> = new Subject<Message>();
  get message$(): Observable<Message> {
    return this._message$;
  }

  private readonly _internalSendMessage$ = new Subject<InternalMessage<unknown>>();

  _ = effect(
    () => {
      const hubConnection = this.hubConnection();
      if (hubConnection && this.retryCounter()) {
        const abortController = this.abortController;
        if (abortController) {
          abortController.abort();
        }

        const stopAll = () => {
          abortController?.abort();
          hubConnection.stop();
          if (this.reconnectDelayHandle) {
            clearTimeout(this.reconnectDelayHandle);
          }
        };

        this.abortController = new AbortController();
        if (this.requestRun() && this.active()) {
          this.internalStart(hubConnection, this.abortController.signal)
            .then(() => {
              this.connected.set(true);
            })
            // We don't want to bubble this.
            .catch(() => {});
        } else {
          stopAll();
        }

        return () => {
          stopAll();
        };
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  __ = effect(() => {
    const config = this.config();
    const hubConnection = this.hubConnection();
    if (config && hubConnection) {
      const listeningMethod = this.signalRConfig(config).listeningMethod;
      const method = (data: Message) => {
        this._message$.next(data);
      };
      hubConnection.on(listeningMethod, method);

      return () => {
        hubConnection.off(listeningMethod, method);
      };
    }
  });

  ___ = effect(
    () => {
      const hubConnection = this.hubConnection();
      if (hubConnection) {
        const onClose = () => {
          this.lastSuccessfulConnection.set(new Date());
          this.connected.set(false);
        };
        const onReconnected = () => {
          this.connected.set(true);
        };
        const onReconnecting = () => {
          this.lastSuccessfulConnection.set(new Date());
          this.connected.set(false);
        };

        // The API does not allow to off these.
        hubConnection.onclose(onClose);
        hubConnection.onreconnected(onReconnected);
        hubConnection.onreconnecting(onReconnecting);
      }
    },
    { allowSignalWrites: true }
  );

  constructor() {
    combineLatest([toObservable(this.hubConnection).pipe(filter(notEmpty)), this._internalSendMessage$])
      .pipe(
        takeUntilDestroyed(),
        concatMap(([hubConnection, message]) => {
          return from(hubConnection.invoke(message.method, ...message.args)).pipe(
            map(result => ({ success: true, result, message })),
            catchError(err =>
              throwError(() => {
                return {
                  err,
                  message,
                };
              })
            ),
            retry({
              count: 3,
              delay: 100,
            }),
            catchError(({ err, message }: { err: any; message: InternalMessage<unknown> }) => {
              return of({ success: false, result: undefined, message });
            })
          );
        })
      )
      .subscribe({
        next: ({ success, result, message }) => {
          if (success) {
            message.resolve(result);
          } else {
            message.reject(new SignalRError('Problem invoking SignalR invoke.'));
          }
        },
      });
  }

  protected signalRConfig(config: ThreeCConfig): { url: string; listeningMethod: string } {
    throw new Error('urlFnc is not implemented');
  }

  /**
   * Set if the user is active or not on the site.
   * When the user is not active, we attempt a disconnection.
   * When the user comes back, we will re-connect.
   */
  setIsActive(isActive: boolean): void {
    this.active.set(isActive);
  }

  /**
   * Starts a connection with the hub.
   *
   * @returns A guid in order to stop it if necessary.
   */
  start(): string | undefined {
    if (environment.disableSignalR) {
      return;
    }

    const guid = newGuid();
    this.listeners.set(this.listeners().concat(guid));

    return guid;
  }

  stop(guid: string | undefined): void {
    if (environment.disableSignalR) {
      return;
    }

    const newListeners = this.listeners().filter(m => m !== guid);
    this.listeners.set(newListeners);
  }

  retryNow(): void {
    this.retryCounter.set(this.retryCounter() + 1);
  }

  protected invoke<T>(method: string, ...args: any[]): Observable<T> {
    return from(
      new Promise<any>((resolve, reject) => {
        this._internalSendMessage$.next({
          retryCount: 0,
          method,
          args,
          resolve,
          reject,
        });
      })
    );
  }

  // This code is taken from the SignalR repo.
  // Unfortunately, it does not auto reconnect when we call a start().
  // So we mimic the auto-reconnection code when starting.
  private async internalStart(hubConnection: HubConnection, abortSignal: AbortSignal): Promise<void> {
    const reconnectStartTime = Date.now();
    let nextRetryDelay: number | null = null;
    let previousReconnectAttempts = 0;
    const retryError = new SignalRError('Retrying connection.');
    do {
      if (nextRetryDelay !== null) {
        await new Promise<void>(resolve => {
          if (nextRetryDelay) {
            this.reconnectDelayHandle = setTimeout(resolve, nextRetryDelay);
          } else {
            resolve();
          }
        });
        this.reconnectDelayHandle = undefined;
      }

      try {
        await hubConnection.start(); return;
      } catch {
        console.info('We are having difficulty connecting. Retrying.');
      }

      if (abortSignal.aborted) {
        break;
      }

      nextRetryDelay = this.getNextRetryDelay(previousReconnectAttempts++, Date.now() - reconnectStartTime, retryError);
    } while (nextRetryDelay !== null);

    if (abortSignal.aborted) {
      await Promise.reject(new SignalRError('We aborted the connection.')); return;
    }

    await Promise.reject(new SignalRError('Impossible to connect to our server.'));
  }

  private getNextRetryDelay(previousRetryCount: number, elapsedMilliseconds: number, retryReason: Error): number | null {
    return this.retryPolicy.nextRetryDelayInMilliseconds({
      elapsedMilliseconds,
      previousRetryCount,
      retryReason,
    });
  }
}

@Injectable()
export class ServerPushService extends SignalRBase<EventGridSignalRMessage> {
  protected signalRConfig(config: ThreeCConfig): { url: string; listeningMethod: string } {
    return { url: config.signalR.serverPushUrl, listeningMethod: 'Message' };
  }
}

@Injectable()
export class SignalRService extends SignalRBase<NotificationMessage> {
  constructor() {
    super();

    this.message$.pipe(takeUntilDestroyed()).subscribe(notificationMessage => {
      if (!this.authService.isImpersonating()) {
        this.confirm(notificationMessage.notificationId).subscribe();
      }
    });
  }

  protected signalRConfig(config: ThreeCConfig): { url: string; listeningMethod: string } {
    return { url: config.signalR.notificationUrl, listeningMethod: 'Handle' };
  }

  confirm(notificationId: Id): Observable<void> {
    return this.invoke('Confirm', notificationId);
  }

  acknowledge(notificationId: Id): Observable<void> {
    return this.invoke('Acknowledge', notificationId);
  }

  remove(notificationId: Id): Observable<void> {
    return this.invoke('Remove', notificationId);
  }
}

const RETRY_DELAYS_IN_MILLISECONDS = [0, 2000, 10000, 30000];

class IndefiniteReconnectPolicy implements IRetryPolicy {
  // The internal of this software does not allow us to check when the next timer is going to happen
  // So by looking at the code, we will tell the user which timeout has been selected.
  constructor(private readonly fnc?: (retryDelay: number) => void) {}

  public nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
    const retryDelay = RETRY_DELAYS_IN_MILLISECONDS[Math.min(RETRY_DELAYS_IN_MILLISECONDS.length - 1, retryContext.previousRetryCount)];
    this.fnc?.(retryDelay);
    return retryDelay;
  }
}
