import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class ReportEntriesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.reportEntryDelete.replace('$0', id.toString()));
  }
}
