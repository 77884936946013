import { type CustomerInformation } from './cards/customer-information';
import { type PaymentInformation } from './cards/payment-information';
import { type SiteInformation } from './cards/site-information';
import { type ChargeSettings } from './charges';
import { type LineItemDetail, type LineItemDiscount, type LineItemRebate, type TaxItemEntry } from './pricing-models';
import {
  type OptionalTextStateResource,
  type RequiredTextOrderedResource,
  type RequiredTextStateResource,
  type Resource,
} from './resource';
import { type FileInformation } from './upload';

export interface DiscountInformation extends Resource {
  afterTax: boolean;
  amount: Money | null;
  percentage: Fraction | null;
}

export interface RebateInformation extends Resource {
  afterTax: boolean;
  amount: Money | null;
}

export enum SalesProposalStatus {
  Created = 1,
  Ready = 2,
  Finalized = 3,
}

export interface SalesProposalBaseViewData {
  allowOverrideTaxes: boolean;
  taxWarning: boolean;
  hasLoanApplications: boolean;
  chargeSettings: ChargeSettings;
}

export interface SalesProposalViewData extends SalesProposalBaseViewData {
  salesProposalInformation: SalesProposalInformation;
  packageDiscountInformations: SalesProposalPackageDiscountInformation[];
  packageRebateInformations: SalesProposalPackageRebateInformation[];
  loanApplicationInformations: SalesProposalLoanApplicationInformation[];
  packageInformations: SalesProposalPackageInformation[];
  packageDetailInformations: SalesProposalPackageDetailInformation[];
  salesProposalPaymentInformations: SalesProposalPaymentInformation[];
  fileInformations: FileInformation[];

  // If we are a template, these will be null
  customerInformation: CustomerInformation | null;
  siteInformation: SiteInformation | null;
  workOrderId: Id | null;

  allowOverrideTaxes: boolean;

  chargeSettings: ChargeSettings;

  // On the create scenario, we want to force a specific tax to be loaded based on the site.
  forceTax?: TaxItemEntry;
}

export interface SalesProposalInformation extends RequiredTextStateResource {
  technicianId: Id;
  siteId: Id | null;
  isTemplate: boolean;
  status: SalesProposalStatus;
  notes: string | null;
  guarantee: string | null;
  warranty: string | null;
  expire: Date | null;
  freezePricing: boolean;
  taxItemId: Id | null;
  taxOverride: Money | null;
  salesTaxPercentage: Fraction | null;
  createdBy: string;
  updatedBy: string;
  createdDate: Date;
  updatedDate: Date;
  localId: Id;
  siteSystemId: Id | null;
}

export interface SalesProposalPaymentInformation extends PaymentInformation {
  salesProposalId: Id;
}

export interface SalesProposalLoanApplicationInformation extends OptionalTextStateResource {
  salesProposalId: Id;
  loanRateId: Id;
  selected: boolean;
}

export interface SalesProposalPackageInformation extends RequiredTextOrderedResource {
  salesProposalId: Id;
  description: string;
  selected: boolean;
}

export interface SalesProposalPackageDetailInformation extends RequiredTextOrderedResource, LineItemDetail {
  salesProposalPackageId: Id;
  serviceRepairId: Id | null;
  nonStockItemId: Id | null;
  amount: Money | null;
  callDepartmentTypeId: Id | null;
  displayPricing: boolean;
  quantity: number;
}

export interface SalesProposalPackageDiscountInformation extends RequiredTextStateResource, LineItemDiscount {
  salesProposalPackageId: Id;
  afterTax: boolean;
  amount: Money | null;
  percentage: Fraction | null;
  callDepartmentTypeId: Id | null;
}

export interface SalesProposalPackageRebateInformation extends RequiredTextStateResource, LineItemRebate {
  salesProposalPackageId: Id;
  afterTax: boolean;
  amount: Money;
  callDepartmentTypeId: Id | null;
}

export interface PackageSelectionOption {
  salesProposalLoanApplicationId?: Id;
}

export interface SalesProposalInformationForList extends RequiredTextStateResource {
  createdDate: Date;
  updatedDate: Date;
  localId: Id;
  customerId: Id | null;
  siteId: Id | null;
  technicianId: Id;
  status: SalesProposalStatus;
  customerName: string;
  siteName: string;
  technicianName: string;
}

export interface SalesProposalPackageWriteHeaderModel {
  text: string;
  description: string;
}

export function getSalesProposalStatus(status: SalesProposalStatus): string | null {
  switch (status) {
    case SalesProposalStatus.Created:
      return 'Created';
    case SalesProposalStatus.Ready:
      return 'Ready';
    case SalesProposalStatus.Finalized:
      return 'Finalized';
  }

  return null;
}

export function createEmpty(): SalesProposalInformation {
  return {
    id: 0,
    text: '',
    localId: 0,
    technicianId: 0,
    siteId: null,
    isTemplate: false,
    status: SalesProposalStatus.Created,
    notes: null,
    guarantee: null,
    warranty: null,
    expire: null,
    freezePricing: true,
    taxItemId: null,
    taxOverride: null,
    salesTaxPercentage: null,
    createdBy: '',
    updatedBy: '',
    siteSystemId: null,
    createdDate: new Date(),
    updatedDate: new Date(),
  };
}

