export function closest(el: HTMLElement, selector: string): HTMLElement | null {
  let matchesFn: string | undefined;

  // find vendor prefix
  ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
    if (typeof (document.body as any)[fn] === 'function') {
      matchesFn = fn;
      return true;
    }

    return false;
  });

  const matched = matchesFn;
  if (matched) {
    let parent: HTMLElement | null;

    // traverse parents
    let currentEl: HTMLElement | null = el;
    while (currentEl) {
      parent = currentEl.parentElement;

      if ((parent as any | undefined)?.[matched]?.(selector)) {
        return parent;
      }

      currentEl = parent;
    }
  }

  return null;
}

export function isDescendant(parent: HTMLElement, child: HTMLElement): boolean {
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

export function getPosition(element: HTMLElement): { x: number; y: number } {
  let current = element;
  let x = 0;
  let y = 0;
  while (current !== null) {
    x += current.offsetLeft;
    y += current.offsetTop;

    current = current.offsetParent as HTMLElement;
  }

  return { x, y };
}

export function findScrollable(element: HTMLElement): HTMLElement | null {
  let current = element;
  while (current !== null) {
    if (current.scrollTop || current.scrollLeft) {
      return current;
    }

    current = current.parentNode as HTMLElement;
  }

  return null;
}
