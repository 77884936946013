import { Directive, ElementRef, EventEmitter, HostListener, Output, inject } from '@angular/core';

@Directive({
  selector: '[wmFileSelect]',
  standalone: true,
})
export class FileSelectDirective {
  protected readonly element = inject(ElementRef);

  @Output() public fileSelect = new EventEmitter<File[]>();

  public isEmptyAfterSelection(): boolean {
    return !!this.element.nativeElement.attributes.multiple;
  }

  @HostListener('change')
  public onChange(): any {
    const files = this.element.nativeElement.files;

    this.fileSelect.emit(files);

    // Let's change the value to nothing so we can re-upload the same file path.
    this.element.nativeElement.value = '';
  }
}
