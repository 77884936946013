import { TechniciansService } from '@services/live/technicians.service';
import { ActionService } from './action.service';
import { AlertsService } from './alerts.service';
import { AppInsightsService } from './app-insights.service';
import { ApplicationCacheService } from './application-cache.service';
import { AuthService } from './auth.service';
import { AzureStorageService } from './azure-storage.service';
import { CacheService } from './cache.service';
import { CustomTitleService } from './custom-title.service';
import { CustomerDataHelperService } from './customer-data-helper.service';
import { DynamicFilterService } from './dynamic-filter.service';
import { ErrorHelperService } from './error-helper.service';
import { ErrorLoggingService } from './error-logging.service';
import { ErrorService } from './error.service';
import { GoogleMapApiService } from './google-map-api/google-map-api.service';
import { HelpJsonService } from './help-json.service';
import { HttpClientBaseService } from './http-client-base.service';
import { HttpClientService } from './http-client.service';
import { IntercomService } from './intercom.service';
import { AccountsService } from './live/accounts.service';
import { AgreementSiteSystemsService } from './live/agreement-site-systems.service';
import { AgreementsService } from './live/agreements.service';
import { BeltsService } from './live/belts.service';
import { BillingsService } from './live/billings.service';
import { CallDepartmentTypesService } from './live/call-department-types.service';
import { CallReasonsService } from './live/call-reasons.service';
import { CallWorkOrderTypesService } from './live/call-work-order-types.service';
import { CallsService } from './live/calls.service';
import { ChargesService } from './live/charges.service';
import { ContractorBillingsService } from './live/contractor-billings.service';
import { ContractorImportsService } from './live/contractor-imports.service';
import { ContractorSettingsService } from './live/contractor-settings.service';
import { CustomFieldsService } from './live/custom-fields.service';
import { CustomersService } from './live/customers.service';
import { DebriefAnswersService } from './live/debrief-answers.service';
import { DebriefCategoriesService } from './live/debrief-categories.service';
import { DebriefEntriesService } from './live/debrief-entries.service';
import { DiscountsService } from './live/discounts.service';
import { DistributorsService } from './live/distributors.service';
import { DutiesService } from './live/duties.service';
import { DutyCategoriesService } from './live/duty-categories.service';
import { EventsService } from './live/events.service';
import { FeatureFlagsService } from './live/feature-flags.service';
import { FeaturesService } from './live/features.service';
import { FilesService } from './live/files.service';
import { FilterTypesService } from './live/filter-types.service';
import { FiltersService } from './live/filters.service';
import { InspectionAnswersService } from './live/inspection-answers.service';
import { InspectionCategoriesService } from './live/inspection-categories.service';
import { InspectionEntriesService } from './live/inspection-entries.service';
import { InvoicesService } from './live/invoices.service';
import { LeaderboardCategoriesService } from './live/leaderboard-categories.service';
import { LoansService } from './live/loans.service';
import { LocationsService } from './live/locations.service';
import { PartItemsService } from './live/part-items.service';
import { PaymentMethodsService } from './live/payment-methods.service';
import { PaymentTypesService } from './live/payment-types.service';
import { PriceBooksService } from './live/price-books.service';
import { RebatesService } from './live/rebates.service';
import { RecommendationPhotosService } from './live/recommendation-photos.service';
import { RecommendationsService } from './live/recommendations.service';
import { ReferralTypesService } from './live/referral-types.service';
import { ReplenishmentsService } from './live/replenishments.service';
import { ReportCategoriesService } from './live/report-categories.service';
import { ReportEntriesService } from './live/report-entries.service';
import { ReportsService } from './live/reports.service';
import { SalesProposalsService } from './live/sales-proposals.service';
import { SandboxService } from './live/sandbox.service';
import { SearchService } from './live/search.service';
import { SettingsService } from './live/settings.service';
import { SiteSystemBehaviorService } from './live/site-system-behavior.service';
import { SiteSystemsService } from './live/site-systems.service';
import { SitesService } from './live/sites.service';
import { SmsMessagesService } from './live/sms-messages.service';
import { SquareService } from './live/square.service';
import { StripeService } from './live/stripe.service';
import { SubscriptionsService } from './live/subscriptions.service';
import { SynchronizerService } from './live/synchronizer.service';
import { SystemTypesService } from './live/system-types.service';
import { TagEntitiesService } from './live/tag-entities.service';
import { TagsService } from './live/tags.service';
import { TaxItemSectionsService } from './live/tax-item-sections.service';
import { TechnicianSettingsService } from './live/technician-settings.service';
import { VideosService } from './live/videos.service';
import { WebMarketingService } from './live/web-marketing.service';
import { WorkOrdersService } from './live/work-orders.service';
import { ZapierService } from './live/zapier.service';
import { ZonesService } from './live/zones.service';
import { EnerBankLoanHandlerService } from './loan-handlers/ener-bank.loan-handler.service';
import { LOAN_HANDLER } from './loan-handlers/loan-handler';
import { ManualLoanHandlerService } from './loan-handlers/manual.loan-handler.service';
import { Filter2Service, FilterService } from './pager.service';
import { NonePaymentGatewayHandlerService } from './payment-gateways/none-payment-gateway-handler.service';
import { PAYMENT_GATEWAY_HANDLER } from './payment-gateways/payment-gateway-handler';
import { SquarePaymentGatewayHandlerService } from './payment-gateways/square-payment-gateway-handler.service';
import { StripePaymentGatewayHandlerService } from './payment-gateways/stripe-payment-gateway-handler.service';
import { PopoverEventInfoFactoryService } from './popover-event-info-factory.service';
import { PriceService } from './price.service';
import { PubSubService } from './pub-sub.service';
import { RendererService } from './renderer.service';
import { RolesService } from './roles.service';
import { ScrollService } from './scroll.service';
import { ServerPushService, SignalRService } from './signalr.service';
import { StaticDataService } from './static-data.service';
import { TechnicianMapImageService } from './technician-map-image.service';
import { TechnicianMapService } from './technician-map.service';
import { TimerService } from './timer.service';
import { UNIQUE_SELECTION_DISPATCHER_PROVIDER } from './unique-selection-dispatcher';
import { UrlHelperService } from './url-helper.service';
import { UrlService } from './url.service';
import { VisitService } from './visit.service';
import { WindowRefService } from './window-ref.service';
import { WorkOrderViewService } from './work-order/work-order-view.service';

export const providers = [
  AppInsightsService,
  ApplicationCacheService,
  IntercomService,
  AuthService,
  RolesService,
  CacheService,
  ErrorService,
  ErrorHelperService,
  HttpClientBaseService,
  HttpClientService,
  StaticDataService,
  UrlService,
  ActionService,
  RendererService,
  TimerService,
  PriceService,
  VisitService,
  WindowRefService,
  UrlHelperService,
  ErrorLoggingService,
  FilterService,
  AzureStorageService,
  ScrollService,
  SignalRService,
  ServerPushService,
  AlertsService,
  CustomTitleService,
  CustomerDataHelperService,
  WorkOrderViewService,
  DynamicFilterService,
  SandboxService,
  AccountsService,
  BillingsService,
  FeatureFlagsService,
  FeaturesService,
  AgreementsService,
  AgreementSiteSystemsService,
  BeltsService,
  PaymentMethodsService,
  PaymentTypesService,
  CallDepartmentTypesService,
  ReferralTypesService,
  FilterTypesService,
  CallWorkOrderTypesService,
  CallReasonsService,
  DutyCategoriesService,
  SystemTypesService,
  CallsService,
  ContractorImportsService,
  ContractorBillingsService,
  CustomersService,
  Filter2Service,
  CustomFieldsService,
  EventsService,
  FiltersService,
  InspectionAnswersService,
  RecommendationPhotosService,
  RecommendationsService,
  SiteSystemsService,
  SiteSystemBehaviorService,
  SitesService,
  DutiesService,
  InvoicesService,
  PartItemsService,
  PriceBooksService,
  SquareService,
  StripeService,
  SalesProposalsService,
  WebMarketingService,
  WorkOrdersService,
  LocationsService,
  ZonesService,
  ZapierService,
  SearchService,
  ReplenishmentsService,
  SettingsService,
  SmsMessagesService,
  LoansService,
  ChargesService,
  SubscriptionsService,
  RebatesService,
  DiscountsService,
  DistributorsService,
  SynchronizerService,
  ContractorSettingsService,
  TaxItemSectionsService,
  TechnicianSettingsService,
  ReportsService,
  DebriefAnswersService,
  DebriefCategoriesService,
  DebriefEntriesService,
  InspectionCategoriesService,
  InspectionEntriesService,
  LeaderboardCategoriesService,
  ReportCategoriesService,
  ReportEntriesService,
  TagEntitiesService,
  TagsService,
  TagEntitiesService,
  FilesService,
  VideosService,
  TechnicianMapImageService,
  GoogleMapApiService,
  TechnicianMapService,
  PopoverEventInfoFactoryService,
  PubSubService,
  TechniciansService,

  HelpJsonService,

  UNIQUE_SELECTION_DISPATCHER_PROVIDER,

  { provide: LOAN_HANDLER, useClass: EnerBankLoanHandlerService, multi: true },
  { provide: LOAN_HANDLER, useClass: ManualLoanHandlerService, multi: true },

  {
    provide: PAYMENT_GATEWAY_HANDLER,
    useClass: SquarePaymentGatewayHandlerService,
    multi: true,
  },
  {
    provide: PAYMENT_GATEWAY_HANDLER,
    useClass: StripePaymentGatewayHandlerService,
    multi: true,
  },
  {
    provide: PAYMENT_GATEWAY_HANDLER,
    useClass: NonePaymentGatewayHandlerService,
    multi: true,
  },
];
