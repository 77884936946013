import { Injectable } from '@angular/core';
import { LoanCompany, type LoanCreate, type LoanSelection, type WorkOrderLoanApplicationInformation } from '@models/loans';
import { type Observable, of } from 'rxjs';
import { type LoanContext, type LoanHandler } from './loan-handler';

@Injectable()
export class ManualLoanHandlerService implements LoanHandler {
  loanCompany: LoanCompany = LoanCompany.Manual;

  handle(loanSelection: LoanSelection, loanCreate: LoanCreate, context: LoanContext): Observable<LoanCreate> {
    return of(loanCreate);
  }

  canStartLoan(): boolean {
    return false;
  }

  startLoan(_: WorkOrderLoanApplicationInformation): void {
    throw new Error('Not supported');
  }

  getLoanLink(application: WorkOrderLoanApplicationInformation): { url: string; text: string } | null {
    return null;
  }

  getCompanyName(): string {
    return '';
  }

  canModifyReferenceNumber(): boolean {
    return true;
  }
}
