import { DatePipe } from '@angular/common';
import { AddressPipe } from './address.pipe';
import { BeltInfoPipe } from './belt-info.pipe';
import { CentsPipe } from './cents.pipe';
import { DeletedPipe } from './deleted.pipe';
import { EmailPipe } from './email.pipe';
import { EntityPipe } from './entity.pipe';
import { FilenamePipe } from './filename.pipe';
import { FilesizePipe } from './filesize.pipe';
import { FilterInfoPipe } from './filter-info.pipe';
import { HighlightPipe } from './highlight.pipe';
import { IntPipe } from './int.pipe';
import { KvpPipe } from './kvp.pipe';
import { LoanPipe } from './loan.pipe';
import { MoneyPipe } from './money.pipe';
import { Nl2BrPipe } from './nl2br.pipe';
import { PercentagePipe } from './percentage.pipe';
import { Percentage2Pipe } from './percentage2.pipe';
import { PhonePipe } from './phone.pipe';
import { QuickBooksPipe } from './quick-books.pipe';
import { RefPipe } from './ref.pipe';
import { SecurePipe } from './secure.pipe';
import { SiteSystemWordingPipe } from './site-system-wording.pipe';
import { SiteSystemPipe } from './site-system.pipe';
import { SitePipe } from './site.pipe';
import { StatusPipe } from './status.pipe';
import { TaxPipe } from './tax.pipe';
import { TimePipe } from './time.pipe';

export const providers = [
  AddressPipe,
  BeltInfoPipe,
  CentsPipe,
  DatePipe,
  DeletedPipe,
  EmailPipe,
  EntityPipe,
  FilenamePipe,
  FilesizePipe,
  FilterInfoPipe,
  HighlightPipe,
  IntPipe,
  KvpPipe,
  LoanPipe,
  MoneyPipe,
  Nl2BrPipe,
  PercentagePipe,
  Percentage2Pipe,
  PhonePipe,
  QuickBooksPipe,
  RefPipe,
  SecurePipe,
  SiteSystemWordingPipe,
  SiteSystemPipe,
  SitePipe,
  StatusPipe,
  TaxPipe,
  TimePipe,
];
