import { Pipe, type PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'percentage2',
  standalone: true,
})
export class Percentage2Pipe implements PipeTransform {
  transform(percentage: number | null | undefined): string {
    if (isNil(percentage)) {
      return '';
    }

    return `${percentage}%`;
  }
}
