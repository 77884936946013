import { type ComponentType } from '@angular/cdk/portal';
import { type DatePipe } from '@angular/common';
import { type Type } from '@angular/core';
import { type FormControl } from '@angular/forms';
import { FilterDynamicCheckboxComponent } from '@controls/filter-dynamic/filter-dynamic-checkbox.component';
import { type FilterDynamicDropdownComponent } from '@controls/filter-dynamic/filter-dynamic-dropdown.component';
import { type FilterDynamicEasyDateComponent } from '@controls/filter-dynamic/filter-dynamic-easydate.component';
import { type FilterDynamicMagicDateComponent } from '@controls/filter-dynamic/filter-dynamic-magicdate.component';
import { type FilterDynamicTechnicianComponent } from '@controls/filter-dynamic/filter-dynamic-technician.component';
import { type FilterDynamicTextComponent } from '@controls/filter-dynamic/filter-dynamic-text.component';
import { getObjectKeys } from '@utility/object';
import { pickBy } from 'lodash';
import { type Observable } from 'rxjs';
import { type DialogFilter2Impl } from './dialog-models';
import { type ExtraResource1, type OrderedResource, type Resource1 } from './resource';

export interface TableSegment<TFilters extends SupportedFilters<any> | undefined, TColumns extends string[]> extends OrderedResource<Id> {
  query: FilterBase<TFilters, TColumns>;
  system: boolean;
  title?: string;
}

export interface FancyFilterUICustom {
  type: Type<any>;
  inputs: Record<string, unknown>;
}

export interface FancyFilterUIText {
  type: typeof FilterDynamicTextComponent;
}

export interface FancyFilterUICheckbox {
  type: typeof FilterDynamicCheckboxComponent;
  inputs: {
    options: { id: number | string; text: string }[];
  };
}

export interface FancyFilterUITechnician {
  type: typeof FilterDynamicTechnicianComponent;
}

export interface FancyFilterUIDropdown {
  type: typeof FilterDynamicDropdownComponent;
  inputs: {
    clearable?: boolean;
    multiselect?: boolean;
    changeEmptyTo?: any | null;
    options: { id: number | string | null; text: string }[];
  };
}

export interface FancyFilterUIDate {
  type: typeof FilterDynamicMagicDateComponent;
}

export interface FancyFilterUIEasyDate {
  type: typeof FilterDynamicEasyDateComponent;
}

export type FancyFilterUI = (
  | FancyFilterUICustom
  | FancyFilterUIText
  | FancyFilterUICheckbox
  | FancyFilterUITechnician
  | FancyFilterUIDropdown
  | FancyFilterUIDate
  | FancyFilterUIEasyDate
) & { closeAfterSelection: boolean; inputs: { label?: string; code: string; name: string } };

export interface FancyFilterConfig<T = unknown, TResult = T> {
  /**
   * Gets the value from the FormControl.
   *
   * @param controlValue
   * @returns
   */
  get: (controlValue: T | null) => TResult | null;

  /**
   * Returns the value to set in the FormControl.
   * If you return undefined, there is no-op
   *
   * @param value
   * @param context
   * @returns
   */
  set: (value: TResult | null, context: FancyFilterData) => T | null | undefined;
  ui: FancyFilterUI;
}

export type FancyFilterData = SupportedFilters<any>[0]['value'];

export interface FancyFilterImpl<T, TResult = T> {
  readonly code: string;

  /**
   * Indicates this filter can be there multiple times.
   */
  readonly multiple?: boolean;

  init?: () => FormControl<SupportedFilters<any> | null>;

  /**
   * Loads the required data for the filter to work.
   */
  load?: () => Promise<void>;

  /**
   * Returns the configuration on how to display the filter on the screen.
   */
  getUIConfig: () => FancyFilterConfig<T, TResult>[];

  /**
   * Returns the summary of what is currently being filtered.
   */
  getUISummary: (value: T | null) => string;

  /**
   * Gets the simple name with potentially an icon.
   */
  getSimpleName: () => ExtraResource1<string>;

  /**
   * Returns the icon to use.
   */
  getIcon?: () => { matIcon?: string; url?: string };
}

type TableColumnRender = { component: Type<any>; inputs: Record<string, unknown> };

export interface TableColumnDefinition<TResult> {
  readonly id: string;
  readonly text: string;
  readonly classes?: string[];
  readonly dataTestId?: string;
  readonly required?: boolean;
  readonly order?: number;
  load?: () => Promise<void>;
  getColumns?: (items: TResult[]) => { id?: string; text?: string; classes?: string[]; dataTestId?: string }[];
  renderComponent?: (item: TResult, id: string) => TableColumnRender;
}

export interface FancyFilterDateRange {
  from?: Date;
  to?: Date;
}

export type RelativeValue =
  | { days: number; months: null; years: null }
  | { days: null; months: number; years: null }
  | { days: null; months: null; years: number };
export type Relative = { $type: 'DateRangeFilter.Relative'; from?: RelativeValue; to?: RelativeValue };
export type AbsoluteValue = Date;
export type Absolute = { $type: 'DateRangeFilter.Absolute'; from?: AbsoluteValue; to?: AbsoluteValue };
export type FancyFilterMagicDateRange = Relative | Absolute;

export const isRelative = (value: FancyFilterMagicDateRange): value is Relative => {
  return value.$type === 'DateRangeFilter.Relative';
};

export const isAbsolute = (value: FancyFilterMagicDateRange): value is Absolute => {
  return value.$type === 'DateRangeFilter.Absolute';
};

export const magicDateTransform = (
  value: FancyFilterMagicDateRange,
  key: keyof Omit<FancyFilterMagicDateRange, '$type'>,
  yearOnly: boolean,
  datePipe: DatePipe
): string => {
  if (isAbsolute(value)) {
    if (yearOnly) {
      return value[key].getUTCFullYear().toString();
    }

    return datePipe.transform(value[key]);
  }

  let v = 0;
  let word = '';
  const val = value[key];
  if (val.years) {
    v = val.years;
    word = 'year';
  } else if (val.months) {
    v = val.months;
    word = 'month';
  } else if (val.days) {
    v = val.days;
    word = 'day';
  }

  const moment = yearOnly ? 'old' : v < 0 ? 'ago' : 'ahead';
  const plural = Math.abs(v) > 1 ? 's' : '';

  if (v === 0) {
    return 'now';
  }

  return `${Math.abs(v)} ${word}${plural} ${moment}`;
};

type MatchesConstructor<T> = T extends new (...args: any[]) => infer U ? U : never;

type MatchesFilters<T> = T extends FancyFilterImpl<infer R> ? R : any; // Should be never instead of any
type MatchesFilterCode<T extends { prototype: { code: string } }> = T['prototype']['code'];
type FilterObject<T extends { prototype: { code: string } }> = {
  id: string;
  $type: MatchesFilterCode<T>; // We need this one, but the server needs the one in value.
  value:
    | (MatchesFilters<MatchesConstructor<Extract<T, { prototype: { code: MatchesFilterCode<T> } }>>> & { $type: MatchesFilterCode<T> })
    | null;
};
export type SupportedFilters<T extends readonly Type<FancyFilterImpl<unknown>>[]> = FilterObject<T[number]>[];

type MatchesColumnCode<T extends { prototype: { id: string } }> = T['prototype']['id'];
export type SupportedColumns<T extends readonly Type<TableColumnDefinition<any>>[]> = MatchesColumnCode<T[number]>[];

export type X<T extends Type<FancyFilterImpl<unknown>>> = SupportedFilters<[T]>[0];

export interface FilterBase<TFilters extends SupportedFilters<any> | undefined, TColumns extends string[]> {
  columns: TColumns;
  filters: TFilters;
  orders?: OrderBySettings[];
}

/** @deprecated */
export interface Filter<TFilters extends Record<string, unknown> | undefined> {
  filters?: TFilters;
  orders?: OrderBySettings[];
  take?: number;
  skip?: number;
}

export interface Filter2<TFilters extends SupportedFilters<any> | undefined, TColumns extends string[]>
  extends FilterBase<TFilters, TColumns> {
  take?: number;
  skip?: number;
}

export interface FilterData {
  filters: FilterInfo[];
}

export enum Direction {
  Asc = 1,
  Desc = 2,
}

export interface ListOrderBy<T> {
  field: keyof T;
  direction: Direction;
}

export interface FilterByRuleBase<T> {
  text?: string;
  field: keyof T;
}

export interface FilterByRuleIn<T> extends FilterByRuleBase<T> {
  operator: 'in';
  value: T[keyof T][];
}

export interface FilterByRuleSingle<T> extends FilterByRuleBase<T> {
  operator: '==' | '!=' | '>' | '<' | '>=' | '<=';
  value: T[keyof T];
}

export type FilterByRule<T> = FilterByRuleSingle<T> | FilterByRuleIn<T>;

export interface FilterBy<T> {
  condition: 'and' | 'or';
  rules: (FilterByRule<T> | FilterBy<T>)[];
}

export interface ListParameters<T> {
  pageNumber: number;
  pageSize: number;
  orderBys: ListOrderBy<T>[];
  filterBys: FilterBy<T> | null;
}

export interface PaginatedList<T> {
  pageNumber: number;
  totalPages: number;
  totalCount: number;
  results: T[];
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface FilterConfig<T> {
  filterCountFnc: (filterBys: FilterBy<T>) => number;
  dialogType: ComponentType<DialogFilter2Impl<T>>;
}

export interface DynamicFilterHelper<FILTER_DATA, RESULT_DATA> {
  paginatedList$: Observable<PaginatedList<RESULT_DATA>>;
  results$: Observable<RESULT_DATA[]>;
  opened$: Observable<boolean>;
  filterAmount$: Observable<number>;
  currentListParameters$: Observable<ListParameters<FILTER_DATA>>;
  setConfig: (filterConfig: FilterConfig<FILTER_DATA>) => void;
  setDefaultParameters: (listParameters: Partial<ListParameters<FILTER_DATA>>) => void;
  changeParameters: (listParameters: Partial<ListParameters<FILTER_DATA>>) => void;
  openChange: (open: boolean) => void;
  openDialog: () => void;
  clearAllFilters: () => void;
  refresh: () => void;
}

export interface DynamicFilterContext<T> {
  orderBys: ListOrderBy<T>[];
  filterBy: FilterBy<T> | null;
  opened: boolean;
}

export interface DynamicFilterData<T> {
  listParameters: ListParameters<T>;
}

export enum FilterAction {
  Cancel,
  AddFilter,
}

export interface FilterResult<T> {
  action: FilterAction;
  result?: T;
}

/** @deprecated */
export interface FilterInfo extends Resource1<string> {
  value: any;
  operator?: string;
}

export interface PagedResult<T> {
  /**
   * How many results we take.
   */
  take: number;

  /**
   * How many results we skip.
   */
  skip: number;

  /**
   * How many results total.
   */
  count: number;

  /**
   * The results.
   */
  results: T[];

  /**
   * If an error happened.
   */
  error?: string | null;
}

export interface DefaultTableSegment<TFilters extends SupportedFilters<any> | undefined> {
  id: Id;
  name: string;
  description: string;
  filters: TFilters;
}

export type TableQueryResult<T> = PagedResult<T>;

export interface OrderBySettings {
  by: string;
  descending?: boolean;
}

export enum StatusFilter {
  All = 0,
  Visible = 1,
  Deleted = 2,
}

export enum ConnectionType {
  Customer,
  Site,
  Equipment,
}

export enum LogicalOperator {
  None = 0,
  Not = 1,
}

export interface LogicalOperatorData {
  logicalOperator?: LogicalOperator;
}

export const combineData = <T, K extends keyof T>(key: K, value: T[K], data: T): T | null => {
  const result = { ...data, [key]: value };
  const final = pickBy(result, v => v != null) as T;

  const keys = getObjectKeys(final as Record<string, unknown>);
  if (keys.length === 1 && keys[0] === 'logicalOperator') {
    return null;
  }

  return keys.length ? final : null;
};

export function getReversedFilterConfig<T>(code: string): FancyFilterConfig<T & LogicalOperatorData, [boolean?]> {
  return {
    get: controlValue => {
      return controlValue?.logicalOperator === LogicalOperator.Not ? [true] : [];
    },
    set: (value, context) => {
      const logicalOperator = value?.[0] ? LogicalOperator.Not : LogicalOperator.None;
      return combineData('logicalOperator', logicalOperator === LogicalOperator.Not ? logicalOperator : null, context) as T &
        LogicalOperatorData;
    },
    ui: {
      type: FilterDynamicCheckboxComponent,
      closeAfterSelection: false,
      inputs: {
        code,
        name: `${code}-reverse`,
        options: [
          {
            id: true,
            text: 'Reverse this filter',
          },
        ],
      },
    },
  };
}

export function getNormalizedUIConfigs(filter: FancyFilterImpl<unknown>): FancyFilterConfig<unknown, unknown>[] {
  return [filter.getUIConfig()].flat();
}
