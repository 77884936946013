import { Injectable, inject } from '@angular/core';
import { type InspectionCategoryInformation } from '@models/inspection-models';
import { clone } from '@utility/object';
import { sortWithChildren } from '@utility/observable';
import { type Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class InspectionCategoriesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(): Observable<InspectionCategoryInformation[]> {
    return (
      this.applicationCacheService
        // We load all anyway, since our API does not filter for deleted inspection sheets.
        .getDataFromCache(UpdateCategory.Inspections, this.url.inspectionCategoryList)
        .pipe(map(m => clone(m))) // The data is frozen
        .pipe(sortWithChildren())
    );
  }

  get(id: Id): Observable<InspectionCategoryInformation> {
    return this.http.get<InspectionCategoryInformation>(this.url.inspectionCategoryGet.replace('$0', id.toString()));
  }

  save(inspectionCategory: InspectionCategoryInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.inspectionCategoryPost, inspectionCategory).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.inspectionCategoryDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  hide(id: Id): Observable<void> {
    return this.http.post<void>(this.url.inspectionCategoryHide.replace('$0', id.toString()), null).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.inspectionCategoryRestore.replace('$0', id.toString()), null).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.Inspections);
    }
  }
}
