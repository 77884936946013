import { type AgreementSiteSystemInformation } from '@models/cards/agreement-site-system-information';
import { type CallInformation } from '@models/cards/call-information';
import { type InspectionAnswerInformation } from '@models/cards/inspection-answer-information';
import { type RecommendationInformation, type RecommendationPhotoInformation } from '@models/cards/recommendation-information';
import { isMiscellaneousSiteSystem, type SiteSystemInformation, type SiteSystem } from '@models/cards/site-system-information';
import { type WorkOrderAgreementInformation } from '@models/cards/work-order-agreement-information';
import { type WorkOrderDetailInformation } from '@models/cards/work-order-detail-information';
import {
  type WorkOrderDiscountInformation,
  type WorkOrderInformation,
  type WorkOrderRebateInformation,
} from '@models/cards/work-order-information';
import { type WorkOrderPaymentInformation } from '@models/cards/work-order-payment-information';
import { type WorkOrderRepairPartInformation } from '@models/cards/work-order-repair-part-information';
import { type WorkOrderSiteSystemInformation } from '@models/cards/work-order-site-system-information';
import { type DebriefAnswerInformation } from '@models/debrief-models';
import { type WorkOrderLoanApplicationInformation } from '@models/loans';
import { type Creatable, type Deletable, type Mutable, type Updatable } from '@models/mutable';
import { type TempFile } from '@models/photo';
import { type TaxItemEntry } from '@models/pricing-models';
import { type Resource } from '@models/resource';
import { type SalesProposalInformation, type SalesProposalPackageInformation } from '@models/sales-proposal-models';
import { type TagEntityInformation } from '@models/tag-models';
import { type TaxItemSection } from '@models/tax-item-section';
import { type Action, createReducer, on } from '@ngrx/store';
import { indexBy } from '@utility/array';
import { clone } from '@utility/object';
import { type SaveState } from '../save-store/save.actions';
import * as WorkOrderActions from './work-order.actions';
import { type NonStockItemInformation } from '@models/cards/non-stock-item-information';

export type WorkOrderInformationState = WorkOrderInformation & Creatable & Updatable;
export type CallInformationState = CallInformation & Updatable;
export type WorkOrderLoanApplicationState = Record<string, WorkOrderLoanApplicationInformation & Mutable>;
export type WorkOrderDetailState = Record<string, WorkOrderDetailInformation & Mutable>;
export type WorkOrderRepairPartState = Record<string, WorkOrderRepairPartInformation & Mutable>;
export type NonStockItemState = Record<string, NonStockItemInformation & Mutable>;
export type WorkOrderDiscountState = Record<string, WorkOrderDiscountInformation & Mutable>;
export type WorkOrderRebateState = Record<string, WorkOrderRebateInformation & Mutable>;
export type WorkOrderPaymentState = Record<string, WorkOrderPaymentInformation & Mutable>;
export type RecommendationState = Record<string, RecommendationInformation & Mutable>;
export type RecommendationPhotoState = Record<string, (RecommendationPhotoInformation & Mutable) | (TempFile & Creatable)>;
export type DebriefAnswerState = Record<string, DebriefAnswerInformation & Mutable>;
export type TagEntityState = Record<string, TagEntityInformation & Mutable>;
export type InspectionAnswerState = Record<string, InspectionAnswerInformation & Mutable>;
export type SiteSystemState = Record<string, SiteSystem & Mutable>;
export type WorkOrderSiteSystemState = Record<string, WorkOrderSiteSystemInformation>;
export type SalesProposalState = SalesProposalInformation & Updatable;
export type SalesProposalPackageState = SalesProposalPackageInformation & Updatable;
export type WorkOrderAgreementState = Record<string, WorkOrderAgreementInformation & Mutable>;
export type AgreementSiteSystemState = Record<string, AgreementSiteSystemInformation & Mutable>;
export type TaxItemEntryState = (TaxItemEntry & Mutable) | null;
export interface EntitiesState {
  workOrder: WorkOrderInformationState | null;
  call: CallInformationState;
  workOrderLoanApplications: WorkOrderLoanApplicationState;
  workOrderDetails: WorkOrderDetailState;
  workOrderRepairParts: WorkOrderRepairPartState;
  nonStockItems: NonStockItemState;
  workOrderDiscounts: WorkOrderDiscountState;
  workOrderRebates: WorkOrderRebateState;
  workOrderPayments: WorkOrderPaymentState;
  recommendations: RecommendationState;
  recommendationPhotos: RecommendationPhotoState;
  debriefAnswers: DebriefAnswerState;
  tagEntities: TagEntityState;
  inspectionAnswers: InspectionAnswerState;
  siteSystems: SiteSystemState;
  workOrderSiteSystems: WorkOrderSiteSystemState;
  salesProposal: SalesProposalState;
  salesProposalPackage: SalesProposalPackageState;
  workOrderAgreements: WorkOrderAgreementState;
  agreementSiteSystems: AgreementSiteSystemState;
  internalTaxItemEntry: TaxItemEntryState;
  propertyTypeId: Id | null;
  classMappingPerDetail: boolean;
}

export interface WorkOrderState extends SaveState {
  edit: boolean;
  entities: EntitiesState;
  entitiesSnapshot: EntitiesState | null;
  taxItemSections: TaxItemSection[];
}

export const initialState = (): WorkOrderState => ({
  edit: false,
  entities: {
    workOrder: null,
    call: null,
    workOrderLoanApplications: {},
    workOrderDetails: {},
    workOrderRepairParts: {},
    nonStockItems: {},
    workOrderDiscounts: {},
    workOrderRebates: {},
    workOrderPayments: {},
    recommendations: {},
    recommendationPhotos: {},
    debriefAnswers: {},
    tagEntities: {},
    inspectionAnswers: {},
    siteSystems: {},
    workOrderSiteSystems: {},
    salesProposal: null,
    salesProposalPackage: null,
    workOrderAgreements: {},
    agreementSiteSystems: {},
    internalTaxItemEntry: null,
    propertyTypeId: null,
    classMappingPerDetail: false,
  },
  entitiesSnapshot: null,
  taxItemSections: null,
  _submitCounter: 0,
  _error: null,
  _cacheCorrelationId: null,
  _correlationId: null,
});

function loadReducer(arg: { data: Resource[] }): Record<string, Resource> {
  return indexBy(arg.data, 'id');
}

function addReducer(
  arg: { data: Partial<Resource> },
  order: number,
  created = true
): Record<number, Partial<Resource> & Creatable & { order: number }> {
  return {
    [arg.data.id]: { ...arg.data, order, created },
  };
}

function updateReducer(
  stateResource: Record<number, Resource>,
  arg: { id: Id; patch: Partial<Resource & Mutable> }
): Record<number, Resource & Updatable> {
  return {
    [arg.id]: { ...stateResource[arg.id], ...arg.patch, updated: true },
  };
}

function setDeleted<T extends Resource>(resource: T): T & Mutable {
  return {
    ...resource,
    created: false,
    updated: false,
    deleted: true,
  };
}

function deleteReducer(stateResource: Record<number, Resource>, arg: { id: Id }): Record<number, Resource & Deletable> {
  return {
    [arg.id]: setDeleted(stateResource[arg.id]),
  };
}

function entityReducer(state: WorkOrderState, key: keyof WorkOrderState['entities'], value: any): WorkOrderState {
  const obj = {};
  obj[key] = value;
  return { ...state, entities: { ...state.entities, ...obj } };
}

// Start: WorkOrderReducer
const workOrderReducer = createReducer(
  initialState(),
  on(WorkOrderActions.loadWorkOrder, (state, action) => {
    const data = entityReducer(state, 'workOrder', action.data);
    data.entities.internalTaxItemEntry = null;
    return data;
  }),
  on(WorkOrderActions.loadPropertyTypeId, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        propertyTypeId: action.propertyTypeId,
      },
    };
  }),
  on(WorkOrderActions.loadClassMappingPerDetail, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        classMappingPerDetail: action.classMappingPerDetail,
      },
    };
  }),
  on(WorkOrderActions.loadCall, (state, action) => entityReducer(state, 'call', action.data)),
  on(WorkOrderActions.loadDetails, (state, action) => entityReducer(state, 'workOrderDetails', loadReducer(action))),
  on(WorkOrderActions.loadRepairParts, (state, action) => entityReducer(state, 'workOrderRepairParts', loadReducer(action))),
  on(WorkOrderActions.loadNonStockItems, (state, action) => entityReducer(state, 'nonStockItems', loadReducer(action))),
  on(WorkOrderActions.loadDiscounts, (state, action) => entityReducer(state, 'workOrderDiscounts', loadReducer(action))),
  on(WorkOrderActions.loadRebates, (state, action) => entityReducer(state, 'workOrderRebates', loadReducer(action))),
  on(WorkOrderActions.loadPayments, (state, action) => entityReducer(state, 'workOrderPayments', loadReducer(action))),
  on(WorkOrderActions.loadRecommendations, (state, action) => entityReducer(state, 'recommendations', loadReducer(action))),
  on(WorkOrderActions.loadRecommendationPhotos, (state, action) => entityReducer(state, 'recommendationPhotos', loadReducer(action))),
  on(WorkOrderActions.loadDebriefAnswers, (state, action) => entityReducer(state, 'debriefAnswers', loadReducer(action))),
  on(WorkOrderActions.loadTagEntities, (state, action) => entityReducer(state, 'tagEntities', loadReducer(action))),
  on(WorkOrderActions.loadInspectionAnswers, (state, action) => entityReducer(state, 'inspectionAnswers', loadReducer(action))),
  on(WorkOrderActions.loadSiteSystems, (state, action) => entityReducer(state, 'siteSystems', loadReducer(action))),
  on(WorkOrderActions.loadWorkOrderSiteSystems, (state, action) => entityReducer(state, 'workOrderSiteSystems', loadReducer(action))),
  on(WorkOrderActions.loadSalesProposal, (state, action) => entityReducer(state, 'salesProposal', action.data)),
  on(WorkOrderActions.loadSalesProposalPackage, (state, action) => entityReducer(state, 'salesProposalPackage', action.data)),
  on(WorkOrderActions.loadLoanApplications, (state, action) => entityReducer(state, 'workOrderLoanApplications', loadReducer(action))),
  on(WorkOrderActions.loadAgreements, (state, action) => entityReducer(state, 'workOrderAgreements', loadReducer(action))),
  on(WorkOrderActions.loadAgreementSiteSystems, (state, action) => entityReducer(state, 'agreementSiteSystems', loadReducer(action))),
  on(WorkOrderActions.loadTaxItemSections, (state, action) => ({
    ...state,
    taxItemSections: action.taxItemSections.slice(0),
  })),
  on(WorkOrderActions.updateWorkOrder, (state, action) => {
    const previousProcessed = state.entities.workOrder?.processed;
    let updateProcessedDate: { processedDate?: Date } = {};
    if (action.patch.processed !== undefined && state.entities.workOrder && previousProcessed !== action.patch.processed) {
      if (action.patch.processed) {
        updateProcessedDate = { processedDate: new Date() };
      } else {
        updateProcessedDate = { processedDate: null };
      }
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrder: {
          ...state.entities.workOrder,
          ...action.patch,
          updated: true,
          ...updateProcessedDate,
        },
      },
    };
  }),
  on(WorkOrderActions.updateWorkOrderProcessSuccess, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrder: {
        ...state.entities.workOrder,
        processed: action.processed,
        processedDate: new Date(), // Should come from the server :(
      },
    },
  })),
  on(WorkOrderActions.forceCreate, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrder: {
        ...state.entities.workOrder,
        created: true,
      },
    },
  })),
  on(WorkOrderActions.updateCall, (state, action) => {
    // If we do not have a call, we can't update it.
    if (state.entities.call) {
      return entityReducer(state, 'call', {
        ...state.entities.call,
        ...action.patch,
        updated: true,
      });
    }

    return state;
  }),
  on(WorkOrderActions.updateEvent, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        call: {
          ...state.entities.call,
          event: {
            ...state.entities.call.event,
            ...action.patch,
          },
          updated: true,
        },
      },
    };
  }),
  on(WorkOrderActions.updateSalesProposal, (state, action) =>
    entityReducer(state, 'salesProposal', {
      ...state.entities.salesProposal,
      ...action.patch,
      updated: true,
    })
  ),
  on(WorkOrderActions.updateSalesProposalPackage, (state, action) =>
    entityReducer(state, 'salesProposalPackage', {
      ...state.entities.salesProposalPackage,
      ...action.patch,
      updated: true,
    })
  ),

  on(WorkOrderActions.addDetail, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderDetails: {
        ...state.entities.workOrderDetails,
        ...addReducer(action, Object.values(state.entities.workOrderDetails).length),
      },
    },
  })),
  on(WorkOrderActions.addSiteSystem, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      siteSystems: {
        ...state.entities.siteSystems,
        ...addReducer(action, Object.values(state.entities.siteSystems).length, action.data.id !== null),
      },
    },
  })),
  on(WorkOrderActions.addDiscount, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderDiscounts: {
        ...state.entities.workOrderDiscounts,
        ...addReducer(action, Object.values(state.entities.workOrderDiscounts).length),
      },
    },
  })),
  on(WorkOrderActions.addRebate, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderRebates: {
        ...state.entities.workOrderRebates,
        ...addReducer(action, Object.values(state.entities.workOrderRebates).length),
      },
    },
  })),
  on(WorkOrderActions.addPayment, (state, action) => {
    const entitiesSnapshot = { ...state.entitiesSnapshot };
    let workOrderPayment: WorkOrderPaymentState = {};
    if (action.isAlreadySavedOnServer) {
      workOrderPayment[action.data.id] = { ...(action.data as WorkOrderPaymentInformation) };

      // We also add to the copyToSnapshot
      entitiesSnapshot.workOrderPayments = {
        ...entitiesSnapshot.workOrderPayments,
        [action.data.id]: { ...workOrderPayment[action.data.id] },
      };
    } else {
      workOrderPayment = addReducer(action, Object.values(state.entities.workOrderPayments).length) as unknown as WorkOrderPaymentState;
    }

    return {
      ...state,
      entitiesSnapshot,
      entities: {
        ...state.entities,
        workOrderPayments: {
          ...state.entities.workOrderPayments,
          ...workOrderPayment,
        },
      },
    };
  }),
  on(WorkOrderActions.addRecommendation, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      recommendations: {
        ...state.entities.recommendations,
        ...addReducer(action, Object.values(state.entities.recommendations).length),
      },
    },
  })),
  on(WorkOrderActions.addRecommendationPhoto, (state, action) => {
    const newObj = {
      [action.data.id]: { ...action.data, created: true },
    };
    return {
      ...state,
      entities: {
        ...state.entities,
        recommendationPhotos: {
          ...state.entities.recommendationPhotos,
          ...newObj,
        },
      },
    };
  }),
  on(WorkOrderActions.addDebriefAnswer, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      debriefAnswers: {
        ...state.entities.debriefAnswers,
        ...addReducer(action, Object.values(state.entities.debriefAnswers).length),
      },
    },
  })),
  on(WorkOrderActions.setTags, (state, action) => {
    const tags: TagEntityState[string][] = action.tags.map(x => {
      const created = !Object.values(state.entities.tagEntities)
        .map(y => y.tagId)
        .includes(x.tagId);

      return { ...x, created };
    });

    const deletedTags: TagEntityState[string][] = Object.values(state.entities.tagEntities)
      .filter(x => !action.tags.map(y => y.tagId).includes(x.tagId))
      .map(x => setDeleted(x));

    return {
      ...state,
      entities: {
        ...state.entities,
        tagEntities: indexBy([...tags, ...deletedTags], 'id'),
      },
    };
  }),
  on(WorkOrderActions.updateClassMappings, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderDetails: {
          ...state.entities.workOrderDetails,
          ...indexBy(
            Object.values(state.entities.workOrderDetails)
              .filter(x => (x.created || !x.callDepartmentTypeId) && !x.hidden && !x.deleted)
              .map(x => ({ ...x, callDepartmentTypeId: action.callDepartmentTypeId })),
            'id'
          ),
        },
        workOrderAgreements: {
          ...state.entities.workOrderAgreements,
          ...indexBy(
            Object.values(state.entities.workOrderAgreements)
              .filter(x => (x.created || !x.callDepartmentTypeId) && !x.hidden && !x.deleted)
              .map(x => ({ ...x, callDepartmentTypeId: action.callDepartmentTypeId })),
            'id'
          ),
        },
        workOrderDiscounts: {
          ...state.entities.workOrderDiscounts,
          ...indexBy(
            Object.values(state.entities.workOrderDiscounts)
              .filter(x => (x.created || !x.callDepartmentTypeId) && !x.hidden && !x.deleted)
              .map(x => ({ ...x, callDepartmentTypeId: action.callDepartmentTypeId })),
            'id'
          ),
        },
        workOrderRebates: {
          ...state.entities.workOrderRebates,
          ...indexBy(
            Object.values(state.entities.workOrderRebates)
              .filter(x => (x.created || !x.callDepartmentTypeId) && !x.hidden && !x.deleted)
              .map(x => ({ ...x, callDepartmentTypeId: action.callDepartmentTypeId })),
            'id'
          ),
        },
      },
    };
  }),
  on(WorkOrderActions.addRepairPart, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderRepairParts: {
        ...state.entities.workOrderRepairParts,
        ...addReducer(action, Object.values(state.entities.workOrderRepairParts).length),
      },
    },
  })),
  on(WorkOrderActions.addNonStockItem, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      nonStockItems: {
        ...state.entities.nonStockItems,
        ...addReducer(action, Object.values(state.entities.nonStockItems).length),
      },
    },
  })),
  on(WorkOrderActions.addLoanApplication, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderLoanApplications: {
        ...state.entities.workOrderLoanApplications,
        ...addReducer(action, Object.values(state.entities.workOrderLoanApplications).length),
      },
    },
  })),
  on(WorkOrderActions.addAgreement, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderAgreements: {
          ...state.entities.workOrderAgreements,
          [action.id]: {
            ...action,
            created: true,
            updated: false,
          },
        },
      },
    };
  }),
  on(WorkOrderActions.updateDetail, (state, action) => {
    const nonStockItemId = state.entities.workOrderDetails[action.id]?.nonStockItemId;

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderDetails: {
          ...state.entities.workOrderDetails,
          ...updateReducer(state.entities.workOrderDetails, action),
        },
        nonStockItems: {
          ...state.entities.nonStockItems,
          ...indexBy(
            Object.values(state.entities.nonStockItems)
              .filter(x => x.id < 0 && x.id === nonStockItemId && action.patch.nonStockItemId === null)
              .map(x => setDeleted(x)),
            'id'
          ),
        },
      },
    };
  }),
  on(WorkOrderActions.updateNonStockItem, (state, action) =>
    entityReducer(state, 'nonStockItems', {
      ...state.entities.nonStockItems,
      ...updateReducer(state.entities.nonStockItems, action),
    })
  ),
  on(WorkOrderActions.updateDiscount, (state, action) =>
    entityReducer(state, 'workOrderDiscounts', {
      ...state.entities.workOrderDiscounts,
      ...updateReducer(state.entities.workOrderDiscounts, action),
    })
  ),
  on(WorkOrderActions.updateRebate, (state, action) =>
    entityReducer(state, 'workOrderRebates', {
      ...state.entities.workOrderRebates,
      ...updateReducer(state.entities.workOrderRebates, action),
    })
  ),
  on(WorkOrderActions.updatePayment, (state, action) =>
    entityReducer(state, 'workOrderPayments', {
      ...state.entities.workOrderPayments,
      ...updateReducer(state.entities.workOrderPayments, action),
    })
  ),
  on(WorkOrderActions.updateRecommendation, (state, action) =>
    entityReducer(state, 'recommendations', {
      ...state.entities.recommendations,
      ...updateReducer(state.entities.recommendations, action),
    })
  ),
  on(WorkOrderActions.updateRecommendationPhoto, (state, action) =>
    entityReducer(state, 'recommendationPhotos', {
      ...state.entities.recommendationPhotos,
      ...updateReducer(state.entities.recommendationPhotos, action),
    })
  ),
  on(WorkOrderActions.updateDebriefAnswer, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        debriefAnswers: {
          ...state.entities.debriefAnswers,
          [action.id]: {
            ...state.entities.debriefAnswers[action.id],
            ...action.patch,
            updated: true,
            deleted: false,
          },
        },
      },
    };
  }),
  on(WorkOrderActions.updateRepairPart, (state, action) =>
    entityReducer(state, 'workOrderRepairParts', {
      ...state.entities.workOrderRepairParts,
      ...updateReducer(state.entities.workOrderRepairParts, action),
    })
  ),
  on(WorkOrderActions.updateLoanApplication, (state, action) =>
    entityReducer(state, 'workOrderLoanApplications', {
      ...state.entities.workOrderLoanApplications,
      ...updateReducer(state.entities.workOrderLoanApplications, action),
    })
  ),
  on(WorkOrderActions.updateSiteSystem, (state, action) =>
    entityReducer(state, 'siteSystems', {
      ...state.entities.siteSystems,
      ...updateReducer(state.entities.siteSystems, action),
    })
  ),
  on(WorkOrderActions.updateAgreement, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderAgreements: {
        ...state.entities.workOrderAgreements,
        ...updateReducer(state.entities.workOrderAgreements, action),
      },
    },
  })),

  on(WorkOrderActions.deleteDetail, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderDetails: {
        ...state.entities.workOrderDetails,
        ...deleteReducer(state.entities.workOrderDetails, action),
      },
      workOrderRepairParts: {
        ...state.entities.workOrderRepairParts,
        ...indexBy(
          Object.values(state.entities.workOrderRepairParts)
            .filter(x => x.parentId === action.id)
            .map(x => setDeleted(x)),
          'id'
        ),
      },
      nonStockItems: {
        ...state.entities.nonStockItems,
        ...indexBy(
          Object.values(state.entities.nonStockItems)
            .filter(x => x.id < 0 && x.id === state.entities.workOrderDetails[action.id]?.nonStockItemId)
            .map(x => setDeleted(x)),
          'id'
        ),
      },
    },
  })),
  on(WorkOrderActions.deleteDiscount, (state, action) =>
    entityReducer(state, 'workOrderDiscounts', {
      ...state.entities.workOrderDiscounts,
      ...deleteReducer(state.entities.workOrderDiscounts, action),
    })
  ),
  on(WorkOrderActions.deleteRebate, (state, action) =>
    entityReducer(state, 'workOrderRebates', {
      ...state.entities.workOrderRebates,
      ...deleteReducer(state.entities.workOrderRebates, action),
    })
  ),
  on(WorkOrderActions.deletePayment, (state, action) =>
    entityReducer(state, 'workOrderPayments', {
      ...state.entities.workOrderPayments,
      ...deleteReducer(state.entities.workOrderPayments, action),
    })
  ),
  on(WorkOrderActions.deleteRecommendation, (state, action) =>
    entityReducer(state, 'recommendations', {
      ...state.entities.recommendations,
      ...deleteReducer(state.entities.recommendations, action),
    })
  ),
  on(WorkOrderActions.deleteRecommendationPhoto, (state, action) =>
    entityReducer(state, 'recommendationPhotos', {
      ...state.entities.recommendationPhotos,
      ...deleteReducer(state.entities.recommendationPhotos, action),
    })
  ),
  on(WorkOrderActions.deleteDebriefAnswer, (state, action) =>
    entityReducer(state, 'debriefAnswers', {
      ...state.entities.debriefAnswers,
      ...deleteReducer(state.entities.debriefAnswers, action),
    })
  ),
  on(WorkOrderActions.deleteRepairPart, (state, action) =>
    entityReducer(state, 'workOrderRepairParts', {
      ...state.entities.workOrderRepairParts,
      ...deleteReducer(state.entities.workOrderRepairParts, action),
    })
  ),
  on(WorkOrderActions.deleteLoanApplication, (state, action) =>
    entityReducer(state, 'workOrderLoanApplications', {
      ...state.entities.workOrderLoanApplications,
      ...deleteReducer(state.entities.workOrderLoanApplications, action),
    })
  ),
  on(WorkOrderActions.removeSiteSystem, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      siteSystems: {
        ...indexBy(
          Object.values(state.entities.siteSystems).filter(x => x.id !== action.id),
          'id'
        ),
      },
      workOrderDetails: {
        ...state.entities.workOrderDetails,
        ...indexBy(
          Object.values(state.entities.workOrderDetails)
            .filter(x => x.siteSystemId === action.id)
            .map(x => setDeleted(x)),
          'id'
        ),
      },
      workOrderRepairParts: {
        ...state.entities.workOrderRepairParts,
        ...indexBy(
          Object.values(state.entities.workOrderRepairParts)
            .filter(x =>
              Object.values(state.entities.workOrderDetails)
                .filter(y => y.siteSystemId === action.id)
                .map(y => y.id)
                .includes(x.parentId)
            )
            .map(x => setDeleted(x)),
          'id'
        ),
      },
      workOrderAgreements: {
        ...state.entities.workOrderAgreements,
        // Get WOA with siteSystemId (not purchased)
        ...indexBy(
          Object.values(state.entities.workOrderAgreements)
            .filter(x => x.siteSystemId !== null && x.siteSystemId === action.id && !x.hidden)
            .map(x => setDeleted(x)),
          'id'
        ),
        // Get WOA without siteSystemId (previously purchased)
        ...indexBy(
          Object.values(state.entities.agreementSiteSystems)
            .filter(x => x.siteSystemId !== null && x.siteSystemId === action.id && !x.hidden)
            .flatMap(x => Object.values(state.entities.workOrderAgreements).filter(y => y.agreementSiteSystemId === x.id && !y.hidden))
            .filter(x => !!x && x.siteSystemId === null)
            .map(x => setDeleted(x)),
          'id'
        ),
      },
    },
  })),

  on(WorkOrderActions.deleteAgreement, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrderAgreements: {
        ...state.entities.workOrderAgreements,
        ...deleteReducer(state.entities.workOrderAgreements, {
          id: action.id,
        }),
      },
    },
  })),
  on(WorkOrderActions.updateTax, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      internalTaxItemEntry: { ...action.taxItemEntry, updated: true },
    },
  })),
  on(WorkOrderActions.editMode, state => ({
    ...state,
    entitiesSnapshot: clone(state.entities),
    edit: true,
  })),
  on(WorkOrderActions.cancel, state => ({
    ...state,
    entities: state.entitiesSnapshot,
    entitiesSnapshot: null,
    edit: false,
  })),
  on(WorkOrderActions.reset, state => initialState()),
  on(WorkOrderActions.saveWorkOrder, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      workOrder: {
        ...state.entities.workOrder,
        customerId: action.customerId,
        siteId: action.siteId,
      },
      siteSystems: indexBy(
        Object.values(state.entities.siteSystems).map(x => {
          if (!isMiscellaneousSiteSystem(x) && action.siteId !== null) {
            return { ...x, siteId: action.siteId };
          }

          return x;
        }),
        'id'
      ),
    },
  })),
  on(WorkOrderActions.saveWorkOrderSuccess, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrder: {
          ...state.entities.workOrder,
          id: action.resource.id,
          updated: false,
          created: false,
        },
        internalTaxItemEntry: {
          ...state.entities.internalTaxItemEntry,
          updated: false,
        },
      },
    };
  }),
  on(WorkOrderActions.saveCallSuccess, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        call: state.entities.call ? { ...state.entities.call, updated: false } : null,
      },
    };
  }),
  on(WorkOrderActions.saveSiteSystemSuccess, (state, action) => {
    const newSiteSystems = { ...state.entities.siteSystems };

    if (isMiscellaneousSiteSystem(newSiteSystems[action.oldId])) {
      newSiteSystems[action.newId] = {
        ...newSiteSystems[action.oldId],
        updated: false,
        created: false,
      };
    } else {
      newSiteSystems[action.newId] = {
        ...(newSiteSystems[action.oldId] as SiteSystemInformation),
        id: action.newId,
        updated: false,
        created: false,
      };
    }

    if (action.oldId !== action.newId) {
      delete newSiteSystems[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        siteSystems: newSiteSystems,
        workOrderDetails: {
          ...state.entities.workOrderDetails,
          ...indexBy(
            Object.values(state.entities.workOrderDetails)
              .filter(x => x.siteSystemId === action.oldId)
              .map(x => ({ ...x, siteSystemId: action.newId })),
            'id'
          ),
        },
        workOrderAgreements: {
          ...state.entities.workOrderAgreements,
          ...indexBy(
            Object.values(state.entities.workOrderAgreements)
              .filter(x => x.siteSystemId === action.oldId)
              .map(x => ({ ...x, siteSystemId: action.newId })),
            'id'
          ),
        },
      },
    };
  }),
  on(WorkOrderActions.saveSalesProposalSuccess, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposal: state.entities.salesProposal ? { ...state.entities.salesProposal, updated: false } : null,
      },
    };
  }),
  on(WorkOrderActions.saveSalesProposalPackageSuccess, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        salesProposalPackage: state.entities.salesProposalPackage ? { ...state.entities.salesProposalPackage, updated: false } : null,
      },
    };
  }),
  on(WorkOrderActions.saveDiscountSaveSuccess, (state, action) => {
    const newWorkOrderDiscounts = { ...state.entities.workOrderDiscounts };
    newWorkOrderDiscounts[action.newId] = {
      ...newWorkOrderDiscounts[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newWorkOrderDiscounts[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderDiscounts: newWorkOrderDiscounts,
      },
    };
  }),
  on(WorkOrderActions.saveDiscountDeleteSuccess, (state, action) => {
    const newWorkOrderDiscounts = { ...state.entities.workOrderDiscounts };
    delete newWorkOrderDiscounts[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderDiscounts: newWorkOrderDiscounts,
      },
    };
  }),
  on(WorkOrderActions.saveRebateSaveSuccess, (state, action) => {
    const newWorkOrderRebates = { ...state.entities.workOrderRebates };
    newWorkOrderRebates[action.newId] = {
      ...newWorkOrderRebates[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newWorkOrderRebates[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderRebates: newWorkOrderRebates,
      },
    };
  }),
  on(WorkOrderActions.saveRebateDeleteSuccess, (state, action) => {
    const newWorkOrderRebates = { ...state.entities.workOrderRebates };
    delete newWorkOrderRebates[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderRebates: newWorkOrderRebates,
      },
    };
  }),
  on(WorkOrderActions.savePaymentSaveSuccess, (state, action) => {
    const newWorkOrderPayments = { ...state.entities.workOrderPayments };
    newWorkOrderPayments[action.newId] = {
      ...newWorkOrderPayments[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newWorkOrderPayments[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderPayments: newWorkOrderPayments,
      },
    };
  }),
  on(WorkOrderActions.savePaymentDeleteSuccess, (state, action) => {
    const newWorkOrderPayments = { ...state.entities.workOrderPayments };
    delete newWorkOrderPayments[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderPayments: newWorkOrderPayments,
      },
    };
  }),
  on(WorkOrderActions.saveRecommendationSaveSuccess, (state, action) => {
    const newRecommendations = { ...state.entities.recommendations };
    newRecommendations[action.newId] = {
      ...newRecommendations[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newRecommendations[action.oldId];
    }

    // We check the recommendation photos.
    const recommendationPhotos = Object.values(state.entities.recommendationPhotos).map(recommendationPhoto => {
      let recommendationId = recommendationPhoto.recommendationId;
      if (recommendationId === action.oldId) {
        recommendationId = action.newId;
      }

      return {
        ...recommendationPhoto,
        recommendationId,
      };
    });

    return {
      ...state,
      entities: {
        ...state.entities,
        recommendations: newRecommendations,
        recommendationPhotos: indexBy(recommendationPhotos, 'id'),
      },
    };
  }),
  on(WorkOrderActions.saveRecommendationDeleteSuccess, (state, action) => {
    const newRecommendations = { ...state.entities.recommendations };
    delete newRecommendations[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        recommendations: newRecommendations,
      },
    };
  }),
  on(WorkOrderActions.saveRecommendationPhotoSaveSuccess, (state, action) => {
    const newRecommendationPhotos = { ...state.entities.recommendationPhotos };
    newRecommendationPhotos[action.newId] = {
      ...newRecommendationPhotos[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newRecommendationPhotos[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        recommendationPhotos: newRecommendationPhotos,
      },
    };
  }),
  on(WorkOrderActions.saveRecommendationPhotoFail, (state, action) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        recommendationPhotos: {
          ...state.entities.recommendationPhotos,
          [action.id]: {
            ...state.entities.recommendationPhotos[action.id],
            error: action.error,
          },
        },
      },
    };
  }),
  on(WorkOrderActions.saveRecommendationPhotoDeleteSuccess, (state, action) => {
    const newRecommendationPhotos = { ...state.entities.recommendationPhotos };
    delete newRecommendationPhotos[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        recommendationPhotos: newRecommendationPhotos,
      },
    };
  }),
  on(WorkOrderActions.saveDebriefAnswerSaveSuccess, (state, action) => {
    const newDebriefAnswers = { ...state.entities.debriefAnswers };
    newDebriefAnswers[action.newId] = {
      ...newDebriefAnswers[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newDebriefAnswers[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        debriefAnswers: newDebriefAnswers,
      },
    };
  }),
  on(WorkOrderActions.saveDebriefAnswerDeleteSuccess, (state, action) => {
    const newDebriefAnswers = { ...state.entities.debriefAnswers };
    delete newDebriefAnswers[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        debriefAnswers: newDebriefAnswers,
      },
    };
  }),
  on(WorkOrderActions.saveTagEntitySaveSuccess, (state, action) => {
    const newTagEntities = { ...state.entities.tagEntities };
    newTagEntities[action.newId] = {
      ...newTagEntities[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newTagEntities[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        tagEntities: newTagEntities,
      },
    };
  }),
  on(WorkOrderActions.saveTagEntityDeleteSuccess, (state, action) => {
    const newTagEntities = { ...state.entities.tagEntities };
    delete newTagEntities[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        tagEntities: newTagEntities,
      },
    };
  }),
  on(WorkOrderActions.saveRepairPartSaveSuccess, (state, action) => {
    const newWorkOrderRepairParts = { ...state.entities.workOrderRepairParts };
    newWorkOrderRepairParts[action.newId] = {
      ...newWorkOrderRepairParts[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newWorkOrderRepairParts[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderRepairParts: newWorkOrderRepairParts,
      },
    };
  }),
  on(WorkOrderActions.saveRepairPartDeleteSuccess, (state, action) => {
    const newWorkOrderRepairParts = { ...state.entities.workOrderRepairParts };
    delete newWorkOrderRepairParts[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderRepairParts: newWorkOrderRepairParts,
      },
    };
  }),
  on(WorkOrderActions.saveDetailSaveSuccess, (state, action) => {
    const newWorkOrderDetails = { ...state.entities.workOrderDetails };
    newWorkOrderDetails[action.workOrderDetail.id] = {
      ...action.workOrderDetail,
      created: false,
      updated: false,
    };

    if (action.oldId !== action.workOrderDetail.id) {
      delete newWorkOrderDetails[action.oldId];
    }

    const nonStockItem = Object.values(state.entities.nonStockItems).find(x => x.id === action.oldNonStockItemId);
    const newNonStockItems = { ...state.entities.nonStockItems };

    if (nonStockItem && action.workOrderDetail.nonStockItemId) {
      newNonStockItems[action.workOrderDetail.nonStockItemId] = {
        ...nonStockItem,
        id: action.workOrderDetail.nonStockItemId,
        created: false,
        updated: false,
      };

      delete newNonStockItems[action.oldNonStockItemId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderDetails: newWorkOrderDetails,
        workOrderRepairParts: {
          ...state.entities.workOrderRepairParts,
          ...indexBy(
            Object.values(state.entities.workOrderRepairParts)
              .filter(repairPart => repairPart.parentId === action.oldId)
              .map(repairPart => ({
                ...repairPart,
                parentId: action.workOrderDetail.id,
              })),
            'id'
          ),
        },
        nonStockItems: newNonStockItems,
      },
    };
  }),
  on(WorkOrderActions.saveDetailDeleteSuccess, (state, action) => {
    const workOrderDetail = Object.values(state.entities.workOrderDetails).find(x => x.id === action.resource.id);

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderDetails: indexBy(
          Object.values(state.entities.workOrderDetails).filter(x => x.id !== action.resource.id),
          'id'
        ),
        workOrderRepairParts: indexBy(
          Object.values(state.entities.workOrderRepairParts).filter(x => x.parentId !== action.resource.id),
          'id'
        ),
        nonStockItems: indexBy(
          Object.values(state.entities.nonStockItems).filter(x => x.id !== workOrderDetail.nonStockItemId),
          'id'
        ),
      },
    };
  }),
  on(WorkOrderActions.saveLoanApplicationSaveSuccess, (state, action) => {
    const newWorkOrderLoanApplications = { ...state.entities.workOrderLoanApplications };
    newWorkOrderLoanApplications[action.newId] = {
      ...newWorkOrderLoanApplications[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newWorkOrderLoanApplications[action.oldId];
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderLoanApplications: newWorkOrderLoanApplications,
      },
    };
  }),
  on(WorkOrderActions.saveLoanApplicationDeleteSuccess, (state, action) => {
    const newWorkOrderLoanApplications = { ...state.entities.workOrderLoanApplications };
    delete newWorkOrderLoanApplications[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderLoanApplications: newWorkOrderLoanApplications,
      },
    };
  }),
  on(WorkOrderActions.saveWorkOrderAgreementSaveSuccess, (state, action) => {
    const newWorkOrderAgreements = { ...state.entities.workOrderAgreements };
    newWorkOrderAgreements[action.newId] = {
      ...newWorkOrderAgreements[action.oldId],
      id: action.newId,
      created: false,
      updated: false,
    };
    if (action.oldId !== action.newId) {
      delete newWorkOrderAgreements[action.oldId];
    }

    const obj: AgreementSiteSystemState = {};
    if (action.agreementSiteSystem) {
      obj[action.agreementSiteSystem.id] = action.agreementSiteSystem;
      newWorkOrderAgreements[action.newId].agreementId = null;
      newWorkOrderAgreements[action.newId].siteSystemId = null;
      newWorkOrderAgreements[action.newId].agreementSiteSystemId = action.agreementSiteSystem.id;
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderAgreements: newWorkOrderAgreements,
        agreementSiteSystems: {
          ...state.entities.agreementSiteSystems,
          ...obj,
        },
      },
    };
  }),
  on(WorkOrderActions.saveWorkOrderAgreementDeleteSuccess, (state, action) => {
    const newWorkOrderAgreements = { ...state.entities.workOrderAgreements };
    delete newWorkOrderAgreements[action.resource.id];
    return {
      ...state,
      entities: {
        ...state.entities,
        workOrderAgreements: newWorkOrderAgreements,
      },
    };
  })
);

export function reducer(state: WorkOrderState | undefined, action: Action): WorkOrderState {
  return workOrderReducer(state, action);
}
