import { InjectionToken } from '@angular/core';
import { type CustomerInformation } from '@models/cards/customer-information';
import { type SiteInformation } from '@models/cards/site-information';
import { type WorkOrderInformation } from '@models/cards/work-order-information';
import { type LoanCompany, type LoanCreate, type LoanSelection, type WorkOrderLoanApplicationInformation } from '@models/loans';
import { type Observable } from 'rxjs';

export interface LoanContext {
  workOrderInformation: WorkOrderInformation;
  customerInformation: CustomerInformation;
  siteInformation: SiteInformation;
}

export interface LoanHandler {
  loanCompany: LoanCompany;
  handle: (loanSelection: LoanSelection, loanCreate: LoanCreate, context: LoanContext) => Observable<LoanCreate>;
  canStartLoan: () => boolean;
  startLoan: (workOrderLoanApplication: WorkOrderLoanApplicationInformation) => void;
  getLoanLink: (application: WorkOrderLoanApplicationInformation) => { url: string; text: string } | null;
  getCompanyName: () => string;
  canModifyReferenceNumber: () => boolean;
}

export const LOAN_HANDLER = new InjectionToken<LoanHandler[]>('LOAN_HANDLER');
