import { Component, effect, forwardRef, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { RwText2Component } from '@controls/rw-text/rw-text2.component';
import { AfterViewInitDirective } from '@directives/after-view-init.directive';
import { Mode } from '@models/form';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import { combineLatest, concatMap, debounceTime, filter } from 'rxjs';

@Component({
  template:
    '<wm-rw-text2 #t="rwText" (afterViewInit)="t.focus()" [name]="label()" [formControl]="control" [mode]="Mode.Write" placeholder="Start typing to filter" />',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDynamicTextComponent),
      multi: true,
    },
  ],
  imports: [ReactiveFormsModule, RwText2Component, AfterViewInitDirective],
  standalone: true,
})
export class FilterDynamicTextComponent extends ElementBaseWithFormControl<string> {
  readonly Mode = Mode;

  private readonly dynamicFilterService = inject(DynamicFilterService2);

  readonly filterId = input<string>('');
  readonly label = input<string>('');
  readonly code = input<string>('');
  readonly name = input<string>('');

  // Typing fast would trigger too many updates.
  // We delay it by 400 ms
  private readonly valueChanges = toSignal(this.control.valueChanges.pipe(debounceTime(400)));
  private readonly serviceChanges = toSignal(
    combineLatest([toObservable(this.code), toObservable(this.name)]).pipe(
      filter(([code, name]) => !!code && !!name),
      concatMap(([code, name]) => this.dynamicFilterService.changes<string>(this.filterId(), code, name))
    ),
    { initialValue: '' }
  );

  _ = effect(() => {
    this.dynamicFilterService.patch(this.filterId(), this.code(), this.name(), this.valueChanges());
  });

  __ = effect(
    () => {
      this.control.patchValue(this.serviceChanges() ?? '');
    },
    { allowSignalWrites: true }
  );

  constructor() {
    super(() => '');
  }
}
