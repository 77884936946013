import { PercentPipe } from '@angular/common';
import { Injectable, LOCALE_ID, Pipe, inject, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
  standalone: true,
})
@Injectable()
export class PercentagePipe implements PipeTransform {
  private readonly _locale = inject(LOCALE_ID);

  private readonly percentPipe: PercentPipe;

  constructor() {
    this.percentPipe = new PercentPipe(this._locale);
  }

  // Need to keep string|boolean to satisfy the PercentPipe
  transform(percentage: Percentage, hideSymbol?: string | boolean, showDecimal?: string | boolean): string {
    let digits: string | undefined;
    if (showDecimal === true) {
      digits = '1.2-2';
    }

    if (hideSymbol) {
      const final = this.percentPipe.transform(percentage, digits);
      return final?.substring(0, final.length - 1) ?? '';
    }

    return this.percentPipe.transform(percentage, digits) ?? '';
  }
}
