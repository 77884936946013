// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  display: inline-block;
}

@keyframes wm-checkbox-fade-in-background {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    background-color: #246ee9;
  }
}
@keyframes wm-checkbox-fade-out-background {
  0%, 50% {
    opacity: 1;
    background-color: #246ee9;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wm-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 22.910259;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 0.1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes wm-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes wm-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -22.910259;
  }
}
@keyframes wm-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 0.1);
    opacity: 1;
    transform: rotate(0deg);
  }
  to {
    opacity: 0;
    transform: rotate(45deg);
  }
}
@keyframes wm-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    opacity: 0;
    transform: rotate(45deg);
  }
  to {
    opacity: 1;
    transform: rotate(360deg);
  }
}
@keyframes wm-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 0.1);
    opacity: 0;
    transform: rotate(-45deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes wm-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    opacity: 1;
    transform: rotate(0deg);
  }
  to {
    opacity: 0;
    transform: rotate(315deg);
  }
}
@keyframes wm-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    opacity: 1;
    transform: scaleX(1);
  }
  32.8%, 100% {
    opacity: 0;
    transform: scaleX(0);
  }
}
.wm-checkbox-checkmark, .wm-checkbox-background, .wm-checkbox-frame {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.wm-checkbox-mixedmark, .wm-checkbox-checkmark {
  width: calc(100% - 4px);
}

.wm-checkbox-background, .wm-checkbox-frame {
  border-radius: 4px;
  box-sizing: border-box;
  pointer-events: none;
}

.wm-checkbox {
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif;
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), wm-elevation-transition-property-value();
}

.wm-checkbox-layout {
  cursor: inherit;
  align-items: baseline;
  vertical-align: middle;
  display: inline-flex;
}

.wm-checkbox-inner-container {
  display: inline-block;
  height: 16px;
  line-height: 0;
  margin: auto;
  margin-right: 4px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 16px;
  flex-shrink: 0;
}
[dir=rtl] .wm-checkbox-inner-container {
  margin-left: 4px;
  margin-right: auto;
}

.wm-checkbox-frame {
  background-color: #ffffff;
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
  border-width: 0;
  border-style: solid;
}

.wm-checkbox-background {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  transition: background-color 90ms cubic-bezier(0, 0, 0.2, 0.1), opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
  border: 1px solid #d1d5d8;
}

.wm-checkbox-checkmark {
  width: 100%;
}

.wm-checkbox-checkmark-path {
  stroke-dashoffset: 22.910259;
  stroke-dasharray: 22.910259;
  stroke-width: 2.1333333333px;
}

.wm-checkbox-mixedmark {
  height: 2px;
  opacity: 0;
  transform: scaleX(0) rotate(0deg);
}

.wm-checkbox-inner-container {
  margin-top: 2px;
}

.wm-checkbox-label-before .wm-checkbox-inner-container {
  order: 1;
  margin-left: 4px;
  margin-right: auto;
}
[dir=rtl] .wm-checkbox-label-before .wm-checkbox-inner-container {
  margin-left: auto;
  margin-right: 4px;
}

.wm-checkbox-checked .wm-checkbox-background {
  background-color: #246ee9;
}
.wm-checkbox-checked .wm-checkbox-checkmark {
  opacity: 1;
}
.wm-checkbox-checked .wm-checkbox-checkmark-path {
  stroke-dashoffset: 0;
}
.wm-checkbox-checked .wm-checkbox-mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.wm-checkbox-indeterminate .wm-checkbox-checkmark {
  opacity: 0;
  transform: rotate(45deg);
}
.wm-checkbox-indeterminate .wm-checkbox-checkmark-path {
  stroke-dashoffset: 0;
}
.wm-checkbox-indeterminate .wm-checkbox-mixedmark {
  opacity: 1;
  transform: scaleX(1) rotate(0deg);
}

.wm-checkbox-unchecked .wm-checkbox-background {
  background-color: transparent;
}

.wm-checkbox-disabled {
  cursor: default;
  opacity: 0.25;
}

.wm-checkbox-anim-unchecked-checked .wm-checkbox-background {
  animation: 180ms linear 0ms wm-checkbox-fade-in-background;
  animation-fill-mode: forwards;
}
.wm-checkbox-anim-unchecked-checked .wm-checkbox-checkmark-path {
  animation: 180ms linear 0ms wm-checkbox-unchecked-checked-checkmark-path;
}
.wm-checkbox-anim-unchecked-indeterminate .wm-checkbox-background {
  animation: 180ms linear 0ms wm-checkbox-fade-in-background;
  animation-fill-mode: forwards;
}
.wm-checkbox-anim-unchecked-indeterminate .wm-checkbox-mixedmark {
  animation: 90ms linear 0ms wm-checkbox-unchecked-indeterminate-mixedmark;
}
.wm-checkbox-anim-checked-unchecked .wm-checkbox-background {
  animation: 180ms linear 0ms wm-checkbox-fade-out-background;
}
.wm-checkbox-anim-checked-unchecked .wm-checkbox-checkmark-path {
  animation: 90ms linear 0ms wm-checkbox-checked-unchecked-checkmark-path;
}
.wm-checkbox-anim-checked-indeterminate .wm-checkbox-checkmark {
  animation: 90ms linear 0ms wm-checkbox-checked-indeterminate-checkmark;
}
.wm-checkbox-anim-checked-indeterminate .wm-checkbox-mixedmark {
  animation: 90ms linear 0ms wm-checkbox-checked-indeterminate-mixedmark;
}
.wm-checkbox-anim-indeterminate-checked .wm-checkbox-checkmark {
  animation: 500ms linear 0ms wm-checkbox-indeterminate-checked-checkmark;
}
.wm-checkbox-anim-indeterminate-checked .wm-checkbox-mixedmark {
  animation: 500ms linear 0ms wm-checkbox-indeterminate-checked-mixedmark;
}
.wm-checkbox-anim-indeterminate-unchecked .wm-checkbox-background {
  animation: 180ms linear 0ms wm-checkbox-fade-out-background;
}
.wm-checkbox-anim-indeterminate-unchecked .wm-checkbox-mixedmark {
  animation: 300ms linear 0ms wm-checkbox-indeterminate-unchecked-mixedmark;
}

.wm-checkbox-input {
  bottom: 0;
  left: 50%;
}

.wm-checkbox-ripple {
  position: absolute;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
  border-radius: 50%;
  z-index: 1;
  pointer-events: none;
}

.wm-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px;
}

.check-only {
  width: 16px;
}
.check-only .tn-checkbox-inner-container {
  margin-right: 0;
}
[dir=rtl] .check-only .tn-checkbox-inner-container {
  margin-left: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
