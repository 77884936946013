import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppInsightsService } from '@services/app-insights.service';
import { allowRetries } from '@utility/angular';
import { isCypress } from '@utility/cypress';
import { isOldBrowser } from '@utility/user-agent';
import { Subject } from 'rxjs';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { UrlService } from './url.service';

export const networkErrors = [0, 499];

@Injectable()
export class ErrorLoggingService {
  private readonly http = inject(HttpClient);
  private readonly url = inject(UrlService);
  private readonly appinsightsService = inject(AppInsightsService);

  private readonly errorSubject = new Subject<any>();

  constructor() {
    this.errorSubject
      .pipe(throttleTime(5000))
      .pipe(distinctUntilChanged()) // This will not really work as the object will be different. Lacking time to make it better.
      .subscribe(error => {
        this.sendToServer(error);

        // Software-as-a-Service (SaaS) tracking.
        this.sendToAzure(error);
      });
  }

  public logError(error: any): void {
    if (this.skipReport(error)) {
      console.log('Skipping sending exception.');
      return;
    }

    // Internal tracking.
    this.sendToConsole(error);

    this.errorSubject.next(error);
  }

  private sendToAzure(error: any): void {
    let finalError = error;
    if (typeof error === 'object' && !(error instanceof Error)) {
      finalError = new Error(JSON.stringify(error));
    }

    this.appinsightsService.trackException(finalError);
  }

  private skipReport(error: any): boolean {
    if (isOldBrowser()) {
      return true;
    }

    // We don't want to send an exception if we were offline.
    if (error instanceof Response) {
      if (error.url === null && networkErrors.includes(error.status)) {
        return true;
      }

      // These errors are mostly because the user went offline in the middle of the request
      if (networkErrors.includes(error.status)) {
        return true;
      }
    }

    // Skip azure if request was unauthorized
    if (error instanceof HttpErrorResponse) {
      if (error.status === 404) {
        return true;
      }

      if (error.status === 401) {
        return true;
      }

      // These errors are mostly because the user went offline in the middle of the request
      if (networkErrors.includes(error.status)) {
        return true;
      }
    }

    if (error?.message) {
      // - contains
      // ~ starts with
      // otherwise ===
      const exceptionMessages = [
        // Chunk loading should not be reported. You have been told you should refresh the page.
        '-Error: Loading chunk ',
        // Websocket errors, we don't track.
        'Invocation canceled due to connection being closed.',
        'Server timeout elapsed without receiving a message from the server.',
        'WebSocket is not in the OPEN state',
        '~WebSocket closed with status code: ',
        '-Error parsing handshake response',
        // Chrome 49 reports this error constantly.
        "~NotSupportedError: Failed to execute 'animate'",
        "~An unexpected error occurred invoking '",
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        'ResizeObserver loop limit exceeded',
      ];
      for (const message of exceptionMessages) {
        if (message.startsWith('~')) {
          if (error.message.startsWith(message.substr(1))) {
            return true;
          }
        } else if (message.startsWith('-')) {
          if (error.message.indexOf(message.substr(1)) >= 0) {
            return true;
          }
        } else if (message === error.message) {
          return true;
        }
      }
    }

    return false;
  }

  private sendToConsole(error: any): void {
    if (console && console.group && console.error) {
      console.group('Error Log Service');
      console.error(error);
      if (error) {
        console.error(error.message);
        console.error(error.stack);
      }
      console.groupEnd();
    }
  }

  private sendToServer(error: any): void {
    if (isCypress()) {
      return;
    }
    if (error && networkErrors.includes(error.status)) {
      return;
    }

    this.http
      .post(
        this.url.errorLog,
        {
          type: error.name,
          message: error.message,
          stack: error.stack,
          location: window.location.href,
        },
        allowRetries()
      )
      // Catch to avoid throwing an uncaught error
      .subscribe({ error: () => {} });
  }
}
