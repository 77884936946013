import { Injectable, inject } from '@angular/core';
import { type SmsMessageInformation } from '@models/cards/sms-message';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class SmsMessagesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  list(): Observable<SmsMessageInformation[]> {
    return this.http.get<SmsMessageInformation[]>(this.url.smsMessageList);
  }
}
