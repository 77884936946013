import { Injectable, inject } from '@angular/core';
import { type Mutable } from '@models/mutable';
import { type Resource } from '@models/resource';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select, type Action, type ActionCreator, type Creator } from '@ngrx/store';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { PartItemsService } from '@services/live/part-items.service';
import { PriceBooksService } from '@services/live/price-books.service';
import { StaticDataService } from '@services/static-data.service';
import { from, of, type Observable } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as ServiceRepairDetailActions from './service-repair-detail.actions';
import { type ServiceRepairDetailState } from './service-repair-detail.reducer';
import * as ServiceRepairDetailSelectors from './service-repair-detail.selectors';

@Injectable()
export class ServiceRepairDetailEffects {
  private readonly store = inject(Store<ServiceRepairDetailState>);
  private readonly actions$ = inject(Actions<ServiceRepairDetailActions.Actions>);
  private readonly priceBooksService = inject(PriceBooksService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly staticDataService = inject(StaticDataService);
  private readonly partItemsService = inject(PartItemsService);

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceRepairDetailActions.save),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(ServiceRepairDetailSelectors.serviceRepair$))))),
      mergeMap(([action, serviceRepair]) => {
        if (serviceRepair.created || serviceRepair.updated) {
          return this.priceBooksService.saveServiceRepair(serviceRepair.priceBookId, serviceRepair).pipe(
            concatMap(({ id: newServiceRepairId }) => {
              // The value returned is too primitive to use immediately, we will request our service repairs again
              // But first clear our cache
              this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairs);
              return this.staticDataService
                .getServiceRepairs()
                .pipe(map(newServiceRepairs => newServiceRepairs.find(n => n.id === newServiceRepairId)))

                .pipe(map(newServiceRepair => ServiceRepairDetailActions.saveServiceRepairSuccess({ serviceRepair: newServiceRepair })));
            }),
            catchError(error => of(ServiceRepairDetailActions.saveServiceRepairFail({ error })))
          );
        }

        return of(ServiceRepairDetailActions.saveServiceRepairSuccess({ serviceRepair }));
      })
    )
  );

  saveServiceRepairSuccess1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceRepairDetailActions.saveServiceRepairSuccess),
      map(_ => ServiceRepairDetailActions.saveCategoryIds())
    )
  );

  saveServiceRepairSuccess2$ = createEffect(() =>
    this.triggerDeleteAndSaveActions(
      ServiceRepairDetailActions.saveServiceRepairSuccess,
      () => select(ServiceRepairDetailSelectors.partItems$),
      ServiceRepairDetailActions.noop2,
      ServiceRepairDetailActions.savePartItemSave,
      false
    )
  );

  saveServiceRepairSuccess3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceRepairDetailActions.saveServiceRepairSuccess, ServiceRepairDetailActions.savePartItemSaveSuccess),
      map(_ => ServiceRepairDetailActions.savePartItemServiceRepairsSave())
    )
  );

  saveCategoryIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceRepairDetailActions.saveCategoryIds),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(ServiceRepairDetailSelectors.serviceRepairWithCategoryIds))))
      ),
      mergeMap(([action, { serviceRepair, categoryIds }]) => {
        if (categoryIds.updated) {
          return this.priceBooksService.setServiceRepairCategories(serviceRepair.priceBookId, serviceRepair.id, categoryIds.ids).pipe(
            map(_ => ServiceRepairDetailActions.saveCategoryIdsSuccess()),
            tap(_ => {
              this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairCategories);
            }),
            catchError(error => of(ServiceRepairDetailActions.saveCategoryIdsFail({ error })))
          );
        }

        return of(ServiceRepairDetailActions.noopWithCounting());
      })
    )
  );

  savePartItemSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceRepairDetailActions.savePartItemSave),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(ServiceRepairDetailSelectors.partItems$)).pipe(map(m => m[action.id]))))
      ),
      mergeMap(([action, resource]) =>
        this.partItemsService.save(resource).pipe(
          map(newResource => ServiceRepairDetailActions.savePartItemSaveSuccess({ oldId: resource.id, newId: newResource.id })),
          tap(_ => {
            this.applicationCacheService.clearCategory(UpdateCategory.PartItems);
          }),
          catchError(error => of(ServiceRepairDetailActions.savePartItemFail({ error })))
        )
      )
    )
  );

  savePartItemServiceRepair$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceRepairDetailActions.savePartItemServiceRepairsSave),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(ServiceRepairDetailSelectors.partItemIdsWithParentId$))))),
      mergeMap(([action, { serviceRepairId, partItemIds, priceBookId }]) => {
        // We have to call this setPartItem only when we have non-negative part item ids,
        // So we trigger this method every time but we have to check if we have to call.
        if (!partItemIds.updated || partItemIds.ids.some(n => n.id <= 0)) {
          return of(ServiceRepairDetailActions.noopWithCounting());
        }

        return this.priceBooksService
          .setServiceRepairPartItems(
            priceBookId,
            serviceRepairId,
            partItemIds.ids.map(n => n.id)
          )
          .pipe(
            map(newResource => ServiceRepairDetailActions.savePartItemServiceServiceRepairsSaveSuccess()),
            tap(_ => {
              this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairs);
            }),
            catchError(error => of(ServiceRepairDetailActions.savePartItemServiceRepairsFail({ error })))
          );
      })
    )
  );

  private triggerDeleteAndSaveActions(
    action: ActionCreator<string, Creator>,
    selector: (action: Action) => (source$: Observable<any>) => Observable<Record<number, Resource & Mutable>>,
    deleteAction: ActionCreator<string, (props: { id: Id }) => { id: Id } & Action>,
    createOrUpdateAction: ActionCreator<string, (props: { id: Id }) => { id: Id } & Action>,
    triggerAnyway: boolean
  ) {
    return this.actions$.pipe(
      ofType(action),
      concatMap((act: Action) => of(act).pipe(withLatestFrom(this.store.pipe(selector(act))))),
      mergeMap(([act, resourcesByIds]) => {
        const newActions: Action[] = [];
        const values = Object.values(resourcesByIds);
        newActions.push(...values.filter(m => m.deleted && m.id > 0).map(m => deleteAction({ id: m.id })));
        newActions.push(
          ...values.filter(m => !m.deleted && (m.created || m.updated || triggerAnyway)).map(m => createOrUpdateAction({ id: m.id }))
        );

        return from(newActions);
      })
    );
  }
}
