// Port from https://github.com/ng-select/ng-select/blob/88023334b09b61b4086af0536bb8a92bc383bd91/src/ng-option-highlight/lib/ng-option-highlight.directive.ts
// Once @ng-select/ng-option-highlight package is updated with Angular 14+ dependencies, we can remove this and use the package instead
import { NgModule } from '@angular/core';
import { NgOptionHighlightDirective } from './ng-option-highlight.directive';

@NgModule({
  imports: [NgOptionHighlightDirective],
  exports: [NgOptionHighlightDirective],
})
export class NgOptionHighlightModule {}
