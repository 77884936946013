import { Injectable } from '@angular/core';
import { addDays, differenceInDays, getDay } from 'date-fns';

const SUNDAY = 0;
const SATURDAY = 6;

@Injectable()
export class VisitService {
  findDates(numberOfDates: number, startingDate: Date, endingDate: Date, avoidWeekends: boolean): Date[] {
    const mEnd = endingDate;
    const mStart = startingDate;
    const days = differenceInDays(mEnd, mStart) + 1;

    const factor = Math.floor(days / numberOfDates);

    const dates: Date[] = [];
    for (let i = 1; i <= numberOfDates; i++) {
      let current = mStart;
      current = addDays(current, factor * i);

      if (i === numberOfDates) {
        dates.push(endingDate);
      } else {
        dates.push(current);
      }
    }

    if (avoidWeekends) {
      for (let i = 0; i < numberOfDates; i++) {
        let d = dates[i];
        const dayOfTheWeek = getDay(d);
        if (dayOfTheWeek === SUNDAY) {
          // Backward
          d = addDays(d, -2);
        } else if (dayOfTheWeek === SATURDAY) {
          // Backward
          d = addDays(d, -1);
        }

        dates[i] = d;
      }
    }

    return dates;
  }
}
