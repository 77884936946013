import { ErrorHandler, Injectable, InjectionToken, inject } from '@angular/core';
import { ErrorLoggingService } from './error-logging.service';
import { SignalRError } from './signalr.service';

export const notConnectedError = new Error('Not Connected');

const ignoreErrors = (err: Error) => {
  if (err === notConnectedError) {
    return true;
  }

  if (err instanceof SignalRError) {
    return true;
  }

  return false;
};

export interface LoggingErrorHandlerOptions {
  rethrowError: boolean;
  unwrapError: boolean;
}

const LOGGING_ERROR_HANDLER_OPTIONS_CODE = new InjectionToken<LoggingErrorHandlerOptions>('LOGGING_ERROR_HANDLER_OPTIONS');

export const LOGGING_ERROR_HANDLER_OPTIONS: LoggingErrorHandlerOptions = {
  rethrowError: false,
  unwrapError: false,
};

@Injectable()
export class LoggingErrorHandler implements ErrorHandler {
  private readonly errorLoggingService = inject(ErrorLoggingService);
  private readonly options = inject(LOGGING_ERROR_HANDLER_OPTIONS_CODE);

  // I handle the given error.
  public handleError(error: any): void {
    if (ignoreErrors(error)) {
      console.debug('Ignoring error.');
      console.debug(error);

      return;
    }

    // Log to the console.
    try {
      console.group('ErrorHandler');
      if (error) {
        console.error(error.message);
        console.error(error.stack);
      }

      console.groupEnd();
    } catch (handlingError) {
      console.group('ErrorHandler');
      console.warn('Error when trying to output error.');
      console.error(handlingError);
      console.groupEnd();
    }

    // Send to the error-logging service.
    try {
      this.options.unwrapError
        ? this.errorLoggingService.logError(this.findOriginalError(error))
        : this.errorLoggingService.logError(error);
    } catch (loggingError) {
      console.group('ErrorHandler');
      console.warn('Error when trying to log error to', this.errorLoggingService);
      console.error(loggingError);
      console.groupEnd();
    }

    if (this.options.rethrowError) {
      throw error;
    }
  }

  // I attempt to find the underlying error in the given Wrapped error.
  private findOriginalError(error: any): any {
    while (error?.originalError) {
      error = error.originalError;
    }

    return error;
  }
}

export function LOGGING_ERROR_HANDLER_OPTIONS_FACTORY(): LoggingErrorHandlerOptions {
  return LOGGING_ERROR_HANDLER_OPTIONS;
}

export const LOGGING_ERROR_HANDLER_PROVIDERS = [
  {
    provide: LOGGING_ERROR_HANDLER_OPTIONS_CODE,
    useFactory: LOGGING_ERROR_HANDLER_OPTIONS_FACTORY,
  },
  {
    provide: ErrorHandler,
    useClass: LoggingErrorHandler,
  },
];
