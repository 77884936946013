import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, TemplateRef, computed, contentChild, forwardRef, input, type Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { Mode } from '@models/form';

const movingAnimation = '.2s ease-out';

const RW_COMPONENT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Rw2Component),
  multi: true,
};

// Angular 18
@Component({
  selector: 'wm-rw2',
  templateUrl: 'rw2.component.html',
  styleUrls: ['rw.component.scss'],
  providers: [RW_COMPONENT_VALUE_ACCESSOR],
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('rwContainer', [
      transition('r => w', [
        style({ height: '24px' }),
        group([
          animate(
            movingAnimation,
            style({
              height: '{{ expanded_size }}',
            })
          ),
          query('@wContainer', [animateChild()]),
        ]),
      ]),
      transition('w => r', [
        style({ height: '{{ expanded_size }}' }),
        group([
          animate(
            movingAnimation,
            style({
              height: '24px',
            })
          ),
          query('@rContainer', [animateChild()]),
        ]),
      ]),
    ]),
    trigger('rContainer', [
      state(
        'r',
        style({
          transform: 'translateY(0)',
        })
      ),
      // We want this animation to happen ONLY when we come back from W
      transition('w => r', [style({ transform: 'translateY(4px)' }), animate(movingAnimation)]),
    ]),
    trigger('wContainer', [
      state(
        'w',
        style({
          transform: 'translateY(0)',
        })
      ),
      transition('void => w', [style({ transform: 'translateY(-4px)' }), animate(movingAnimation)]),
    ]),
  ],
  host: {
    '[class.rw]': 'true',
    '[class.read-mode]': 'readMode()',
    '[class.write-mode]': 'writeMode()',
  },
})
export class Rw2Component extends ElementBaseWithFormControl<unknown> {
  readonly Mode = Mode;

  isReady = false;

  readonly expandedSize = input('32px');
  readonly name = input<string>();
  readonly mode = input(Mode.Read);

  readonly readMode = computed(() => this.mode() === Mode.Read);
  readonly writeMode = computed(() => this.mode() === Mode.Write);
  readonly modeStr = computed(() => (Mode.Read === this.mode() ? 'r' : 'w'));

  labelTemplate2 = contentChild('labelTemplate', { read: TemplateRef });
  helperTemplate = contentChild('helperTemplate', { read: TemplateRef });
  readTemplate = contentChild.required('readTemplate', { read: TemplateRef });
  writeTemplate = contentChild.required('writeTemplate', { read: TemplateRef });

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
