import { type Resource } from './resource';

export enum PaymentGateway {
  None = 0,
  Square = 1,
  Stripe = 2,
}

export enum PaymentGatewayCapability {
  Charge = 1,
  Subscription = 2,
}

export interface PaymentGatewayData {
  gateway: PaymentGateway;
  externalServiceName: string;
  capability: PaymentGatewayCapability;
}

export interface PaymentCard extends Resource {
  // text: CardHolderName
  last4: string;
  expireMonth: number | null;

  /**
   * Expiration year. 4 digits. Ex: 2014
   */
  expireYear: number | null;

  paymentGateway: PaymentGateway;
}

export function getPaymentGatewayText(paymentGateway: PaymentGateway): string | null {
  switch (paymentGateway) {
    case PaymentGateway.Square:
      return 'Square';
    case PaymentGateway.Stripe:
      return 'Stripe';
    // Don't include None.
  }

  return null;
}

export function getBestCard(cards: PaymentCard[] | null): PaymentCard | null {
  // We select the newest card (expires the farthest)
  if (cards && cards.length > 0) {
    const sorted = cards.slice().sort((card1, card2) => {
      if ((card1.expireYear ?? 0) < (card2.expireYear ?? 0)) {
        return -1;
      } else if ((card1.expireYear ?? 0) > (card2.expireYear ?? 0)) {
        return 1;
      } else {
        if ((card1.expireMonth ?? 0) < (card2.expireMonth ?? 0)) {
          return -1;
        }

        return 1;
      }
    });

    return sorted[sorted.length - 1];
  }

  return null;
}

export interface NormalPaymentIntake {
  $type: 'NormalPaymentIntake';
  name: string;
  card: string;
  expiration: string;
  cvc: string;
  save: boolean;
}

export interface SquarePaymentIntake {
  $type: 'SquarePaymentIntake';
  name: string;
  nonce: string;
  save: boolean;
}

export interface StripePaymentIntake {
  $type: 'StripePaymentIntake';
  name: string;
  paymentMethod: string;
  postalCode: string;
  save: boolean;
}

export type PaymentIntake = NormalPaymentIntake | SquarePaymentIntake | StripePaymentIntake;

export const isNormalPaymentIntake = (paymentIntake: PaymentIntake): paymentIntake is StripePaymentIntake => {
  return paymentIntake.$type === 'NormalPaymentIntake';
};

export const isSquarePaymentIntake = (paymentIntake: PaymentIntake): paymentIntake is SquarePaymentIntake => {
  return paymentIntake.$type === 'SquarePaymentIntake';
};

export const isStripePaymentIntake = (paymentIntake: PaymentIntake): paymentIntake is StripePaymentIntake => {
  return paymentIntake.$type === 'StripePaymentIntake';
};
