import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { type ActionReducer, StoreModule } from '@ngrx/store';
import { saveReducerWithCode } from '@services/save-store/save.actions';
import { ServiceRepairDetailEffects } from './service-repair-detail.effects';
import { reducer as serviceRepairDetailReducer } from './service-repair-detail.reducer';
import { SERVICE_REPAIR_DETAIL_STORE_KEY } from './service-repair-detail.selectors';

export function reducer(r: ActionReducer<any>): ActionReducer<any> {
  return saveReducerWithCode(SERVICE_REPAIR_DETAIL_STORE_KEY)(r);
}

export const metaReducers = [reducer];

@NgModule({
  imports: [
    StoreModule.forFeature(SERVICE_REPAIR_DETAIL_STORE_KEY, serviceRepairDetailReducer, { metaReducers }),
    EffectsModule.forFeature([ServiceRepairDetailEffects]),
  ],
})
export class ServiceRepairDetailStoreModule {}
