import { Injectable } from '@angular/core';
import { type Observable, ReplaySubject } from 'rxjs';

export interface Position {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

@Injectable()
export class StickyService {
  private groups: Record<string, ReplaySubject<Position | null>> = {};

  private getReplaySubject(group: string) {
    const replaySubject = this.groups[group] || (this.groups[group] = new ReplaySubject(1));
    return replaySubject;
  }

  registerAsSticky(group: string): Observable<Position | null> {
    return this.getReplaySubject(group).asObservable();
  }

  handleSticky(group: string, position: Position): void {
    this.getReplaySubject(group).next(position);
  }

  stopSticky(group: string): void {
    const replaySubject = this.getReplaySubject(group);
    replaySubject.next(null);
  }
}
