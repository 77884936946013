// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wm-pager {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 32px;
}
.wm-pager.with-slider {
  padding: 32px;
}
.wm-pager.hidden {
  display: none;
}

.wm-pager.initialized .current {
  transition: left 0.35s ease-out;
}

.wm-pager-slider * {
  font-size: 12px;
}

wm-pager-bubble.highlight {
  pointer-events: none;
}

.wm-pager-data > * {
  display: inline;
}

.wm-pager-slider {
  padding-top: 16px;
  padding-bottom: 24px;
  display: flex;
  align-items: baseline;
  width: 100%;
}
.wm-pager-slider.hidden {
  display: none;
}
.wm-pager-slider .highlight {
  display: none;
}
.wm-pager-slider:hover .highlight {
  display: block;
}
.wm-pager-slider .current {
  position: absolute;
  top: 0;
}
.wm-pager-slider .start,
.wm-pager-slider .end,
.wm-pager-slider .current .text {
  white-space: nowrap;
}
.wm-pager-slider .start,
.wm-pager-slider .end {
  color: #a3aab1;
  padding: 0 4px;
  cursor: default;
  display: none;
}
.wm-pager-slider .wm-slider {
  background-color: #f1f2f3;
  height: 8px;
  border-radius: 4px;
  flex-basis: 100%;
  position: relative;
}

.wm-pager-data .value,
.wm-pager-slider .value {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}

.wm-connector {
  margin-left: 4px;
  margin-right: 4px;
}

.wm-results .value,
.wm-pages .value {
  margin-right: 4px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
