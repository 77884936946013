import { Injectable, inject } from '@angular/core';
import { type ReferralTypeInformation } from '@models/referral-type-models';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class ReferralTypesService {
  private readonly http = inject(HttpClientService);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly url = inject(UrlService);

  list(id?: Id): Observable<ReferralTypeInformation[]> {
    return this.applicationCacheService.getFromCache(UpdateCategory.ReferralTypes, this.url.referralTypesList, id);
  }

  save(referralType: ReferralTypeInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(referralType, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.referralTypesSave, obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.referralTypesHide.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.referralTypesRestore.replace('$0', id.toString()), null);
  }
}
