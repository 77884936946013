import { CommonModule } from '@angular/common';
import { Component, TemplateRef, contentChild, forwardRef, input, type Provider, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { Rw2Component } from '@controls/rw/rw2.component';
import { Mode } from '@models/form';

const RW_CHECKBOX_COMPONENT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RwCheckbox2Component),
  multi: true,
};

// Angular 18
@Component({
  selector: 'wm-rw-checkbox2',
  templateUrl: 'rw-checkbox2.component.html',
  styleUrls: ['rw-checkbox.component.scss'],
  providers: [RW_CHECKBOX_COMPONENT_VALUE_ACCESSOR],
  exportAs: 'rwCheckbox',
  host: {
    '[class.rw]': 'true',
  },
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, Rw2Component, CheckboxComponent],
})
export class RwCheckbox2Component extends ElementBaseWithFormControl<boolean> {
  readonly Mode = Mode;

  readonly name = input<string>();
  readonly checkedText = input.required<string>();
  readonly uncheckedText = input<string>('');
  readonly mode = input(Mode.Read);

  private readonly signalValue = toSignal(this.innerValue);
  readonly readText = computed(() => (this.signalValue() ? this.checkedText() : this.uncheckedText()));

  readonly helperTemplate2 = contentChild('helperTemplate', { read: TemplateRef });

  constructor() {
    super(() => false);
  }
}
