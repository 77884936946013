import { Component, effect, forwardRef, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementBaseWithFormControl } from '@controls/element-base';
import { InputTextComponent } from '@controls/input-text/input-text.component';
import { type Relative, type RelativeValue } from '@models/filter-models';
import { Mode } from '@models/form';
import { DynamicFilterService2 } from '@services/dynamic-filter.service';
import { combineLatest } from 'rxjs';
import { concatMap, debounceTime, filter } from 'rxjs/operators';

@Component({
  template: `<div class="wm-description">
    <label>{{ label() }}</label>
    <div class="read range">
      <div>
        <label>Older than</label>
        <wm-input-text type="number" [ngModel]="getTo()" (ngModelChange)="setTo($event)" />
        <span>years old</span>
      </div>
      <div>
        <label>But not older than</label>
        <wm-input-text type="number" [ngModel]="getFrom()" (ngModelChange)="setFrom($event)" />
        <span>years old</span>
      </div>
    </div>
  </div>`,
  styleUrl: 'filter-dynamic-common.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterCustomerSiteSystemYearManufacturedComponent),
      multi: true,
    },
  ],
  styles: [
    `
      wm-input-text {
        width: 75px;
      }

      .range div {
        align-items: center;

        span {
          margin-left: 4px;
        }
      }
    `,
  ],
  imports: [FormsModule, InputTextComponent],
  standalone: true,
})
export class FilterCustomerSiteSystemYearManufacturedComponent extends ElementBaseWithFormControl<Relative | null> {
  readonly Mode = Mode;

  private readonly dynamicFilterService = inject(DynamicFilterService2);

  readonly filterId = input<string>('');
  readonly label = input<string>('');
  readonly code = input<string>(); // required
  readonly name = input<string>(); // required

  private readonly valueChanges = toSignal(this.control.valueChanges.pipe(debounceTime(400)));
  private readonly serviceChanges = toSignal(
    combineLatest([toObservable(this.code), toObservable(this.name)]).pipe(
      filter(([code, name]) => !!code && !!name),
      concatMap(([code, name]) => this.dynamicFilterService.changes<Relative | null>(this.filterId(), code, name))
    ),
    { initialValue: null }
  );

  _ = effect(() => {
    this.dynamicFilterService.patch(this.filterId(), this.code(), this.name(), this.valueChanges());
  });

  __ = effect(
    () => {
      this.control.patchValue(this.serviceChanges());
    },
    { allowSignalWrites: true }
  );

  constructor() {
    super(() => null);
  }

  getFrom(): number | null {
    const year = this.control.getRawValue()?.from?.years;
    if (year) {
      return -1 * year;
    }

    return null;
  }

  setFrom(value: Int | null): void {
    const existing = { ...(this.control.getRawValue() ?? {}) };
    delete existing['from'];
    if (value != null) {
      existing['from'] = {
        days: null,
        months: null,
        years: Math.abs(value) * -1,
      };
    }

    this.control.patchValue({
      $type: 'DateRangeFilter.Relative',
      ...existing,
    });
  }

  getTo(): number | null {
    const year = this.control.getRawValue()?.to?.years;
    if (year) {
      return -1 * year;
    }

    return null;
  }

  setTo(value: Int): void {
    const existing = { ...(this.control.getRawValue() ?? {}) };
    delete existing['to'];
    if (value) {
      existing['to'] = {
        days: null,
        months: null,
        years: Math.abs(value) * -1,
      };
    }

    this.control.patchValue({
      $type: 'DateRangeFilter.Relative',
      ...existing,
    });
  }
}
