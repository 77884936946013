import { HttpParams } from '@angular/common/http';
import { Injectable, inject, type OnDestroy } from '@angular/core';
import { type Resource, type StateResource } from '@models/resource';
import { deepFreeze } from '@utility/object';
import { Observable, Subject, of } from 'rxjs';
import { concatMap, first, map, share, shareReplay, take, takeUntil, tap } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';
import { PubSubService } from './pub-sub.service';

export enum UpdateCategory {
  CallWorkOrderTypes = 0,
  PartServiceTypes = 1,
  PropertyTypes = 2,
  ReferralTypes = 3,
  SystemTypes = 4,
  SiteTypes = 5,
  RateTypes = 6,
  CallReasons = 7,
  Inspections = 8,
  Surveys = 9,
  Agreements = 10,
  Zones = 11,
  ServiceRepairCategories = 12,
  PartItems = 13,
  ServiceRepairs = 14,
  Pricings = 15,
  LaborTimes = 16,
  MarkupPricings = 17,
  LaborPricings = 18,
  TaxItemSections = 19,
  Contractors = 20,
  PartItemCategories = 21, // Deprecated
  PaymentMethods = 22,
  States = 23,
  FilterTypes = 24,
  PaymentTypes = 25,
  CallDepartmentTypes = 26,
  ContractorSettings = 27,
  ServiceRatings = 28,
  Tags = 29,
  Debriefs = 30,
  DutyCategories = 31,
  Avatars = 32,
  Technicians = 33,
  AgreementConfigurations = 34,
  QuickBooksSettings = 35,
  LoanRates = 36,
  Rebates = 37,
  Discounts = 38,
  PriceBooks = 39,
  Distributors = 41,
  PartDeliveryMethods = 42,
  ReplenishmentTypes = 43,
  Charges = 44,
  Subscriptions = 45,
  CustomFields = 46,
}

export class SavedData {
  public request?: Observable<any>;
  public lastChange: Date = new Date();
  public stale = true;
}

@Injectable()
export class ApplicationCacheService implements OnDestroy {
  private readonly http = inject(HttpClientService);
  private readonly pubSubService = inject(PubSubService);

  private saved: Record<number, Observable<any> | null> = {};

  protected savedData: Record<string, SavedData> = {};
  protected savedCategoryIdForUrl?: Record<number, string>;
  private lastCheck?: Date;
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.clear();

    this.pubSubService
      .getEventEmitter('LOGIN')
      .pipe(takeUntil(this.destroy$))
      .pipe(
        tap(() => {
          console.log('Clearing ApplicationCacheService.');
        })
      )
      .subscribe(() => {
        this.clear();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clear(): void {
    this.saved = {};
    this.savedData = {};
    this.lastCheck = undefined;
  }

  clearCategory(category: UpdateCategory): void {
    this.saved[category] = null;
    if (this.savedCategoryIdForUrl?.[category]) {
      delete this.savedData[this.savedCategoryIdForUrl[category]];
    }
  }

  getSupportedApplicationCache(): Observable<void> {
    const obs = new Observable<void>(resolver => {
      this.http.get<Record<string, string>>('/static/list').subscribe(data => {
        this.savedCategoryIdForUrl = data;

        resolver.next();
        resolver.complete();
      });
    }).pipe(share());
    obs.subscribe();
    return obs;
  }

  get<T>(url: string, forceRefresh?: boolean): Observable<T> {
    let savedData = this.savedData[url] || (this.savedData[url] = new SavedData());

    // If we are stale, let's make a request. First time we create a SavedData, we are marked as stale.
    if (savedData === null || savedData.stale || forceRefresh) {
      savedData = this.savedData[url] = new SavedData();
      savedData.stale = false; // We shouldn't start stale. Our first request will be quite fresh.
      savedData.request = this.http.get(url).pipe(shareReplay(1));
    }

    return savedData.request?.pipe(take(1)) ?? of(null); // We need to take 1 otherwise our observable is not marked as "completed".
  }

  getClassResource<T extends Resource, U>(type: new (arg: U) => T, url: string, forceRefresh?: boolean): Observable<T> {
    // eslint-disable-next-line new-cap
    return this.get<U>(url, forceRefresh).pipe(map(obs => new type(obs)));
  }

  getClassResources<T extends Resource, U>(type: new (arg: U) => T, url: string, forceRefresh?: boolean): Observable<T[]> {
    // eslint-disable-next-line new-cap
    return this.get(url, forceRefresh).pipe(map((obs: any[]) => obs.map(ob => new type(ob))));
  }

  getClassWithId<T extends Resource, U>(type: new (arg: U) => T, url: string, id?: number): Observable<T[]> {
    const observable = this.getClassResources(type, url, false)
      .pipe(concatMap(this.searchOn(url, id)))
      .pipe(take(1));

    return observable as any; // TS 2.5.2 bug
  }

  getWithId<T extends Resource>(url: string, id?: number): Observable<T[]> {
    let observable = this.get<T[]>(url, false);

    if (id) {
      // TODO test this.
      observable = observable.pipe(concatMap(this.searchOn(url, id))).pipe(take(1));
    }

    return observable;
  }

  getDataFromCache<T>(category: UpdateCategory, httpUrl: string, id?: number): Observable<T> {
    let obs: Observable<T> = this.saved[category] as Observable<T>;
    if (!this.saved[category]) {
      obs = this.saved[category] = this.http
        .get<T>(httpUrl)
        .pipe(map(m => deepFreeze(m)))
        .pipe(shareReplay(1));
    }

    return obs;
  }

  getSettingsFromCache<T>(category: UpdateCategory, httpUrl: string): Observable<T> {
    return this.getDataFromCache<T>(category, httpUrl);
  }

  getFromCache<T extends StateResource>(category: UpdateCategory, httpUrl: string, id?: number): Observable<T[]> {
    const obs = this.getDataFromCache<T[]>(category, httpUrl, id);

    return obs
      .pipe(
        concatMap(results => {
          if (id) {
            let idFound = false;
            if (results && results.length > 0) {
              for (const result of results) {
                idFound = result.id === id;
                if (idFound) {
                  break;
                }
              }
            }

            if (!idFound) {
              const params = new HttpParams().set('id', id.toString());
              const newObs = this.http.get<T[]>(httpUrl, { params });
              return (this.saved[category] = newObs
                .pipe(
                  map(newResults => {
                    const finalResults = results.slice(0);
                    for (const newResult of newResults) {
                      if (!results.some(n => n.id === newResult.id)) {
                        finalResults.push(newResult);
                      }
                    }

                    return deepFreeze(finalResults);
                  })
                )
                .pipe(shareReplay(1)));
            }
          }

          return of(results);
        })
      )
      .pipe(
        map(results => {
          // We keep only visible ones or the ones we requested from the Id.
          return results.filter(result => {
            return !result.hidden || result.id === id;
          });
        })
      )
      .pipe(first());
  }

  private searchOn<T extends Resource>(url: string, id?: number): (data: T[]) => Observable<T[]> {
    return (data: T[]): Observable<T[]> => {
      let idFound = false;
      if (data && data.length > 0) {
        for (const d of data) {
          idFound = d.id === id;
          if (idFound) {
            break;
          }
        }
      }

      if (!idFound) {
        return this.http.get<T[]>(`${url}?id=${id}`);
      }

      return of(data);
    };
  }

  isStale(updateCategory: UpdateCategory): boolean {
    if (this.savedCategoryIdForUrl?.[updateCategory]) {
      const savedData = this.savedData[this.savedCategoryIdForUrl[updateCategory]];
      if (savedData) {
        return savedData.stale;
      }
    }

    return true;
  }

  checkStale(): void {
    if (!this.savedCategoryIdForUrl) {
      console.warn("Can't call this method before checking the server.");
      this.getSupportedApplicationCache();
      return;
    }

    this.lastCheck = this.lastCheck || new Date(1970, 0, 1);
    this.http
      .get<{ category: UpdateCategory; lastChange: Date }[]>('/static/lastchange?after=' + this.lastCheck.toISOString())
      .subscribe(data => {
        // Let's put our last check with the latest value we have
        for (const option of data) {
          if (!this.lastCheck || option.lastChange > this.lastCheck) {
            this.lastCheck = option.lastChange;
          }

          this.saved[option.category] = null;

          const url = this.savedCategoryIdForUrl?.[option.category];
          if (url) {
            let savedData = this.savedData[url];
            if (!savedData) {
              savedData = this.savedData[url] = new SavedData();

              // Uhoh, ContractorSettings is different, let's set it to not stale to start with.
              if (option.category === UpdateCategory.ContractorSettings) {
                savedData.stale = false;
              }
            }

            if (savedData.lastChange < option.lastChange) {
              savedData.lastChange = option.lastChange;
              savedData.stale = true;
            }
          }
        }
      });
  }
}
