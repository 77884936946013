import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputDropdownComponent } from '@controls/input-dropdown/input-dropdown.component';
import { type Resource1 } from '@models/resource';
import { InputResourceBase } from '../input-resource/input-resource.component';

const INPUT_TAG_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputTagComponent),
  multi: true,
};

@Component({
  selector: 'wm-input-tag',
  templateUrl: 'input-tag.component.html',
  styleUrls: ['input-tag.component.scss'],
  providers: [INPUT_TAG_COMPONENT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, InputDropdownComponent],
})
export class InputTagComponent<T> extends InputResourceBase<Resource1<T>[], T, Resource1<T>> {}
