import { Injectable, inject } from '@angular/core';
import { type DebriefCategoryInformation } from '@models/debrief-models';
import { clone } from '@utility/object';
import { sortWithChildren } from '@utility/observable';
import { type Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { ApplicationCacheService, UpdateCategory } from '../application-cache.service';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class DebriefCategoriesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);
  private readonly applicationCacheService = inject(ApplicationCacheService);

  list(): Observable<DebriefCategoryInformation[]> {
    return this.applicationCacheService
      .getFromCache(UpdateCategory.Debriefs, this.url.debriefCategoryList)
      .pipe(map(m => clone(m))) // The data is frozen
      .pipe(sortWithChildren()) as Observable<DebriefCategoryInformation[]>;
  }

  get(id: Id): Observable<DebriefCategoryInformation> {
    return this.http.get<DebriefCategoryInformation>(this.url.debriefCategoryGet.replace('$0', id.toString()));
  }

  save(debriefCategory: DebriefCategoryInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.debriefCategoryPost, debriefCategory).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  delete(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.debriefCategoryDelete.replace('$0', id.toString())).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  hide(id: Id): Observable<void> {
    return this.http.post<void>(this.url.debriefCategoryHide.replace('$0', id.toString()), null).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.debriefCategoryRestore.replace('$0', id.toString()), null).pipe(
      tap(_ => {
        this.clearCache();
      }),
      share()
    );
  }

  private clearCache(): void {
    if (this.applicationCacheService) {
      this.applicationCacheService.clearCategory(UpdateCategory.Debriefs);
    }
  }
}
