import { Pipe, type PipeTransform } from '@angular/core';
import { QuickBooksSynchronizationStatus } from '@models/quick-books';

@Pipe({
  name: 'quickBooks',
  standalone: true,
})
export class QuickBooksPipe implements PipeTransform {
  transform(status: QuickBooksSynchronizationStatus): string {
    let img: string | undefined;
    if (status === QuickBooksSynchronizationStatus.Synchronized) {
      img = '<img src="/assets/images/quick-books-synchronized.svg" title="QuickBooks Synchronized" alt="" />';
    } else if (status === QuickBooksSynchronizationStatus.Pending || status === QuickBooksSynchronizationStatus.PendingDeletion) {
      img = '<img src="/assets/images/quick-books-pending.svg" title="QuickBooks Pending" alt="" />';
    }

    if (img) {
      return `<span class="quick-books-status">${img}</span>`;
    }

    return '';
  }
}
