import { Directive, ElementRef, EventEmitter, HostListener, Output, inject } from '@angular/core';

@Directive({
  selector: '[wmFileDrop]',
  standalone: true,
})
export class FileDropDirective {
  protected readonly element = inject(ElementRef);

  @Output() public fileEnterWindow = new EventEmitter<boolean>();
  @Output() public fileOver = new EventEmitter<boolean>();
  @Output() public fileDrop = new EventEmitter<File[]>();

  windowDragEnterCounter = 0;
  @HostListener('window:dragenter', ['$event'])
  public onDragEnter(event: Event /* DragEvent */) {
    // DragEvent is not available in Safari
    this.windowDragEnterCounter++;
    const transfer = this._getTransfer(event);
    if (!this._haveFiles(transfer.types)) {
      return;
    }

    this.fileEnterWindow.emit(true);
  }

  @HostListener('window:dragleave', ['$event'])
  public onDragLeaveWindow(event: Event /* DragEvent */) {
    // DragEvent is not available in Safari
    this.windowDragEnterCounter--;
    if (this.windowDragEnterCounter === 0) {
      this.fileEnterWindow.emit(false);
    }
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    const transfer = this._getTransfer(event);
    if (!transfer) {
      return;
    }

    this._preventAndStop(event);
    this.fileOver.emit(false);
    this.fileEnterWindow.emit(false);
    this.fileDrop.emit(transfer.files);
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: any): void {
    const transfer = this._getTransfer(event);
    if (!this._haveFiles(transfer.types)) {
      return;
    }

    transfer.dropEffect = 'copy';
    this._preventAndStop(event);
    this.fileOver.emit(true);
    this.windowDragEnterCounter = 0;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any): any {
    if ((this as any).element) {
      if (event.currentTarget === (this as any).element[0]) {
        return;
      }
    }

    this._preventAndStop(event);
    this.fileOver.emit(false);
  }

  protected _getTransfer(event: any): any {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer; // jQuery fix;
  }

  protected _preventAndStop(event: any): any {
    event.preventDefault();
    event.stopPropagation();
  }

  protected _haveFiles(types: any): any {
    if (!types) {
      return false;
    }

    if (types.indexOf) {
      return types.indexOf('Files') !== -1;
    } else if (types.contains) {
      return types.contains('Files');
    } else {
      return false;
    }
  }
}
