export enum CallType {
  Scheduled = 0,
  Released = 1,
  Arrived = 2,
  Departed = 3,
  Blocked = 4,
  Completed = 5,
  Processed = 6,
}

export function getCallTypeText(callType: CallType): string {
  switch (callType) {
    case CallType.Scheduled:
      return 'Scheduled';
    case CallType.Arrived:
      return 'Arrived';
    case CallType.Departed:
      return 'Departed';
    case CallType.Blocked:
      return 'Blocked';
    case CallType.Released:
      return 'Sent to App';
    case CallType.Completed:
      return 'Completed';
    case CallType.Processed:
      return 'Processed';
  }

  return '';
}
