import { animate, state, style, transition, trigger, type AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ANIMATION_TIMINGS } from '@models/animations';
import { BACKDROP_DATA } from '@models/dialog';
import { SharedModule } from '@modules/shared.module';

// From: https://blog.thoughtram.io/angular/2017/11/27/custom-overlays-with-angulars-cdk-part-two.html
@Component({
  templateUrl: 'dialog-backdrop.component.html',
  styleUrls: ['dialog-backdrop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MatDialogModule],
  animations: [
    trigger('fadePink', [
      transition(':enter', [style({ opacity: 0 }), animate(ANIMATION_TIMINGS)]),
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 0.5 })),
    ]),
    trigger('fadeBlue', [
      transition(':enter', [style({ opacity: 0 }), animate(ANIMATION_TIMINGS)]),
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 0.33 })),
    ]),
    trigger('blur', [
      state('clear', style({ filter: 'blur(0px)' })),
      state('active', style({ filter: 'blur(4px)' })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
  ],
})
export class DialogBackdropComponent {
  private readonly cd = inject(ChangeDetectorRef);
  private readonly backdropData = inject(BACKDROP_DATA, { optional: true });

  animationState: 'fadeIn' | 'fadeOut' = 'fadeIn';

  @HostBinding('@blur')
  get blurState(): 'clear' | 'active' {
    return this._isLogin ? 'active' : 'clear';
  }

  animationStateChanged = new EventEmitter<AnimationEvent>();

  @HostBinding('class.is-login')
  get _isLogin(): boolean {
    return this.backdropData?.isLogin ?? false;
  }

  onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation(): void {
    this.animationState = 'fadeOut';
    this.cd.markForCheck();
  }
}
