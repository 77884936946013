import { Injectable, Pipe, type PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  public static getWithMarkers(text: string, needle: string | null): string {
    if (needle) {
      const regex = HighlightPipe.getSearchRegex(needle);
      return text.replace(regex, '$1#!#$2!#!$3');
    }

    return text;
  }

  private static escapeRegExp(str: string): string {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  private static getSearchRegex(needle: string) {
    return new RegExp('(.*)' + '(' + HighlightPipe.escapeRegExp(needle) + ')' + '(.*)', 'ig');
  }

  transform(text: string, startTag?: string, endTag?: string): string {
    // Make sure text is secure.
    text = (text || '').replace(/</g, '&lt;').replace(/>/g, '&gt;');

    startTag = startTag || '#!#';
    endTag = endTag || '!#!';

    return text.replace(new RegExp(startTag, 'g'), '<em>').replace(new RegExp(endTag, 'g'), '</em>');
  }
}
