import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';

// Angular 18
@Component({
  selector: 'wm-pager-bubble',
  templateUrl: 'pager-bubble.component.html',
  styleUrls: ['pager-bubble.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PagerBubbleComponent {
  readonly active = input(false);
  readonly width = input(0);
  readonly value = input(0);
  readonly initialized = input(false);
  readonly showPreviousArrow = input(false);
  readonly showNextArrow = input(false);

  readonly previousClick = output<void>();
  readonly nextClick = output<void>();

  internalPreviousClick(): void {
    if (this.showPreviousArrow()) {
      this.previousClick.emit();
    }
  }

  internalNextClick(): void {
    if (this.showNextArrow()) {
      this.nextClick.emit();
    }
  }
}
