import { Injectable, inject, type OnDestroy } from '@angular/core';
import * as EventGrid from '@models/event-grid-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AppInsightsService } from '@services/app-insights.service';
import { EventsService } from '@services/live/events.service';
import { AppRole, RolesService } from '@services/roles.service';
import { ServerPushService } from '@services/signalr.service';
import { addMinutes, endOfDay, isBefore, isWithinInterval, startOfDay } from 'date-fns';
import { Subject, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as DashboardActions from './dashboard.actions';
import { DashboardSignalRMessageType, type DashboardSignalRMessageCustomer, type DashboardSignalRMessageSite } from './dashboard.actions';
import { type DashboardState } from './dashboard.reducer';
import * as DashboardSelectors from './dashboard.selectors';

@Injectable()
export class DashboardEffects implements OnDestroy {
  private readonly store = inject(Store<DashboardState>);
  private readonly actions$ = inject(Actions<DashboardActions.Actions>);
  private readonly eventsService = inject(EventsService);
  private readonly serverPushService = inject(ServerPushService);
  private readonly appInsightService = inject(AppInsightsService);
  private readonly rolesService = inject(RolesService);

  changeCurrentDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.changeCurrentDate),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(DashboardSelectors.loadedDates$))))),
      map(([action, loadedDates]) => {
        if (
          loadedDates.some(loadedDate =>
            isWithinInterval(action.date, {
              start: startOfDay(loadedDate.date),
              end: endOfDay(loadedDate.date),
            })
          )
        ) {
          return DashboardActions.noop();
        }

        return DashboardActions.loadEventsByDate({ date: action.date });
      })
    )
  );

  loadEventsByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadEventsByDate),
      mergeMap(action => {
        const start = startOfDay(action.date);
        const end = endOfDay(action.date);

        return this.eventsService.list(start, end).pipe(
          map(scheduleEvents => DashboardActions.loadEventsByDateSuccess({ date: action.date, scheduleEvents })),
          takeUntil(this.actions$.pipe(ofType(DashboardActions.loadEventsByDate))),
          catchError(err => of(DashboardActions.loadEventByIdFail(err)))
        );
      })
    )
  );

  loadEventById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadEventById),
      mergeMap(action => {
        return this.eventsService.get(action.id).pipe(
          map(scheduleEvent => DashboardActions.loadEventByIdSuccess({ scheduleEvent })),
          catchError(err => of(DashboardActions.loadEventByIdFail(err)))
        );
      })
    )
  );

  loadEventByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadEventByIdSuccess),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DashboardSelectors.requestedDate$)),
            this.store.pipe(select(DashboardSelectors.rawScheduleEvents$))
          )
        )
      ),
      map(([action, requestedDate, scheduleEvents]) => {
        if (!action.scheduleEvent.callDetails && requestedDate) {
          const startOfRequestedMoment = startOfDay(requestedDate);
          const endOfRequestedMoment = endOfDay(requestedDate);

          if (
            isWithinInterval(action.scheduleEvent.start, { start: startOfRequestedMoment, end: endOfRequestedMoment }) ||
            isWithinInterval(action.scheduleEvent.end, { start: startOfRequestedMoment, end: endOfRequestedMoment }) ||
            scheduleEvents.some(
              scheduleEvent =>
                scheduleEvent.id === action.scheduleEvent.id &&
                (isWithinInterval(scheduleEvent.start, { start: startOfRequestedMoment, end: endOfRequestedMoment }) ||
                  isWithinInterval(scheduleEvent.end, { start: startOfRequestedMoment, end: endOfRequestedMoment }))
            )
          ) {
            return DashboardActions.forceRefresh();
          }
        }

        return DashboardActions.updateDashboardEvent({ scheduleEvent: action.scheduleEvent });
      })
    )
  );

  changeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.changeStatus),
      map(action => {
        if (action.active) {
          return DashboardActions.forceRefresh();
        }

        return DashboardActions.noop();
      })
    )
  );

  signalRMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.signalRMessage),
      map(action => {
        switch (action.message.messageType) {
          case DashboardActions.DashboardSignalRMessageType.Event:
            return DashboardActions.signalRMessageEvent({ message: action.message });
          case DashboardActions.DashboardSignalRMessageType.Customer:
            return DashboardActions.signalRMessageCustomer({ message: action.message });
          case DashboardActions.DashboardSignalRMessageType.Site:
            return DashboardActions.signalRMessageSite({ message: action.message });
          case DashboardActions.DashboardSignalRMessageType.WorkOrder:
            return DashboardActions.signalRMessageWorkOrder({ message: action.message });
          default:
            return DashboardActions.noop();
        }
      })
    )
  );

  signalRMessageEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.signalRMessageEvent),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(DashboardSelectors.loadedDates$))))),
      mergeMap(([action, loadedDates]) => {
        if (!action.message.removed) {
          if (
            loadedDates.some(loadedDate =>
              isWithinInterval(loadedDate.date, {
                start: startOfDay(action.message.start),
                end: endOfDay(action.message.end),
              })
            )
          ) {
            return [DashboardActions.loadEventById({ id: action.message.id })];
          }
        }

        return [DashboardActions.noop()];
      })
    )
  );

  signalRMessageCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.signalRMessageCustomer),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(DashboardSelectors.scheduleEventIdsByCustomerId$, { id: action.message.id })))
        )
      ),
      mergeMap(([action, scheduleEventIds]) => {
        if (scheduleEventIds.length > 0) {
          return scheduleEventIds.map(id => DashboardActions.loadEventById({ id }));
        }

        return [DashboardActions.noop()];
      })
    )
  );

  signalRMessageSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.signalRMessageSite),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(DashboardSelectors.scheduleEventIdsBySiteId$, { id: action.message.id }))))
      ),
      mergeMap(([action, scheduleEventIds]) => {
        if (scheduleEventIds.length > 0) {
          return scheduleEventIds.map(id => DashboardActions.loadEventById({ id }));
        }

        return [DashboardActions.noop()];
      })
    )
  );

  signalRMessageWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.signalRMessageWorkOrder),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(DashboardSelectors.scheduleEventIdsByWorkOrderId$, { id: action.message.id })))
        )
      ),
      mergeMap(([action, scheduleEventIds]) => {
        if (scheduleEventIds.length > 0) {
          return scheduleEventIds.map(id => DashboardActions.loadEventById({ id }));
        }

        return [DashboardActions.noop()];
      })
    )
  );

  // We can only forceRefresh if we have the DashboardView.
  forceRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.forceRefresh),
      filter(m => this.rolesService.isInRole(AppRole.DashboardView)),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(DashboardSelectors.requestedDate$))))),
      filter(([action, date]) => !!date),
      map(([action, date]) => {
        return DashboardActions.loadEventsByDate({ date });
      })
    )
  );

  startListeningToSignalR$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.changeStatus, DashboardActions.changeConnected, DashboardActions.changeLiveUpdate),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DashboardSelectors.canLiveUpdate$)),
            this.store.pipe(select(DashboardSelectors.active$)),
            this.store.pipe(select(DashboardSelectors.connected$))
          )
        )
      ),
      switchMap(([action, canLiveUpdate, active, connected]) => {
        if (active && connected && canLiveUpdate) {
          return this.serverPushService.message$.pipe(
            map(signalRMessage => {
              switch (signalRMessage.eventType) {
                case EventGrid.EVENT_WEB_CUSTOMERS_UPDATED:
                  return DashboardActions.signalRMessage({
                    message: {
                      id: signalRMessage.message.id,
                      messageType: DashboardSignalRMessageType.Customer,
                    } as DashboardSignalRMessageCustomer,
                  });
                case EventGrid.EVENT_WEB_SITES_UPDATED:
                  return DashboardActions.signalRMessage({
                    message: {
                      id: signalRMessage.message.id,
                      messageType: DashboardSignalRMessageType.Site,
                    } as DashboardSignalRMessageSite,
                  });
                case EventGrid.EVENT_WEB_WORKORDERS_UPDATED:
                  return DashboardActions.signalRMessage({
                    message: {
                      id: signalRMessage.message.id,
                      messageType: DashboardSignalRMessageType.WorkOrder,
                    } as DashboardActions.DashboardSignalRMessageWorkOrder,
                  });
                case EventGrid.EVENT_WEB_EVENTS_CREATED:
                case EventGrid.EVENT_WEB_EVENTS_UPDATED:
                case EventGrid.EVENT_WEB_EVENTS_DELETED:
                case EventGrid.EVENT_WEB_EVENTS_RESTORED:
                  const eventMessage = signalRMessage.message as EventGrid.EventGridEventResource;
                  return DashboardActions.signalRMessage({
                    message: {
                      id: eventMessage.id,
                      callId: eventMessage.callId,
                      end: eventMessage.end,
                      start: eventMessage.start,
                      removed: eventMessage.removed,
                      messageType: DashboardSignalRMessageType.Event,
                    } as DashboardActions.DashboardSignalRMessageEvent,
                  });
              }

              return DashboardActions.noop();
            })
          );
        }

        return of(DashboardActions.noop());
      })
    )
  );

  evictOldEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.evictOldEvents),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DashboardSelectors.requestedDate$)),
            this.store.pipe(select(DashboardSelectors.loadedDates$)),
            this.store.pipe(select(DashboardSelectors.rawScheduleEvents$))
          )
        )
      ),
      map(([action, requestedDate, loadedDates, rawCheduleEvents]) => {
        const requestedMoment = requestedDate;
        const fiveMinutesAgo = addMinutes(new Date(), -DashboardSelectors.DASHBOARD_EVICT_MINUTES);
        const toRemoveLoadedDates = loadedDates.filter(
          m => isBefore(m.lastUse, fiveMinutesAgo) && m.date.getTime() !== requestedMoment.getTime()
        );
        let toRemoveloadedMoments = loadedDates.map(m => m.date);

        // We never delete our requested date.
        if (requestedDate) {
          toRemoveloadedMoments = toRemoveloadedMoments.filter(m => m.getTime() !== requestedDate.getTime());
        }

        const toRemoveScheduleEvents = rawCheduleEvents.filter(scheduleEvent =>
          toRemoveloadedMoments.some(loadedMoment =>
            isWithinInterval(loadedMoment, {
              start: startOfDay(scheduleEvent.start),
              end: endOfDay(scheduleEvent.end),
            })
          )
        );

        return DashboardActions.trackEviction({
          loadedDates: toRemoveLoadedDates.length,
          scheduleEvents: toRemoveScheduleEvents.length,
        });
      })
    )
  );

  trackEviction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.trackEviction),
        map(action => {
          if (action.loadedDates || action.scheduleEvents) {
            this.appInsightService.trackEvent('Dashboard Eviction', {
              'Loaded Dates': action.loadedDates,
              'Schedule Events': action.scheduleEvents,
            });
          }
        })
      ),
    { dispatch: false }
  );

  private readonly destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
