import { Component, input } from '@angular/core';
import { PhonePipe } from '@pipes/phone.pipe';

@Component({
  template: '<span class="phone">{{ phone() | phone }}</span>@if (label()) {<span class="label">({{ label() }})</span>}',
  imports: [PhonePipe],
  standalone: true,
})
export class TableDynamicNodePhoneComponent {
  readonly phone = input<string>('');
  readonly label = input<string | undefined>();
}
