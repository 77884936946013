// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-deepest-emphasis: \$color-deepest-emphasis;
  --color-default-text: \$color-default-text;
  --color-nav-line: \$color-nav-line;
  --color-nav-icon: \$color-nav-icon;
  --color-small-head: \$color-small-head;
  --color-left-nav-text: \$color-left-nav-text;
  --color-faded-text: \$color-faded-text;
  --color-icon: \$color-icon;
  --color-heading-bg: \$color-heading-bg;
  --color-background: \$color-background;
  --color-line: \$color-line;
  --color-card-hover: \$color-card-hover;
  --color-card: \$color-card;
  --color-darkest: \$color-darkest;
  --color-helper-text: \$color-helper-text;
  --color-tab-black: \$color-tab-black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:host {
  display: inline-block;
}

.tag {
  padding: 0 8px;
  border-radius: 24px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
.tag.gray {
  background-color: #697076;
}
.tag.green {
  background-color: #1f961e;
}
.tag.editable {
  background-color: #246ee9;
}
.tag.living {
  background: linear-gradient(-45deg, #fbdcdb, #e2d9ee, #f9cda2, #bed4f9, #b2dbb1, #a5d5a5, #1f961e, #1f961e, #1f961e);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  font-weight: 500;
}
.tag button {
  margin-left: -4px;
  padding: 0 4px;
  line-height: 24px;
  display: flex;
  background: none;
  border: 0;
}
.tag img {
  width: 12px;
  height: 12px;
  display: inline-block;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  20% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  65% {
    background-position: 100% 50%;
  }
  80% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
