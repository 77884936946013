import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { type ActionReducer, StoreModule } from '@ngrx/store';
import { saveReducerWithCode } from '@services/save-store/save.actions';
import { DashboardEffects } from './dashboard.effects';
import { reducer as dashboardReducer } from './dashboard.reducer';
import { DASHBOARD_STORE_KEY } from './dashboard.selectors';

export function reducer(r: ActionReducer<any>): ActionReducer<any> {
  return saveReducerWithCode(DASHBOARD_STORE_KEY)(r);
}

export const metaReducers = [reducer];

@NgModule({
  imports: [StoreModule.forFeature(DASHBOARD_STORE_KEY, dashboardReducer, { metaReducers }), EffectsModule.forFeature([DashboardEffects])],
})
export class DashboardStoreModule {}
