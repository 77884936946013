/// <reference types="@types/intercom-web" />

import { Injectable, type OnDestroy, inject } from '@angular/core';
import { ConfigService } from './config.service';

export interface IntercomUser {
  technicianId: number;
}

export type IntercomSettings = Intercom_.IntercomSettings & Partial<IntercomUser>;

@Injectable()
export class IntercomService implements OnDestroy {
  private readonly configService = inject(ConfigService);

  ngOnDestroy() {
    this.shutdown();
  }

  public boot(settings?: IntercomSettings) {
    const s: IntercomSettings = {
      ...settings,
      api_base: 'https://api-iam.intercom.io',
      horizontal_padding: 20,
      app_id: this.configService.config.intercom.appId,
    };

    window.Intercom('boot', s);
  }

  public update(settings?: IntercomSettings) {
    window.Intercom('update', settings);
  }

  public setWidgetActionable(actionable: boolean) {
    this.update({
      vertical_padding: actionable ? 60 : 0,
    });
  }

  public shutdown() {
    window.Intercom('shutdown');
  }
}
