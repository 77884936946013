import { Injectable } from '@angular/core';
import { type Observable, Subject } from 'rxjs';

@Injectable()
export class PubSubService {
  private eventEmitter: Record<string, Subject<any>> = {};

  public publish(eventName: string, context: any = null): void {
    if (this.eventEmitter[eventName]) {
      this.eventEmitter[eventName].next(context);
    }
  }

  public getEventEmitter(eventName: string): Observable<any> {
    return (this.eventEmitter[eventName] || (this.eventEmitter[eventName] = new Subject<any>())).asObservable();
  }
}
