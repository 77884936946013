import { Directive, ElementRef, inject } from '@angular/core';

@Directive({
  selector: '[templateRef]',
  exportAs: 'templateRef',
  standalone: true,
})
export class TemplateRefDirective {
  public readonly elementRef = inject(ElementRef);
}
