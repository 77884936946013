import { ChangeDetectionStrategy, Component, effect, inject, input, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '@controls/button/button.component';
import { PopoverHelpComponent } from '@dialogviews/popover-help.component';
import { DIALOG_SERVICE_IMPL, type PopoverRef } from '@models/dialog';

@Component({
  selector: 'wm-filter-button',
  templateUrl: 'filter-button.component.html',
  styleUrls: ['filter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, MatIconModule],
  host: {
    '[class.text-has-focus]': 'textHasFocus()',
    '[class.remove-has-focus]': 'removeIsFocused()',
  },
})
export class FilterButtonComponent {
  readonly dialog2 = inject(DIALOG_SERVICE_IMPL);

  readonly text = input.required<string>();
  readonly fontSet = input<string>();
  readonly matIcon = input<string>();
  readonly imageUrl = input<string>();
  readonly textClick = output<void>();
  readonly removeClick = output<void>();

  readonly textHasFocus = signal(false);

  readonly removeIsHovered = signal<HTMLElement | undefined>(undefined);
  readonly removeIsFocused = signal<HTMLElement | undefined>(undefined);
  private popoverRef: PopoverRef<any> | undefined;

  _ = effect(onCleanup => {
    const parent = this.removeIsHovered() ?? this.removeIsFocused();
    if (parent && !this.popoverRef) {
      this.popoverRef = PopoverHelpComponent.open(this.dialog2, parent, {
        text: 'Remove this filter',
      });
    }

    onCleanup(() => {
      if (this.popoverRef) {
        this.popoverRef.close();
        this.popoverRef = undefined;
      }
    });
  });

  removeFocusInAMoment(): void {
    setTimeout(() => {
      this.textHasFocus.set(false);
    });
  }
}
