import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { filesize } from 'filesize';

@Pipe({
  name: 'filesize',
  standalone: true,
})
@Injectable()
export class FilesizePipe implements PipeTransform {
  transform(size: number | null | undefined): string {
    // TODO: angular14
    return filesize(size ?? 0, { base: 2, standard: 'jedec' });
  }
}
