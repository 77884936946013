import { Injectable, inject } from '@angular/core';
import { type LeaderboardCategoryInformation } from '@models/leaderboard-models';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class LeaderboardCategoriesService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  list(): Observable<LeaderboardCategoryInformation[]> {
    return this.http.get<LeaderboardCategoryInformation[]>(this.url.leaderboardCategoryGetList);
  }

  get(id: Id): Observable<LeaderboardCategoryInformation> {
    return this.http.get<LeaderboardCategoryInformation>(this.url.leaderboardCategoryGet.replace('$0', id.toString()));
  }

  save(leaderboardCategory: LeaderboardCategoryInformation): Observable<{ id: Id }> {
    return this.http.post<{ id: Id }>(this.url.leaderboardCategoryPost, leaderboardCategory);
  }

  hide(id: Id): Observable<void> {
    return this.http.post<void>(this.url.leaderboardCategoryHide.replace('$0', id.toString()), null);
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.leaderboardCategoryRestore.replace('$0', id.toString()), null);
  }
}
