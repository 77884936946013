import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  transform(minutes: number, forceHours: boolean = false): string {
    const hours = minutes / 60;
    if (forceHours || parseInt(hours.toString(), 10) === hours) {
      if (hours === 1) {
        return '1 Hour';
      }

      return `${Math.round(hours * 100) / 100} Hours`;
    }

    return `${minutes} Minutes`;
  }
}
