import { Directive, ElementRef, inject, type OnInit } from '@angular/core';

@Directive({
  selector: '[remove-host]',
  standalone: true,
})
export class RemoveHostDirective implements OnInit {
  private readonly el = inject(ElementRef);

  // wait for the component to render completely
  ngOnInit(): void {
    const nativeElement: HTMLElement = this.el.nativeElement;
    const parentElement: HTMLElement | null = nativeElement.parentElement;

    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement?.insertBefore(nativeElement.firstChild, nativeElement);
    }

    // remove the empty element(the host)
    parentElement?.removeChild(nativeElement);
  }
}
