import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ApplicationCacheService, UpdateCategory } from '@services/application-cache.service';
import { PartItemsService } from '@services/live/part-items.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as PartItemDetailActions from './part-item-detail.actions';
import { type PartItemDetailState } from './part-item-detail.reducer';
import * as PartItemDetailSelectors from './part-item-detail.selectors';

@Injectable()
export class PartItemDetailEffects {
  private readonly store = inject(Store<PartItemDetailState>);
  private readonly actions$ = inject(Actions<PartItemDetailActions.Actions>);
  private readonly applicationCacheService = inject(ApplicationCacheService);
  private readonly partItemsService = inject(PartItemsService);

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartItemDetailActions.save),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(PartItemDetailSelectors.partItem$))))),
      mergeMap(([action, partItem]) => {
        if (partItem.created || partItem.updated) {
          return this.partItemsService.save(partItem).pipe(
            map(resource => PartItemDetailActions.savePartItemSuccess({ id: resource.id })),
            tap(_ => {
              this.applicationCacheService.clearCategory(UpdateCategory.PartItems);
            }),
            catchError(error => of(PartItemDetailActions.savePartItemFail({ error })))
          );
        }

        return of(PartItemDetailActions.savePartItemSuccess({ id: partItem.id }));
      })
    )
  );

  // Because the setCategoryIds and saveServiceRepairSave touches the same partItem
  // we put them in serie.
  saveServiceRepairSaveSuccess1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartItemDetailActions.saveServiceRepairSaveSuccess, PartItemDetailActions.noop1),
      map(_ => PartItemDetailActions.saveCategoryIds())
    )
  );

  savePartItemSuccess2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartItemDetailActions.savePartItemSuccess),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(PartItemDetailSelectors.serviceRepairs$))))),
      map(([action, serviceRepairs]) => {
        if (serviceRepairs.updated) {
          return PartItemDetailActions.saveServiceRepairSave();
        }

        return PartItemDetailActions.noop1();
      })
    )
  );

  saveCategoryIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartItemDetailActions.saveCategoryIds),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(PartItemDetailSelectors.partItemWithCategoryIds))))),
      mergeMap(([action, { partItem, categoryIds }]) => {
        if (categoryIds.updated) {
          return this.partItemsService.setPartItemCategories(partItem.id, categoryIds.ids).pipe(
            map(_ => PartItemDetailActions.saveCategoryIdsSuccess()),
            tap(_ => {
              this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairCategories);
            }),
            catchError(error => of(PartItemDetailActions.saveCategoryIdsFail({ error })))
          );
        }

        return of(PartItemDetailActions.noopWithCounting());
      })
    )
  );

  saveServiceRepairSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartItemDetailActions.saveServiceRepairSave),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(PartItemDetailSelectors.serviceRepairIdsWithParentId$))))),
      mergeMap(([action, { partItemId, serviceRepairIds }]) =>
        this.partItemsService
          .setPartItemServiceRepairs(
            partItemId,
            serviceRepairIds.map(m => m.id)
          )
          .pipe(
            map(newResource => PartItemDetailActions.saveServiceRepairSaveSuccess()),
            tap(_ => {
              this.applicationCacheService.clearCategory(UpdateCategory.ServiceRepairs);
            }),
            catchError(error => of(PartItemDetailActions.saveServiceRepairFail({ error })))
          )
      )
    )
  );
}
