import { Injectable, inject } from '@angular/core';
import { type CustomerInformation } from '@models/cards/customer-information';
import { type SiteInformation } from '@models/cards/site-information';
import { type WorkOrderForList } from '@models/cards/work-order-information';
import { StatusFilter } from '@models/filter-models';
import { type SearchResult as SR } from '@models/result';
import { SearchType } from '@models/search';
import { combineLatest, of, zip, type Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CustomersService, type CustomerSiteSearchResult } from './customers.service';
import { WorkOrdersService } from './work-orders.service';

@Injectable()
export class SearchService {
  private readonly customersService = inject(CustomersService);
  private readonly workOrdersService = inject(WorkOrdersService);

  setupSearch(
    terms: Observable<string>,
    category: Observable<SearchType>,
    debounceDuration: number,
    withHighlight: boolean
  ): Observable<SearchResult> {
    return combineLatest([terms.pipe(debounceTime(debounceDuration)).pipe(distinctUntilChanged()), category]).pipe(
      switchMap(options => this.rawSearch(options[0], options[1], withHighlight))
    );
  }

  private rawSearch(term: string, category: SearchType, withHighlight: boolean): Observable<SearchResult> {
    return zip(
      category === SearchType.All || category === SearchType.Customers || category === SearchType.Sites
        ? this.customersService.searchWithSites(term, null, 0, StatusFilter.Visible, withHighlight)
        : of({} as CustomerSiteSearchResult),
      category === SearchType.All || category === SearchType.WorkOrders
        ? this.workOrdersService.search(term, null, null, null, null, 0, true)
        : of([]),
      (a: CustomerSiteSearchResult, b: SR<WorkOrderForList>[]) => {
        const customers = category === SearchType.All || category === SearchType.Customers ? a.customers : [];
        const sites = category === SearchType.All || category === SearchType.Sites ? a.sites : [];
        const workorders = b;
        return {
          customers,
          sites,
          workorders,
        } as SearchResult;
      }
    );
  }
}

export interface SearchResult {
  customers: SR<CustomerInformation>[];
  sites: SR<SiteInformation>[];
  workorders: SR<WorkOrderForList>[];
}
