import { Injectable, Pipe, type PipeTransform } from '@angular/core';
import { type FilterInformation } from '@models/cards/filter-information';

@Pipe({
  name: 'filterInfo',
  standalone: true,
})
@Injectable()
export class FilterInfoPipe implements PipeTransform {
  transform(filter: FilterInformation): string {
    if (!filter) {
      return '';
    }

    return '#' + (filter.localId || '').toString();
  }
}
