import { Injectable, inject } from '@angular/core';
import {
  type AgreementSiteSystemInformation,
  type AgreementSiteSystemInformationForList,
  type AgreementSiteSystemViewData,
} from '@models/cards/agreement-site-system-information';
import {
  type Filter2,
  type SupportedColumns,
  type SupportedFilters,
  type TableQueryResult,
  type TableSegment,
  type Filter,
  type PagedResult,
} from '@models/filter-models';
import { type Resource } from '@models/resource';
import {
  type CancelSubscriptionInput,
  type CancelSubscriptionResult,
  type SetupSubscriptionInput,
  type SetupSubscriptionResult,
  type SubscriptionViewData,
} from '@models/subscriptions';
import { type ExportToFileResponse } from '@services/pager.service';
import { allowRetries } from '@utility/angular';
import { type Observable } from 'rxjs';
import { HttpClientService, type ExtraOptionsJson } from '../http-client.service';
import { UrlService } from '../url.service';
import {
  type allSupportedAgreementSiteSystemColumns,
  type supportedAgreementSiteSystemFilterTypes,
} from '@models/filter-agreement-site-system-models';

export type PossibleFilter = SupportedFilters<typeof supportedAgreementSiteSystemFilterTypes>;
export type PossibleColumns = SupportedColumns<typeof allSupportedAgreementSiteSystemColumns>;

@Injectable()
export class AgreementSiteSystemsService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  list(filter: Filter<any>): Observable<PagedResult<AgreementSiteSystemInformationForList>> {
    return this.http.post<PagedResult<AgreementSiteSystemInformationForList>>(this.url.agreementSiteSystemList, filter, allowRetries());
  }

  tableList(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<TableQueryResult<AgreementSiteSystemInformationForList>> {
    return this.http.post<TableQueryResult<AgreementSiteSystemInformationForList>>(
      this.url.agreementSiteSystemTableList,
      filter,
      allowRetries()
    );
  }

  exportToFile(filter: Filter2<PossibleFilter, PossibleColumns>): Observable<ExportToFileResponse> {
    return this.http.post<ExportToFileResponse>(this.url.agreementSiteSystemExport, filter);
  }

  getTableSegments(): Observable<TableSegment<PossibleFilter, PossibleColumns>[]> {
    return this.http.get<TableSegment<PossibleFilter, PossibleColumns>[]>(this.url.agreementSiteSystemGetTableSegments);
  }

  saveTableSegment(tableSegment: TableSegment<PossibleFilter, PossibleColumns>): Observable<Id> {
    return this.http.post<Id>(this.url.agreementSiteSystemSaveTableSegment, tableSegment, allowRetries());
  }

  deleteTableSegment(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.agreementSiteSystemDeleteTableSegment.replace('$0', id.toString()));
  }

  viewData(id: Id): Observable<AgreementSiteSystemViewData> {
    return this.http.get<AgreementSiteSystemViewData>(this.url.agreementSiteSystemViewData.replace('$0', id.toString()));
  }

  get(id: Id): Observable<AgreementSiteSystemInformation> {
    return this.http.get<AgreementSiteSystemInformation>(this.url.agreementSiteSystemSingle.replace('$0', id.toString()));
  }

  /**
   * Saves an agreement site system.
   *
   * @param agreementSiteSystemInformation AgreementSiteSystemInformation
   * @returns The AgreementSiteSystem.Id
   */
  save(agreementSiteSystemInformation: AgreementSiteSystemInformation): Observable<Resource> {
    return this.http.post<Resource>(this.url.agreementSiteSystemSave, agreementSiteSystemInformation);
  }

  patch(id: Id, obj: Partial<AgreementSiteSystemInformation>): Observable<void> {
    obj = this.http.removeProperties(obj, 'id', 'agreementId', 'workOrderId', 'siteSystemId', 'hidden');
    return this.http.patch<void>(this.url.agreementSiteSystemPatch.replace('$0', id.toString()), obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.agreementSiteSystemDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.agreementSiteSystemRestore.replace('$0', id.toString()), null);
  }

  toggleProcess(id: Id, processed: boolean): Observable<boolean> {
    return this.http.post<boolean>(this.url.agreementSiteSystemToggleProcess.replace('$0', id.toString()), { input: processed });
  }

  getSubscriptionViewData(id: Id): Observable<SubscriptionViewData> {
    return this.http.get<SubscriptionViewData>(this.url.agreementSiteSystemGetSubscriptionViewData.replace('$0', id.toString()));
  }

  setupSubscription(id: Id, input: SetupSubscriptionInput, extraOptions?: ExtraOptionsJson): Observable<SetupSubscriptionResult> {
    return this.http.post<SetupSubscriptionResult>(
      this.url.agreementSiteSystemSetupSubscription.replace('$0', id.toString()),
      input,
      extraOptions
    );
  }

  cancelSubscription(id: Id, input: CancelSubscriptionInput, extraOptions?: ExtraOptionsJson): Observable<CancelSubscriptionResult> {
    return this.http.post<CancelSubscriptionResult>(
      this.url.agreementSiteSystemCancelSubscription.replace('$0', id.toString()),
      input,
      extraOptions
    );
  }
}
