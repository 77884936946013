import { Injectable, inject } from '@angular/core';
import { SubscriptionInterval, type AgreementInformation } from '@models/cards/agreement-information';
import { type AgreementSiteSystemInformation } from '@models/cards/agreement-site-system-information';
import { type NonStockItemInformation } from '@models/cards/non-stock-item-information';
import { type PaymentInformation } from '@models/cards/payment-information';
import { type WorkOrderAgreementInformation } from '@models/cards/work-order-agreement-information';
import { type WorkOrderDetailInformation } from '@models/cards/work-order-detail-information';
import { type WorkOrderRepairPartInformation } from '@models/cards/work-order-repair-part-information';
import { type ZoneInformation } from '@models/cards/zone-information';
import { type ContractorLaborPricing } from '@models/contractor-labor-pricing';
import { type ContractorMarkupPricing } from '@models/contractor-markup-pricing';
import { type ContractorServiceRepairOverride } from '@models/contractor-service-repair-override';
import { type PartItemInformation, type ServiceRepairInformation } from '@models/price-book-models';
import {
  type LineItemDetail,
  type LineItemDiscount,
  type LineItemRebate,
  type LineItemSubDetail,
  type PriceSnapshot,
  type TaxEntry,
  type TaxItemEntry,
} from '@models/pricing-models';
import { toMoney } from '@utility/decimal';
import { Decimal } from 'decimal.js';
import { combineLatest, map, type Observable } from 'rxjs';
import { ContractorSettingsService } from './live/contractor-settings.service';
import { StaticDataService } from './static-data.service';

export interface WorkOrderDetailPricing {
  price: Money | null;
  laborTime: number | null;
  contractorServiceRepairOverrides: ContractorServiceRepairOverride[] | null;
}

export interface PriceAmounts {
  amount: Money;
  discountAmount: Money | null;
}

export interface WorkOrderDetailPricingData {
  siteSystemId: Id | null;
  propertyTypeId: Id;
  rateTypeId: Id;
  agreementSiteSystems: AgreementSiteSystemInformation[];
  workOrderAgreements: WorkOrderAgreementInformation[];
  agreements: AgreementInformation[];
  serviceRepairs: ServiceRepairInformation[];
  nonStockItems: NonStockItemInformation[];
  workOrderDetails: WorkOrderDetailInformation[];
  laborPricings: ContractorLaborPricing[];
}

export interface WorkOrderRepairPartPricingData {
  siteSystemId: Id | null;
  agreementSiteSystems: AgreementSiteSystemInformation[];
  workOrderAgreements: WorkOrderAgreementInformation[];
  agreements: AgreementInformation[];
  partItems: PartItemInformation[];
  workOrderRepairParts: WorkOrderRepairPartInformation[];
}

@Injectable()
export class PriceService {
  private readonly contractorSettingsService = inject(ContractorSettingsService);
  private readonly staticDataService = inject(StaticDataService);

  computeDetailAmounts = (
    // If true, set the amount from the service repair if it exists. If false, keep the current WOD amount.
    setAmounts: boolean,
    // If true, recompute the discount amount. If false, keep the current WOD discount amount.
    setDiscountAmounts: boolean,
    workOrderDetail: WorkOrderDetailInformation,
    defaultWorkOrderDetail: WorkOrderDetailInformation | null,
    {
      siteSystemId,
      agreementSiteSystems,
      agreements,
      serviceRepairs,
      workOrderAgreements,
      nonStockItems,
      propertyTypeId,
      rateTypeId,
      laborPricings,
      workOrderDetails,
    }: WorkOrderDetailPricingData
  ): PriceAmounts => {
    const serviceRepair = serviceRepairs.find(x => x.id === workOrderDetail.serviceRepairId);
    const nonStockItem = nonStockItems.find(x => x.id === workOrderDetail.nonStockItemId);

    defaultWorkOrderDetail ??= workOrderDetails.find(x => x.id === workOrderDetail.id) ?? workOrderDetail;

    const discountDecimal = this.getDiscountDecimal(siteSystemId, agreementSiteSystems, workOrderAgreements, agreements);
    const unitPrice = toMoney(new Decimal(defaultWorkOrderDetail.amount).dividedBy(defaultWorkOrderDetail.quantity));
    const pricing: WorkOrderDetailPricing = serviceRepair ?? {
      price: nonStockItem?.retailPrice ?? unitPrice,
      laborTime: null,
      contractorServiceRepairOverrides: null,
    };

    const getAmounts = (pricing: WorkOrderDetailPricing): PriceAmounts =>
      this.getWorkOrderDetailAmounts(propertyTypeId, rateTypeId, pricing, workOrderDetail.quantity, discountDecimal, laborPricings);

    const amount = setAmounts ? getAmounts(pricing).amount : toMoney(new Decimal(unitPrice).times(workOrderDetail.quantity));

    let discountAmount: Money | null = null;

    if (setDiscountAmounts) {
      discountAmount = getAmounts(setAmounts ? pricing : { ...pricing, price: unitPrice }).discountAmount;
    } else if (defaultWorkOrderDetail.discountAmount !== null) {
      discountAmount = toMoney(
        new Decimal(defaultWorkOrderDetail.discountAmount).dividedBy(defaultWorkOrderDetail.quantity).times(workOrderDetail.quantity)
      );
    }

    return { amount, discountAmount };
  };

  computeRepairAmounts = (
    // If true, set the amount from the part item if it exists. If false, keep the current WOR amount.
    setAmounts: boolean,
    // If true, recompute the discount amount. If false, keep the current WOR discount amount.
    setDiscountAmounts: boolean,
    workOrderRepairPart: WorkOrderRepairPartInformation,
    defaultWorkOrderRepairPart: WorkOrderRepairPartInformation | null,
    { siteSystemId, agreementSiteSystems, agreements, workOrderAgreements, partItems, workOrderRepairParts }: WorkOrderRepairPartPricingData
  ): PriceAmounts => {
    const partItem = partItems.find(y => y.id === workOrderRepairPart.partItemId);
    defaultWorkOrderRepairPart ??= workOrderRepairParts.find(x => x.id === workOrderRepairPart.id) ?? workOrderRepairPart;

    const discountDecimal = this.getDiscountDecimal(siteSystemId, agreementSiteSystems, workOrderAgreements, agreements);
    const unitPrice = toMoney(new Decimal(defaultWorkOrderRepairPart.amount).dividedBy(defaultWorkOrderRepairPart.quantity));
    const price = partItem ? (partItem.retailPrice === null ? partItem.computedPrice : partItem.retailPrice) || 0 : unitPrice;

    const getAmounts = (price: Money): PriceAmounts =>
      this.getWorkOrderRepairPartAmounts(price, workOrderRepairPart.quantity, discountDecimal);

    const amount = setAmounts ? getAmounts(price).amount : toMoney(new Decimal(unitPrice).times(workOrderRepairPart.quantity));

    let discountAmount: Money | null = null;

    if (setDiscountAmounts) {
      discountAmount = getAmounts(setAmounts ? price : unitPrice).discountAmount;
    } else if (defaultWorkOrderRepairPart.discountAmount !== null) {
      discountAmount = toMoney(
        new Decimal(defaultWorkOrderRepairPart.discountAmount ?? 0)
          .dividedBy(defaultWorkOrderRepairPart.quantity)
          .times(workOrderRepairPart.quantity)
      );
    }

    return { amount, discountAmount };
  };

  getWorkOrderDetailAmounts = (
    propertyTypeId: Id,
    rateTypeId: Id,
    pricing: WorkOrderDetailPricing,
    quantity: number,
    discountDecimal: Fraction,
    laborPricings: ContractorLaborPricing[]
  ): PriceAmounts => {
    const nonAgreementPrice = this.getServiceRepairNonAgreementPrice(pricing, propertyTypeId, rateTypeId, laborPricings);
    const agreementPrice = this.getServiceRepairAgreementPrice(pricing, propertyTypeId, rateTypeId, discountDecimal, laborPricings);
    const amount = toMoney(new Decimal(quantity).times(nonAgreementPrice));
    const discountAmount = discountDecimal > 0 ? toMoney(new Decimal(quantity).times(agreementPrice)) : null;

    return { amount, discountAmount };
  };

  getWorkOrderRepairPartAmounts = (price: Money, quantity: number, discountDecimal: Fraction): PriceAmounts => {
    const ratio = new Decimal(1).minus(new Decimal(discountDecimal));
    const amount = toMoney(new Decimal(price).times(quantity));
    const discountAmount = discountDecimal > 0 ? toMoney(new Decimal(price).times(quantity).times(ratio)) : null;

    return { amount, discountAmount };
  };

  getBestAgreement = (
    siteSystemId: Id | null,
    agreementSiteSystems: AgreementSiteSystemInformation[],
    workOrderAgreements: WorkOrderAgreementInformation[],
    agreements: AgreementInformation[]
  ): AgreementInformation | null => {
    const activeAgreementData = workOrderAgreements
      .filter(x => !x.hidden && x.accepted)
      .map(workOrderAgreement => ({
        workOrderAgreement,
        agreementSiteSystem: agreementSiteSystems.find(n => n.id === workOrderAgreement.agreementSiteSystemId),
      }))
      .filter(
        ({ workOrderAgreement, agreementSiteSystem }) =>
          (!agreementSiteSystem && workOrderAgreement.siteSystemId === siteSystemId) ||
          (agreementSiteSystem && !agreementSiteSystem.hidden && agreementSiteSystem.siteSystemId === siteSystemId)
      );

    const activeAgreements = activeAgreementData
      .map(({ workOrderAgreement, agreementSiteSystem }) =>
        agreements.find(a => a.id === (workOrderAgreement.agreementId ?? agreementSiteSystem?.agreementId))
      )
      .filter(a => !!a);

    let highestPercentageAgreement: AgreementInformation | null = null;
    for (const agreement of activeAgreements) {
      if (!highestPercentageAgreement || agreement.discountPercentage > highestPercentageAgreement.discountPercentage) {
        highestPercentageAgreement = agreement;
      }
    }

    return highestPercentageAgreement;
  };

  getDiscountPercentage = (
    siteSystemId: Id | null,
    agreementSiteSystems: AgreementSiteSystemInformation[],
    workOrderAgreements: WorkOrderAgreementInformation[],
    agreements: AgreementInformation[]
  ): Percentage | null => {
    const agreement = this.getBestAgreement(siteSystemId, agreementSiteSystems, workOrderAgreements, agreements);
    if (agreement) {
      return agreement.discountPercentage;
    }

    return null;
  };

  getDiscountDecimal = (
    siteSystemId: Id | null,
    agreementSiteSystems: AgreementSiteSystemInformation[],
    workOrderAgreements: WorkOrderAgreementInformation[],
    agreements: AgreementInformation[]
  ): Fraction => {
    // We go through all the work order repair and parts and we assign a new value to the discount percentage
    const discountPercentage = this.getDiscountPercentage(siteSystemId, agreementSiteSystems, workOrderAgreements, agreements) ?? 0;
    const discountDecimal = new Decimal(discountPercentage).dividedBy(100).toNumber();
    return discountDecimal;
  };

  getNonStockRetailPrice = (
    partCost: Money,
    rateTypeId: Id,
    propertyTypeId: Id,
    laborTime: number | null,
    markupPricings: ContractorMarkupPricing[],
    laborPricings: ContractorLaborPricing[]
  ): Money => {
    const partRetail = this.calculateMarkup(partCost, 1, markupPricings);
    const laborRetail = this.getServiceRepairPricing(
      { laborTime, price: null, contractorServiceRepairOverrides: null },
      propertyTypeId,
      rateTypeId,
      0,
      laborPricings
    );

    return toMoney(new Decimal(partRetail).plus(laborRetail));
  };

  getNonStockRetailPrice$ = (partCost: Money, rateTypeId: Id, propertyTypeId: Id, laborTime: number | null): Observable<Money> => {
    return combineLatest([this.contractorSettingsService.getMarkups(), this.staticDataService.getContractorLaborPricings()]).pipe(
      map(([markupPricings, laborPricings]) =>
        this.getNonStockRetailPrice(partCost, rateTypeId, propertyTypeId, laborTime, markupPricings, laborPricings)
      )
    );
  };

  calculateMarkup(price: Money, rate: Fraction, markupPricings: ContractorMarkupPricing[]): Money {
    const priceAfterRate = toMoney(new Decimal(price).times(rate));
    markupPricings = [...markupPricings].sort((a, b) => (a.lowerPrice < b.lowerPrice ? -1 : 1));

    for (const markup of markupPricings) {
      if (markup.lowerPrice <= priceAfterRate && price <= markup.upperPrice) {
        return toMoney(new Decimal(markup.markupMultiple).times(priceAfterRate));
      }
    }

    return toMoney(new Decimal(priceAfterRate));
  }

  calculateMarkup$(price: Money, rate: Fraction): Observable<Money> {
    return this.contractorSettingsService.getMarkups().pipe(map(x => this.calculateMarkup(price, rate, x)));
  }

  getWorkOrderDetailPrice(workOrderDetail: LineItemDetail, workOrderRepairParts: LineItemSubDetail[], forceAmount: boolean): Money {
    return (workOrderRepairParts || []).reduce(
      (sum: Money, workOrderRepairPart: LineItemSubDetail) => {
        return new Decimal(sum).plus(new Decimal(this.getObjectPrice(workOrderRepairPart, forceAmount))).toNumber();
      },
      this.getObjectPrice(workOrderDetail, forceAmount)
    );
  }

  getObjectPrice(object: { discountAmount?: Money | null; amount: Money | null }, forceAmount: boolean): Money {
    // DiscountAmount could be 0. In this case, we can't go with falsy!
    if (object.discountAmount == null || forceAmount) {
      // Keep weak, if we enter negative values, DiscountAmount will be undefined.
      return parseFloat((object.amount || '0').toString()) || 0;
    }

    return parseFloat(object.discountAmount.toString()) || 0;
  }

  getStrikethroughPrice(
    workOrderDetail: LineItemDetail,
    workOrderRepairParts: LineItemSubDetail[],
    ignoreAccepted: boolean = false
  ): Money | null {
    // If it's not accepted, show the accurate price (discounted or not)
    if (!ignoreAccepted && workOrderDetail.accepted !== undefined && !workOrderDetail.accepted) {
      return this.getWorkOrderDetailPrice(workOrderDetail, workOrderRepairParts, false);
    }

    // If it is accepted, we will show the strikethrough price only if we have some discounts
    if (
      (workOrderDetail.discountAmount != null && workOrderDetail.discountAmount >= 0) ||
      (workOrderRepairParts || []).some(
        workOrderRepairPart => workOrderRepairPart.discountAmount != null && workOrderRepairPart.discountAmount > 0
      )
    ) {
      return this.getWorkOrderDetailPrice(workOrderDetail, workOrderRepairParts, true);
    }

    return null;
  }

  getCurrentPrice(workOrderDetail: LineItemDetail, workOrderRepairParts: LineItemSubDetail[], ignoreAccepted: boolean = false): Money {
    if (ignoreAccepted || workOrderDetail.accepted === undefined || workOrderDetail.accepted) {
      return this.getWorkOrderDetailPrice(workOrderDetail, workOrderRepairParts, false);
    }

    return 0;
  }

  getServiceRepairAgreementPrice(
    pricing: WorkOrderDetailPricing,
    propertyTypeId: Id,
    rateTypeId: Id,
    agreementDiscountPercentage: number,
    laborPricings: ContractorLaborPricing[]
  ): Money {
    return this.getPrice(pricing, propertyTypeId, rateTypeId, true, agreementDiscountPercentage, laborPricings);
  }

  getServiceRepairAgreementPrice$(
    pricing: WorkOrderDetailPricing,
    propertyTypeId: Id,
    rateTypeId: Id,
    agreementDiscountPercentage: number
  ): Observable<Money> {
    return this.staticDataService
      .getContractorLaborPricings()
      .pipe(map(x => this.getPrice(pricing, propertyTypeId, rateTypeId, true, agreementDiscountPercentage, x)));
  }

  getServiceRepairNonAgreementPrice(
    pricing: WorkOrderDetailPricing,
    propertyTypeId: Id,
    rateTypeId: Id,
    laborPricings: ContractorLaborPricing[]
  ): Money {
    return this.getPrice(pricing, propertyTypeId, rateTypeId, false, 0, laborPricings);
  }

  getServiceRepairNonAgreementPrice$(pricing: WorkOrderDetailPricing, propertyTypeId: Id, rateTypeId: Id): Observable<Money> {
    return this.staticDataService
      .getContractorLaborPricings()
      .pipe(map(x => this.getPrice(pricing, propertyTypeId, rateTypeId, false, 0, x)));
  }

  getAgreementPrice(agreement: AgreementInformation | null, zone: ZoneInformation | null, zoneRoundUp: boolean): Money {
    if (agreement) {
      if (agreement.interval) {
        return this.getRecurringPrice(agreement, zone, zoneRoundUp);
      }

      const zonePrice = zone?.price ?? 0;
      return toMoney(new Decimal(agreement.price).plus(new Decimal(zonePrice).times(agreement.visitsOwed)));
    }

    return 0;
  }

  getRecurringPrice(agreement: AgreementInformation, zone: ZoneInformation | null, zoneRoundUp: boolean): Money {
    if (!agreement.interval || !agreement.intervalCount) {
      throw new Error('You cannot use this method');
    }

    let zoneCost = new Decimal(0);
    let roundUpAllowed = false;
    if (zone) {
      let numberOfPaymentsInDuration = new Decimal(agreement.monthsValidFor).dividedBy(agreement.intervalCount);
      switch (agreement.interval) {
        case SubscriptionInterval.Year:
          numberOfPaymentsInDuration = numberOfPaymentsInDuration.dividedBy(12);
          break;
        case SubscriptionInterval.Month:
          break;
        case SubscriptionInterval.Week:
          numberOfPaymentsInDuration = numberOfPaymentsInDuration.times(4.345);
          break;
        case SubscriptionInterval.Day:
          numberOfPaymentsInDuration = numberOfPaymentsInDuration.times(30.416);
          break;
      }

      zoneCost = new Decimal(zone.price).times(agreement.visitsOwed).dividedBy(numberOfPaymentsInDuration);
      roundUpAllowed = true;
    }

    let finalPrice = new Decimal(agreement.price).plus(zoneCost);
    if (roundUpAllowed && zoneRoundUp) {
      finalPrice = finalPrice.ceil();
    }

    return toMoney(finalPrice);
  }

  private pricingPredicate(
    propertyTypeId: Id | null,
    rateTypeId: Id | null,
    withAgreement: boolean
  ): (contractorServiceRepairOverride: ContractorServiceRepairOverride) => boolean {
    return (contractorServiceRepairOverride: ContractorServiceRepairOverride) => {
      return (
        contractorServiceRepairOverride.propertyTypeId === propertyTypeId &&
        contractorServiceRepairOverride.rateTypeId === rateTypeId &&
        contractorServiceRepairOverride.withAgreement === withAgreement
      );
    };
  }

  private getPrice(
    pricing: WorkOrderDetailPricing,
    propertyTypeId: Id,
    rateTypeId: Id,
    agreementPrice: boolean,
    agreementDiscountPercentage: number,
    laborPricings: ContractorLaborPricing[]
  ): Money {
    const finalOverride = this.getServiceRepairPricingOverride(
      pricing.contractorServiceRepairOverrides ?? [],
      propertyTypeId,
      rateTypeId,
      agreementPrice
    );

    if (finalOverride) {
      return this.getServiceRepairPricing(
        { laborTime: finalOverride.laborTime, price: finalOverride.price, contractorServiceRepairOverrides: null },
        propertyTypeId,
        rateTypeId,
        !finalOverride.withAgreement ? agreementDiscountPercentage : 0,
        laborPricings
      );
    }

    return this.getServiceRepairPricing(pricing, propertyTypeId, rateTypeId, agreementDiscountPercentage, laborPricings);
  }

  getServiceRepairPricingOverride(
    overrides: ContractorServiceRepairOverride[],
    propertyTypeId: Id,
    rateTypeId: Id,
    agreementPrice: boolean
  ): ContractorServiceRepairOverride | undefined {
    const override1 = overrides.find(this.pricingPredicate(propertyTypeId, rateTypeId, agreementPrice));
    const override2 = overrides.find(this.pricingPredicate(propertyTypeId, rateTypeId, !agreementPrice));
    const override3 = overrides.find(this.pricingPredicate(null, null, false));

    return override1 ?? override2 ?? override3;
  }

  private getServiceRepairPricing(
    pricing: WorkOrderDetailPricing,
    propertyTypeId: Id,
    rateTypeId: Id,
    agreementDiscountPercentage: number,
    laborPricings: ContractorLaborPricing[]
  ): Money {
    let price = new Decimal(0.0);
    if (pricing.price) {
      price = new Decimal(pricing.price);
    } else {
      const contractorLaborPricing = laborPricings.find(m => m.propertyTypeId === propertyTypeId && m.rateTypeId === rateTypeId);
      if (contractorLaborPricing) {
        price = new Decimal(
          toMoney(new Decimal(contractorLaborPricing.rate).times(new Decimal(pricing.laborTime || 0).dividedBy(new Decimal(60))))
        );
      }
    }

    if (agreementDiscountPercentage) {
      price = price.times(new Decimal(1).minus(new Decimal(agreementDiscountPercentage)));
    }

    return toMoney(price);
  }

  getPriceSnapshot(
    workOrderDetails: LineItemDetail[],
    workOrderRepairParts: LineItemSubDetail[],
    workOrderAgreements: WorkOrderAgreementInformation[],
    payments: PaymentInformation[],
    workOrderDiscounts: LineItemDiscount[],
    workOrderRebates: LineItemRebate[],
    taxItemEntries: TaxItemEntry[]
  ): PriceSnapshot {
    // Force to have arrays.
    workOrderDetails = workOrderDetails || [];
    workOrderRepairParts = workOrderRepairParts || [];
    workOrderAgreements = workOrderAgreements || [];
    payments = payments || [];
    workOrderDiscounts = workOrderDiscounts || [];
    workOrderRebates = workOrderRebates || [];
    taxItemEntries = taxItemEntries || [];

    const subtotalBeforeDiscounts = this.getSubtotalBeforeDiscount(workOrderDetails, workOrderRepairParts, workOrderAgreements);
    const beforeTaxDiscounts = workOrderDiscounts.filter(m => !m.afterTax);
    const beforeTaxRebates = workOrderRebates.filter(m => !m.afterTax);
    const afterTaxDiscounts = workOrderDiscounts.filter(m => m.afterTax);
    const afterTaxRebates = workOrderRebates.filter(m => m.afterTax);
    const beforeTaxDiscountCalulation = this.getDiscount(beforeTaxDiscounts, subtotalBeforeDiscounts);
    const beforeTaxRebateCalculation = this.getRebate(beforeTaxRebates);
    const subtotalAfterDiscounts = toMoney(
      new Decimal(subtotalBeforeDiscounts).minus(beforeTaxDiscountCalulation.money).minus(beforeTaxRebateCalculation)
    );
    const savings = this.getSavings(workOrderDetails, workOrderRepairParts);
    const taxes = this.getTaxes(taxItemEntries, subtotalAfterDiscounts);
    const taxSum = toMoney(taxes.reduce((previousValue, taxEntry) => previousValue.plus(taxEntry.taxAmount), new Decimal(0)));
    const totalAfterTax = toMoney(new Decimal(subtotalAfterDiscounts).plus(taxSum));
    const afterTaxDiscountCalculation = this.getDiscount(afterTaxDiscounts, totalAfterTax);
    const afterTaxRebateCalculation = this.getRebate(afterTaxRebates);
    const totalAfterDiscounts = toMoney(
      new Decimal(totalAfterTax).minus(afterTaxDiscountCalculation.money).minus(afterTaxRebateCalculation)
    );
    const paymentCalculation = this.getPayments(payments);
    const balanceOwed = toMoney(new Decimal(totalAfterDiscounts).minus(paymentCalculation));

    return {
      subtotalBeforeDiscounts,
      beforeTaxDiscount: beforeTaxDiscountCalulation.money,
      beforeTaxDiscountPercentage: beforeTaxDiscountCalulation.percentage,
      beforeTaxRebate: beforeTaxRebateCalculation,
      subtotalAfterDiscounts,
      savings,
      taxes,
      taxSum,
      totalAfterTax,
      afterTaxDiscount: afterTaxDiscountCalculation.money,
      afterTaxDiscountPercentage: afterTaxDiscountCalculation.percentage,
      afterTaxRebate: afterTaxRebateCalculation,
      totalAfterDiscounts,
      payment: paymentCalculation,
      balanceOwed,
    };
  }

  private getPayments(payments: PaymentInformation[]): Money {
    const value = payments.reduce((previousValue, payment) => {
      if (!payment.hidden && payment.valid) {
        try {
          return previousValue.plus(payment.amount);
        } catch {
          // We don't sum this one... you entered a wrong data.
        }
      }

      return previousValue;
    }, new Decimal(0));

    return toMoney(value);
  }

  private getTaxes(taxItemEntries: TaxItemEntry[], subtotalAfterDiscounts: Money): TaxEntry[] {
    return taxItemEntries.map(taxItemEntry => {
      let taxAmount: Money = 0;
      let taxPercentage: Percentage | null = null;

      if (taxItemEntry.taxOverride != null) {
        // Keep weak
        try {
          taxAmount = toMoney(new Decimal(taxItemEntry.taxOverride));
        } catch {}
      } else {
        let taxFraction: Fraction = 0;
        if (taxItemEntry.taxItemManual) {
          try {
            taxFraction = new Decimal(taxItemEntry.taxItemManual).dividedBy(100).toNumber();
          } catch {}
        } else {
          if (taxItemEntry.taxItem) {
            try {
              taxFraction = new Decimal(taxItemEntry.taxItem.rate).toNumber();
            } catch {}
          }
        }

        if (taxFraction) {
          taxAmount = toMoney(new Decimal(subtotalAfterDiscounts).times(taxFraction));
          taxPercentage = new Decimal(taxFraction).times(100).toNumber();
        }
      }

      return {
        taxAgency: taxItemEntry?.taxItem?.text,
        taxAmount,
        taxPercentage,
      } as TaxEntry;
    });
  }

  private getSavings(workOrderDetails: LineItemDetail[], workOrderRepairParts: LineItemSubDetail[]): Money {
    let value: Money = 0;

    if (workOrderDetails) {
      workOrderDetails.forEach(workOrderDetail => {
        if ((workOrderDetail.accepted === undefined || workOrderDetail.accepted) && !workOrderDetail.hidden) {
          const activeWorkOrderRepairParts = workOrderRepairParts?.filter(m => m.parentId === workOrderDetail.id);
          const strikethroughPrice = this.getStrikethroughPrice(workOrderDetail, activeWorkOrderRepairParts);

          if (strikethroughPrice !== null) {
            const currentPrice = this.getCurrentPrice(workOrderDetail, activeWorkOrderRepairParts);
            value = toMoney(new Decimal(value).plus(strikethroughPrice).minus(currentPrice));
          }
        }
      });
    }

    return value;
  }

  private getRebate(workOrderRebates: LineItemRebate[]): Money {
    let money = new Decimal(0);
    for (const rebate of workOrderRebates.filter(m => !m.hidden)) {
      money = money.plus(rebate.amount);
    }

    return toMoney(money);
  }

  private getDiscount(discounts: LineItemDiscount[], moneyUsedForPercentage: Money): { money: Money; percentage: Percentage | null } {
    let money = new Decimal(0);
    const percentage: (Percentage | null)[] = [];
    for (const discount of discounts.filter(m => !m.hidden)) {
      if (discount.amount) {
        money = money.plus(discount.amount);
        percentage.push(null);
      } else if (discount.percentage) {
        const moneyDiscount = toMoney(new Decimal(discount.percentage).dividedBy(100).times(moneyUsedForPercentage));
        money = money.plus(moneyDiscount);
        percentage.push(discount.percentage);
      }
    }

    const finalPercentage = percentage.length === 1 && percentage[0] ? percentage[0] : null;
    return { money: toMoney(money), percentage: finalPercentage };
  }

  private getSubtotalBeforeDiscount(
    workOrderDetails: LineItemDetail[],
    workOrderRepairParts: LineItemSubDetail[],
    workOrderAgreements: WorkOrderAgreementInformation[]
  ): Money {
    let value: Money = 0;
    let agreementPrice: Money = 0;

    if (workOrderDetails) {
      workOrderDetails.forEach(workOrderDetail => {
        if ((workOrderDetail.accepted === undefined || workOrderDetail.accepted) && !workOrderDetail.hidden) {
          const activeWorkOrderRepairParts = workOrderRepairParts?.filter(m => m.parentId === workOrderDetail.id && !m.hidden);
          const price = this.getWorkOrderDetailPrice(workOrderDetail, activeWorkOrderRepairParts, false);
          value = toMoney(new Decimal(value).plus(price));
        }
      });
    }

    if (workOrderAgreements) {
      workOrderAgreements.forEach(workOrderAgreement => {
        if (workOrderAgreement.accepted && !workOrderAgreement.hidden) {
          agreementPrice = toMoney(new Decimal(agreementPrice).plus(workOrderAgreement.amount));
        }
      });
    }

    return toMoney(new Decimal(value).plus(agreementPrice));
  }
}
