import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DIALOG_DATA_TYPED, type DialogConfirmData, dialogTypeToImage, type ConfirmButtons } from '@models/dialog';
import { SharedModule } from '@modules/shared.module';

export const injectionToken = DIALOG_DATA_TYPED<DialogConfirmData>();

@Component({
  templateUrl: 'dialog-confirm.component.html',
  styleUrls: ['dialog-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MatDialogModule],
})
export class DialogConfirmComponent {
  private readonly dialogRef = inject(MatDialogRef<DialogConfirmComponent, boolean>);
  readonly data = inject(injectionToken);

  get dialogImage(): string {
    if (this.data.type) {
      return dialogTypeToImage[this.data.type];
    }

    return '';
  }

  internalButtonClick(button: ConfirmButtons): void {
    button.click(this.dialogRef);
  }

  close(): void {
    this.dialogRef.close();
  }
}
