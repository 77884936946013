export enum QuickBooksSynchronizationStatus {
  None = 0,
  Pending = 1,
  Synchronized = 2,
  PendingDeletion = 3,
}

export enum QuickBooksVersion {
  None = 0,
  Desktop = 1,
  Online = 2,
}
