import { type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ConfigService } from '@services/config.service';

export const UrlTransformInterceptor: HttpInterceptorFn = (req, next, configService = inject(ConfigService)) => {
  const getUrl = (url: string) => {
    const apiUrl = configService.config?.apiUrl;

    if (apiUrl && (url.startsWith('/api/') || url.startsWith('/static/'))) {
      return apiUrl + url;
    }

    return url;
  };

  return next(
    req.clone({
      url: getUrl(req.url),
    })
  );
};

