import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DIALOG_DATA_TYPED, type Dialog2ServiceImpl } from '@models/dialog';
import { type HelpModelData, type HelpModelLink } from '@models/help';
import { SharedModule } from '@modules/shared.module';

const injectionToken = DIALOG_DATA_TYPED<HelpModelData>();

@Component({
  templateUrl: 'help-dialog.component.html',
  styleUrls: ['help-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MatDialogModule, MatIconModule],
})
export class HelpDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<HelpDialogComponent>);
  public readonly data = inject(injectionToken);

  cancel() {
    this.dialogRef.close();
  }

  getTarget(link: HelpModelLink) {
    if (this.isExternal(link) || this.isStorylane(link)) {
      return '_blank';
    }

    return '_self';
  }

  isExternal(link: HelpModelLink) {
    return link.type === 'external';
  }

  isStorylane(link: HelpModelLink) {
    return link.type === 'storylane';
  }

  static open(dialog: Dialog2ServiceImpl, data: HelpModelData) {
    dialog
      .open(HelpDialogComponent, {
        width: '300px',
        dialogData: {
          injectionToken,
          data,
        },
      })
      .afterClosed();
  }
}
