export const EVENT_IPAD_CALLS_STARTED = 'IPad.Calls.Started';
export const EVENT_WEB_CUSTOMERS_CREATED = 'Web.Customers.Created';
export const EVENT_WEB_CUSTOMERS_UPDATED = 'Web.Customers.Updated';
export const EVENT_WEB_CUSTOMERS_DELETED = 'Web.Customers.Deleted';
export const EVENT_WEB_CUSTOMERS_RESTORED = 'Web.Customers.Restored';
export const EVENT_WEB_SITES_CREATED = 'Web.Sites.Created';
export const EVENT_WEB_SITES_UPDATED = 'Web.Sites.Updated';
export const EVENT_WEB_SITES_DELETED = 'Web.Sites.Deleted';
export const EVENT_WEB_SITES_RESTORED = 'Web.Sites.Restored';
export const EVENT_WEB_EVENTS_CREATED = 'Web.Events.Created';
export const EVENT_WEB_EVENTS_UPDATED = 'Web.Events.Updated';
export const EVENT_WEB_EVENTS_DELETED = 'Web.Events.Deleted';
export const EVENT_WEB_EVENTS_RESTORED = 'Web.Events.Restored';
export const EVENT_WEB_WORKORDERS_CREATED = 'Web.WorkOrders.Created';
export const EVENT_WEB_WORKORDERS_UPDATED = 'Web.WorkOrders.Updated';
export const EVENT_WEB_WORKORDERS_DELETED = 'Web.WorkOrders.Deleted';
export const EVENT_WEB_WORKORDERS_RESTORED = 'Web.WorkOrders.Restored';
export const EVENT_WEB_WORKORDERS_TOTALUPDATED = 'Web.WorkOrders.TotalUpdated';

export enum EventGridType {
  ContractorResource = 1,
  EventResource = 2,
}

export interface EventGridMessageBase {
  type: EventGridType;
  contractorId: Id;
  technicianId: Id | null;
}

export interface EventGridContractorResource extends EventGridMessageBase {
  type: EventGridType.ContractorResource;
  id: Id;
}

export interface EventGridEventResource extends EventGridMessageBase {
  type: EventGridType.EventResource;
  id: Id;
  callId: Id | null;
  start: Date | null;
  end: Date | null;
  removed: boolean;
}

export type EventGridMessage = EventGridContractorResource | EventGridEventResource;

export interface EventGridSignalRMessage {
  eventType: string;
  message: EventGridMessage;
}
