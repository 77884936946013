import { Injectable, inject } from '@angular/core';
import { type FilterInformation } from '@models/cards/filter-information';
import { type Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';
import { UrlService } from '../url.service';

@Injectable()
export class FiltersService {
  private readonly http = inject(HttpClientService);
  private readonly url = inject(UrlService);

  get(id: Id): Observable<FilterInformation> {
    return this.http.get<FilterInformation>(this.url.filterSingle.replace('$0', id.toString()));
  }

  save(filterInformation: FilterInformation): Observable<{ id: Id }> {
    let obj = this.http.removeProperties(filterInformation, 'hidden');
    obj = this.http.removeFalsyProperties(obj, 'id');
    obj = this.http.setEmptyStringToNull(obj);
    this.http.trimStringProperties(obj);

    return this.http.post<{ id: Id }>(this.url.filterSave, obj);
  }

  hide(id: Id): Observable<void> {
    return this.http.delete<void>(this.url.filterDelete.replace('$0', id.toString()));
  }

  restore(id: Id): Observable<void> {
    return this.http.put<void>(this.url.filterRestore.replace('$0', id.toString()), null);
  }
}
