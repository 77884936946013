import { createFeatureSelector, createSelector } from '@ngrx/store';
import { type ServiceRepairDetailState } from './service-repair-detail.reducer';

export const SERVICE_REPAIR_DETAIL_STORE_KEY = 'serviceRepairDetail';

export const serviceRepairDetailStateSelector = createFeatureSelector<ServiceRepairDetailState>(SERVICE_REPAIR_DETAIL_STORE_KEY);

export const mode = createSelector(serviceRepairDetailStateSelector, state => state.edit);
export const serviceRepair$ = createSelector(serviceRepairDetailStateSelector, state => state.entities.serviceRepair);
export const categoryIds$ = createSelector(serviceRepairDetailStateSelector, state => state.entities.categoryIds);
export const partItemIds$ = createSelector(serviceRepairDetailStateSelector, state => state.entities.partItemIds);
export const partItems$ = createSelector(serviceRepairDetailStateSelector, state => state.entities.partItems);

export const cacheCorrelationId$ = createSelector(serviceRepairDetailStateSelector, state => state._cacheCorrelationId);
export const saveCorrelationId$ = createSelector(serviceRepairDetailStateSelector, state => state._correlationId);
export const error$ = createSelector(serviceRepairDetailStateSelector, state => state._error);

export const serviceRepairWithCategoryIds = createSelector(serviceRepair$, categoryIds$, (serviceRepair, categoryIds) => ({
  serviceRepair,
  categoryIds,
}));

export const partItemLines$ = createSelector(partItemIds$, partItemds => {
  return partItemds && partItemds.ids ? Object.values(partItemds.ids) : [];
});

export const partItemIdsWithParentId$ = createSelector(serviceRepair$, partItemIds$, (serviceRepair, partItemIds) => ({
  serviceRepairId: serviceRepair.id,
  partItemIds,
  priceBookId: serviceRepair.priceBookId,
}));
