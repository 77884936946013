import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withPreloading } from '@angular/router';
import { Dialog2Service } from '@dialogs/dialog2.service';
import { providers as dialogProviders } from '@dialogs/dialogs.providers';
import { DIALOG_SERVICE_IMPL } from '@models/dialog';
import { providers as dynamicCustomerFilterProviders } from '@models/filter-customer-models';
import { providers as dynamicDutyFilterProviders } from '@models/filter-duty-models';
import { providers as dynamicSalesProposalFilterProviders } from '@models/filter-sales-proposal-models';
import { providers as dynamicAgreementSiteSystemFilterProviders } from '@models/filter-agreement-site-system-models';
import { HELP_JSON_IMPL } from '@models/help';
import { providers as pipesProviders } from '@pipes/pipes.providers';
import { ConfigService } from '@services/config.service';
import { HelpJsonService } from '@services/help-json.service';
import { interceptors } from '@services/http/interceptors';
import { LOGGING_ERROR_HANDLER_PROVIDERS } from '@services/logging-error-handler.service';
import { SelectivePreloadingStrategyService } from '@services/selective-preloading-strategy.service';
import { providers as serviceProviders } from '@services/services.providers';
import { StickyModule } from '@services/sticky/sticky.module';
import { providers as appStoreProviders } from '@store/app-store.providers';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routing';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};

void bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom([BrowserAnimationsModule]),
    importProvidersFrom(StickyModule.forRoot()),
    ...serviceProviders,
    ...dialogProviders,
    ...pipesProviders,
    ...appStoreProviders,
    ...dynamicCustomerFilterProviders,
    ...dynamicDutyFilterProviders,
    ...dynamicSalesProposalFilterProviders,
    ...dynamicAgreementSiteSystemFilterProviders,
    ...LOGGING_ERROR_HANDLER_PROVIDERS,
    provideRouter(routes, withPreloading(SelectivePreloadingStrategyService)),
    { provide: DIALOG_SERVICE_IMPL, useExisting: Dialog2Service },
    { provide: HELP_JSON_IMPL, useExisting: HelpJsonService },

    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true,
    },
    provideHttpClient(withInterceptors(interceptors)),
  ],
});
