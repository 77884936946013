import { type Address } from '@models/address';
import { type PaymentGateway } from '@models/payments';
import { type SubscriptionSettings } from '@models/subscriptions';
import { type DutyInformation, Status as DutyStatus } from '../duty-models';
import { type Entity } from '../entity';
import { type StateResource } from '../resource';
import { type CallInformation } from './call-information';
import { type CustomerInformation } from './customer-information';
import { type PaymentInformation } from './payment-information';
import { type SiteInformation } from './site-information';
import { type SiteSystemInformation } from './site-system-information';
import { type WorkOrderAgreementInformation } from './work-order-agreement-information';
import { type WorkOrderInformationForList } from './work-order-information';
import { type ZoneInformation } from './zone-information';

export { DutyStatus };

export enum AgreementSiteSystemSetupStatus {
  Grandfathered = 0,
  StandBy = 1,
  ReadyForSetup = 2,
  Activated = 3,
}

export interface AgreementSiteSystemInformation extends StateResource {
  localId: Id;
  agreementId: Id;
  amount: Money;
  siteSystemId: Id;
  workOrderId: Id | null;
  setupStatus: AgreementSiteSystemSetupStatus;
  notes: string | null;
  createdDate: Date;
  endDate: Date | null;
  deadDate: Date | null;
  processed: boolean;
  processedDate: Date | null;
  paymentGateway: PaymentGateway | null;
  subscriptionExtra: string | null;
}

export interface AgreementVisit extends StateResource {
  status: DutyStatus;
  dueDate: Date | null;
  callId: Id | null;
}

export interface AgreementSiteSystemInformationForList {
  data: AgreementSiteSystemInformation;
  agreementVisits: AgreementVisit[];
  customerId: Id;
  siteId: Id;
  nextVisit: Date | null;
  visitsLeft: number;
  locations: string[];
  customerEntity: Entity;
  siteAddress: Address;
  siteSystemLocalId: Id;
  siteSystemType: string;
}

export interface AgreementSiteSystemViewData {
  agreementSiteSystemInformation: AgreementSiteSystemInformation;
  dutyInformations: DutyInformation[];
  callInformations: CallInformation[];
  workOrderAgreementInformations: WorkOrderAgreementInformation[];
  workOrderInformationForLists: WorkOrderInformationForList[];
  dutyCategoryId: Id;
  siteSystemInformation: SiteSystemInformation;
  siteInformation: SiteInformation;
  customerInformation: CustomerInformation;
  zoneInformation: ZoneInformation;
  customerId: Id;
  subscriptionSettings: SubscriptionSettings;
  paymentInformations: PaymentInformation[];
}

export const createEmpty = (): AgreementSiteSystemInformation => {
  return {
    id: 0,
    localId: 0,
    agreementId: 0,
    amount: 0,
    siteSystemId: 0,
    workOrderId: null,
    hidden: false,
    notes: null,
    setupStatus: AgreementSiteSystemSetupStatus.StandBy,
    createdDate: new Date(),
    endDate: null,
    deadDate: null,
    processed: false,
    processedDate: null,
    paymentGateway: null,
    subscriptionExtra: null,
  };
};
