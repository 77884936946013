import { Directive, Input, input } from '@angular/core';
import { type AbstractControl, NG_VALIDATORS, type Validator } from '@angular/forms';
import { isValid, parse } from 'date-fns';

@Directive({
  selector: '[wmMomentDateValidator]',
  standalone: true,
  providers: [{ provide: NG_VALIDATORS, useExisting: MomentDateValidatorDirective, multi: true }],
})
export class MomentDateValidatorDirective implements Validator {
  readonly format = input.required<string | string[]>({ alias: 'wmMomentDateValidator' });

  validate(control: AbstractControl): Record<string, any> | null {
    if (control.value == null || control.value === '') {
      return null;
    }

    const format = this.format();
    const formats: string[] = typeof format === 'string' ? [format] : format;
    if (formats !== null) {
      for (const format of formats) {
        if (isValid(parse(control.value, format, new Date()))) {
          return null;
        }
      }
    }

    return { dateInvalid: true };
  }
}
