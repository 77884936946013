import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  type AbstractControl,
  type ControlValueAccessor,
  type ValidationErrors,
  type Validator,
} from '@angular/forms';
import { PopoverCalendarComponent } from '@dialogviews/popover-calendar.component';
import { MomentDateValidatorDirective } from '@directives/moment-date-validator.directive';
import { DIALOG_SERVICE_IMPL } from '@models/dialog';
import { parseDateNicely } from '@utility/date';
import { format, isValid, startOfDay } from 'date-fns';
import { EMPTY, of, switchMap } from 'rxjs';
import { ErrorHelperService } from '../../services/error-helper.service';

const noop = (): void => {};

const possibleDateFormats = ['MM-dd-yyyy', 'P'];
const possibleTimeFormats = ['hh:mm a'];

let allPossibleFormats: string[] = [];
possibleDateFormats.forEach(d => {
  possibleTimeFormats.forEach(t => {
    allPossibleFormats.push(`${d} ${t}`);
  });
});

// Date or time only is possible.
allPossibleFormats = allPossibleFormats.concat(possibleDateFormats).concat(possibleTimeFormats);

// Angular 18
@Component({
  selector: 'wm-input-moment-picker',
  templateUrl: 'input-moment-picker.component.html',
  styleUrls: ['input-moment-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMomentPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputMomentPickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, MomentDateValidatorDirective],
})
export class InputMomentPickerComponent implements ControlValueAccessor, Validator {
  private readonly dialog2 = inject(DIALOG_SERVICE_IMPL);
  private readonly errorHelperService? = inject(ErrorHelperService);

  readonly helper = input<string | undefined>(undefined);

  readonly showDate = input(false);
  readonly showTime = input(false);
  readonly minDate = input<Date>();
  readonly maxDate = input<Date>();

  readonly autoComplete = input<string>();
  readonly autoCorrect = input<string>();
  readonly autoCapitalize = input<string>();
  readonly autoFocus = input(false);
  readonly disabled = input(false);
  readonly id = input('');
  readonly list = input<string>();
  readonly max = input<string | number>();
  readonly maxLength = input<number>();
  readonly min = input<string | number>();
  readonly minLength = input<number>();
  readonly placeholder = input<string>();
  readonly readOnly = input(false);
  readonly required = input(false);
  readonly spellCheck = input(false);
  readonly step = input<number>();
  readonly tabIndex = input<number>();
  readonly type = input('text');
  readonly name = input<string>();

  readonly onBlur = output<FocusEvent>({ alias: 'blur' });
  readonly onFocus = output<FocusEvent>({ alias: 'focus' });

  private readonly error = signal<string | null>(null);
  readonly helperUI = computed(() => {
    const error = this.error();
    if (error) {
      return error;
    }

    const internalHelper = this.helper();
    if (internalHelper) {
      return internalHelper;
    }

    return null;
  });

  readonly formattedValue = signal('');

  readonly inputElement = viewChild<ElementRef<HTMLInputElement>, ElementRef<HTMLInputElement>>('input', {
    read: ElementRef<HTMLInputElement>,
  });

  readonly inputModel = viewChild('input', { read: NgModel });
  readonly pickerButton = viewChild<ElementRef<HTMLElement>, ElementRef<HTMLElement>>('pickerButton', { read: ElementRef<HTMLElement> });

  // The internal data model
  readonly innerValue = signal<Date | null>(null);
  readonly valueChanges = toSignal(toObservable(this.inputModel).pipe(switchMap(inputModel => inputModel?.valueChanges ?? of())), {
    initialValue: EMPTY,
  });

  _ = effect(
    () => {
      this.formattedValue.set(this.getFormattedValue(this.innerValue()));
    },
    { allowSignalWrites: true }
  );

  __ = effect(
    () => {
      const _ = this.valueChanges();
      const errorMessage = this.errorHelperService?.getError(this.inputModel()?.errors ?? null) ?? null;
      this.error.set(errorMessage);
    },
    { allowSignalWrites: true }
  );

  // get accessor
  get value(): any {
    return this.innerValue();
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue()) {
      this.innerValue.set(v);
      this.onChangeCallback(v);
    }
  }

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    value ??= null;
    if (value !== this.innerValue()) {
      this.innerValue.set(value);
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  // From Validator interface
  validate(_control: AbstractControl): ValidationErrors | null {
    if (!this.innerValue()) {
      return { required: true };
    }

    return null;
  }

  /** Set focus on input */
  focus(): void {
    this.inputElement()?.nativeElement.focus();
  }

  handleFocus(event: FocusEvent): void {
    this.onFocus.emit(event);
  }

  handleBlur(event: FocusEvent): void {
    this.onTouchedCallback();
    this.onBlur.emit(event);
  }

  private currentDisplayFormat(): string {
    const f: string[] = [];
    if (this.showDate()) {
      f.push('M/d/yyyy');
    }

    if (this.showTime()) {
      f.push('p');
    }

    return f.join(' ');
  }

  currentFormat(): string[] {
    if (this.showDate() && this.showTime()) {
      return allPossibleFormats;
    }

    if (this.showTime()) {
      return possibleTimeFormats;
    }

    return possibleDateFormats;
  }

  handleChange(_event: Event): void {
    const formattedValue = this.inputElement()?.nativeElement.value;
    let value: Date | null = null;
    if (formattedValue) {
      for (const possibleFormat of allPossibleFormats) {
        let m = parseDateNicely(formattedValue, possibleFormat, new Date());
        if (isValid(m)) {
          if (!this.showTime()) {
            m = startOfDay(m); // We must keep it in the current timezone.
          }

          value = m;
          break;
        }
      }
    }

    this.value = value;

    this.onTouchedCallback();
  }

  getFormattedValue(value: Date | null): string {
    if (value) {
      return format(value, this.currentDisplayFormat());
    }

    return '';
  }

  togglePopoverContent(): void {
    const element = this.pickerButton()?.nativeElement;
    if (element) {
      const popoverRef = PopoverCalendarComponent.open(this.dialog2, element, {
        date: this.value,
        minDate: this.minDate(),
        maxDate: this.maxDate(),
        dateChangeFnc: (date: Date) => {
          this.value = date;
          if (!this.showTime()) {
            // If we show the time, we don't want to auto close.
            popoverRef.close();
          }
        },
        showDate: this.showDate(),
        showTime: this.showTime(),
      });
    }
  }
}
